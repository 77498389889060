import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useMetricsServiceContext } from '@src/hooks/contexts/metrics_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { forReviewMetricsPath } from '@src/routes';

import ServiceMenu from '@src/components/common/service_menu';
import ServiceSidebar from '@src/components/ui/service_sidebar';
import { Button } from '@src/components/ui_v2/buttons';
import {
  EnvelopeCustomIcon,
  IndustryMetricIcon,
  PlusIcon,
} from '@src/components/utils/icomoon';

import { useImportMetricsServiceModal } from '../modal/import_metric_service_modal';

import styles from './styles.module.scss';

const MetricsServiceSideMenu = () => {
  const business = useBusinessContext();
  const service = useMetricsServiceContext();
  const importMetricsServiceModal = useImportMetricsServiceModal();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.METRIC_REPORT_MAPPING_TEMPLATE,
  );
  return (
    <>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            service={ service }
            featureQuery={featureQuery.data}
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE }
          />
      ) }
      >
        <ServiceSidebar.Item
          href={ `/businesses/${business.id}/metrics_service` }
          icon={ <IndustryMetricIcon /> }
          title="Metrics List"
        />
        <ServiceSidebar.Item
          href={ forReviewMetricsPath(business.id) }
          icon={ <EnvelopeCustomIcon variant="brown" /> }
          title="For Review"
        />
        {featureQuery.data && (
        <Button className={ styles['custom-button'] } variant="link" onClick={ importMetricsServiceModal.open }>
          <PlusIcon fontVariant="bold" />
          {' '}
          Import Data
        </Button>
        )}
      </ServiceSidebar>
      <importMetricsServiceModal.Component
        businessId={ business.id }
        businessName={ `${business.name}` }
        serviceEmail={ `${service?.uploadEmail}` }
        standardIndustryId={ business.standardCategoryId }
        { ...importMetricsServiceModal.props }
      />
    </>
  );
};

export default MetricsServiceSideMenu;
