import React from 'react';

import Root from '@src/components/root';

import MyTeamContainer from './components/my_team_container';

interface IMyTeamPageProps {
  showAddMemberModal: () => void;
}

const MyTeamPage: React.FC<IMyTeamPageProps> = ({ showAddMemberModal }) => {
  return (
    <Root>
      <MyTeamContainer showAddMemberModal={ showAddMemberModal } />
    </Root>
  );
};

export default MyTeamPage;
