import React, { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useGetBusinessQuery, useGetBusinessesForPortfolio } from '@src/hooks/queries/businesses';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import { useCreatePortfolioModal } from '@src/components/business_reports/custom_reports/modals/create_portfolio';
import { useEditManagementGroupModal } from '@src/components/management_groups/modals/edit_management_group_modal';

import BusinessView from './business_view';
import ClientView from './client_view';
import { UnifiedHeaderDropdown } from './unified_header_dropdown';

import styles from './styles.module.scss';

interface IDetailsProps {
  focusToMyClient: boolean,
}

const Details = ({
  focusToMyClient,
}: IDetailsProps) => {
  const isUnifiedHeaderDropDownFeatureFlagEnabled = useUserFeatureFlag(
    window.Docyt.Common.Constants.UNIFIED_HEADER_DROP_DOWN,
  );
  const createPortfolioModal = useCreatePortfolioModal();
  // Setup the management group create modal
  const createManagementGroupModal = useEditManagementGroupModal();
  const location = useLocation();
  let currentBusinessId: string | undefined;

  const isClientView = window.Docyt.currentAdvisor.isBookgptAccountantUser();

  if (focusToMyClient) {
    currentBusinessId = location.pathname.split('/')[3] === 'clients' ? location.pathname.split('/')[4] : location.pathname.split('/')[2];
  } else {
    currentBusinessId = location.pathname.split('/')[1] === 'businesses' ? location.pathname.split('/')[2] : undefined;
  }

  const portfolioBusinessesQuery = useGetBusinessesForPortfolio({
    forDataExport: true,
    perPage:       100,
  });

  const businesses = useMemo(() => {
    const businessesPages = portfolioBusinessesQuery.data?.collection || [];
    return businessesPages;
  }, [portfolioBusinessesQuery.data?.collection]);

  const businessQuery = useGetBusinessQuery(Number(currentBusinessId));
  const currentBusiness = useMemo(() => {
    return businessQuery.data;
  }, [businessQuery.data]);

  const renderContent = () => {
    if (typeof isUnifiedHeaderDropDownFeatureFlagEnabled === 'undefined') {
      return null;
    }

    if (isUnifiedHeaderDropDownFeatureFlagEnabled) {
      return (
        <div className={ styles['business-client-items-view'] }>
          <div className="global-horizontal-divider" />
          <UnifiedHeaderDropdown
            openCreateManagementGroupModal={ createManagementGroupModal.open }
            openCreatePortfolioModal={ createPortfolioModal.open }
          />
        </div>
      );
    }

    if (focusToMyClient || isClientView) {
      return <ClientView currentClientBusiness={ currentBusiness } />;
    }

    return <BusinessView currentBusiness={ currentBusiness } />;
  };

  return (
    <div>
      <createManagementGroupModal.Component
        { ...createManagementGroupModal.props }
      />
      <createPortfolioModal.Component
        { ...createPortfolioModal.props }
        businesses={ businesses }
      />
      {renderContent()}
    </div>
  );
};

export default Details;
