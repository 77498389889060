import React from 'react';

import moment from 'moment';

import { taskDetailsViewPath } from '@src/routes';
import { ITask } from '@src/types/task_notification';
import { getDateGap } from '@src/utils/date_helpers';

import { AppLink } from '@src/components/ui_v2/buttons';

import TaskNotificationMessage from './task_notification_message';
import { TaskNotificationType } from './types';

import styles from './styles.module.scss';

interface ITaskNotificationItemProps {
    task: ITask;
  }
const TaskNotificationItem: React.FC<ITaskNotificationItemProps> = ({ task }) => {
  return (
    <AppLink
      href={ taskDetailsViewPath(task.taskId) }
    >
      <div
        key={ task.externalId }
        className={ styles['task-header'] }
      >
        <div className={ styles['task-item-list'] }>
          <div className={ styles['notification-message'] }>
            <div className={ styles['notification-text'] }>
              <TaskNotificationMessage message={ task.message as TaskNotificationType } taskId={ task.taskId } />
            </div>
          </div>
          <div className={ styles['task-update'] }>
            {getDateGap({ date:       task.createdAt,
              endDate:    moment.now(),
              suffixText: 'ago' })?.gap}
          </div>
        </div>
      </div>
    </AppLink>
  );
};

export default TaskNotificationItem;
