module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var business, i, j, len, len1, ref, ref1;
    
      __out.push('<div class="p-b-50 text-center dashboard-accounting-container">\n  <div class="m-t-20 text-center dashboard-updated-panel">\n    <p class="dashboard-updates-panel refresh-view-panel pointer">\n      <span>Dashboard has been updated. Click here to reload</span>\n    </p>\n  </div>\n  ');
    
      ref = this.invited_businesses;
      for (i = 0, len = ref.length; i < len; i++) {
        business = ref[i];
        __out.push('\n    <div class="clearfix font-semi-bold m-t-10 in-white padding-10 invited-business-wrapper accounting-tab">\n      <div class="display-flex">\n        <div class="font-14">Invite to join ');
        __out.push(__sanitize(business.get('display_name')));
        __out.push('</div>\n        <div class="m-l-auto pointer view-invitation-js" data-value="');
        __out.push(__sanitize(business.get('invitation_id')));
        __out.push('">View</div>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n  <div class="m-t-20">\n    <div class="display-table width-100-percent text-left">\n      <div class="display-table-row header-row pointer">\n        <div class="dashboard-table-column width-10-percent avatar-column"></div>\n        <div class="dashboard-table-column width-30-percent">Business</div>\n        <div class="dashboard-table-column width-15-percent">Inbox</div>\n        <div class="dashboard-table-column width-15-percent">Accounts Payable</div>\n        <div class="dashboard-table-column width-15-percent">Receipts</div>\n        <div class="dashboard-table-column width-15-percent">Transactions</div>\n      </div>\n      ');
    
      ref1 = this.dashboard_businesses;
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        business = ref1[j];
        __out.push('\n        <div class="display-table-row pointer">\n          <div class="dashboard-table-column width-10-percent avatar-column">\n            <a class="display-flex middle-avatar-wrapper m-l-auto m-r-auto border-gray" href="/businesses/');
        __out.push(__sanitize(business.business_id));
        __out.push('">\n              ');
        if (business.image_url) {
          __out.push('\n              <img src="');
          __out.push(__sanitize(business.image_url));
          __out.push('">\n              ');
        } else {
          __out.push('\n              <span class=\'icon-business-default-avatar font-48\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span><span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span><span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span><span class=\'path13\'></span><span class=\'path14\'></span></span>\n              ');
        }
        __out.push('\n            </a>\n          </div>\n          <div class="dashboard-table-column width-30-percent">\n            <div class="client-name-column">\n              <div class="flex-full-width dashboard-business-name-wrapper">\n                <div>\n                  <a class="font-20" href="/businesses/');
        __out.push(__sanitize(business.business_id));
        __out.push('">');
        __out.push(__sanitize(business.display_name || business.legal_name));
        __out.push('</a>\n                  <br>\n                  <a class="font-14 in-grey-1100" href="/businesses/');
        __out.push(__sanitize(business.business_id));
        __out.push('">');
        __out.push(__sanitize(business.legal_name));
        __out.push('</a>\n                </div>\n                ');
        if (business.bookkeeping_status_blocked) {
          __out.push('\n                <a class="blocked-status-badge" href="/client_central/');
          __out.push(__sanitize(business.business_id));
          __out.push('">BLOCKED</a>\n                ');
        }
        __out.push('\n                ');
        if (business.needs_update_billing) {
          __out.push('\n                <a class="update-billing-badge" href="/setup-business/');
          __out.push(__sanitize(business.business_id));
          __out.push('?step=8">Update Billing</a>\n                ');
        }
        __out.push('\n              </div>\n              ');
        if (!Docyt.Utils.isDashboardBusinessSetupCompleted(business)) {
          __out.push('\n              ');
          if (business.is_business_partner) {
            __out.push('\n              <a href="/setup-business/');
            __out.push(__sanitize(business.business_id));
            __out.push('">\n                <button class="btn btn-blue">Finish Setup</button>\n              </a>\n              ');
          }
          __out.push('\n              ');
        }
        __out.push('\n            </div>\n          </div>\n          <div class="dashboard-table-column width-15-percent">\n            ');
        if (business.inbox_status) {
          __out.push('\n              <a class="dashboard-default-link" href="/businesses/');
          __out.push(__sanitize(business.business_id));
          __out.push('/business_inbox">\n                ');
          if (business.inbox_status.unread_docs > 0) {
            __out.push('\n                <span class="in-blue-900 font-16">\n                  ');
            __out.push(__sanitize(business.inbox_status.unread_docs));
            __out.push('\n                  ');
            __out.push(__sanitize(business.inbox_status.unread_docs === 1 ? 'Doc' : 'Docs'));
            __out.push('\n                </span>\n                ');
          } else {
            __out.push('\n                -\n                ');
          }
          __out.push('\n              </a>\n            ');
        } else {
          __out.push('\n              -\n            ');
        }
        __out.push('\n          </div>\n          <div class="dashboard-table-column width-15-percent">\n            ');
        if (business.accounts_payable_status) {
          __out.push('\n              <div class="m-b-0 line-height-20 status-box">\n                <div>\n                <a class="dashboard-default-link invoice" href="/businesses/');
          __out.push(__sanitize(business.business_id));
          __out.push('/accounts_payable/queue?type=queued">\n                  ');
          if (business.accounts_payable_status.count_queued > 0) {
            __out.push('\n                  <span class="in-blue-900 font-16">\n                    ');
            __out.push(__sanitize(business.accounts_payable_status.count_queued));
            __out.push('\n                    ');
            __out.push(__sanitize(business.accounts_payable_status.count_queued === 1 ? 'Invoice' : 'Invoices'));
            __out.push('\n                  </span>\n                  <br>\n                  ');
          } else {
            __out.push('\n                  -\n                  ');
          }
          __out.push('\n                </a>\n                ');
          if (business.accounts_payable_status.count_queued > 0) {
            __out.push('\n                <div class="m-t-5 accounts-payable-dashboard-badge-wrapper" data-toggle="tooltip" data-html="true" data-placement="bottom" title="');
            __out.push(__sanitize(business.accounts_payable_tooltip));
            __out.push('">\n                  ');
            if (business.accounts_payable_status.count_unverified > 0) {
              __out.push('\n                  <div class="accounts-payable-dashboard-badge unverified-counts-badge">\n                    <div class="icon icon-unverified font-10 m-t-3">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n                    </div>\n                    <span class="badge-counter">');
              __out.push(__sanitize(business.accounts_payable_status.count_unverified));
              __out.push('</span>\n                  </div>\n                  ');
            }
            __out.push('\n                  ');
            if (business.accounts_payable_status.count_approval > 0) {
              __out.push('\n                  <div class="accounts-payable-dashboard-badge unapproved-counts-badge">\n                    <div class="icon icon-unapproved font-10 m-t-3">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n                    </div>\n                    <span class="badge-counter">');
              __out.push(__sanitize(business.accounts_payable_status.count_approval));
              __out.push('</span>\n                  </div>\n                  ');
            }
            __out.push('\n                  ');
            if (business.accounts_payable_status.count_ready_for_pay > 0) {
              __out.push('\n                  <div class="accounts-payable-dashboard-badge unpaid-counts-badge">\n                    <div class="icon icon-unpaid font-10 m-t-3">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span>\n                    </div>\n                    <span class="badge-counter">');
              __out.push(__sanitize(business.accounts_payable_status.count_ready_for_pay));
              __out.push('</span>\n                  </div>\n                  ');
            }
            __out.push('\n                </div>\n                ');
          }
          __out.push('\n                </div>\n                ');
          if (business.accounts_payable_status.count_qbo_errors) {
            __out.push('\n                  <span class="error-count">');
            __out.push(__sanitize(business.accounts_payable_status.count_qbo_errors));
            __out.push('</span>\n                ');
          }
          __out.push('\n              </div>\n            ');
        } else {
          __out.push('\n              -\n            ');
        }
        __out.push('\n          </div>\n          <div class="dashboard-table-column width-15-percent">\n            ');
        if (business.receipt_box_status) {
          __out.push('\n            <div class="status-box">\n            <p class="m-b-0 line-height-20">\n              <a class="dashboard-default-link receipt" href="/businesses/');
          __out.push(__sanitize(business.business_id));
          __out.push('/receipt_box/queue?type=unverified">\n                ');
          if (business.receipt_box_status.count_queued > 0) {
            __out.push('\n                <span class="in-blue-900 font-16">');
            __out.push(__sanitize(business.receipt_box_status.count_queued));
            __out.push(' Unverified</span><br>\n                ');
          } else {
            __out.push('\n                -\n                ');
          }
          __out.push('\n              </a>\n            </p>\n            ');
          if (business.receipt_box_status.count_qbo_errors) {
            __out.push('\n              <span class="error-count">');
            __out.push(__sanitize(business.receipt_box_status.count_qbo_errors));
            __out.push('</span>\n            ');
          }
          __out.push('\n            </div>\n            ');
        } else {
          __out.push('\n              -\n            ');
        }
        __out.push('\n          </div>\n          <div class="dashboard-table-column width-15-percent">\n            <p class="m-b-0 line-height-20">\n              ');
        if (business.reconciliation_status) {
          __out.push('\n                ');
          if (business.reconciliation_status.count_flagged > 0) {
            __out.push('\n                  <a class="btn btn-blue" href="/businesses/');
            __out.push(__sanitize(business.business_id));
            __out.push('/open_items/flagged_transactions">\n                    View Open Issues\n                  </a>\n                ');
          } else {
            __out.push('\n                  <div class="status-box">\n                  <a class="dashboard-default-link transaction" href="/businesses/');
            __out.push(__sanitize(business.business_id));
            __out.push('/reconciliation_center">\n                  ');
            if (business.reconciliation_status.count_queued > 0) {
              __out.push('\n                  <span class="in-blue-900 font-16">');
              __out.push(__sanitize(business.reconciliation_status.count_queued));
              __out.push(' Transactions</span><br>\n                  ');
            } else {
              __out.push('\n                  -\n                  ');
            }
            __out.push('\n                  </a>\n                  ');
            if (business.reconciliation_status.count_qbo_errors) {
              __out.push('\n                    <span class="error-count">');
              __out.push(__sanitize(business.reconciliation_status.count_qbo_errors));
              __out.push('</span>\n                  ');
            }
            __out.push('\n                  </div>\n                ');
          }
          __out.push('\n              ');
        } else {
          __out.push('\n                ');
          if (business.is_business_partner) {
            __out.push('\n                <a class="btn btn-blue" href="/businesses/');
            __out.push(__sanitize(business.business_id));
            __out.push('/reconciliation_center/activate">\n                  Activate\n                </a>\n                ');
          } else {
            __out.push('\n                N/A\n                ');
          }
          __out.push('\n              ');
        }
        __out.push('\n            </p>\n          </div>\n          <div class="dashboard-table-column relative">\n            ');
        if (business.activated_employee_management) {
          __out.push('\n            <button class="pointer dropdown-toggle action-menu-toggle-btn m-r-10" type="button" id="business-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n              <i class=\'icon icon-3-dots font-20\'></i>\n            </button>\n            <div class="business-management-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="business-action-dropdown-btn">\n              <a href="/setup-business/');
          __out.push(__sanitize(business.business_id));
          __out.push('" class="dropdown-item">\n                <i class=\'icon icon-setup font-24\'></i>\n                <span class="font-16 m-l-15">Business Setup</span>\n              </a>\n            </div>\n            ');
        }
        __out.push('\n          </div>\n        </div>\n      ');
      }
    
      __out.push('\n    </div>\n  </div>\n  ');
    
      if (this.isAdminInAnyBusiness && this.isAccountantUser && !this.isBusinessUser && !this.isDocytSupport) {
        __out.push('\n    <div class="text-center m-t-20">\n      <a class="pointer add-business-btn font-18"><i class="icon icon-plus m-r-10"></i> Add another business</a>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}