import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IFlaggedTransactionPredictedEventFayeEvent } from '@src/types/docyt_events/flagged_transaction_predicted';

import { useFayeChannel } from './common';

export const useFlaggedTransactionPredictedFayeChannel = (
  txnID: TID,
  businessId: TID,
  callback: TFayeEventCallback<IFlaggedTransactionPredictedEventFayeEvent>,
) => {
  useFayeChannel(
    `/flagged_transaction_predicted-${businessId}-${txnID}`,
    callback,
  );
};
