import React, { useState, useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';

import Form from '@src/components/ui_v2/form';

import { blockTaskStatusValidation, IBlockTaskStatusValuesProps } from './helper/validations';

import styles from './styles.module.scss';

interface ITaskNotesProps{
  value: string,
  taskId: string,
  disabled?: boolean
}

const TaskNotes = ({ value, taskId, disabled = false }: ITaskNotesProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>(value);
  const updateNotes = useUpdateTaskServiceDocument();
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<IBlockTaskStatusValuesProps>({
    defaultValues: { notes: value },
    resolver:      yupResolver(blockTaskStatusValidation),
  });

  const handleUpdateNotes = useCallback(({ formData, id }) => {
    if (formData && id) {
      updateNotes.mutate(
        { formData, taskId },
        { onSuccess: () => {
          toastr.success(
            'Notes Updated Successfully',
            'Update Task Notes',
          );
        },
        onError: (error: { message: string; }) => {
          toastr.error(
            error.message,
            'Error Notes Update',
          );
          setCurrentValue(value);
        } },
      );
    }
  }, [taskId, value, updateNotes]);

  const saveChanges = useCallback(
    async (notes: string) => {
      if (notes.trim() !== value.trim()) {
        handleUpdateNotes({ formData: { notes }, id: taskId });
        setCurrentValue(notes);
      }
      setIsEditing(false);
    },
    [handleUpdateNotes, taskId, value],
  );

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue('notes', newValue);

    const isValid = await trigger('notes');
    if (isValid) {
      saveChanges(newValue);
    }
  };

  const submitHandler = handleSubmit((bodyData) => {
    saveChanges(bodyData.notes);
  });

  const handleNotes = useCallback(() => {
    if (!isEditing && !disabled) {
      setIsEditing(true);
    }
  }, [isEditing, disabled]);

  const classes = classNames({
    [styles['task-notes-disabled']]: disabled,
    [styles['task-notes-pointer']]:  !disabled,
  });

  return (
    <div
      className={ classes }
      role="cell"
      onClick={ handleNotes }
    >
      {isEditing ? (
        <Form onSubmit={ submitHandler }>
          <Form.TextField
            autoFocus
            hiddenLabel
            hideClear
            autoComplete="off"
            className={ styles['status-notes-input'] }
            disabled={ disabled }
            error={ errors.notes?.message }
            label=""
            { ...register('notes', {
              onBlur: handleBlur,
            }) }
          />
        </Form>
      ) : (
        <span className={ styles['task-notes'] }>{currentValue ?? ''}</span>
      )}
    </div>
  );
};

export default TaskNotes;
