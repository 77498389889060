import React, { useCallback } from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IPracticeInformation } from '@src/types/practice_informations';

import Dropdown from '@src/components/ui/dropdown';
import { ViewClientCentralIcon } from '@src/components/utils/icomoon';

interface IMyPracticeViewInClientCentralActionProps {
  information: IPracticeInformation,
}

const MyPracticeViewInClientCentralAction = ({
  information,
}: IMyPracticeViewInClientCentralActionProps) => {
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  const handleSelect = useCallback(() => {
    if (sunsetClientCentralEnabled) {
      window.open(`/businesses/${information.id}/open_items/document_requests`, '_blank');
    } else {
      window.open(`/client_central/${information.id}`, '_blank');
    }
  }, [information.id, sunsetClientCentralEnabled]);

  return (
    <Dropdown.Item className="display-flex-important align-items-center" onSelect={ handleSelect }>
      <ViewClientCentralIcon fontSize={ 16 } mr={ 22 } />
      { ' ' }
      View in Client Central
    </Dropdown.Item>
  );
};

export default MyPracticeViewInClientCentralAction;
