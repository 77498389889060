import React from 'react';

import classNames from 'classnames';

import { Button } from '@src/components/ui_v2/buttons';
import { RemoveIcon } from '@src/components/utils/fa_icons';
import DownTriangeIcon from '@src/components/utils/icomoon/down_triange';

import styles from './styles.module.scss';

interface IFilterLabelProps{
    label:string;
    suffixLabel?:string;
    selectedItems?:string[];
    onRemoveClick:()=>void;
    isAllSelected?:boolean;
}
const FilterLabel = ({ label, suffixLabel = 'selected', selectedItems,
  onRemoveClick, isAllSelected = false }:IFilterLabelProps) => {
  const classes = classNames(styles['filter-label'], {
    [styles.selected]: selectedItems?.length,
  });
  return (
    <div className={ classes }>
      {selectedItems?.length || isAllSelected ? (
        <div className={ styles['selected-items'] }>
          {isAllSelected ? null : selectedItems?.length}
          {' '}
          {suffixLabel}
          <Button
            className={ styles['close-icon'] }
            type="button"
            variant="link"
            onClick={ onRemoveClick }
          >
            <RemoveIcon inColor="grey-1050" />
          </Button>
        </div>
      ) : (
        <span>{label}</span>
      )}
      <DownTriangeIcon className={ styles['dropdown-icon'] } fontSize={ 5 } />
    </div>
  );
};

export default FilterLabel;
