import React, { useCallback, useMemo, useState } from 'react';

import { useGetUsersBusinesses } from '@src/hooks/queries/businesses';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useLocalStorage } from '@src/hooks/storage';
import { IUsersBusiness } from '@src/types/businesses';
import { navigateHome } from '@src/utils/navigate';

import { TIconOption } from '@src/components/ui_v2/inputs/types';
import { SearchDropdownInput } from '@src/components/ui_v2/search_dropdown';
import { BusinessDefaultAvatarIcon } from '@src/components/utils/icomoon';

import NewPortfolioDropDownList from './new_portfolio_dropdown_option';
import PortfolioDropDownList from './portfolio_dropdown_list';
import { navigateBusinessByIntent } from '../utils/business_navigation';

import styles from './styles.module.scss';

const businessAvatar = (
  business: IUsersBusiness,
) => {
  if (business.imageUrl === null) {
    return <BusinessDefaultAvatarIcon />;
  }

  return (
    <img
      alt="Avatar"
      className={ styles['business-avatar'] }
      src={ business.imageUrl }
    />
  );
};

const defaultOption: TIconOption = {
  label: 'All Businesses',
  icon:  <BusinessDefaultAvatarIcon />,
  value: 'all',
};

const businessOption = (
  business: IUsersBusiness,
): TIconOption => {
  return {
    label: business.displayName,
    value: String(business.id),
    icon:  businessAvatar(business),
  };
};

const businessesToOptions = (
  businesses: IUsersBusiness[],
) => {
  const options: TIconOption[] = [];
  options.push(defaultOption);

  businesses.forEach((business) => {
    options.push(businessOption(business));
  });

  return options;
};

interface IBusinessViewProps {
  currentBusiness?: IUsersBusiness,
}

const BusinessView = ({
  currentBusiness,
}: IBusinessViewProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [resetPortfolioKey, setResetPortfolioKey] = useState<number>(0);
  const { setValue: setSelectedPortfolio } = useLocalStorage('selectedPortfolio', {});

  const query = useGetUsersBusinesses({
    view:         'business',
    businessName: searchValue,
    pageSize:     100,
    column:       'name',
  });

  const businesses = useMemo(() => {
    const businessesPages = query.data?.collection || [];
    return businessesPages;
  }, [query.data?.collection]);

  const businessesOptions = useMemo(() => {
    if (!businesses) return [];

    return businessesToOptions(businesses);
  }, [businesses]);

  const currentOption = useMemo(() => {
    if (!currentBusiness) return undefined;

    return businessOption(currentBusiness);
  }, [currentBusiness]);

  const handleSelect = useCallback((selectedOption: TIconOption) => {
    if (!selectedOption.value) return;

    if (selectedOption.value === 'all') {
      navigateHome(false);
      return;
    }

    const newBusinessId = selectedOption.value;
    const newBusiness = businesses?.find((business) => business.id === Number(newBusinessId));
    setSelectedPortfolio({});
    setResetPortfolioKey((prev) => prev + 1);
    navigateBusinessByIntent(
      newBusiness?.businessIntent,
      newBusinessId,
      null,
      true,
      currentBusiness,
      newBusiness,
    );
  }, [businesses, currentBusiness, setSelectedPortfolio, setResetPortfolioKey]);

  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
  );

  const isGlobalNavigationEnabled: boolean = !!globalNavigationUserEnabled;
  const dropdownKey = `business-dropdown-${resetPortfolioKey}`;

  return (
    <div className={ styles['business-client-items-view'] }>
      <div className="global-horizontal-divider" />
      {isGlobalNavigationEnabled ? (
        <NewPortfolioDropDownList
          key={ dropdownKey }
          launchDarklyFeature={ window.Docyt.Common.Constants.KPI_PORTFOLIO_DASHBOARD }
        />
      ) : (
        <PortfolioDropDownList
          key={ dropdownKey }
          launchDarklyFeature={ window.Docyt.Common.Constants.KPI_PORTFOLIO_DASHBOARD }
        />
      )}
      <SearchDropdownInput
        className={ styles['business-dropdown-input'] }
        openOnHover={ isGlobalNavigationEnabled }
        options={ businessesOptions }
        placeholder={ defaultOption }
        searchPlaceholder="Search your businesses"
        searchValue={ searchValue }
        title={
          isGlobalNavigationEnabled ? window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.ALL_BUSINESSES : ''
        }
        value={ currentOption || null }
        onSearch={ setSearchValue }
        onSelect={ handleSelect }
      />
    </div>
  );
};

export default BusinessView;
