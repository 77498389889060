import React, { useCallback } from 'react';

import { useLDManagementGroupFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useLocalStorage } from '@src/hooks/storage';
import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { FinancialInstitutionAvatar } from '@src/components/ui/avatars';
import Table from '@src/components/ui/table';
import { CaretIcon } from '@src/components/utils/fa_icons';

import Actions from './actions/actions';
import SubItem from './financial_institution_connection_sub_item';

import styles from '../styles.module.scss';

interface IFinancialInstitutionConnectionCenterTableItemProps {
  financialInstitutionConnection: IFinancialInstitutionConnection,
  openFinancialBankAccountMapping: IFinancialInstitutionBankAccount | null,
  refetchFinancialConnectionList: () => void,
  handleClickForMapFICAccount: (
    financialAccount: IFinancialInstitutionBankAccount,
    financialInstitutionConnection: IFinancialInstitutionConnection
  ) => void,
  isUnifiedHeaderDropDownFeatureFlagEnabled: boolean | string | undefined,
}

const FinancialInstitutionConnectionCenterTableItem = ({
  financialInstitutionConnection,
  openFinancialBankAccountMapping,
  refetchFinancialConnectionList,
  handleClickForMapFICAccount,
  isUnifiedHeaderDropDownFeatureFlagEnabled = false,
}: IFinancialInstitutionConnectionCenterTableItemProps) => {
  const expand = useLocalStorage(`financial_institution_${financialInstitutionConnection.id}`, true);
  const employeeCardFeatureQuery = useLDManagementGroupFeatureQuery(
    financialInstitutionConnection.managementGroup.id,
    window.Docyt.Common.Constants.EMPLOYEE_CARDS_MULTI_ENTITY_SUPPORT,
  );

  const employeeCardFeatureFlag = financialInstitutionConnection.aggregator === 'plaid'
    ? !!employeeCardFeatureQuery.data
    : false;

  const handleExpand = useCallback(() => {
    expand.setValue(!expand.storedValue);
  }, [expand]);

  const AGGREGATOR_NAME: Record<string, string> = {
    plaid:  'Plaid',
    quiltt: 'MX',
  };

  return (
    <>
      <Table.Row>
        <Table.Cell className={ styles['financial-institution-name-section'] } onClick={ handleExpand }>
          <div className="display-flex align-items-center">
            {
              expand.storedValue ? <CaretIcon ml={ 10 } mr={ 10 } variant="down" />
                : <CaretIcon ml={ 10 } mr={ 10 } variant="up" />
            }
            <FinancialInstitutionAvatar
              logo={ financialInstitutionConnection.financialInstitution.logo }
            />
            <div className="m-l-10">
              <span>{ financialInstitutionConnection.financialInstitution.name }</span>
              <div className="aggregator-name">
                {financialInstitutionConnection.aggregator
                  ? AGGREGATOR_NAME[financialInstitutionConnection.aggregator]
                  : AGGREGATOR_NAME.plaid}
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell />
        {!isUnifiedHeaderDropDownFeatureFlagEnabled && (
          <Table.Cell>
            { financialInstitutionConnection.managementGroup.name }
          </Table.Cell>
        )}
        <Table.Cell />
        <Table.Cell>
          {
            financialInstitutionConnection.isActive
              ? (<span className={ styles['connected-banner'] }>Connected</span>)
              : (<span className={ styles['disconnected-banner'] }>Disconnected</span>)
          }
        </Table.Cell>
        <Table.Cell className={ styles['actions-column'] }>
          <Actions financialInstitutionConnection={ financialInstitutionConnection } />
        </Table.Cell>
      </Table.Row>
      {
        expand.storedValue && financialInstitutionConnection.financialInstitutionBankAccounts
          .map((financialInstitutionAccount) => (
            <SubItem
              key={ financialInstitutionAccount.id }
              employeeCardFeatureFlag={ !!employeeCardFeatureFlag }
              financialInstitutionAccount={ financialInstitutionAccount }
              handleClickForSetupAccount={ () => handleClickForMapFICAccount(financialInstitutionAccount, financialInstitutionConnection) }
              isUnifiedHeaderDropDownFeatureFlagEnabled={ isUnifiedHeaderDropDownFeatureFlagEnabled }
              managementGroup={ financialInstitutionConnection.managementGroup }
              openFinancialBankAccountMapping={ openFinancialBankAccountMapping }
              refetchFinancialConnectionList={ refetchFinancialConnectionList }
            />
          ))
      }
    </>
  );
};

export default React.memo(FinancialInstitutionConnectionCenterTableItem);
