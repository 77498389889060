import React, { useMemo } from 'react';

import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { useEditClientModal } from '@src/components/settings/my_team/components/modal/edit_client_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditBusinessIcon, EditClientIcon } from '@src/components/utils/icomoon';

interface IViewProfileActionProps {
  member: IBusinessTeamMember;
  isAccountant: boolean;
}

const EditClientOrBusinessAction: React.FC<IViewProfileActionProps> = ({ isAccountant, member }) => {
  const {
    Component: EditClientComponent,
    props: editClientModalProps,
    open: openEditClientModal,
  } = useEditClientModal();
  const icon = useMemo(() => {
    return isAccountant ? <EditClientIcon fontSize={ 16 } /> : <EditBusinessIcon fontSize={ 16 } />;
  }, [isAccountant]);

  const title = useMemo(() => {
    return isAccountant ? 'Edit Clients' : 'Edit Businesses';
  }, [isAccountant]);

  return (
    <>
      <EditClientComponent
        { ...editClientModalProps }
        member={ member }
      />
      <ActionsDropdown.Action
        icon={ icon }
        title={ title }
        onClick={ openEditClientModal }
      />
    </>
  );
};

export default EditClientOrBusinessAction;
