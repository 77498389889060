import * as yup from 'yup';

export interface IManagementGroupInput {
  name: string;
  intercompanyEnabled: boolean;
  users?: number[];
  managementGroupUsers?: number[];
  userChanges?: {
    added: number[];
    removed: number[];
  };
  addedUsers: string[];
  removedUsers: string[];
}

export const managementGroupValidation = yup.object().shape({
  name:                yup.string().required('Name is required'),
  intercompanyEnabled: yup.boolean(),
  users:               yup.array().of(yup.number()),
});
