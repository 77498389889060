/* eslint-disable import/order */
import React, { memo, useMemo } from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui/dropdown';
import { TOption } from '@src/components/ui_v2/inputs';
import { CaretIcon } from '@src/components/utils/fa_icons';

import { defaultStatusOptions, statusLabels } from './helper/constants';
import { StatusKey } from '../types';

import styles from './styles.module.scss';

interface ITaskStatusSelectProps {
  value:StatusKey,
  onChange?:(option:TOption)=>void, statusOptions?:TOption[];
  className?:string;
  menuClassName?:string;
  disabled?:boolean;
  hasBackgroundColor?:boolean;
}

const statusStyleMap = {
  'Pending':     styles['task-status-select-label-red'],
  'In Progress': styles['task-status-select-label-green'],
} as const;

const TaskStatusSelect = ({ value,
  onChange,
  statusOptions,
  className,
  hasBackgroundColor,
  disabled = false,
  menuClassName }
  :ITaskStatusSelectProps) => {
  const selectedOptionLabel = useMemo(() => statusLabels[value as StatusKey], [value]);
  const handleOptionSelect = (option:TOption) => {
    if (onChange) {
      onChange(option);
    }
  };

  const options = useMemo(
    () => (statusOptions ?? defaultStatusOptions).filter((item) => item.label !== selectedOptionLabel),
    [statusOptions, selectedOptionLabel],
  );

  const mainClass = classNames(
    'form-control',
    styles['task-status-select-container'],
    styles['with-bgcolor'],
    {
      [styles.disabled]: disabled && hasBackgroundColor,
    },
    className,
  );

  const menuClasses = classNames(styles['task-status-select-menu'], menuClassName);

  const classes = classNames(styles['dropdown-cursor-disabled']);
  return (
    <Dropdown
      className={ disabled ? classes : styles['dropdown-cursor-pointer'] }
    >
      <Dropdown.LinkToggle noButton className="amount-select-menu-btn" disabled={ disabled }>
        <div className={ mainClass }>
          <span className={ statusStyleMap[selectedOptionLabel as keyof typeof statusStyleMap] || '' }>
            { selectedOptionLabel }
          </span>
          <CaretIcon fontSize={ 14 } variant="down" />
        </div>
      </Dropdown.LinkToggle>
      <Dropdown.Menu pointer alignment="left" className={ menuClasses } fontSize={ 12 }>
        {
        options.map((option) => {
          return (
            <Dropdown.Item
              key={ option.value }
              defaultValue={ value }
              onSelect={ () => handleOptionSelect(option) }
            >
              { option.label }
            </Dropdown.Item>
          );
        })
      }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default memo(TaskStatusSelect);
