import React, { useEffect, useRef } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useInfiniteRegionScroll } from '@src/hooks/scroll';
import { IGetTaskNotificationResponse } from '@src/requests/task_notification';
import { taskNotificationsStatusPath } from '@src/routes';
import { ITask } from '@src/types/task_notification';

import { TASK_TABS } from '@src/components/task_management/components/helper/constants';
import Text from '@src/components/ui/text';
import { AppLink } from '@src/components/ui_v2/buttons';
import LocalQueryStatus from '@src/components/utils/local_query_status';

import { useGetTaskNotificationTotalCount } from './hooks';
import TaskNotificationItem from './task_notification_item';

import styles from './styles.module.scss';

interface ITaskNotificationProps {
  query: UseInfiniteQueryResult<IGetTaskNotificationResponse, Error>;
  records:ITask[];
  toggle:boolean;
  setToggle:(val:boolean)=>void;
}
const EMPTY_STATE_MESSAGE = 'No Notifications Found';

const TaskNotificationItemList = ({ query, records, toggle, setToggle } : ITaskNotificationProps) => {
  const collection = useGetTaskNotificationTotalCount();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useInfiniteRegionScroll({
    elementRef: wrapperRef,
    query,
  });

  useEffect(() => {
    if (toggle) {
      collection.query.refetch();
      query.refetch();
      setToggle(false);
    }
    return () => {
      setToggle(false);
    };
  }, [collection.query, setToggle, toggle, query]);

  return (
    <div>
      <div
        ref={ wrapperRef }
        className={ styles['task-item-list-container'] }
      >
        {records.length > 0
          ? (records.map((data) => (
            <TaskNotificationItem
              key={ data.externalId }
              task={ data }
            />
          ))
          ) : (
            <div
              aria-live="polite"
              className={ styles['no-records'] }
              role="status"
            >
              {EMPTY_STATE_MESSAGE}
            </div>
          )}
        <div className={ styles['local-query-status-spinner'] }>
          <LocalQueryStatus query={ query } />
        </div>
      </div>
      <AppLink
        className={ styles['task-footer'] }
        href={ taskNotificationsStatusPath(TASK_TABS.OPEN.toLocaleLowerCase()) }
      >
        <Text as="div" fontSize={ 14 }>
          Open Task
          {' '}
          (
          {collection?.totalCount || 0}
          )
        </Text>
      </AppLink>
    </div>
  );
};

export default TaskNotificationItemList;
