import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  createTransactionMatch,
  ICreateTransactionMatchParams,
  ICreateTransactionMatchResponse,
  postMarkAsCleared,
} from '@src/requests/transaction_matches';

const useCreateTransactionMatch = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateTransactionMatchResponse, Error, ICreateTransactionMatchParams>(
    createTransactionMatch,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.combinedServiceDocumentsQuery);
      },
    },
  );
};

const useMarkAsCleared = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateTransactionMatchResponse, Error, ICreateTransactionMatchParams>(
    postMarkAsCleared,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.combinedServiceDocumentsQuery);
      },
    },
  );
};

export {
  useCreateTransactionMatch,
  useMarkAsCleared,
};
