import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getVendorsServiceByBusinessId,
  getVendorService,
  getDuplicateVendors,
  IGetDuplicateVendorsResponse,
} from '@src/requests/vendors_service';
import { IVendorsService, IBussinessVendorService } from '@src/types/services';

const useGetVendorsServiceByBusinessIdQuery = (businessId: number) => {
  return useQuery<IVendorsService, Error>(
    [QueryKey.vendorService, businessId],
    () => getVendorsServiceByBusinessId(businessId),
    {
      staleTime: Infinity,
      enabled:   !!businessId,
    },
  );
};

const useVendorService = (params: { role: 'admin' }) => {
  return useQuery<IBussinessVendorService, Error>(
    [QueryKey.vendorService, params],
    () => getVendorService(params),
  );
};

const useGetDuplicateVendors = (businessId: number) => {
  return useQuery<IGetDuplicateVendorsResponse, Error>(
    [QueryKey.duplicateVendors, businessId],
    () => getDuplicateVendors(businessId),
  );
};

export {
  useGetVendorsServiceByBusinessIdQuery,
  useVendorService,
  useGetDuplicateVendors,
};
