import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDeleteMember } from '@src/hooks/queries/team_management';
import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { CrossIcon } from '@src/components/utils/icomoon';

interface IViewProfileActionProps {
  member: IBusinessTeamMember;
}

const RemoveMemberAction: React.FC<IViewProfileActionProps> = ({ member }) => {
  const { mutate: removeMember } = useDeleteMember();

  const handleContinue = useCallback(() => {
    removeMember(member.id, {
      onSuccess: () => {
        toastr.success('Member removed successfully', 'Success');
      },
      onError: () => {
        toastr.error('Failed to remove member', 'Error');
      },
    });
  }, [member, removeMember]);

  const {
    Component: DeleteConfirmationModal,
    props: modalProps,
    open:openModal,
  } = useConfirmDeleteModal({
    onDone: handleContinue,
  });

  return (
    <>
      <DeleteConfirmationModal
        { ...modalProps }
        confirmTitle="Remove"
        text={ (
          <>
            <strong>
              {member.parsedFullname}
            </strong>
            {' '}
            will be removed from all the businesses. Are you sure you want to proceed?
          </>
        ) }
        title="Remove Member"
      />
      <ActionsDropdown.Action
        danger
        icon={ <CrossIcon fontSize={ 16 } /> }
        title="Remove Member"
        onClick={ openModal }
      />
    </>
  );
};

export default RemoveMemberAction;
