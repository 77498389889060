import React, { useMemo } from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IGetTemplatesResponse } from '@src/requests/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';

import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import { PlusIcon } from '@src/components/utils/icomoon';

import Actions from '../actions/portfolio_list_header_action';
import { useCreateNewReportModal } from '../modals/create_report/create_report';

import styles from '../styles.module.scss';

interface IPortfolioListHeaderProps {
  businesses: IBusiness[],
  templates: IGetTemplatesResponse[],
  multiBusinessReports: IMultiBusinessReportDetail[],
  action: string,
  setAction: React.Dispatch<React.SetStateAction<string>>,
  onCreateExportData: (value: boolean) => void,
}

const PortfolioListHeader = ({
  action,
  businesses,
  templates,
  multiBusinessReports,
  setAction,
  onCreateExportData,
}: IPortfolioListHeaderProps) => {
  const { data: isPortfolioFeatureEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.get('email'),
    window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION,
  );
  const modal = useCreateNewReportModal();

  const isShowDownload = useMemo(() => {
    const isDownloadReportEnabled = window.configData.launch_darkly_enabled_global_features
      .includes(window.Docyt.Common.Constants.CONSOLIDATED_REPORT_DOWNLOAD_ENABLED);

    if (isDownloadReportEnabled) {
      for (let i = 0; i < multiBusinessReports.length; i += 1) {
        const report = multiBusinessReports[i];

        for (let j = 0; j < report.businesses.length; j += 1) {
          const business = report.businesses[j];

          if (business.standardCategoryId
            === window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID) {
            return true;
          }
        }
      }
    }

    return false;
  }, [multiBusinessReports]);

  return (
    <DetailsRegion.Header
      divider="horizontal"
      title={ isPortfolioFeatureEnabled ? "Portfolio Reports" : "Advanced Reports" }
    >
      <Button
        className={ styles['create-new-report-btn'] }
        variant="primary"
        onClick={ () => {
          modal.open();
          setAction('create');
        } }
      >
        <PlusIcon fontSize={ 20 } />
      </Button>

      { isShowDownload && <Actions multiBusinessReports={ multiBusinessReports } onCreateExportData={ onCreateExportData } /> }
      <modal.Component
        { ...modal.props }
        action={ action }
        businesses={ businesses }
        templates={ templates }
      />
    </DetailsRegion.Header>
  );
};

export default React.memo(PortfolioListHeader);
