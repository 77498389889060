import React, { useCallback, useMemo, useState } from 'react';

import { flatten } from 'lodash';

import { useGetAllMembers } from '@src/hooks/queries/team_management';
import { IGetAllMembersParams } from '@src/requests/team_management/team_management';

import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';

import Header from './header';
import TableList from './table/table_list';

import styles from './styles.module.scss';

interface IMyTeamContainerProps {
  showAddMemberModal: () => void;
}

const MyTeamContainer: React.FC<IMyTeamContainerProps> = ({ showAddMemberModal }) => {
  const [filter, setFilter] = useState<IGetAllMembersParams['filter']>({});

  const query = useGetAllMembers({
    businessId: window.Docyt.currentAdvisor.defaultAccountingFirmId(),
    filter,
  }, {
    onSuccess: () => {
      window.Docyt.vent.on('member:added', () => {
        query.refetch();
      });
    },
  });

  const { data: membersData } = query;

  const membersPages = useMemo(() => {
    return membersData?.pages || [];
  }, [membersData?.pages]);

  const membersList = useMemo(() => {
    return flatten(membersPages.map((item) => {
      return item.collection;
    }));
  }, [membersPages]);

  const hasNoMessages = useMemo(() => {
    return (
      !query.isLoading
      && !query.isFetching
      && !query.isError
      && membersList.length === 0
      && !query.hasNextPage);
  }, [
    query.isLoading,
    query.isFetching,
    query.isError,
    membersList.length,
    query.hasNextPage,
  ]);

  const handleSearch = useCallback((value: string) => {
    setFilter({
      name: value,
    });
  }, []);

  return (
    <div className={ styles['my-team-container'] }>
      <Header showAddMemberModal={ showAddMemberModal } />
      <div className={ styles['my-team-table-wrapper'] }>
        <div className={ styles['my-team-search-input-wrapper'] }>
          <SearchInput
            placeholder="Search Members"
            width={ 200 }
            onChange={ handleSearch }
          />
        </div>
        <TableList
          hasNoMessages={ hasNoMessages }
          membersList={ membersList }
          query={ query }
        />
      </div>
    </div>
  );
};

export default MyTeamContainer;
