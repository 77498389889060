import React from 'react';

import { DateInput } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

interface IDateInputProps {
  value: string | Date | number;
  disabled?: boolean;
}
const TaskDateField = ({ value, disabled = false }:IDateInputProps) => {
  return (
    <div className={ styles['task-date-input-container'] }>
      <DateInput
        hideClear
        className={ styles['task-date-input'] }
        disabled={ disabled }
        value={ new Date(value).toISOString() }
      />
    </div>
  );
};

export default TaskDateField;
