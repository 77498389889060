import React, { useState, useCallback } from 'react';

import Dropzone, { FileRejection } from 'react-dropzone';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { Button } from '@src/components/ui_v2/buttons';

import FileDragging from './file_dragging';
import InvoiceUploadStatusPopover from './invoice_upload_status_popover';
import { useFileUpload } from '../hooks';
import { maxFileUploadCount } from '../utils';

import styles from '../styles.module.scss';

interface IUploadFileProps {
  businessId: number;
  setIsUploading: (isUploading: boolean) => void;
  setIsUploadDone: (isUploadDone: boolean) => void;
  setDocumentUploadCount: React.Dispatch<React.SetStateAction<number>>;
  onSingleDocumentUpload: (serviceDocument: IAccountsPayableServiceDocument) => void;
  showAddInvoiceModal: (showAddInvoiceModal: boolean) => void;
}

const UploadFile = ({ businessId,
  setIsUploading,
  setIsUploadDone,
  setDocumentUploadCount,
  onSingleDocumentUpload,
  showAddInvoiceModal }: IUploadFileProps) => {
  const [isFileUploadDragging, setIsFileUploadDragging] = useState<boolean>(false);
  const [draggedFilesCount, setDraggedFilesCount] = useState<number>(0);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const resetUploadState = useCallback(() => {
    setIsPopoverVisible(false);
    setIsUploading(false);
    setIsUploadDone(true);
  }, [setIsPopoverVisible, setIsUploading, setIsUploadDone]);

  const afteUploadDone = useCallback(() => {
    setTimeout(() => {
      resetUploadState();
    }, 2000);
  }, [resetUploadState]);

  const resetStateAfterError = useCallback(() => {
    showAddInvoiceModal(true);
    setIsUploadDone(false);
    setIsUploading(false);
    setIsPopoverVisible(false);
    setIsFileUploadDragging(false);
  }, [setIsUploading, setIsPopoverVisible, showAddInvoiceModal, setIsUploadDone, setIsFileUploadDragging]);

  const { fileStatuses, onUploadFiles, onStop } = useFileUpload({
    businessId,
    onUploadDone: afteUploadDone,
    setDocumentUploadCount,
    onSingleDocumentUpload,
    resetStateAfterError,
  });

  const handleUploadFiles = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setSelectedFiles(acceptedFiles);
    setIsUploading(true);
    setIsPopoverVisible(true);
    onUploadFiles(acceptedFiles, rejectedFiles);
  }, [onUploadFiles, setIsUploading, setIsPopoverVisible, setSelectedFiles]);

  const handleStop = useCallback(() => {
    showAddInvoiceModal(false);
    setIsUploadDone(true);
    setIsFileUploadDragging(false);
    setIsPopoverVisible(false);
    setIsUploading(false);
    onStop();
  }, [onStop, showAddInvoiceModal, setIsUploadDone, setIsFileUploadDragging, setIsPopoverVisible, setIsUploading]);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.items.length > 0) {
      setIsFileUploadDragging(true);
      setDraggedFilesCount(e.dataTransfer.items.length);
    }
  }, []);

  const handleDragLeave = useCallback((_e: React.DragEvent<HTMLDivElement>) => {
    setIsFileUploadDragging(false);
    setDraggedFilesCount(0);
  }, []);

  return (
    <div className={ isPopoverVisible ? styles['upload-file-dropzone'] : styles['upload-file-container'] }>
      { isPopoverVisible ? (
        <InvoiceUploadStatusPopover
          files={ fileStatuses }
          isExpanded={ selectedFiles.length > 1 }
          onStop={ handleStop }
        />
      ) : (
        <Dropzone
          maxFiles={ maxFileUploadCount }
          onDragEnter={ handleDragEnter }
          onDragLeave={ handleDragLeave }
          onDrop={ handleUploadFiles }
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={ styles['upload-file-dropzone'] }
              { ...getRootProps() }
            >
              <input { ...getInputProps() } />
              { !isFileUploadDragging ? (
                <>
                  <p className={ styles['upload-file-text'] }>Drag and drop document(s) here.</p>
                  <p className={ styles['upload-file-text-or'] }>OR</p>
                  <Button className={ styles['add-data-manually'] } size="compact" variant="primary">
                    Add Data Manually
                  </Button>
                  <p>
                    <Button variant="link">Select from Computer</Button>
                  </p>
                </>

              ) : (
                <FileDragging draggedFilesCount={ draggedFilesCount } />
              )}
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default UploadFile;
