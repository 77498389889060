import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetAllClients, useAssignClient, useRemoveClient } from '@src/hooks/queries/team_management';
import { IBusinessTeamMember, IClientBusiness } from '@src/types/team_management/team_management';

import BaseModalMyTeam from '@src/components/settings/my_team/components/modal/base_modal_my_team';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { EditClientIcon } from '@src/components/utils/icomoon';

import AnimatedLoaderModal from './animated_loader_modal';
import ModalTitle from './modal_title';

import styles from './styles.module.scss';

const PAGE_SIZE = 450;

interface IEditClientModalProps extends IUseModalProps {
  member: IBusinessTeamMember;
}

const EditClientModal = ({
  member,
  isOpen,
  onDone,
  onCancel,
}: IEditClientModalProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedClients, setSelectedClients] = useState<IClientBusiness[]>([]);
  const [deselectedClients, setDeselectedClients] = useState<IClientBusiness[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { mutateAsync: assignClient } = useAssignClient();
  const { mutateAsync: removeClient } = useRemoveClient();
  const queryClient = useQueryClient();
  const query = useGetAllClients({
    id:      member.id,
    perPage: PAGE_SIZE,
  }, {
    enabled:          isOpen,
    staleTime:        0,
    keepPreviousData: false,
    refetchOnMount:   'always',
  });
  const { data: clientsData, isLoading, refetch } = query;

  // Initialize selected clients when data is loaded
  useEffect(() => {
    if (clientsData?.businesses) {
      setSelectedClients(clientsData.businesses);
      setDeselectedClients([]); // Reset deselected clients when data changes
    }
  }, [clientsData]);

  // Filter clients based on search term
  const filteredCheckedBusinesses = useMemo(() => {
    if (!clientsData?.businesses) return [];

    if (!searchTerm) return clientsData.businesses;

    return clientsData.businesses.filter((client) => client.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [clientsData, searchTerm]);

  const filteredAvailableBusinesses = useMemo(() => {
    if (!clientsData?.availableBusinesses || !clientsData?.businesses) return [];

    // Get the IDs of businesses that are already in the checked list
    const existingBusinessIds = clientsData.businesses.map((business) => business.id);

    // Filter out available businesses that already exist in the businesses list
    const uniqueAvailableBusinesses = clientsData.availableBusinesses.filter(
      (business) => !existingBusinessIds.includes(business.id),
    );

    if (!searchTerm) return uniqueAvailableBusinesses;

    return uniqueAvailableBusinesses.filter((client) => client.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [clientsData, searchTerm]);

  // Check if a client is selected
  const isClientSelected = useCallback((clientId: number) => {
    return selectedClients.some((client) => client.id === clientId);
  }, [selectedClients]);

  // Toggle client selection
  const toggleClientSelection = useCallback((client: IClientBusiness) => {
    // Check if the client was initially selected (exists in clientsData.businesses)
    const wasInitiallySelected = clientsData?.businesses?.some((b) => b.id === client.id) || false;

    if (isClientSelected(client.id)) {
      // Client is being deselected
      setSelectedClients((prev) => prev.filter((c) => c.id !== client.id));

      // Only add to deselectedClients if it was initially selected
      if (wasInitiallySelected) {
        setDeselectedClients((prev) => [...prev, client]);
      }
    } else {
      // Client is being selected
      setSelectedClients((prev) => [...prev, client]);

      // Remove from deselectedClients if it was there
      setDeselectedClients((prev) => prev.filter((c) => c.id !== client.id));
    }
  }, [clientsData, isClientSelected]);

  // Process clients sequentially using assign_client and remove_client APIs
  const processClientsSequentially = useCallback(async (
    clientsToAdd: IClientBusiness[],
    clientsToRemove: IClientBusiness[],
  ) => {
    const totalClients = clientsToAdd.length + clientsToRemove.length;
    if (totalClients === 0) return;

    // Initialize progress tracking
    setIsProcessing(true);
    setCurrentCount(0);
    setTotalCount(totalClients);

    let success = true;
    try {
      // Process additions one by one
      for (let i = 0; i < clientsToAdd.length; i += 1) {
        const client = clientsToAdd[i];
        // Call assign_client API
        // eslint-disable-next-line no-await-in-loop
        await assignClient({
          userId:     member.id,
          clientId:   client.id,
          businessId: client.serviceProviderBusinessId,
        });

        // Update progress
        setCurrentCount(i + 1);
      }

      // Process removals one by one
      for (let i = 0; i < clientsToRemove.length; i += 1) {
        const client = clientsToRemove[i];
        // Call remove_client API
        // eslint-disable-next-line no-await-in-loop
        await removeClient({
          id:               member.id,
          clientBusinessId: client.id,
          businessId:       client.serviceProviderBusinessId,
        });

        // Update progress
        setCurrentCount(clientsToAdd.length + i + 1);
      }

      toastr.success('Success', 'Clients updated successfully');
    } catch (error) {
      success = false;
      toastr.error('Error', (error as Error).message);
    } finally {
      setIsProcessing(false);
      setCurrentCount(0);
      setTotalCount(0);

      if (success) {
        // Invalidate queries once after all operations complete
        queryClient.invalidateQueries([QueryKey.allClients, member.id]);
        queryClient.invalidateQueries(QueryKey.myTeamMembers);

        // Refresh current client list
        refetch();
      }
    }
  }, [assignClient, removeClient, member.id, refetch, queryClient]);

  // Handle save changes
  const handleSaveChanges = useCallback(() => {
    // Get newly added clients
    const clientsToAdd = filteredAvailableBusinesses.filter((client) => isClientSelected(client.id));

    // If there are no changes, just close the modal and return
    if (clientsToAdd.length === 0 && deselectedClients.length === 0) {
      onDone();
      return;
    }

    // Close the edit modal
    onDone();

    // Process clients sequentially with the assign_client and remove_client APIs
    processClientsSequentially(clientsToAdd, deselectedClients);
  }, [
    deselectedClients,
    filteredAvailableBusinesses,
    isClientSelected,
    onDone,
    processClientsSequentially,
  ]);

  const handleCancel = useCallback(() => {
    setSearchTerm('');
    if (clientsData?.businesses) {
      setSelectedClients(clientsData.businesses);
      setDeselectedClients([]);
    } else {
      setSelectedClients([]);
      setDeselectedClients([]);
    }
    onCancel();
  }, [
    clientsData,
    onCancel,
  ]);

  const hasSearchResults = filteredCheckedBusinesses.length > 0 || filteredAvailableBusinesses.length > 0;

  return (
    <>
      {/* Show AnimatedLoaderModal with progress during processing */}
      <AnimatedLoaderModal
        showProgress
        currentCount={ currentCount }
        isOpen={ isProcessing }
        totalCount={ totalCount }
      />

      <BaseModalMyTeam
        className={ styles['edit-client-modal-body'] }
        footerContent={ (
          <div className={ styles['edit-client-modal-footer'] }>
            <Button
              variant="link"
              onClick={ handleCancel }
            >
              Cancel
            </Button>
            <Button
              disabled={ isProcessing }
              variant="primary"
              onClick={ handleSaveChanges }
            >
              Save Changes
            </Button>
          </div>
        ) }
        isOpen={ isOpen }
        modalTitle={ <ModalTitle member={ member } /> }
      >
        {!isLoading ? (
          <div className={ styles['edit-client-container'] }>
            <div className={ styles['search-container'] }>
              <SearchInput
                placeholder="Search client"
                value={ searchTerm }
                onChange={ setSearchTerm }
              />
            </div>

            {hasSearchResults ? (
              <div
                className={ styles['clients-list'] }
              >
                {/* Checked businesses */}
                <div className={ styles['clients-list-checked'] }>
                  {filteredCheckedBusinesses.length > 0 && (
                    <>
                      {filteredCheckedBusinesses.map((client) => (
                        <div key={ client.id } className={ styles['client-item'] }>
                          <CheckboxInput
                            checked={ isClientSelected(client.id) }
                            id={ `client-${client.id}` }
                            title={ client.name }
                            onChange={ () => toggleClientSelection(client) }
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {/* Separator - only show if both lists have items */}
                {filteredCheckedBusinesses.length > 0 && filteredAvailableBusinesses.length > 0 && (
                  <div className={ styles['client-separator'] } />
                )}

                {/* Available businesses */}
                {filteredAvailableBusinesses.length > 0 && (
                  <>
                    {filteredAvailableBusinesses.map((client) => (
                      <div key={ client.id } className={ styles['client-item'] }>
                        <CheckboxInput
                          checked={ isClientSelected(client.id) }
                          id={ `client-${client.id}` }
                          title={ client.name }
                          onChange={ () => toggleClientSelection(client) }
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ) : (
              <div className={ styles['no-results'] }>
                <div className={ styles['no-results-icon'] }>
                  <EditClientIcon fontSize={ 40 } />
                </div>
                No clients found
              </div>
            )}
          </div>
        ) : (
          <div className={ styles['loading-container'] }>
            <ProgressActivityLoader size="xlarge" />
          </div>
        )}
      </BaseModalMyTeam>
    </>
  );
};

const useEditClientModal = makeUseModal(EditClientModal);

export {
  IEditClientModalProps,
  useEditClientModal,
  EditClientModal as default,
};
