import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { TDate } from '@src/types/common';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { formatDate } from '@src/utils/date_helpers';

import { availableStatuses, CLOSE_DATE_FORMAT } from '@src/components/task_management/components/helper/constants';
import { closedTaskStatusValidation, IClosedTaskStatusValuesProps }
  from '@src/components/task_management/components/helper/validations';
import Modal from '@src/components/ui/modal';
import ModalBody from '@src/components/ui/modal/body';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import { IUpdateStatusProps } from '../types';

import styles from './styles.module.scss';

interface IClosedModalProps {
    open:boolean;
    onCancel:()=>void;
    onSubmit?:(bodyData:IClosedTaskStatusValuesProps)=>void;
    businessName:string;
    minDate?:number | TDate;
    maxDate?: number | TDate;
    shouldUpdate?:boolean;
    taskDocument?:ITaskServiceDocument | null;
}

const CloseModal = ({ open, taskDocument, shouldUpdate = true,
  businessName, onCancel, onSubmit, minDate }:IClosedModalProps) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    clearErrors,
  } = useForm<IClosedTaskStatusValuesProps>({
    resolver: yupResolver(closedTaskStatusValidation),
  });

  const handleCancel = useCallback(() => {
    setValue('closeDate', '');
    clearErrors('closeDate');
    onCancel();
  }, [clearErrors, onCancel, setValue]);
  const updateStatus = useUpdateTaskServiceDocument();

  const handleUpdateStatus = useCallback((
    { formData, id }:IUpdateStatusProps,
  ) => {
    if (formData && (taskDocument?.taskId || id)) {
      updateStatus.mutate(
        { formData: { ...formData, status: availableStatuses.CLOSED },
          taskId:   id ?? taskDocument?.taskId as string },
        { onSuccess: () => {
          toastr.success(
            'Status Updated Successfully',
            'Update Task Status',
          );
          handleCancel();
        },
        onError: (error) => {
          toastr.error(
            error.message,
            'Error Update Status',
          );
        } },
      );
    }
  }, [handleCancel, taskDocument?.taskId, updateStatus]);

  const submitHandler = handleSubmit((bodyData) => {
    if (bodyData.closeDate) {
      if (shouldUpdate) {
        handleUpdateStatus({ formData:
           { closeDate: formatDate(bodyData.closeDate, CLOSE_DATE_FORMAT) },
        id: taskDocument?.taskId });
      } else if (onSubmit) {
        onSubmit({ ...bodyData, closeDate: formatDate(bodyData.closeDate, CLOSE_DATE_FORMAT) });
        setValue('closeDate', '');
        clearErrors('closeDate');
      }
    }
  });
  const closedDateController = useController({ name: 'closeDate', control });
  return (
    <Modal
      show={ open }
      title="Close Task"
      onCancel={ handleCancel }
    >
      <Form onSubmit={ submitHandler }>
        <ModalBody>
          <div className={ styles['status-modal-body'] }>
            <div className={ styles['task-info'] }>
              <p>
                Task ID :&nbsp;
                #
                {taskDocument?.taskId}
              </p>
              <p>
                Business:&nbsp;
                {businessName}
              </p>
            </div>
            <div className={ styles['date-field-container'] }>
              <span className={ styles['date-field-title'] }>
                This task will be closed on
              </span>
              <Form.DateField
                className={ styles['status-date-input'] }
                error={ errors.closeDate?.message }
                label=""
                maxDate={ new Date(Date.now()) }
                minDate={ minDate ? new Date(minDate) : undefined }
                { ...closedDateController.field }
              />
            </div>
          </div>
        </ModalBody>
        <Modal.Footer className={ styles['status-modal-footer'] }>
          <Button variant="link" onClick={ handleCancel }>Cancel</Button>
          <Button disabled={ updateStatus?.isLoading } type="submit" variant="primary">
            {updateStatus?.isLoading ? (
              <>
                Closing
                <SpinnerIcon spin />
              </>
            ) : 'Close Task'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CloseModal;
