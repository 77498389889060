import React from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { SplitBillIcon } from '@src/components/utils/icomoon';

import LinkAction from './link_document_request_action';
import UnlinkAction from './unlink_document_request_action';

interface IBusinessInboxItemDocumentRequestActionProps {
  business: IBusiness,
  document: IInboxServiceDocument,
}

const BusinessInboxItemDocumentRequestAction = ({
  business,
  document,
}: IBusinessInboxItemDocumentRequestActionProps) => {
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  if (!document.documentRequestId) {
    return (
      <LinkAction business={ business } document={ document } />
    );
  }

  return (
    <>
      <ActionsDropdown.Action
        href={ sunsetClientCentralEnabled
          ? documentRequestsPath(business.id)
          : clientCenteralPath(business.id) }
        icon={ <SplitBillIcon fontSize={ 16 } /> }
        name="View Document Request"
      />
      <UnlinkAction document={ document } />
    </>
  );
};

export default BusinessInboxItemDocumentRequestAction;
