import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  IUpsertIntegrationResponse,
} from '@src/requests/integrations/integrations';
import { revenueServicePathRevenueCapturePath } from '@src/routes';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckGreenIcon, ContentCopyIcon } from '@src/components/utils/icomoon';

import { useAddReportTypeModal } from '../../report_type/add_report_type/add_report_type_modal';

import styles from './styles.module.scss';

interface IpushIntegrationSuccessModalProps extends IUseModalProps {
  UpsertIntegrationResponse?: IUpsertIntegrationResponse | null
}

const PushIntegrationSuccessModal = ({
  isOpen,
  onDone,
  UpsertIntegrationResponse,
}: IpushIntegrationSuccessModalProps) => {
  const navigate = useNavigate();
  const business = useBusinessContext();
  const service = useRevenueServiceContext();
  const addReportTypeModal = useAddReportTypeModal();
  const [badgeShow, setBadgeShow] = useState(false);
  const [addReport, setAddReport] = useState<boolean>(false);
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );

  const integration = useGetIntegration(
    { integrationId: UpsertIntegrationResponse?.id, businessId: business.id },
    { enabled: !!UpsertIntegrationResponse?.id },
  );

  const featureSkipRevenueReportTypeValidation = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.SKIP_REVENUE_REPORT_MAPPING,
  );

  const copyEmail = useCallback(() => {
    navigator.clipboard.writeText(UpsertIntegrationResponse?.emailAddress || '');
    setBadgeShow(true);
  }, [UpsertIntegrationResponse]);

  const navigateToRevenueSystem = useCallback(() => {
    const integrationId = integration.data?.id;
    const selectedRevenueSystem =
            service.revenueSystems.find((o) => o.integrationId === Number(integrationId))
            || service.revenueSystems[0];
    const selectedId = selectedRevenueSystem?.id;
    navigate(
      revenueServicePathRevenueCapturePath(
        business.id,
        selectedId,
      ),
    );
  }, [business.id, integration.data?.id, navigate, service.revenueSystems]);

  const handleOnClick = useCallback(() => {
    setBadgeShow(false);
    setAddReport(true);
    if (!integration?.data?.skipRevenueMapping) {
      addReportTypeModal.open();
    } else {
      navigateToRevenueSystem();
    }
    onDone();
  }, [
    addReportTypeModal,
    integration.data?.skipRevenueMapping,
    onDone,
    navigateToRevenueSystem]);

  const handleCancelClick = useCallback((event) => {
    event.preventDefault();
    navigateToRevenueSystem();
    onDone();
  }, [navigateToRevenueSystem, onDone]);

  return (
    <>
      {addReport && (
      <addReportTypeModal.Component
        UpsertIntegrationResponse={ UpsertIntegrationResponse }
        integration={ integration.data }
        isConnect="push"
        { ...addReportTypeModal.props }
      />
      )}
      <Modal
        show={ isOpen && integration?.data !== undefined }
        title={ integration?.data?.skipRevenueMapping
          ? `${UpsertIntegrationResponse?.externalSystemName} System Connected!`
          : 'Revenue System Connected!' }
      >
        <Modal.Body>
          <div className={ styles['bold-text'] }>
            <strong>
              You have successfully connected to
              {' '}
              {UpsertIntegrationResponse?.externalSystemName}
              {' '}
              {integration?.data?.skipRevenueMapping ? 'system.' : 'revenue system.'}
              {' '}
            </strong>
          </div>
          <div className={ styles['second-text'] }>
            {integration?.data?.skipRevenueMapping
              ? 'Use the below email address to send daily metric reports to Docyt.'
              : 'Use the below email address in your revenue system to send daily revenue reports to Docyt.'}
          </div>
          <div className={ styles['mixed-line-text'] }>
            <Text fontSize={ 12 } inColor="black">
              <Text className={ styles['email-text'] } fontVariant="semi-bold">
                {UpsertIntegrationResponse?.emailAddress}
              </Text>
            </Text>
            <Button
              variant="link"
              onClick={ copyEmail }
            >
              <ContentCopyIcon fontSize={ 16 } ml={ 10 } />
            </Button>
            {badgeShow ? (
              <Text className={ styles['badge-copy'] } fontSize={ 12 }>
                <CheckGreenIcon fontSize={ 17 } />
                Copied to Clipboard
              </Text>
            ) : null}
          </div>
          {!integration?.data?.skipRevenueMapping && (
            <div className={ styles['bottom-text'] }>
              Next step is to map the report line items with the category, department and the journal entry.
              It will be saved as revenue report type after the mapping.
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="modal-footer-v2">
          {integration?.data?.skipRevenueMapping ? (
            <div className={ styles['center-align'] }>
              <Button
                type="submit"
                variant="primary"
                onClick={ handleOnClick }
              >
                Done
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="link"
                onClick={ handleCancelClick }
              >
                Do it Later
              </Button>
              {featureQuery.data && featureSkipRevenueReportTypeValidation.data && (
              <Button
                type="submit"
                variant="primary"
                onClick={ handleOnClick }
              >
                Map Now
              </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const usePushIntegrationSuccessModel = makeUseModal(PushIntegrationSuccessModal);

export {
  usePushIntegrationSuccessModel,
  PushIntegrationSuccessModal as default,
};
