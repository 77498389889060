import React, { useCallback } from 'react';

import { useRequestBalanceSheetStatement } from '@src/hooks/queries/balance_sheet_statements';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { TID, TYear } from '@src/types/common';
import { API_DATE_FORMAT, formatDate, formatFullMonth } from '@src/utils/date_helpers';

import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import { IRequestDocumentFormValues } from './form';
import { useRequestDocumentModal } from './modal';

import styles from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/balance_sheet_information_details/styles.module.scss';

interface IRequestStatementActionProps {
  businessId: TID,
  chartOfAccountId: TID,
  chartOfAccountName: string,
  year: TYear,
  months: number,
}

const RequestStatementAction = ({
  businessId,
  chartOfAccountId,
  year,
  months,
  chartOfAccountName,
}: IRequestStatementActionProps): JSX.Element => {
  const month = formatFullMonth(new Date(parseInt(year, 10), months - 1, 1));

  const requestBalanceSheetStatement = useRequestBalanceSheetStatement();
  const { mutate } = requestBalanceSheetStatement;

  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  const redirectToClientCenteral = useCallback(() => {
    Backbone.history.navigate(
      sunsetClientCentralEnabled
        ? documentRequestsPath(businessId) : clientCenteralPath(businessId),
      { trigger: true },
    );
  }, [businessId, sunsetClientCentralEnabled]);

  const handleRequestStatement = useCallback((values: IRequestDocumentFormValues) => {
    const firstDate = new Date(month);
    const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
    mutate({
      businessId,
      chartOfAccountId,
      date:        formatDate(lastDate, API_DATE_FORMAT),
      description: values.description,
    }, {
      onSuccess: () => redirectToClientCenteral(),
    });
  }, [businessId, chartOfAccountId, mutate, redirectToClientCenteral, month]);

  const requestDocumentModal = useRequestDocumentModal({
    onDone: handleRequestStatement,
  });

  return (
    <>
      <MutationStatus mutation={ requestBalanceSheetStatement } />
      <requestDocumentModal.Component
        chartOfAccountName={ chartOfAccountName }
        month={ month }
        { ...requestDocumentModal.props }
      />
      <Button
        className={ styles['upload-statement-button'] }
        variant="ghost"
        onClick={ requestDocumentModal.open }
      >
        Request Documents
      </Button>
    </>
  );
};

export default RequestStatementAction;
