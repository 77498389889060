import React from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import LedgerItem from '@src/components/header/module_header/revamp_items/ledger_item';
import ReportsServiceProvider from '@src/components/providers/reports_service_provider';
import RevenueServiceProvider from '@src/components/providers/revenue_service_provider';

import BusinessItem from './revamp_items/business_item';
import ExpenseItem from './revamp_items/expense_item';
import ReconciliationItem from './revamp_items/reconciliation_item';
import ReportsItem from './revamp_items/reports_item';
import RevenueItem from './revamp_items/revenue_item';
import { activatedModuleHeader } from './utils';

import styles from '../styles.module.scss';

interface IModuleHeaderActionsProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

interface IRevenueServiceProviderProps {
  businessId: number,
  children: React.ReactNode,
  isGlobalNavigationEnabled?: boolean
}

const GlobalNavigationHeaderActions = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  isGlobalNavigationEnabled,
}: IModuleHeaderActionsProps): JSX.Element | null => {
  const { data: info } = useGetBusinessServicesInfo(businessId);

  const sunsetVaultFeatureFlagEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG);

  const activeItem = activatedModuleHeader(currentModulePath, sunsetVaultFeatureFlagEnabled, isGlobalNavigationEnabled);

  if (!info) {
    return null;
  }

  const components = [
    { component: BusinessItem, key: 'BusinessItem' },
    { component: ExpenseItem, key: 'ExpenseItem' },
    { component: RevenueItem, key: 'RevenueItem' },
    { component: ReconciliationItem, key: 'ReconciliationItem' },
    { component: ReportsItem, key: 'ReportsItem' },
    { component: LedgerItem, key: 'LedgerItem' },
  ];

  const serviceProviders: { [key: string]: React.ComponentType<IRevenueServiceProviderProps> | null } = {
    RevenueItem: RevenueServiceProvider,
    ReportsItem: ReportsServiceProvider,
  };

  const renderComponent = (Component: React.ComponentType<IServiceMenuProps>, key: string) => (
    <Component
      key={ key }
      activeItem={ activeItem }
      businessId={ businessId }
      focusToMyClient={ focusToMyClient }
      info={ info }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
    />
  );

  const renderWithProvider = (Component: React.ComponentType<IServiceMenuProps>, key: string) => {
    const ServiceProvider = serviceProviders[key] || React.Fragment;

    return (
      <ServiceProvider key={ key } businessId={ businessId } isGlobalNavigationEnabled={ isGlobalNavigationEnabled }>
        {renderComponent(Component, key)}
      </ServiceProvider>
    );
  };

  return (
    <div className={ styles['service-menu-page'] }>
      {components.map(({ component: Component, key }) => renderWithProvider(Component, key))}
    </div>
  );
};

export default GlobalNavigationHeaderActions;
