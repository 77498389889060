import React from 'react';

import TasksNotificationsIcon from '@src/components/utils/icomoon/tasks-notifications';

import styles from '../styles.module.scss';

interface ITaskCountProps {
  count: number
  onClick?: () => void
}

const TaskNotificationBadge = ({ count, onClick }: ITaskCountProps) => {
  return (
    <div className={ styles['task-notification-icon-container'] } role="cell" onClick={ onClick }>
      <TasksNotificationsIcon fontSize={ 24 } />
      {count > 0 && <span className={ styles['task-badge'] }>{count}</span>}
    </div>
  );
};

export default TaskNotificationBadge;
