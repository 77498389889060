import { useCallback } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';

export const useClearQueryCache = () => {
  const queryClient = useQueryClient();

  const clearCacheOnLogout = useCallback(() => {
    // Clear user-specific queries
    queryClient.removeQueries(QueryKey.portfolioList);
    queryClient.removeQueries(QueryKey.usersBusinesses);
    queryClient.removeQueries(QueryKey.managementGroups);
    // You can add more query keys that need to be cleared on logout
  }, [queryClient]);

  return { clearCacheOnLogout };
};
