import React, { useCallback } from 'react';

import { forEach } from 'lodash';

import { taskDetailsViewPath } from '@src/routes';
import { TFilterData } from '@src/types/filter';
import { ISortingParams } from '@src/types/sorting';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { restoreFilterFromStorage } from '@src/utils/filter';
import { getSearchParamsFromFilter } from '@src/utils/filter/url_helpers';

import { AssignedTasksTable } from './assigned';
import { BlockedTasksTable } from './blocked';
import { ClosedTasksTable } from './closed';
import { OpenTasksTable } from './open';
import { TASK_TABS } from '../components/helper/constants';
import { ITasksCollection } from '../hooks';
import { TTaskManagementTabs } from '../types';

const TasksTable = ({ collection, tab }:
  { tab:TTaskManagementTabs, collection: ITasksCollection}) => {
  const restoreSortingFromStorage = <TSortColumn extends string>(
    section: string,
  ): ISortingParams<TSortColumn> | undefined => {
    const serializedData = localStorage.getItem(section);
    if (!serializedData) return undefined;

    let data: ISortingParams<TSortColumn> | undefined;
    const storedData = JSON.parse(serializedData);
    forEach(storedData, (value, key) => {
      if (!key.endsWith('_order')) return;

      data = {
        orderColumn:    key.replace('_order', '') as any as TSortColumn,
        orderDirection: value,
      };
    });

    return data;
  };
  const onRowClick = useCallback((rowData:ITaskServiceDocument) => {
    const filterData = restoreFilterFromStorage({ section: tab }) as TFilterData;
    const sorting = restoreSortingFromStorage(tab);
    let url = taskDetailsViewPath(rowData.taskId as string);
    if (filterData || sorting) {
      const filterParams = getSearchParamsFromFilter({ ...filterData });
      url = `${url}?filter=${encodeURIComponent(String(filterParams))}`;
      const sortingParams = getSearchParamsFromFilter({ ...sorting });
      if (sorting) {
        url += `&sorting=${encodeURIComponent(String(sortingParams))}`;
      }
    }
    Backbone.history.navigate(
      url,
      { trigger: true },
    );
  }, [tab]);
  const renderTabContent = useCallback(() => {
    switch (tab) {
      case TASK_TABS.OPEN:
        return (
          <OpenTasksTable
            collection={ collection }
            onRowClick={ onRowClick }
          />
        );
      case TASK_TABS.ASSIGNED:
        return (
          <AssignedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      case TASK_TABS.CLOSED:
        return (
          <ClosedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      case TASK_TABS.BLOCKED:
        return (
          <BlockedTasksTable collection={ collection } onRowClick={ onRowClick } />
        );
      default:
        return (
          null
        );
    }
  }, [tab, collection, onRowClick]);
  return renderTabContent();
};
export default TasksTable;
