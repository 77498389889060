import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetDuplicateVendors } from '@src/hooks/queries/vendors_services';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import FullPageModal from '@src/components/ui_v2/full_page_modal';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytBotAi, LaunchIcon } from '@src/components/utils/icomoon';
import QueryStatus from '@src/components/utils/query_status';

import DuplicateVendorsTable from './duplicate_vendors_table';

import styles from './styles.module.scss';

const DuplicateVendorsList = (): JSX.Element => {
  const business = useBusinessContext();
  const duplicateVendorsQuery = useGetDuplicateVendors(business.id);
  const { data: duplicateVendorsResponse } = duplicateVendorsQuery;
  const duplicateVendors = duplicateVendorsResponse?.duplicateVendors;
  const qboLink = duplicateVendorsResponse?.qboLink;

  const duplicateVendorsCount = Object.keys(duplicateVendors || {}).length;

  const handleCancelClick = useCallback(() => {
    backboneNavigateToUrl('/');
  }, []);

  const handleOpenVendorsInQboClick = useCallback(() => {
    if (qboLink) {
      window.open(qboLink, '_blank');
    }
  }, [qboLink]);

  return (
    <FullPageModal>
      <QueryStatus query={ duplicateVendorsQuery } />
      <FullPageModal.Header
        divider="horizontal"
        title={ business.name }
        onCancel={ handleCancelClick }
      />
      <FullPageModal.Body hasSidemenu>
        <div className={ styles['duplicate-vendors'] }>
          <div className={ styles['duplicate-vendors-subheader'] }>
            <div className={ styles['duplicate-vendors-subheader-left'] }>
              <DocytBotAi fontSize={ 20 } />
              <h3 className={ styles['duplicate-vendors-subheader-title-text'] }>
                { duplicateVendorsCount }
                {' '}
                Possible Duplicate Vendors
              </h3>
            </div>
            <div className={ styles['duplicate-vendors-subheader-right'] }>
              {/* <p className={ styles['duplicate-vendors-subheader-right-text'] }>
                Last detected on 09/09/2024
              </p> */}
              <Tooltip.Hover content="Open Vendors in QuickBooks">
                <LaunchIcon
                  pointer
                  className={ styles['qbo-link'] }
                  color="black"
                  fontSize={ 18 }
                  onClick={ handleOpenVendorsInQboClick }
                />
              </Tooltip.Hover>
            </div>
          </div>
          {duplicateVendors && <DuplicateVendorsTable duplicateVendors={ duplicateVendors } />}
        </div>
      </FullPageModal.Body>
    </FullPageModal>
  );
};

export default DuplicateVendorsList;
