import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MultiValue, ActionMeta } from 'react-select';

import toastr from '@lib/toastr';
import { useGetAllManagementGroupMembers } from '@src/hooks/queries/users';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { IManagementGroup, IUserOption } from '@src/types/management_groups';

import {
  IManagementGroupInput,
  managementGroupValidation,
} from '@src/components/management_groups/modals/schema';
import Form from '@src/components/ui/form/form';
import { ReactSelectOutsideInput, SwitchInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import { MANAGEMENT_GROUP_USER_MANAGEMENT_MESSAGES } from './user_management_messages';

import styles from '../styles.module.scss';

interface IManagementGroupModalProps {
  managementGroup?: IManagementGroup,
  formId?: string,
  onSubmit: SubmitHandler<IManagementGroupInput>,
}

const EditManagementGroupModalForm = ({
  managementGroup,
  formId,
  onSubmit,
}: IManagementGroupModalProps) => {
  const isUnifiedHeaderDropDownFeatureFlagEnabled = useUserFeatureFlag('unified-header-drop-down');
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IManagementGroupInput>({
    defaultValues: {
      name:                managementGroup?.name,
      intercompanyEnabled: managementGroup?.intercompanyEnabled,
      users:               managementGroup?.managementGroupUsers?.map(
        (user: { id: number; }) => Number(user.id),
      ) || [],
    },
    resolver: yupResolver(managementGroupValidation),
  });
  const [isIntercompanyEnabled, setIsIntercompanyEnabled] = useState(managementGroup?.intercompanyEnabled);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<IUserOption[]>([]);
  // Add state to track user changes
  const [userChanges, setUserChanges] = useState<{
    added: number[];
    removed: number[];
  }>({
    added:   [],
    removed: [],
  });

  // Get management group members
  const managementGroupMembersQuery = useGetAllManagementGroupMembers({
    managementGroupId: managementGroup?.id || 0,
    filter:            {
      name: searchValue,
    },
  });

  useEffect(() => {
    if (managementGroupMembersQuery.data?.managementGroupMembers) {
      const existingUsers = managementGroupMembersQuery.data.managementGroupMembers
        .map((user) => ({
          value: String(user.id),
          label: user.email,
        }));
      setSelectedUsers(existingUsers);
    }
  }, [managementGroupMembersQuery.data]);

  const handleChange = useCallback((isChecked: boolean) => {
    setValue('intercompanyEnabled', isChecked);
    setIsIntercompanyEnabled(isChecked);
  }, [setValue]);

  const handleInputChange = useCallback((newValue: string) => {
    setSearchValue(newValue);
  }, []);

  const handleUsersChange = (
    selectedOptions: MultiValue<IUserOption>,
    actionMeta: ActionMeta<IUserOption>,
  ) => {
    const mutableOptions = [...selectedOptions];
    setSelectedUsers(mutableOptions);
    setValue('users', mutableOptions.map((option: IUserOption) => parseInt(option.value, 10)));

    // Track removed users
    if (actionMeta.action === 'remove-value' && actionMeta.removedValue) {
      const removedUserId = parseInt(actionMeta.removedValue.value, 10);
      setUserChanges((prev) => ({
        added:   prev.added.filter((id) => id !== removedUserId),
        removed: [...prev.removed, removedUserId],
      }));
    }

    // Track added users
    if (actionMeta.action === 'select-option' && actionMeta.option) {
      const addedUserId = parseInt(actionMeta.option.value, 10);
      setUserChanges((prev) => ({
        removed: prev.removed.filter((id) => id !== addedUserId),
        added:   [...prev.added, addedUserId],
      }));
    }
  };

  const isLoading = managementGroupMembersQuery.isLoading || managementGroupMembersQuery.isFetching;

  // Update loadOptions to use the users array for available options
  const loadOptions = useMemo(() => {
    return (managementGroupMembersQuery.data?.users || []).map((user) => ({
      value: String(user.id),
      label: user.email,
    }));
  }, [managementGroupMembersQuery.data]);

  // Modify the form submission handler
  const onSubmitForm: SubmitHandler<IManagementGroupInput> = useCallback(async (data) => {
    try {
      // First submit the form data
      await onSubmit({
        ...data,
        userChanges, // Include the user changes in the submission
      });

      // Reset the user changes after successful submission
      setUserChanges({ added: [], removed: [] });
    } catch (error) {
      toastr.error('Failed to update management group', String(error));
    }
  }, [onSubmit, userChanges]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmitForm) }>
      <div className={ styles['management-group-form'] }>
        <Form.TextField
          { ...register('name') }
          autoFocus
          autoComplete="off"
          error={ errors.name?.message }
          label="Management Group Name"
          maxLength={ 80 }
          placeholder="Enter Management Group Name"
        />
        <div className="form-field">
          <div className="form-field-item form-field-name">
            <div className="display-flex items-center">
              <span>Inter-Company Settlement</span>
              <Tooltip.Hover
                content={ MANAGEMENT_GROUP_USER_MANAGEMENT_MESSAGES.INTERCOMPANY_TOOLTIP }
              >
                <DocytSupportIcon className="pointer font-bold" fontSize={ 16 } ml={ 4 } />
              </Tooltip.Hover>
            </div>
          </div>
          <div className="form-field-item form-field-value">
            <SwitchInput
              { ...register('intercompanyEnabled') }
              title={ isIntercompanyEnabled ? 'Enabled' : 'Disabled' }
              onChange={ handleChange }
            />
          </div>

          {managementGroup && isUnifiedHeaderDropDownFeatureFlagEnabled && (
            <>
              <hr className={ styles['form-field-divider'] } />
              <div className="form-field-item form-field-name">
                <div className="display-flex items-center">
                  <span>Businesses :</span>
                </div>
              </div>
              <div className="form-field-item form-field-value">
                {(managementGroup?.businesses || []).slice(0, 3).map((business, index) => (
                  <React.Fragment key={ business.id }>
                    <span className="text-gray-600">{business.name}</span>
                    {index < Math.min(2, (managementGroup?.businesses?.length || 0) - 1) && (
                    <span className="mx-1">,</span>
                    )}
                  </React.Fragment>
                ))}
                {managementGroup?.businesses && managementGroup.businesses.length > 3 && (
                <Tooltip.Hover
                  content={ (managementGroup.businesses || []).map((b) => b.name).join(', ') }
                >
                  <span className="text-gray-600 ml-1">
                    {`+${managementGroup.businesses.length - 3} more`}
                  </span>
                </Tooltip.Hover>
                )}
              </div>

              <div className="form-field-item form-field-name">
                <div className="display-flex items-center">
                  <span>Select Users:</span>
                </div>
              </div>
              <div className="form-field-item form-field-value">
                <ReactSelectOutsideInput
                  isMulti
                  controlShouldRenderValue={ false }
                  inputValue={ searchValue }
                  isClearable={ false }
                  isDisabled={ isLoading }
                  isLoading={ isLoading }
                  loadingMessage={ () => MANAGEMENT_GROUP_USER_MANAGEMENT_MESSAGES.SEARCHING }
                  noOptionsMessage={ () => MANAGEMENT_GROUP_USER_MANAGEMENT_MESSAGES.NO_USERS_FOUND }
                  options={ loadOptions }
                  placeholder={ selectedUsers.length > 0
                    ? `${selectedUsers.length} Selected`
                    : MANAGEMENT_GROUP_USER_MANAGEMENT_MESSAGES.SELECT_USERS }
                  value={ selectedUsers }
                  onChange={ handleUsersChange }
                  onInputChange={ handleInputChange }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

export default EditManagementGroupModalForm;
