import React, { useCallback, useMemo, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';

import toastr from '@lib/toastr';
import { makeUseModal, IUseModalProps } from '@src/hooks/modal';
import { useGetBusinessesByManagementGroup } from '@src/hooks/queries/businesses';
import {
  useGetFinancialInstitutionAccountOwners,
  useCreateFinancialInstitutionEmployeeMapping,
} from '@src/hooks/queries/financial_institution_connection';
import { useByReconciliationPaymentAccountId } from '@src/hooks/queries/payment_accounts';
import { employeeCardsPath } from '@src/routes';
import { TID } from '@src/types/common';

import { CancelModalButton, Button } from '@src/components/ui/buttons';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';
import { SelectInput, TOption } from '@src/components/ui_v2/inputs';
import { LaunchIcon } from '@src/components/utils/icomoon';

import BaseEmployeeCardMappingModal from './base_employee_card_mapping_modal';
import EmployeeCardMappingTable from './employee_card_mapping_table';
import { IEmployeeCardMappingInput } from './schema';

import './employee_card_mapping.scss';

const SELECT_MENU_PORTAL_ZINDEX:number = 9999;

type TOpenValue = {
  reconciliationPaymentAccountId?: TID | undefined
  financialInstitutionBankAccountId: TID
  businessId: TID
}

interface EmployeeCardMappingModalProps extends IUseModalProps<TOpenValue> {
  managementGroupId: TID
  refetchFinancialConnectionList: () => void
}

const EmployeeCardMappingModal: React.FC<EmployeeCardMappingModalProps> = ({
  isOpen,
  openValue,
  onCancel,
  onDone,
  managementGroupId,
  refetchFinancialConnectionList,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {
    reset,
    control,
    handleSubmit,
  } = useForm<IEmployeeCardMappingInput>();

  const createFinancialInstitutionEmployeeMappingMutation = useCreateFinancialInstitutionEmployeeMapping(
    openValue ? openValue.financialInstitutionBankAccountId : 0,
  );

  // Query to fetch credit card accounts for the selected business
  // Only enabled when modal is open and businessId is available
  const employeeCardQuery = useByReconciliationPaymentAccountId({
    reconciliationPaymentAccountId: openValue?.reconciliationPaymentAccountId,
    businessId:                     openValue?.businessId ?? 0,
    noConsiderArchive:              true,
  }, isOpen && !!openValue?.businessId);

  if (employeeCardQuery.isError) {
    toastr.error(employeeCardQuery.error.message, 'Error');
    onCancel();
  }

  // Query to fetch businesses associated with the management group
  // Only enabled when modal is open
  const businessQuery = useGetBusinessesByManagementGroup({ managementGroupId }, {
    enabled: isOpen,
  });

  // Query to fetch account owners for the selected financial institution bank account and reconciliation payment account
  // Only enabled when modal is open and both IDs are available
  const accountOwnerQuery = useGetFinancialInstitutionAccountOwners({
    financialInstitutionBankAccountId: openValue?.financialInstitutionBankAccountId,
    reconciliationPaymentAccountId:    openValue?.reconciliationPaymentAccountId,
  }, {
    enabled: isOpen && !!openValue?.financialInstitutionBankAccountId && !!openValue?.reconciliationPaymentAccountId,
  });

  const businessOptions: TOption[] = useMemo(() => businessQuery.data?.collection.map((business) => ({
    value: String(business.id),
    label: business.name,
  })) || [], [businessQuery.data]);

  const employeeCardOptions: TOption[] = useMemo(() => employeeCardQuery.data?.paymentAccounts
    .map((employeeCard) => ({
      value: String(employeeCard.id),
      label: employeeCard.name,
    })) || [], [employeeCardQuery.data]);

  const resetForm = useCallback(() => {
    setErrorMessage(undefined);
    reset();
  }, [reset]);

  const onSubmit = useCallback((values: IEmployeeCardMappingInput) => {
    // Get only valid mappings where both IDs are present
    const validMappings = values.financialInstitutionEmployeeMapping.filter(
      ({ businessId, paymentAccountId }) => businessId && paymentAccountId,
    );

    // Early return if no valid mappings
    if (!validMappings.length) {
      setErrorMessage('Please provide at least one valid mapping');
      return;
    }

    // Check for duplicate payment accounts by comparing array length with unique values
    const paymentAccountIds = validMappings.map((mapping) => mapping.paymentAccountId);
    const uniquePaymentAccountIds = new Set(paymentAccountIds);

    if (paymentAccountIds.length !== uniquePaymentAccountIds.size) {
      setErrorMessage('An employee card can be mapped to one account owner only.');
      return;
    }

    if (!openValue?.reconciliationPaymentAccountId) {
      setErrorMessage('Reconciliation payment account is required');
      return;
    }

    // Create the filtered input object with type safety
    const submissionData: IEmployeeCardMappingInput = {
      reconciliationPaymentAccountId:      openValue?.reconciliationPaymentAccountId,
      financialInstitutionEmployeeMapping: validMappings,
    };

    createFinancialInstitutionEmployeeMappingMutation.mutate({
      financial_institution_employee_mapping: submissionData.financialInstitutionEmployeeMapping,
      reconciliation_payment_account_id:      submissionData.reconciliationPaymentAccountId,
    }, {
      onSuccess: () => {
        toastr.success('Account owners have been mapped successfully', 'Success');
        resetForm();
        onDone();
        refetchFinancialConnectionList();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  }, [openValue, onDone, resetForm, createFinancialInstitutionEmployeeMappingMutation, refetchFinancialConnectionList]);

  const handleOnCancel = useCallback(() => {
    resetForm();
    onCancel();
  }, [resetForm, onCancel]);

  const isLoading = useMemo(
    () => businessQuery.isLoading
      || accountOwnerQuery.isLoading
      || employeeCardQuery.isLoading,
    [businessQuery.isLoading, accountOwnerQuery.isLoading, employeeCardQuery.isLoading],
  );

  const handleManageEmployeeCards = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(
      employeeCardsPath(openValue?.businessId ?? 0, openValue?.reconciliationPaymentAccountId ?? 0),
      '_blank',
    );
  }, [openValue]);

  return (
    <BaseEmployeeCardMappingModal
      footerContent={ (
        <>
          <CancelModalButton className="cancel-button" onClick={ handleOnCancel }>
            Cancel
          </CancelModalButton>
          <Button
            bsColor="blue"
            disabled={ createFinancialInstitutionEmployeeMappingMutation.isLoading }
            form="employee-card-mapping-form"
            isLoading={ createFinancialInstitutionEmployeeMappingMutation.isLoading }
            type="submit"
          >
            Save
          </Button>
        </>
      ) }
      isOpen={ isOpen }
      manageEmployeeCardLinkContent={ (
        <div className="manage-employee-card-link-content">
          <a href="#" onClick={ handleManageEmployeeCards }>
            Manage Employee Cards
            {' '}
            <LaunchIcon color="highlight" fontSize={ 16 } />
          </a>
        </div>
      ) }
      subtitle="First, map the employee cards from the financial institution to Docyt, then assign a business to them."
      title="Map Employee Cards from Financial Institution to Docyt"
    >
      <Form id="employee-card-mapping-form" onSubmit={ handleSubmit(onSubmit) }>
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
        {isLoading ? <Spinner /> : (
          <EmployeeCardMappingTable>
            {accountOwnerQuery.data?.financialInstitutionEmployeeMappings.map((row, index) => (
              <div key={ `mapping-row-${row.id}` } className="mapping-row">
                <div className="mapping-cell owner">
                  {row.accountOwner}
                  <Controller<IEmployeeCardMappingInput>
                    control={ control }
                    defaultValue={ row.accountOwner }
                    name={ `financialInstitutionEmployeeMapping.${index}.accountOwner` }
                    render={ ({ field: { ...restField } }) => (
                      <input type="hidden" { ...restField } value={ String(row.accountOwner) } />
                    ) }
                  />
                </div>
                <div className="mapping-cell">
                  <Controller<IEmployeeCardMappingInput>
                    control={ control }
                    name={ `financialInstitutionEmployeeMapping.${index}.paymentAccountId` }
                    render={ ({ field: { value, onChange, ...field } }) => (
                      <SelectInput
                        className="mapping-select"
                        menuPlacement="auto"
                        menuPortalTarget={ document.body }
                        options={ employeeCardOptions || [] }
                        placeholder="Select Employee Card"
                        styles={ {
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: SELECT_MENU_PORTAL_ZINDEX,
                          }),
                        } }
                        value={ employeeCardOptions.find((option) => option.value === value) || null }
                        onChange={ (option) => onChange(option?.value) }
                        { ...field }
                      />
                    ) }
                  />
                </div>
                <div className="mapping-cell">
                  <Controller<IEmployeeCardMappingInput>
                    control={ control }
                    name={ `financialInstitutionEmployeeMapping.${index}.businessId` }
                    render={ ({ field: { value, onChange, ...field } }) => (
                      <SelectInput
                        className="mapping-select"
                        menuPlacement="auto"
                        menuPortalTarget={ document.body }
                        options={ businessOptions || [] }
                        placeholder="Select Business"
                        styles={ {
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: SELECT_MENU_PORTAL_ZINDEX,
                          }),
                        } }
                        value={ businessOptions.find((option) => option.value === value) || null }
                        onChange={ (option) => onChange(option?.value) }
                        { ...field }
                      />
                    ) }
                  />
                </div>
              </div>
            ))}
          </EmployeeCardMappingTable>
        )}
      </Form>
    </BaseEmployeeCardMappingModal>
  );
};

const useEmployeeCardMappingModal = makeUseModal<typeof EmployeeCardMappingModal, TOpenValue>(EmployeeCardMappingModal);

export {
  EmployeeCardMappingModal as default,
  useEmployeeCardMappingModal,
  EmployeeCardMappingModalProps,
};
