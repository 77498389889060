import { useState, useEffect } from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

const useVendorProfileNavigation = (businessId: TID, vendorId: TID) => {
  const [vendorProfileUrl, setVendorProfileUrl] = useState<string>(
    `/businesses/${businessId}/vendor_service/payees/${vendorId}`,
  );
  const feature = window.Docyt.Common.Constants.NEW_VENDOR_PROFILE_FLAG;
  const userId = window.Docyt.currentAdvisor.id;
  const { data: newVendorProfileEnabled } = useLDUserFeatureQuery(
    userId,
    feature,
  );

  const url = newVendorProfileEnabled
    ? `/businesses/${businessId}/vendor_service/payees/${vendorId}/profile`
    : `/businesses/${businessId}/vendor_service/payees/${vendorId}`;

  useEffect(() => {
    setVendorProfileUrl(url);
  }, [url, setVendorProfileUrl]);

  return { vendorProfileUrl };
};

export default useVendorProfileNavigation;
