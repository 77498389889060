import React, { useState } from 'react';

import toastr from '@lib/toastr';
import { useAskDocytAI } from '@src/hooks/queries/transaction_service_documents';

import AsyncAsk from '@src/components/reconciliation_center/ask_docyt_ai/async_ask';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { BotNotificationIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/reconciliation_center/view_transaction_context/styles.module.scss';

const AskAIButton: React.FC<{ askDocytAIData: {id: number, businessId: number} }> = ({ askDocytAIData }) => {
  const { id, businessId } = askDocytAIData;
  const { mutateAsync, isSuccess } = useAskDocytAI();
  const [asyncAsk, setAsyncAsk] = useState(false);
  const onClick = async () => {
    try {
      const { transactionServiceDocument, asyncPredictionInitiated } = await mutateAsync({ id, businessId });
      // toastr.success('Ask Docyt AI Successfully.', 'Success');
      if (transactionServiceDocument) {
        window.Docyt.vent.trigger('transaction:update', transactionServiceDocument);
      } else if (asyncPredictionInitiated) {
        setAsyncAsk(true);
      }
    } catch (error: any) {
      const errors = error?.response?.data?.errors;
      if (errors) {
        const errorMessages = Object.values(errors);
        const errorMessageString = errorMessages.join(' ');
        toastr.error(errorMessageString, 'Error');
      } else {
        toastr.error('Failed to ask Docyt AI.', 'Error');
      }
    }
  };
  return (
    <>
      {asyncAsk && <AsyncAsk askDocytAIData={ askDocytAIData } /> }
      <div className={ styles['context-summary'] }>
        <Tooltip.Hover
          content={ isSuccess ? 'Suggested. Review below.' : '' }
        >
          <Button
            className={ styles['context-header'] }
            disabled={ isSuccess }
            variant="outline"
            onClick={ onClick }
          >
            <BotNotificationIcon className="in-white" fontSize={ 22 } />
            <span>Ask Docyt AI</span>
          </Button>
        </Tooltip.Hover>
      </div>
    </>

  );
};

export default AskAIButton;
