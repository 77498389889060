import { useEffect, useState } from 'react';

import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { IPortfolio } from '@src/types/portfolio';

export const useSelectedPortfolio = () => {
  const portfolioResp = useGetPortfolio();
  const PORTFOLIO_PAGES = window.Docyt.Common.Constants.PORTFOLIO_PAGES;
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<number | null>(null);
  const [selectedPortfolioData, setSelectedPortfolioData] = useState<IPortfolio>();
  const [currentActivePage, setCurrentActivePage] = useState<string>(PORTFOLIO_PAGES.dashboard);

  const path = window.location.pathname.split('/');

  useEffect(() => {
    if (path.length > 2 && path[1] === 'portfolio_dashboards') {
      setSelectedPortfolioId(Number(path[2]));

      if (portfolioResp?.data?.portfolios && portfolioResp.data.portfolios.length > 0) {
        const portfolio = portfolioResp.data.portfolios.find((portfolio) => portfolio.id === Number(path[2]));
        setSelectedPortfolioData(portfolio);
      }

      if (path[3]) {
        setCurrentActivePage(path[3]);
      } else {
        setCurrentActivePage(PORTFOLIO_PAGES.dashboard);
      }
    }
  }, [PORTFOLIO_PAGES.dashboard, path, portfolioResp.data]);

  return { selectedPortfolioId, currentActivePage, selectedPortfolioData };
};
