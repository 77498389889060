import React from 'react';

import Table from '@src/components/ui_v2/table';
import { TLoadingVariant } from '@src/components/ui_v2/table/types';

import LoadingCell from './loading_cell';
import { TColumn, TModel } from './types';

interface ICollectionTableLoadingRowProps<Model extends TModel, TSortColumn extends string = never> {
  columns: TColumn<Model, TSortColumn>[],
  showSelect?: boolean,
  variant: TLoadingVariant
}

const CollectionTableLoadingRow = <Model extends TModel, TSortColumn extends string = never>({
  columns,
  showSelect,
  variant,
}: ICollectionTableLoadingRowProps<Model, TSortColumn>) => {
  return (
    <Table.Row>
      { showSelect && <Table.IconLoadingCell variant={ variant } /> }
      { columns.map((column) => (
        <LoadingCell<Model, TSortColumn>
          key={ String(column.name) }
          column={ column }
          variant={ variant }
        />
      )) }
    </Table.Row>
  );
};

const MemoizedCollectionTableLoadingRow = React.memo(CollectionTableLoadingRow) as typeof CollectionTableLoadingRow;

export { ICollectionTableLoadingRowProps, MemoizedCollectionTableLoadingRow as default };
