import React, { useCallback } from 'react';

import { useRequestBankStatement } from '@src/hooks/queries/bank_statements';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { IReviewedBankStatementState } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import { useStartDateErrorModal } from '@src/components/banking_accounts/bank_statements/bank_statement_details/error_modals/start_date_error_modal';
import { useValidationErrorModal } from '@src/components/banking_accounts/bank_statements/bank_statement_details/error_modals/validation_error_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import MutationStatus from '@src/components/utils/mutation_status';

interface IRequestStatementActionProps {
  businessId: TID,
  state: IReviewedBankStatementState,
  rcPaymentAccountId?: TID,
}

const RequestStatementAction = ({
  businessId,
  state,
  rcPaymentAccountId = 10,
}: IRequestStatementActionProps): JSX.Element => {
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  const requestBankStatement = useRequestBankStatement();
  const { mutate } = requestBankStatement;

  const startDateErrorModal = useStartDateErrorModal();
  const { openWithValue: openStartDateErrorModal } = startDateErrorModal;

  const validationErrorModal = useValidationErrorModal();
  const { open: openValidationError } = validationErrorModal;

  const redirectToClientCenteral = useCallback(() => {
    Backbone.history.navigate(
      sunsetClientCentralEnabled
        ? documentRequestsPath(businessId) : clientCenteralPath(businessId),
      { trigger: true },
    );
  }, [businessId, sunsetClientCentralEnabled]);

  const handleRequestStatement = useCallback(() => {
    const firstDate = new Date(state.month);
    const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
    mutate({
      businessId,
      reconciliationPaymentAccountId: rcPaymentAccountId,
      date:                           formatDate(lastDate, API_DATE_FORMAT),
    }, {
      onSuccess: () => redirectToClientCenteral(),
      onError:   (response) => {
        if (response.message
            === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_START_DATE_ERROR_MSG) {
          openStartDateErrorModal({
            rcPaymentAccountId,
            bankStatement: state,
          });
        }

        if (response.message
          === window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG) {
          openValidationError();
        }
      },
    });
  }, [businessId,
    mutate,
    openStartDateErrorModal,
    openValidationError,
    rcPaymentAccountId,
    redirectToClientCenteral,
    state,
  ]);

  return (
    <>
      <MutationStatus mutation={ requestBankStatement } />
      <startDateErrorModal.Component
        businessId={ businessId }
        needUpdate={ false }
        { ...startDateErrorModal.props }
      />
      <validationErrorModal.Component
        { ...validationErrorModal.props }
        message={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG }
        title={ window.Docyt.Common.Constants.Messages.BANK_STATEMENT_ARCHIVED_ERROR_MSG_TITLE }
      />
      <ActionsDropdown.Action
        title="Request Statement"
        onClick={ handleRequestStatement }
      />
    </>
  );
};

export default RequestStatementAction;
