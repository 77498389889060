import { MouseEvent } from 'react';

import { currencyLocaleFormatter } from '@src/utils/currency';

const dollarFormatter = (data: number) => {
  const value = Number(data.toFixed(2));
  let valueString = '';

  const absValue = Math.abs(value);
  if (absValue >= 1000) valueString = `${(absValue / 1000).toString()}K`;
  else valueString = `${absValue.toString()}`;

  if (value >= 0) return `$${valueString}`;
  return `-$${valueString}`;
};

const percentFormatter = (data: number) => {
  const value = data.toFixed(2);
  return `${value}%`;
};

const countFormatter = (data: number) => {
  const value = data.toFixed(2);
  return `${value}`;
};

const numberFormatterMaker = (unitType: string) => {
  switch (unitType) {
    case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR:
      return dollarFormatter;
    case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.PERCENT:
      return percentFormatter;
    default:
      return countFormatter;
  }
};

const dollarTooltipLabelHandler = (context: any) => {
  let label = `${context.dataset.label || ''}: `;
  if (context.parsed.y === null) return label;
  if (context.parsed.y === 0) label += '$0';
  else label += currencyLocaleFormatter(context.parsed.y);
  return label;
};

const percentTooltipLabelHandler = (context: any) => {
  let label = `${context.dataset.label || ''}: `;
  if (context.parsed.y === null) return label;
  label += `${context.parsed.y}%`;
  return label;
};

const countTooltipLabelHandler = (context: any) => {
  let label = `${context.dataset.label || ''}: `;
  if (context.parsed.y === null) return label;
  label += `${context.parsed.y}`;
  return label;
};

const tooltipLabelHandlerMaker = (unitType: string) => {
  switch (unitType) {
    case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR:
      return dollarTooltipLabelHandler;
    case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.PERCENT:
      return percentTooltipLabelHandler;
    default:
      return countTooltipLabelHandler;
  }
};

const mouseCursorHandler = (event: any, elements: any) => {
  event.native.target.style.cursor = elements[0] ? 'pointer' : 'default';
};

const defaultBriefViewOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements:   {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip:        true,
        autoSkipPadding: 20,
        maxRotation:     0,
        minRotation:     0,
        align:           'start',
        color:           '#667380',
        font:            {
          size: 12,
        },
      },
    },
    y: {
      position: 'right',
      border:   {
        display: false,
      },
      ticks: {
        autoSkip:        true,
        autoSkipPadding: 20,
        beginAtZero:     true,
        color:           '#667380',
        callback:        null,
        font:            {
          size: 12,
        },
      },
    },
  },
};

export const briefViewOptions = (unitType: string) => {
  const option = JSON.parse(JSON.stringify(defaultBriefViewOptions));
  option.scales.y.ticks.callback = numberFormatterMaker(unitType);
  return option;
};

const defaultFullViewOptions = {
  responsive:  true,
  aspectRatio: 3,
  interaction: {
    mode: 'index' as 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: null,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip:        true,
        autoSkipPadding: 15,
        maxRotation:     0,
        minRotation:     0,
        color:           '#667380',
        font:            {
          size: 12,
        },
      },
    },
    y: {
      position: 'right' as const,
      border:   {
        display: false,
      },
      ticks: {
        autoSkip:    true,
        beginAtZero: true,
        color:       '#667380',
        callback:    null,
        font:        {
          size: 12,
        },
      },
    },
  },
  onClick: null,
  onHover: null,
};

export const fullViewOptions = (
  unitType: string,
  onMonthlyLaunch?: () => void,
  isStackedBar?: boolean,
) => {
  const option = JSON.parse(JSON.stringify(defaultFullViewOptions));
  option.scales.y.ticks.callback = numberFormatterMaker(unitType);
  option.plugins.tooltip.callbacks.label = tooltipLabelHandlerMaker(unitType);
  option.onClick = onMonthlyLaunch;
  option.onHover = mouseCursorHandler;
  if (isStackedBar) {
    option.scales.x.stacked = true;
    option.scales.y.stacked = true;
  }
  return option;
};
