import { useMemo } from 'react';

import { IDashboardData } from '../types';

/**
 * Calculates the number of columns in the table header to properly span empty state messages
 * The implementation returns a hardcoded value that should match the number of <th> elements
 * in the table header. This approach is used to avoid DOM nesting validation errors.
 */
export const calculateColspan = (): number => {
  // Default column count - should match the number of columns in the table
  return 6; // Update this value to match the actual number of columns in your table
};

/**
 * Hook that returns the calculated colspan value for use in table empty states
 * This approach avoids direct DOM manipulation during render which can cause issues
 */
export const useColspan = (): number => {
  // Use memoization to avoid recalculating on every render
  return useMemo(() => calculateColspan(), []);
};

/**
 * Utility function to check if the data is missing or incomplete
 * Works with both single objects and arrays
 */
export const hasNoData = (
  data: IDashboardData | IDashboardData[] | null | undefined,
): boolean => {
  if (!data) return true;

  // If it's an array, check if it's empty
  if (Array.isArray(data)) {
    return data.length === 0;
  }

  // For a single object, check for essential properties
  if (Object.keys(data).length === 0) return true;
  if (!data.businessId && !data.displayName) return true;

  return false;
};
