/* global BlobPropertyBag */

const createAndDownloadBlobFile = (body: any, fileName: string, opts?: BlobPropertyBag) => {
  const blob = new Blob([body], opts);
  // Browsers that support HTML5 download attribute
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const downloadFile = (fileUrl: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    link.setAttribute('href', fileUrl);
    link.setAttribute('download', fileUrl.split('/').pop() || 'file');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * Downloads multiple files sequentially with a delay between each download
 * to avoid browser download limitations
 * @param urls Array of file URLs to download
 * @param delayMs Optional delay between downloads in milliseconds (default: 1500)
 */
const downloadFilesSequentially = async (urls: string[], delayMs = 1500) => {
  const container = document.createElement('div');

  document.body.appendChild(container);

  try {
    // Use reduce to create a promise chain instead of await in a loop
    await urls.reduce(
      (promise, url, _index) => promise.then(() => {
        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        container.appendChild(link);

        link.click();

        // Remove link after clicking
        container.removeChild(link);

        // Return a promise that resolves after the delay
        return new Promise((resolve) => {
          setTimeout(resolve, delayMs);
        });
      }),
      Promise.resolve(),
    );
  } finally {
    document.body.removeChild(container);
  }
};

export {
  createAndDownloadBlobFile,
  downloadFile,
  downloadFilesSequentially,
};
