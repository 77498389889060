import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import VendorDetails from './details';

type TTabOption = {
  label: string,
  value: string,
  link: string,
};

interface IMyVendorsDetailPageProps {
  businessId: TID,
  serviceId: TID,
  vendor: any,
}

const MyVendorsDetailPage = ({ vendor, serviceId, businessId }: IMyVendorsDetailPageProps) => {
  return (
    <Root>
      <VendorDetails
        businessId={ businessId }
        serviceId={ serviceId }
        vendor={ vendor }
      />
    </Root>
  );
};

export default MyVendorsDetailPage;
