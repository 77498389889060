import React, { useEffect } from 'react';

import { useClearQueryCache } from '@src/utils/clear_query_cache';

const QueryCacheClear: React.FC = () => {
  const { clearCacheOnLogout } = useClearQueryCache();

  useEffect(() => {
    const handleCacheClear = () => {
      clearCacheOnLogout();
    };

    // Add listener for the custom event
    window.Docyt.vent.on('react:query:cache:clear', handleCacheClear);

    return () => {
      // Clean up the listener when component unmounts
      window.Docyt.vent.off('react:query:cache:clear');
    };
  }, [clearCacheOnLogout]);

  return null; // This component doesn't render anything
};

export default QueryCacheClear;
