import React, { memo } from 'react';

import Root from '@src/components/root';

import TaskManagementRouter from './router';

const TaskManagementMainView = () => {
  return (
    <Root>
      <TaskManagementRouter />
    </Root>
  );
};

export default memo(TaskManagementMainView);
