import React from 'react';

import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { UserAvatar } from '@src/components/ui/avatars';

import styles from './styles.module.scss';

interface IModalTitleProps {
  member?: IBusinessTeamMember;
}

const ModalTitle: React.FC<IModalTitleProps> = ({ member }) => {
  return (
    <div className={ styles['modal-title-wrapper'] }>
      <div>
        <UserAvatar avatar={ { avatarUrl: member?.avatar?.avatarUrl } } size={ 37 } />
      </div>
      <div>
        { member?.parsedFullname }
      </div>
    </div>
  );
};

export default ModalTitle;
