import React, { useCallback, useEffect, useMemo, useState } from 'react';

import toastr from '@lib/toastr';
import { useGetBusinessesForPortfolio } from '@src/hooks/queries/businesses';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useDeletePortfolio, useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { useLocalStorage } from '@src/hooks/storage';
import { chartOfAccountsPath, multiEntityReportsPath, portfolioDashboardsPath } from '@src/routes';
import { IPortfolio } from '@src/types/portfolio';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { useCreatePortfolioModal } from '@src/components/business_reports/custom_reports/modals/create_portfolio';
import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { useSelectedPortfolio } from '@src/components/portfolio_dashboard/hooks';
import Text from '@src/components/ui/text';
import { TrashIcon } from '@src/components/utils/fa_icons';
import { EditIcon } from '@src/components/utils/icomoon';

import ServiceMenuItem from './module_header_item';
import ModuleHeaderMenu from './module_header_menu';

import styles from '../styles.module.scss';

const ModulePortfolioHeaderActions = () => {
  const portfolioDeleteConfirmMsg = window.Docyt.Common.Constants.Messages.PORTFOLIO_DELETE_CONFIRM_MSG;
  const featureFlag = window.Docyt.Common.Constants.CHART_OF_ACCOUNTS_FLAG;
  const { data: isCOAEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    featureFlag,
  );

  const storedPortfolio = useLocalStorage('selectedPortfolio', {});

  const portfolios = useGetPortfolio();
  const { selectedPortfolioId, currentActivePage } = useSelectedPortfolio();

  const [selectedPortfolio, setSelectedPortfolio] = useState<IPortfolio>();

  const deletePortfolio = useDeletePortfolio();
  const { mutate: destroyPortfolio } = deletePortfolio;

  const handleDeletePortfolio = useCallback(() => {
    destroyPortfolio(Number(selectedPortfolioId), {
      onSuccess: () => {
        localStorage.removeItem('portfolio-id');
        storedPortfolio.setValue({});
        backboneNavigateToUrl('/');
        toastr.success('Portfolio Deleted Successfully', 'Success!');
      },
      onError: (error) => {
        toastr.error(error.message, 'Error!');
      },
    });
  }, [destroyPortfolio, selectedPortfolioId, storedPortfolio]);

  const ConfirmDeletePortfolioModal = useConfirmDeleteModal({
    onDone: handleDeletePortfolio,
  });

  const CreatePortfolioModal = useCreatePortfolioModal();

  useEffect(() => {
    if (portfolios.data) {
      const portfolio = portfolios.data.portfolios.find((p) => p.id === Number(selectedPortfolioId));
      setSelectedPortfolio(portfolio);
    }
  }, [portfolios, selectedPortfolioId]);

  const businessQuery = useGetBusinessesForPortfolio({
    forDataExport: true,
    search:        undefined,
    perPage:       100,
  });

  const businesses = useMemo(() => {
    const businessesPages = businessQuery.data?.collection || [];
    return businessesPages;
  }, [businessQuery.data?.collection]);

  const renderIcon = (icon: string) => {
    return (
      <span className={ `m-l-18 ${styles['icon-wrapper']}` }>
        {icon === 'edit' ? <EditIcon /> : <TrashIcon />}
      </span>
    );
  };

  const PORTFOLIO_PAGES = window.Docyt.Common.Constants.PORTFOLIO_PAGES;

  return (
    <div className={ styles['service-menu-page'] }>
      <ModuleHeaderMenu
        active={ currentActivePage === PORTFOLIO_PAGES.dashboard }
        title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.DASHBOARD }
        url={ portfolioDashboardsPath(Number(selectedPortfolioId)) }
      />
      <ModuleHeaderMenu
        active={ currentActivePage === PORTFOLIO_PAGES.reports }
        title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.REPORTS }
        url={ multiEntityReportsPath(Number(selectedPortfolioId)) }
      />
      {isCOAEnabled && (
        <ModuleHeaderMenu
          active={ currentActivePage === PORTFOLIO_PAGES.coa }
          title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.COA }
          url={ chartOfAccountsPath(Number(selectedPortfolioId)) }
        />
      )}
      <ModuleHeaderMenu
        active={ false }
        title={ window.Docyt.Common.Constants.MODULE_PORTFOLIO_HEADER_ITEMS.SETTINGS }
      >
        <ServiceMenuItem
          icon={ renderIcon('edit') }
          serviceUrl=""
          title="Edit Portfolio"
          onClickHandler={ () => CreatePortfolioModal.open() }
        />
        <ServiceMenuItem
          icon={ renderIcon('trash') }
          serviceUrl=""
          title="Delete Portfolio"
          onClickHandler={ () => ConfirmDeletePortfolioModal.open() }
        />
        <ConfirmDeletePortfolioModal.Component
          { ...ConfirmDeletePortfolioModal.props }
          confirmStyle="primary"
          confirmTitle="Proceed"
          text={ (
            <Text as="span" dangerouslySetInnerHTML={ { __html: portfolioDeleteConfirmMsg } } />
          ) }
          title="Delete Portfolio"
        />
        <CreatePortfolioModal.Component
          { ...CreatePortfolioModal.props }
          isEdit
          businesses={ businesses }
          portfolio={ selectedPortfolio }
        />
      </ModuleHeaderMenu>
    </div>
  );
};

export default React.memo(ModulePortfolioHeaderActions);
