import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { revenueServiceMappingPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import { Button } from '@src/components/ui_v2/buttons';
import Panel from '@src/components/ui_v2/panel';
import { MissingRevenueMappingLogo } from '@src/components/utils/img_icons';

import { useAddReportTypeModal } from '../report_type/add_report_type/add_report_type_modal';

import styles from './styles.module.scss';

interface MissingRevenueMappingProps {
  integrationId: TID | undefined,
  isEnabled: boolean | undefined,
  revenueReportTypeId: string | TID,
  revenueSystem?: IRevenueSystem,
  revenueService?: IRevenueService,
}

const MissingRevenueMapping = ({
  integrationId,
  isEnabled,
  revenueReportTypeId,
  revenueSystem,
  revenueService,
}: MissingRevenueMappingProps) => {
  const business = useBusinessContext();
  const integration = useGetIntegration({
    integrationId,
    businessId: business.id,
  });
  const addReportTypeModal = useAddReportTypeModal();

  const handleOnMapNow = useCallback(() => {
    if (!revenueReportTypeId) {
      addReportTypeModal.open();
    }
    if (revenueReportTypeId && (isEnabled === false || isEnabled == null)) {
      const url = revenueServiceMappingPath(business.id, Number(revenueReportTypeId));
      Backbone.history.navigate(url, { trigger: true });
    }
  }, [addReportTypeModal, business.id, revenueReportTypeId, isEnabled]);
  return (
    <>
      <Panel.Landing
        image={ <MissingRevenueMappingLogo size="115px" /> }
      >
        <div className={ styles['empty-wrapper'] }>
          <p className={ styles['title-empty'] }>
            {!revenueReportTypeId ? 'Create' : 'Complete'}
            {' '}
            Revenue Mapping
          </p>
          <p className={ styles['description-empty'] }>
            Before adding your revenue reports, you need to
            {' '}
            {!revenueReportTypeId ? 'define' : 'complete'}
            {' '}
            Revenue Mapping.
          </p>
        </div>
        <Button
          className={ styles['mapnow-button'] }
          variant="primary"
          onClick={ handleOnMapNow }
        >
          Map Now
        </Button>
      </Panel.Landing>
      <addReportTypeModal.Component
        integration={ integration.data }
        revenueService={ revenueService }
        revenueSystem={ revenueSystem }
        { ...addReportTypeModal.props }
      />
    </>
  );
};

export default React.memo(MissingRevenueMapping);
