import React from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { TLoadingVariant } from '@src/components/ui_v2/table/types';

import Cell, { ITableCellProps } from '../cell';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './styles.module.scss';

interface IIconLoadingCellProps extends Omit<ITableCellProps, 'children'> {
  variant: TLoadingVariant,
}

const IconLoadingCell = ({
  className,
  variant,
  ...cellProps
}: IIconLoadingCellProps) => {
  const classes = classNames(styles['icon-skeleton'], className);

  const loadingCellClass = classNames(styles.size, {
    [styles['ai-loading-cell']]: variant === 'ai',
  });

  return (
    <Cell { ...cellProps } className={ classes }>
      <Skeleton className={ loadingCellClass } />
    </Cell>
  );
};

export default IconLoadingCell;
