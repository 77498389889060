import React from 'react';

import { CheckboxInput } from '@src/components/ui_v2/inputs';

import { UserIconSelectOption } from './user_icon_select_option';
import { ITaskIconOption } from '../types';

import styles from './styles.module.scss';

interface IFormatOptionLabelProps {
  data: ITaskIconOption;
  onSingleChecked?:(checked : boolean, value:ITaskIconOption)=>void;
  onSelectAllChecked?:(checked : boolean)=>void;
  isAllSelected?:boolean;
  isMulti?:boolean;
  hideIcon?:boolean;
}
const FormatOptionLabel = ({ data, onSingleChecked, onSelectAllChecked, isAllSelected, isMulti, hideIcon }
    : IFormatOptionLabelProps) => {
  const handleChecked = (checked:boolean) => {
    if (onSingleChecked) onSingleChecked(checked, data);
  };
  const handleSelectAll = (checked:boolean) => {
    if (onSelectAllChecked) onSelectAllChecked(checked);
  };
  if (data?.value === 'select-all' && isMulti) {
    return (
      <div className={ styles['select-all-container'] }>
        <CheckboxInput checked={ isAllSelected ?? false } title="Select All" onChange={ handleSelectAll } />
      </div>
    );
  }
  return (
    <>
      {isMulti && <CheckboxInput checked={ data?.checked } title="" onChange={ handleChecked } />}
      <UserIconSelectOption
        context="menu"
        hideIcon={ hideIcon }
        icon={ data?.icon }
        label={ data?.label }
        subTitle={ data.helper }
        value={ data?.value }
      />
    </>
  );
};
export default FormatOptionLabel;
