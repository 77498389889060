import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { journalEntriesPath } from '@src/routes';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import styles from '@src/components/header/styles.module.scss';

const JournalEntriesAction: React.FC<{ businessId: TID }> = ({ businessId }) => {
  const { data: journalEntriesEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.JOURNAL_ENTRIES_FLAG,
  );

  if (journalEntriesEnabled) {
    return (
      <ActionsDropdown.LinkAction
        className={ styles['settings-item'] }
        href={ journalEntriesPath(businessId) }
        title="Journal Entries"
      />
    );
  }
  return null;
};

export default JournalEntriesAction;
