import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetReportServiceBudgetFormulas } from '@src/hooks/queries/report_service/report_service_budget_formulas';
import { useUpdateReportServiceBudgetItem } from '@src/hooks/queries/report_service/report_service_budget_items';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { IReportServiceBudgetItemValue } from '@src/types/report_service/report_service_budget_item_values';
import { IReportServiceBudgetItem } from '@src/types/report_service/report_service_budget_items';

import Table from '@src/components/ui/table';
import { ItemsSelectorProvider } from '@src/components/utils/items_selector';

import AutoFillMenu from './auto_fill_menu';
import Footer from './footer';
import TableBody from './table_body';
import TableFoot from './table_foot';
import TableHead from './table_head';

interface IBudgetDetailsTableProps {
  budgetItemsData: IReportServiceBudgetItem[],
  chartOfAccounts: IChartOfAccount[],
  monthTotalAmounts: number[],
  query: UseInfiniteQueryResult<any, Error>,
  isDraft: boolean,
  filterParams: any,
  onSetDraft: (value: boolean) => void,
  onCreateExportData: (result: boolean) => void,
  onSuccessUpload: (token: string) => void,
}

const BudgetDetailsTable = ({
  budgetItemsData,
  chartOfAccounts,
  monthTotalAmounts,
  query,
  isDraft,
  filterParams,
  onSetDraft,
  onCreateExportData,
  onSuccessUpload,
}: IBudgetDetailsTableProps): JSX.Element => {
  const business = useBusinessContext();
  const { data: formulaEnabled } = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.BUDGET_FORMULA_FLAG,
  );

  const budgetFormulasQuery = useGetReportServiceBudgetFormulas({
    standardCategoryId: business.standardCategoryId,
  });
  const budgetFormulas = useMemo(() => {
    return (
      budgetFormulasQuery.data?.filter((item) => item.standardIndustryIds === null
        || (Array.isArray(item.standardIndustryIds) && item.standardIndustryIds.includes(business.standardCategoryId)))
        || []
    );
  }, [budgetFormulasQuery.data, business.standardCategoryId]);

  const [totalAmounts, setTotalAmounts] = useState<number[]>(monthTotalAmounts);
  const budget = useReportServiceBudgetContext();
  const { mutate: updateBudgetItem } = useUpdateReportServiceBudgetItem();
  const [isSelectDropOpen, setIsSelectDropOpen] = useState<string>('');

  const [chartOfAccountData, setChartOfAccountData] = useState<IReportServiceBudgetItem[]>([]);
  const [standardMetricData, setStandardMetricData] = useState<IReportServiceBudgetItem[]>([]);
  const [reportLineData, setReportLineData] = useState<IReportServiceBudgetItem[]>([]);

  useEffect(() => {
    // To get the filter data only once so it will not refresh the updated local data with the old values
    if (budgetItemsData.length > 0) {
      const filteredChartOfAcc = budgetItemsData.filter((item) => item.chartOfAccountId);
      setChartOfAccountData(filteredChartOfAcc);

      const filteredStandardMetric = budgetItemsData.filter((item) => item.standardMetricId);
      setStandardMetricData(filteredStandardMetric);

      const filteredReportLine = budgetItemsData.filter((item) => item.reportLineId);
      setReportLineData(filteredReportLine);
    }
  }, [budgetItemsData]);

  useEffect(() => {
    setTotalAmounts(monthTotalAmounts);
  }, [monthTotalAmounts]);

  const handleChange = useCallback((
    item: IReportServiceBudgetItem,
    month: number,
    newValue: number,
    itemIndex: number,
    type: string,
  ) => {
    let tempData: IReportServiceBudgetItem[] = [];
    if (type === 'chartOfAccount') {
      tempData = [...chartOfAccountData];
    } else if (type === 'standardMetric') {
      tempData = [...standardMetricData];
    } else if (type === 'reportLine') {
      tempData = [...reportLineData];
    }
    const currentValue = tempData[itemIndex]?.budgetItemValues[month]?.value;
    const budgetItemValues: IReportServiceBudgetItemValue[] = tempData[itemIndex]?.budgetItemValues.map(
      (budgetItemValue) => {
        return {
          id:    budgetItemValue.id,
          month: budgetItemValue.month,
          value: budgetItemValue.month === month + 1 ? newValue : budgetItemValue.value,
        };
      },
    );
    tempData[itemIndex].budgetItemValues = budgetItemValues;
    if (type === 'chartOfAccount') {
      setChartOfAccountData(tempData);
    } else if (type === 'standardMetric') {
      setStandardMetricData(tempData);
    } else if (type === 'reportLine') {
      setReportLineData(tempData);
    }

    if (!item.standardMetricId) {
      setTotalAmounts((prev) => {
        const newTotalAmounts = [...prev];
        newTotalAmounts[month] += newValue - currentValue;
        return newTotalAmounts;
      });
    }

    updateBudgetItem(
      {
        budgetId: budget.id,
        id:       item.id,
        budgetItemValues,
      },
    );
  }, [budget.id, updateBudgetItem, chartOfAccountData, standardMetricData, reportLineData]);

  return (
    <ItemsSelectorProvider allItems={ budgetItemsData }>
      <AutoFillMenu
        filterParams={ filterParams }
        onCreateExportData={ onCreateExportData }
        onSetDraft={ onSetDraft }
        onSuccessUpload={ onSuccessUpload }
      />
      <Table
        isRegionScroll
        infiniteQuery={ query }
        wrapperClassName="report-budget-details-table"
      >
        <TableHead
          budget={ budget }
          formulaEnabled={ formulaEnabled }
        />
        <TableBody
          budgetFormulas={ budgetFormulas }
          budgetItems={ budgetItemsData }
          chartOfAccountData={ chartOfAccountData }
          chartOfAccounts={ chartOfAccounts }
          formulaEnabled={ formulaEnabled }
          isSelectDropOpen={ isSelectDropOpen }
          setIsSelectDropOpen={ setIsSelectDropOpen }
          onChange={ handleChange }
          onSetDraft={ onSetDraft }
        />
        <TableFoot
          formulaEnabled={ formulaEnabled }
          totalAmounts={ totalAmounts }
        />
      </Table>
      <Footer
        isDraft={ isDraft }
        onSetDraft={ onSetDraft }
      />
    </ItemsSelectorProvider>
  );
};

export {
  BudgetDetailsTable as default,
};
