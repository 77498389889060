import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { GroupBase, MultiValue, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getBusinessesUsers } from '@src/requests/task_service_documents';

import { AsyncPaginateIconSelectInput, TIconOption, TOption } from '@src/components/ui_v2/inputs';
import { ReactSelectDropdownIndicator } from '@src/components/ui_v2/inputs/react_select/helpers';

import { formatSelectOption } from './user_icon_select_option';

import styles from '../styles.module.scss';

interface AsyncSelectTaskProps {
  selectedBusiness: TOption | null;
  setSelectedBusiness: (val: TOption) => void;
  disabled?: boolean;
}
const TaskBusinessSelect = ({ selectedBusiness, setSelectedBusiness, disabled }: AsyncSelectTaskProps) => {
  const perPage = 10;
  const getBusinessOptions = useCallback(
    (allOptions: TOption[], selectedOption: TOption) => {
      const newOption = {
        label: selectedOption.label,
        value: String(selectedOption.value),
      };
      const isAlreadyIncluded = allOptions.some((option) => option.value === selectedOption.value);

      return isAlreadyIncluded ? allOptions : [newOption, ...allOptions];
    },
    [],
  );

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> = useCallback(
    (_, options, { page }) => {
      return getBusinessesUsers({ page, perPage }).then((data) => {
        const hasMore = data.collection?.length > 0;
        const newOptions = data.collection
          .filter((item) => String(item.id) !== selectedBusiness?.value)
          .map((user) => ({
            label: user?.displayName,
            value: String(user?.id),
          }));
        return {
          hasMore,
          options: selectedBusiness?.value && page === 1
            ? getBusinessOptions(newOptions, selectedBusiness) : newOptions,
          additional: { page: page + 1 },
        };
      }).catch(() => {
        return {
          hasMore:    false,
          options:    [],
          additional: { page },
        };
      });
    },
    [getBusinessOptions, selectedBusiness],
  );
  const handleChange = useCallback(
    (e: MultiValue<TOption> | SingleValue<TOption>) => {
      setSelectedBusiness(e as TOption);
    },
    [setSelectedBusiness],
  );
  const classes = classNames({
    [styles['select-disabled']]: disabled,
    [styles['select-pointer']]:  !disabled,
  });

  const selectStyles = useMemo(() => ({
    menu: (provided: any) => ({
      ...provided,
      maxWidth: 400,
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight:    32,
      height:       32,
      borderColor:  'rgba(7, 12, 37, 0.08)',
      borderRadius: 6,
      cursor:       disabled ? 'not-allowed' : 'pointer',
    }),
  }), [disabled]);

  return (
    <div className={ classes }>
      <AsyncPaginateIconSelectInput
        additional={ { page: 1 } }
        className={ styles['task-select-input'] }
        classNamePrefix={ styles['task-select-input'] }
        components={ {
          DropdownIndicator: ReactSelectDropdownIndicator,
        } }
        debounceTimeout={ 300 }
        formatOptionLabel={ formatSelectOption }
        isClearable={ false }
        isDisabled={ disabled }
        isSearchable={ false }
        loadOptions={ handleSource }
        styles={ selectStyles }
        value={ selectedBusiness }
        onChange={ handleChange }
      />
    </div>
  );
};
export default TaskBusinessSelect;
