import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import EditMappingAction from './edit_mapping_action';
import ExportReportAsExcelAction from './export_report_as_excel_action';
import ExportReportAsPdfAction from './export_report_as_pdf_action';
import RenameReportAction from './rename_report_action';
import ReportRefreshViewAction from './report_refresh_view_action';

interface IReportDetailHeaderActionsProps {
  businessId: TID,
  report: IReport,
  category?: TReportCategory,
}

const ReportDetailHeaderActions = ({
  businessId,
  report,
  category,
}: IReportDetailHeaderActionsProps): JSX.Element => {
  const { data: showRefreshLogOption } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.BUSINESS_REPORT_REFRESH_LOG_ENABLED,
  );
  const { data: exportAsPdfEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.EXPORT_REPORT_AS_PDF_FLAG,
  );

  return (
    <ActionsDropdown className="m-l-auto m-r-15 m-t-5">
      <RenameReportAction report={ report } />
      <EditMappingAction category={ category } report={ report } />
      <ExportReportAsExcelAction
        businessId={ businessId }
        category={ category }
        report={ report }
      />
      {showRefreshLogOption && (
        <ReportRefreshViewAction report={ report } />
      )}

      { exportAsPdfEnabled && (
        <ExportReportAsPdfAction report={ report } />
      )}
    </ActionsDropdown>
  );
};

export default ReportDetailHeaderActions;
