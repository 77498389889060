import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getMultiBusinessReportItemAccountValues,
  IMultiBusinessReportItemAccountValuesParams,
  IMultiBusinessReportItemAccountValuesResponse,
  getMultiBusinessReportDetail,
  IMultiBusinessReportDetail,
  getMultiBusinessReports,
} from '@src/requests/report_service/multi_business_report';

const useGetMultiBusinessReportDetail = (multiReportId: string) => {
  return useQuery<IMultiBusinessReportDetail, Error>(
    [QueryKey.getMultiBusinessReportDetail, multiReportId],
    () => getMultiBusinessReportDetail(multiReportId),
  );
};

const useGetMultiBusinessReportItemAccountValuesQuery = (
  endPoint: string,
  params: IMultiBusinessReportItemAccountValuesParams,
) => {
  return useQuery<IMultiBusinessReportItemAccountValuesResponse, Error>(
    [QueryKey.multiBusinessReportItemAccountValues, params],
    () => getMultiBusinessReportItemAccountValues(endPoint, params),
  );
};

export const useGetMultiBusinessReports = (endPoint: string, params?: { portfolioId: string }) => {
  return useQuery<IMultiBusinessReportDetail[], Error>(
    [QueryKey.getMultiBusinessReports, params?.portfolioId],
    () => getMultiBusinessReports(endPoint, params),
    {
      enabled: !!params?.portfolioId,
    }
  );
};

export {
  useGetMultiBusinessReportItemAccountValuesQuery,
  useGetMultiBusinessReportDetail,
};
