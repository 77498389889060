import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import { useGetTaskNotificationData } from './hooks';
import TaskNotificationBadge from './task_notification_badge';
import TaskNotificationItemList from './task_notification_item_list';

import styles from './styles.module.scss';

const TaskNotificationPage = () => {
  const collection = useGetTaskNotificationData();
  const [toggle, setToggle] = useState<boolean>(false);
  const checkAdvisorStatus = useCallback(() => {
    const advisor = window.Docyt?.currentAdvisor;
    if (!advisor) {
      return false;
    }
    const businessIntent = advisor?.get('default_accounting_firm')?.business_intent;
    const isAccountingUser = advisor.get('user_on_boarding_status')?.user_type === 'accountant';
    return isAccountingUser && (businessIntent === 'FirmClosingSuite' || businessIntent === 'PremiumFirmClosingSuite');
  }, []);
  const isTaskNotificationEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.TASK_MANAGEMENT);
  const classes = useMemo(() => classNames(styles['header-task-dropdown'], 'header-item-dropdown'), []);

  const handleToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  if (!(isTaskNotificationEnabled && checkAdvisorStatus())) {
    return null;
  }
  return (
    <ActionsDropdown
      className={ classes }
      title={ (
        <TaskNotificationBadge
          count={ collection?.totalCount ?? 0 }
          onClick={ handleToggle }
        />
) }
      titleClassName="header__notifications"
    >
      <TaskNotificationItemList
        query={ collection.query }
        records={ collection.records }
        setToggle={ setToggle }
        toggle={ toggle }
      />
    </ActionsDropdown>
  );
};

export default React.memo(TaskNotificationPage);
