import React, { useCallback } from 'react';

import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { useProfileModal } from '@src/components/settings/my_team/components/modal/view_profile_modal';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { EditProfileIcon } from '@src/components/utils/icomoon';

interface IViewProfileActionProps {
  member: IBusinessTeamMember;
}

const ViewProfileAction: React.FC<IViewProfileActionProps> = ({ member }) => {
  const profileModal = useProfileModal();

  const handleClick = useCallback(() => {
    profileModal.open();
  }, [profileModal]);

  return (
    <>
      <ActionsDropdown.Action
        icon={ <EditProfileIcon fontSize={ 16 } /> }
        title="View Profile"
        onClick={ handleClick }
      />
      <profileModal.Component
        { ...profileModal.props }
        member={ member }
      />
    </>
  );
};

export default ViewProfileAction;
