import { IBusinessSimple } from '@src/types/businesses';
import { TID, TUUID } from '@src/types/common';
import { TFilterData } from '@src/types/filter';
import { ITaskLogItem, ITaskServiceDocument, ITaskServiceDocumentFilterQuery,
  TTasksDocumentsSortColumn } from '@src/types/task_service_documents';
import { IUser } from '@src/types/users';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { ILogEntry } from '@src/components/task_management/types';

import { IGetBusinessesParams } from './businesses';
import { apiDelete, apiGet, apiPatch, apiPost } from './helpers';

export interface IGetTaskServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: ITaskServiceDocument[],
}

export interface IGetTaskBusinessUsersResponse extends Pick<IGetTaskServiceDocumentsResponse, 'meta'> {
  collection: IBusinessSimple[],
}
export interface IGetTaskLogsDocumentsResponse extends Pick<IGetTaskServiceDocumentsResponse, 'meta'> {
  collection: ITaskLogItem[],
}
export interface IGetTaskServiceDocumentsParams {
  page?: number,
  orderColumn?: TTasksDocumentsSortColumn,
  queryType?: string,
  ps?:number;
  filter?:ITaskServiceDocumentFilterQuery;
}
export interface ICreateTaskBody extends Partial<ITaskServiceDocument> {
    closeDate?:string;
    notes?:string;
    userId?:TID;
}

export interface IDeleteTaskBody {
  taskId:string;
}
export interface IUpdateTaskProps extends Pick<ITaskServiceDocument, 'taskId'> {
  formData: ICreateTaskBody;
}
export interface IAssignTaskFormData {
  userId: string;
}
export interface IAssignTaskProps extends Pick<ITaskServiceDocument, 'taskId'> {
  formData: IAssignTaskFormData;
}

export interface ITaskDetailDocumentProps{
  params:IGetTaskLogsDocumentsParams;
  taskId:string;
}

export interface ISearchTaskUsersParams{
    businessId?: TID;
    search?: string;
    page: number;
    perPage: number;
    filter?: TFilterData;
}
interface ISearchTaskUsersResponse extends Pick<IGetTaskServiceDocumentsResponse, 'meta'> {
  collection: IUser[];
}
export interface IGetTaskLogsDocumentsParams extends IGetTaskServiceDocumentsParams{
  taskId?:string;
}
export const getTaskServiceDocuments = ({
  ...params
}: IGetTaskServiceDocumentsParams): Promise<IGetTaskServiceDocumentsResponse> => {
  const url = '/task/api/v1/task/all';
  return apiGet(
    url,
    {
      ...underscoreKeys(
        { ...params },
      ),
    },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.response,
      meta:       {
        totalCount: cdata.totalCount,
      },
    } as IGetTaskServiceDocumentsResponse;
  });
};
export const getSearchTaskUsers = (
  params: ISearchTaskUsersParams,
): Promise<ISearchTaskUsersResponse> => {
  return apiGet(
    '/api/v1/business_users',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.businessUsers,
    } as ISearchTaskUsersResponse;
  });
};
export const getSearchFilterBusinesses = (
  params: ISearchTaskUsersParams,
): Promise<ISearchTaskUsersResponse> => {
  return apiGet(
    '/task/api/v1/task/businesses',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata,
    } as ISearchTaskUsersResponse;
  });
};
export const getSearchFilterAssignees = (
  params: ISearchTaskUsersParams,
): Promise<ISearchTaskUsersResponse> => {
  return apiGet(
    '/task/api/v1/task/assignees',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata,
    } as ISearchTaskUsersResponse;
  });
};
export const getTaskServiceDocumentLogsDocuments = ({
  ...params
}: IGetTaskLogsDocumentsParams): Promise<IGetTaskLogsDocumentsResponse> => {
  const url = `/task/api/v1/log/${params.taskId}`;
  return apiGet(
    url,
    {
      ...underscoreKeys(
        { ...params },
      ),
    },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.response,
      meta:       {
        totalCount: cdata.totalElements,
      },
    } as IGetTaskLogsDocumentsResponse;
  });
};
interface ICreateTaskResponse {
  id: string;
  message: string
}
export const createTaskServiceDocument = (
  formData: ICreateTaskBody,
): Promise<ICreateTaskResponse> => {
  return apiPost(
    '/task/api/v1/task',
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const deleteTaskServiceDocument = ({ taskId }:IDeleteTaskBody): Promise<ICreateTaskResponse> => {
  return apiDelete(
    `/task/api/v1/task/${taskId}`,
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const updateTaskServiceDocument = ({ formData, taskId }: IUpdateTaskProps): Promise<ICreateTaskResponse> => {
  return apiPatch(
    `/task/api/v1/task/${taskId}`,
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const assignTaskServiceDocument = ({ formData, taskId }: IAssignTaskProps): Promise<ICreateTaskResponse> => {
  return apiPost(
    `/task/api/v1/task/${taskId}/assign`,
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as ICreateTaskResponse;
  });
};
export const getTaskServiceDocumentById = (
  { params, taskId }:ITaskDetailDocumentProps,
): Promise<ITaskServiceDocument> => {
  return apiGet(
    `/task/api/v1/task/${taskId}`,
    {
      ...underscoreKeys(
        { ...params },
      ),
    },
  ).then((result) => {
    return camelizeKeys(result) as ITaskServiceDocument;
  });
};
export const getTaskServiceDocumentLogsById = (taskId: TUUID): Promise<ILogEntry[]> => {
  return apiGet(
    `/task/api/v1/task/${taskId}`,
  ).then((result) => {
    return camelizeKeys(result) as ILogEntry[];
  });
};

export const getBusinessesUsers = (
  params: IGetBusinessesParams,
): Promise<IGetTaskBusinessUsersResponse> => {
  return apiGet(
    '/api/v1/businesses/all_accounting_businesses',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.businesses as IBusinessSimple[],
    };
  });
};
