import React from 'react';

import styles from '../styles.module.scss';

const DropdownSection = ({ title, children }:{
  title?: string,
  children: React.ReactNode,
}) => (
  <div className={ styles['section-container'] }>
    { title && <div className={ styles['section-header'] }>{title}</div> }
    <div className={ styles['section-items'] }>
      {children}
    </div>
  </div>
);

export default DropdownSection;
