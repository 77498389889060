import { useEffect, useMemo } from 'react';

import { has, isNaN, isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { UseQueryResult } from 'react-query';
import { useAtomValue, useSetAtom } from 'jotai';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetReportBudgetComparerIds, useGetReportTemplates } from '@src/hooks/queries/report_service/custom_reports';
import { useGetReportConfigurations } from '@src/hooks/queries/report_service/report_configurations';
import { useGetReportByIdentifier, useGetReportItems } from '@src/hooks/queries/report_service/report_items';
import { TID, TSection } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';
import { IReport, ITemplate, TReportDataPeriodType } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IReportColumnFilter, IReportConfiguration, IReportFilter } from '@src/types/report_service/report_configurations';
import { IItemValue, IReportData, TColumnType } from '@src/types/report_service/report_data';
import { IItemAccount, IReportColumnValue, IReportItem } from '@src/types/report_service/report_item';
import { currencyFormater3 } from '@src/utils/currency';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import { multiSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import { useFilterData } from '@src/components/ui_v2/filter/hooks';
import { useSection } from '@src/components/utils_v2/section';

import { reportDataPeriodType, pickMultiMonths } from '../atoms';

interface IReportDatasCollection {
  query: UseQueryResult<[IReportData[], IReportItem[]], Error> | undefined;
  records?: IReportData[];
  items?: IReportItem[] | undefined;
  section: TSection;
}

interface IReportItemValuesCollection {
  query: UseQueryResult<IItemValue[], Error> | undefined,
  itemAccountValues: IItemAccount[],
  itemValues: IItemValue[],
  section: TSection
}

type TFilterData = {
  reportDataRange: TDateFilter;
};

interface IHeaderColumn {
  key:        number | string;
  name:       string;
  month?:     TID;
  year?:      TID;
  division:   boolean;
}

const reportFilterConfiguration = (configurations: IReportConfiguration[]) => {
  return (
    configurations.find(
      (configuration: IReportConfiguration) =>
        configuration.id === window.Docyt.Common.Constants.REPORT_CONFIGURATIONS.REPORT_FILTERS
    )?.reportFilter || []
  );
};

const multiRangeConfiguration = (configurations: IReportConfiguration[]) => {
  return (
    configurations.find(
      (configuration: IReportConfiguration) =>
        configuration.id === window.Docyt.Common.Constants.REPORT_CONFIGURATIONS.MULTI_RANGE_COLUMNS
    )?.monthlyColumns || []
  );
};

const multiRangeColumns = (report: IReport, ptdColumns: IReportColumn[], configurations: IReportConfiguration[]) => {
  const enabledColumns = multiRangeConfiguration(configurations);
  const columns: IReportColumn[] = [];

  enabledColumns.forEach((column: IReportColumnFilter) => {
    let ptdColumn: IReportColumn | undefined;
    if (!report.enabledBudgetCompare) return;

    if (report.version === 2) {
      ptdColumn = ptdColumns.find((col: IReportColumn) => col.identifier === column.id);
    } else {
      ptdColumn = ptdColumns.find(
        (col: IReportColumn) => col.type === (column.type || null)
        && col.perMetric === (column.perMetric || null)
        && col.range === (column.range || null)
        && col.year === (column.year || null)
      );
    }

    if (ptdColumn) {
      columns.push(ptdColumn);
    }
  });

  return columns;
};

const getFilteringColumnsData = (columns: IReportColumn[], filter: IReportFilter, version: number) => {
  let filteredColumns = columns;

  if (version === 2) {
    for (let i = 0; i < filter.columnFilter.length; i += 1) {
      const cf: IReportColumnFilter = filter.columnFilter[i];
      filteredColumns = filteredColumns.filter((column: IReportColumn) => column.identifier !== cf.id);
    }
  } else {
    for (let i = 0; i < filter.columnFilter.length; i += 1) {
      const cf = filter.columnFilter[i];

      if (cf.year) filteredColumns = filteredColumns.filter((column: IReportColumn) => column.year !== cf.year);
      if (cf.type) filteredColumns = filteredColumns.filter((column: IReportColumn) => column.type !== cf.type);
      if (cf.range) filteredColumns = filteredColumns.filter((column: IReportColumn) => column.range !== cf.range);
      if (cf.perMetric) {
        filteredColumns = filteredColumns.filter((column: IReportColumn) => column.perMetric !== cf.perMetric);
      }
    }
  }

  return filteredColumns;
}

const filterColumns = (
  report: IReport,
  columns: IReportColumn[],
  configurations: IReportConfiguration[],
  columnValues: string[],
  budgetsComparison: string,
  forecastComparison: string) => {
  let filteredColumns = columns;
  if (!report.enabledBudgetCompare || filteredColumns.length === 0) return filteredColumns;

  const reportFilters = reportFilterConfiguration(configurations);
  const tempObj: any = {};

  for (let index = 0; index < columnValues?.length; index += 1) {
    const item = columnValues[index];
    tempObj[item] = '';
  }

  for (let i = 0; i < reportFilters.length; i += 1) {
    const filter = reportFilters[i];

    if ((!budgetsComparison || budgetsComparison === '') && (!forecastComparison || forecastComparison === '')) {
      if (!has(tempObj, filter.id)) {
        filteredColumns = getFilteringColumnsData(filteredColumns, filter, report.version);
      }
    }

    if (budgetsComparison && budgetsComparison !== '' && (!forecastComparison || forecastComparison === '')) {
      if (filter.id !== 'budgets_comparison' && !has(tempObj, filter.id)) {
        filteredColumns = getFilteringColumnsData(filteredColumns, filter, report.version);
      }
    }

    if ((!budgetsComparison || budgetsComparison === '') && forecastComparison && forecastComparison !== '') {
      if (filter.id !== 'forecast_comparison' && !has(tempObj, filter.id)) {
        filteredColumns = getFilteringColumnsData(filteredColumns, filter, report.version);
      }
    }

    if (budgetsComparison && budgetsComparison !== '' && forecastComparison && forecastComparison !== '') {
      if (filter.id !== 'budgets_comparison' && filter.id !== 'forecast_comparison' && !has(tempObj, filter.id)) {
        filteredColumns = getFilteringColumnsData(filteredColumns, filter, report.version);
      }
    }
  }

  return filteredColumns;
};

const isShownCustomizedColumnFilter = (
  enabledBudgetCompare: boolean,
  reportColumns: IReportColumn[],
  configurations: IReportConfiguration[],
  version: number
) => {
  let retStatus = false;
  if (!enabledBudgetCompare) return false;

  const reportFilters = reportFilterConfiguration(configurations);

  for (let i = 0; i < reportFilters?.length; i += 1) {
    const filter = reportFilters[i];

    if (version === 2) {
      const columnfilters = filter?.columnFilter || [];
      const findFilter = reportColumns.find((f: IReportColumn) => columnfilters.find((cf: IReportColumnFilter) => cf.id === f.identifier));

      if (findFilter) {
        retStatus = true;
        break
      }
    } else {
      for (let j = 0; j < filter?.columnFilter?.length; j += 1) {
        const cf = filter?.columnFilter[j];

        if (cf.year && reportColumns.filter((rc: IReportColumn) => rc.year === cf.year).length > 0
          || cf.type && reportColumns.filter((rc: IReportColumn) => rc.type === cf.type).length > 0
          || cf.range && reportColumns.filter((rc: IReportColumn) => rc.range === cf.range).length > 0
          || cf.perMetric && reportColumns.filter((rc: IReportColumn) => rc.perMetric === cf.perMetric).length > 0
        ) {
          retStatus = true;
          break;
        }
      }
    }

    if (retStatus) break;
  }

  return retStatus;
};

export const isTotalData = (report: IReport, showMultiMonths: boolean) => {
  return report.totalColumnVisible && showMultiMonths;
};

const isBasicReport = (report: IReport) => {
  // eslint-disable-next-line max-len
  return (report.templateId === window.Docyt.Common.Constants.BUSINESS_REPORT_TYPE.BALANCE_SHEET_REPORT_TYPE
    // eslint-disable-next-line max-len
    || report.templateId === window.Docyt.Common.Constants.BUSINESS_REPORT_TYPE.PROFIT_AND_LOSS_REPORT_TYPE);
};

const isStoreManagersReport = (report: IReport) => {
  // eslint-disable-next-line max-len
  return report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.STORE_MANAGERS_REPORT && report.version === 1;
};

const isLaborReport = (report: IReport) => {
  return report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.LABOR_REPORT;
}

const isBudgetColumn = (column: IReportColumn) => {
  return (column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.BUDGET_ACTUAL
    || column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.BUDGET_PERCENTAGE
    || column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.BUDGET_VARIANCE
  );
};

const isForecastColumn = (column: IReportColumn) => {
  return (column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.FORECAST_ACTUAL
    || column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.FORECAST_PERCENTAGE
    || column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.FORECAST_VARIANCE
  );
}

const itemIdentifierValueFormatter = (columnType: TColumnType, value: number | null | undefined) => {
  if (isNull(value) || isUndefined(value) || isNaN(value)) {
    return '-';
  }

  const valueString = currencyFormater3(value).replace('$', '');

  switch (columnType) {
    case window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.PERCENTAGE
    || window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.BUDGET_PERCENTAGE:
      return `${valueString}%`;
    case window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.VARIANCE:
      return valueString;
    default:
      return `$${valueString}`;
  }
}

const itemValueFormatter = (
  columns: IReportColumn[],
  childItem: IReportItem,
  columnId: string,
  itemId: string,
  columnType: TColumnType,
  value: number,
  customItemValue: string | null,
) => {
  let colType = childItem?.columnValues?.length === 0 ? columnType : '';

  const isParentZeroRow = childItem.childItems.length > 0 && childItem.show && value === 0;
  if (customItemValue) {
    return customItemValue;
  }

  if (isNull(value) || isUndefined(value) || isParentZeroRow) {
    return '-';
  }

  const valueString = currencyFormater3(value).replace('$', '');

  const column = columns?.find((col: IReportColumn) => col?.id === columnId);
  if (column?.dataType === 'time' || column?.dataType === 'quantity') {
    return valueString;
  }
  if (colType === '' && childItem?.id === itemId) {
    const columnValue = childItem?.columnValues?.find((val: IReportColumnValue) => val?.columnId === column?.identifier);
    colType = columnValue?.dataType as string;
    if (colType === 'quantity' || colType === 'time') {
      return valueString; 
    }
  }

  switch (colType) {
    case window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.PERCENTAGE
    || window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.BUDGET_PERCENTAGE:
      return `${valueString}%`;
    case window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.VARIANCE:
      return valueString;
    default:
      return `$${valueString}`;
  }
};

const genHeaderDataForDailyReport = (collection: IReportDatasCollection) => {
  const topRowColumns: IHeaderColumn[] = [];
  const middleRowColumns: IHeaderColumn[] = [];
  const bottomRowColumns: IHeaderColumn[] = [];

  if (collection?.query?.data?.length) {
    if (collection?.query?.data[0]?.length === 1) {
      topRowColumns.push({ key: topRowColumns.length + 1, name: 'MTD', division: false });
    } else {
      topRowColumns.push({ key: topRowColumns.length + 1, name: 'TOTAL', division: false });
    }

    for (let index = 0; index < collection?.query?.data?.[0]?.length; index += 1) {
      const item = collection?.query?.data?.[0]?.[index];

      if (item?.periodType === 'daily' || item?.periodType === 'weekly') {
        const curYear = moment(String(item.startDate)).year();
        const curMonth = moment(String(item.startDate)).month();
        const curDate = moment(String(item.startDate)).date();

        topRowColumns.push({
          key:       topRowColumns.length + index + 1,
          name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curDate} ${curYear}`,
          division:  false,
        });
      }
    }
  } else {
    const curYear = moment().year();
    const curMonth = moment().month();
    const curDate = moment().date();

    topRowColumns.push({ key: topRowColumns.length + 1, name: 'MTD', division: false });
    topRowColumns.push({
      key:       topRowColumns.length + 1,
      name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curDate} ${curYear}`,
      division:  false,
    });
  }

  return {
    topRowColumns,
    middleRowColumns,
    bottomRowColumns,
  };
};

const genHeaderDataForLaborReport = (
  report: IReport,
  filterData: TFilterData,
  ptdColumns: IReportColumn[],
  periodType: TReportDataPeriodType
) => {
  const topRowColumns: IHeaderColumn[] = [];
  const middleRowColumns: IHeaderColumn[] = [];
  const bottomRowColumns: IHeaderColumn[] = [];
  const filterStartDate = filterData?.reportDataRange?.gte;
  const filterEndDate = filterData?.reportDataRange?.lte;
  const showTotal = report.totalColumnVisible && filterStartDate && filterEndDate && (filterStartDate !== filterEndDate)

  if (filterStartDate && filterEndDate) {
    const startDate = moment(String(filterStartDate));

    if (showTotal) {
      topRowColumns.push({ key: `total-${topRowColumns.length + 1}`, name: 'TOTAL', division: false });
      ptdColumns.forEach((column: IReportColumn) => {
        bottomRowColumns.push({ key: `total-${column?.id}`, name: column.name, division: false });
      });
    }

    if(periodType === 'daily') {
      const endDate = moment(String(filterEndDate)).add(1, 'days');
      while (!moment(startDate).isSame(endDate, 'day')) {
        topRowColumns.push({
          key:       `${startDate.format()}-${topRowColumns.length + 1}`,
          name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[startDate.month()]} ${startDate.date()} ${startDate.year()}`,
          division:  false,
        });
        ptdColumns.forEach((column: IReportColumn) => {
          bottomRowColumns.push({ key: `${startDate.format()}-${column?.id}`, name: column.name, division: false });
        });
        startDate.add(1, 'days');
      }
    }

    if(periodType === 'weekly') {
      while (startDate.isBefore(filterEndDate)) {
        const weekEndDate = moment(startDate).add(6, 'days');
        topRowColumns.push({
          key:       `${startDate.format()}-${topRowColumns.length + 1}`,
          name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[startDate.month()]}
          ${startDate.date()} ${startDate.year()} -
          ${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[weekEndDate.month()]}
          ${weekEndDate.date()} ${weekEndDate.year()}`,
          division:  false,
        });
        ptdColumns.forEach((column: IReportColumn) => {
          bottomRowColumns.push({ key: `${startDate.format()}-${column?.id}`, name: column.name, division: false });
        });
        startDate.add(7, 'days');
      }
    }
  }

  return {
    topRowColumns,
    middleRowColumns,
    bottomRowColumns,
  };
};

const genHeaderDataForOneMonth = (
  report: IReport,
  filterData: TFilterData,
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[]
) => {
  const topRowColumns: IHeaderColumn[] = [];
  const middleRowColumns: IHeaderColumn[] = [];
  const bottomRowColumns: IHeaderColumn[] = [];
  const curYear = filterData?.reportDataRange ? moment(String(filterData?.reportDataRange?.gte)).year() : moment().year();
  const curMonth = filterData?.reportDataRange ? moment(String(filterData?.reportDataRange?.gte)).month() : moment().month();

  if (isStoreManagersReport(report)) {
    topRowColumns.push({
      key:       topRowColumns.length + 1,
      name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
      division:  false,
    });
    topRowColumns.push({
      key:       topRowColumns.length + 1,
      name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear - 1}`,
      division:  true,
    });

    if (curMonth === 0) {
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
        division:  true,
      });
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear - 1}`,
        division:  true,
      });
    } else {
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[0]} - ${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
        division:  true,
      });
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[0]} - ${
                     window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]
                   } ${curYear - 1}`,
        division:  true,
      });
    }

    middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'PTD', division: false });
    middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'PTD LY', division: true });
    middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'YTD', division: true });
    middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'YTD LY', division: true });

    ptdColumns.forEach((column, index) => {
      bottomRowColumns.push({
        key:       column?.id,
        name:      column.name,
        division:  index !== 0 && index % ptdColumns.length === 0,
      });
    });

    ytdColumns.forEach((column, index) => {
      bottomRowColumns.push({ key: column?.id, name: column.name, division: index % ytdColumns.length === 0 });
    });
  } else if (isLaborReport(report)) {
    topRowColumns.push({
      key:       topRowColumns.length + 1,
      name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
      division:  false,
    });

    if (ptdColumns.length !== 0) {
      middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'PTD', division: false });

      ptdColumns.forEach((column: IReportColumn) => {
        bottomRowColumns.push({ key: column?.id, name: column.name, division: false });
      });
    }

    if (ytdColumns.length !== 0) {
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `YTD as of ${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
        division:  false,
      });
      middleRowColumns.push({ key: middleRowColumns.length + 1, name: 'YTD', division: true });

      ytdColumns.forEach((column, index) => {
        bottomRowColumns.push({ key: column?.id, name: column.name, division: index === 0 });
      });
    }
  } else {
    topRowColumns.push({
      key:       topRowColumns.length + 1,
      name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
      division:  false,
    });

    ptdColumns.forEach((column: IReportColumn) => {
      bottomRowColumns.push({ key: column?.id, name: column.name, division: false });
    });

    if (ytdColumns.length !== 0) {
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `YTD as of ${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[curMonth]} ${curYear}`,
        division:  false,
      });
      ytdColumns.forEach((column, index) => {
        bottomRowColumns.push({ key: column?.id, name: column.name, division: index === 0 });
      });
    }
  }

  return {
    topRowColumns,
    middleRowColumns,
    bottomRowColumns,
  };
};

const genHeaderDataForMultiMonth = (
  report: IReport,
  filterData: TFilterData,
  multiMonthColumns: IReportColumn[],
  showMultiMonths: boolean) => {
  const topRowColumns: IHeaderColumn[] = [];
  const middleRowColumns: IHeaderColumn[] = [];
  const bottomRowColumns: IHeaderColumn[] = [];

  if (isTotalData(report, showMultiMonths)) {
    topRowColumns.push({ key: topRowColumns.length + 1, name: 'TOTAL', division: false });

    if (multiMonthColumns.length !== 1) {
      bottomRowColumns.push(
        ...multiMonthColumns.map((column, index) => ({
          key:       bottomRowColumns.length + index,
          name:      column.name,
          division:  index === 0,
        })));
    }
  }

  if (!report.showOnlyTotalColumn && filterData?.reportDataRange?.gte && filterData?.reportDataRange?.lte) {
    const startDate = moment(String(filterData.reportDataRange.gte));
    const endDate = moment(String(filterData.reportDataRange.lte)).add(1, 'months');

    while (!moment(startDate).isSame(endDate, 'month')) {
      topRowColumns.push({
        key:       topRowColumns.length + 1,
        name:      `${window.Docyt.Common.Constants.MONTH_SHORT_NAMES[startDate.month()]} ${startDate.year()}`,
        division:  false,
        month:     startDate.month() + 1,
        year:      startDate.year()
      });

      if (multiMonthColumns.length !== 1) {
        bottomRowColumns.push(
          ...multiMonthColumns.map((column, index) => ({
            key:       bottomRowColumns.length + index,
            name:      column.name,
            division:  index === 0,
            month:     startDate.month() + 1,
            year:      startDate.year()
          })));
      }

      startDate.add(1, 'months');
    }
  }

  if (bottomRowColumns[0]) bottomRowColumns[0].division = false;

  return {
    topRowColumns,
    middleRowColumns,
    bottomRowColumns,
  };
};

const useQueryData = (report: IReport, index: string, identifier?: string) => {
  let startDate = '';
  let endDate = '';
  const params = new URLSearchParams(window.location.search);
  const business = useBusinessContext();

  if (index === 'reportOtherDetail') {
    startDate = formatDate(params.get('from'), API_DATE_FORMAT);
    endDate = formatDate(params.get('to'), API_DATE_FORMAT);
  }

  const setPeriodType = useSetAtom(reportDataPeriodType);
  useEffect(() => {
    setPeriodType(report.periodType);
  }, [report.periodType, setPeriodType]);

  const reportTemplatesQuery = useGetReportTemplates(business.standardCategoryId);
  const templates = useMemo(() => reportTemplatesQuery.data || [], [reportTemplatesQuery.data]);
  const template = useMemo(
    () => templates.find((temp: ITemplate) => temp.id === report.templateId),
    [report.templateId, templates]);

  const reportConfigurationsQuery = useGetReportConfigurations();
  const configurations = useMemo(() => reportConfigurationsQuery.data || [], [reportConfigurationsQuery.data]);
  const endPoint = isBasicReport(report) ? 'report-dc' : 'reports';
  const reportItemsQuery = useGetReportItems(report.id, endPoint);
  const items = useMemo(() => reportItemsQuery.data || [], [reportItemsQuery.data]);


  const getReportBudgetComparerIds = useGetReportBudgetComparerIds(report.id, {from: startDate, to: endDate});
  const comparerIds = useMemo(() => getReportBudgetComparerIds.data || [], [getReportBudgetComparerIds.data]);

  const queryIdentifier = useGetReportByIdentifier(report.id, identifier as string);
  const itemIdentifier = useMemo(() => queryIdentifier?.data, [queryIdentifier?.data]);

  return {
    template,
    items,
    configurations,
    itemIdentifier,
    ...(index === 'reportOtherDetail' ? { comparerIds } : {})
  };
};

const useGenerateTableData = (
  report: IReport,
  configurations: IReportConfiguration[],
  collection: IReportDatasCollection,
  budgetsComparison: string,
  forecastComparison: string
) => {
  let data;
  const section = useSection();
  const filterData = <TFilterData>useFilterData(section);
  const showMultiMonths = useAtomValue(pickMultiMonths);
  const periodType = useAtomValue(reportDataPeriodType);
  let columnValues = useAtomValue(multiSelectAddValueType);
  let budgetsValue = budgetsComparison;
  let forecastValue = forecastComparison;

  if (isStoreManagersReport(report) && !showMultiMonths) {
    columnValues = ['last_year'];
    budgetsValue = '';
    forecastValue = '';
  };

  const isShownCustomize = periodType !== 'daily' &&  isShownCustomizedColumnFilter(
    report.enabledBudgetCompare,
    report.ptdColumns,
    configurations,
    report.version
  );
  let ptdColumns = filterColumns(report, report.ptdColumns, configurations, columnValues, budgetsValue, forecastValue);
  let ytdColumns = filterColumns(report, report.ytdColumns, configurations, columnValues, budgetsValue, forecastValue);
  const multiMonthColumns = multiRangeColumns(report, ptdColumns, configurations);

  if (periodType === 'daily' || periodType === 'weekly') {
    if (!isLaborReport(report)) {
      const ptdColumn = report.ptdColumns.find(
        (column: IReportColumn) => column.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL
        && column.range === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_RANGE.RANGE_CURRENT);

      const mtdColumn = report.mtdColumns.find(
        (column: IReportColumn) => column.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL);

      ytdColumns = ptdColumn ? [ptdColumn] : [];
      ptdColumns = mtdColumn ? [mtdColumn] : [];
      data = genHeaderDataForDailyReport(collection);
    } else {
      data = genHeaderDataForLaborReport(report, filterData, ptdColumns, periodType);
    }
  } else if (showMultiMonths) {
    data = genHeaderDataForMultiMonth(report, filterData, multiMonthColumns, showMultiMonths);
  } else {
    data = genHeaderDataForOneMonth(report, filterData, ptdColumns, ytdColumns);
  }

  return {
    headerTopRowColumns:    data.topRowColumns,
    headerMiddleRowColumns: data.middleRowColumns,
    headerBottomRowColumns: data.bottomRowColumns,
    ptdColumns,
    ytdColumns,
    multiMonthColumns,
    isShownCustomize,
  };
};

const useGenerateReportOtherDetailTableData = (
  report: IReport,
  configurations: IReportConfiguration[],
  budgetsComparison: string,
  forecastComparison: string
) => {
  let data;
  const section = useSection();
  const filterData = <TFilterData>useFilterData(section);
  const showMultiMonths = useAtomValue(pickMultiMonths);
  let columnValues = useAtomValue(multiSelectAddValueType);
  let budgetsValue = budgetsComparison;
  let forecastValue = forecastComparison;

  if (isStoreManagersReport(report) && !showMultiMonths) {
    columnValues = ['last_year'];
    budgetsValue = '';
    forecastValue = '';
  };

  const isShownCustomize = isShownCustomizedColumnFilter(
    report.enabledBudgetCompare,
    report.ptdColumns,
    configurations,
    report.version
  );
  const ptdColumns = filterColumns(report, report.ptdColumns, configurations, columnValues, budgetsValue, forecastValue);
  const ytdColumns = filterColumns(report, report.ytdColumns, configurations, columnValues, budgetsValue, forecastValue);
  let multiMonthColumns = multiRangeColumns(report, ptdColumns, configurations);
  // Additional check for cash flow report to fix total column
  if(multiMonthColumns.length === 0
    && !report.enabledBudgetCompare
    && report.templateId === window.Docyt.Common.Constants.REPORT_TEMPLATES.CASH_FLOW) {
    multiMonthColumns = [ptdColumns[0]];
  }

  if (showMultiMonths) {
    data = genHeaderDataForMultiMonth(report, filterData, multiMonthColumns, showMultiMonths);
  } else {
    data = genHeaderDataForOneMonth(report, filterData, ptdColumns, ytdColumns);
  }

  return {
    headerTopRowColumns:    data.topRowColumns,
    headerMiddleRowColumns: data.middleRowColumns,
    headerBottomRowColumns: data.bottomRowColumns,
    ptdColumns,
    ytdColumns,
    multiMonthColumns,
    isShownCustomize,
  };
}

const useHideReportsForBusiness = (businessId: number) => {
  const { data: showReportToBusinessEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.SHOW_REPORT_TO_BUSINESS_ENABLED
  );

  const hideReportToBusiness = useMemo(() => {
    if (Array.isArray(showReportToBusinessEnabled)) {
      return showReportToBusinessEnabled as string[];
    }

    return [];
  }, [showReportToBusinessEnabled]);

  return hideReportToBusiness;
}

export {
  TFilterData,
  IReportDatasCollection,
  IHeaderColumn,
  IReportItemValuesCollection,
  isBasicReport,
  isStoreManagersReport,
  isBudgetColumn,
  isForecastColumn,
  itemValueFormatter,
  itemIdentifierValueFormatter,
  useQueryData,
  useGenerateTableData,
  reportFilterConfiguration,
  useGenerateReportOtherDetailTableData,
  useHideReportsForBusiness,
};
