import React from 'react';

import { TID } from '@src/types/common';

import Table from '@src/components/ui_v2/table';

import TableRowItem from './table_row/table_row_item';
import EmptyState from '../components/empty_state';
import { IBusinessOwnerConfigFormType } from '../helper';
import { IDashboardData, TViewOperationalDashboard } from '../types';

interface IDashboardTableBodyProps {
  records: IDashboardData[];
  preferences?: IBusinessOwnerConfigFormType;
  viewNotes?: (businessId: TID) => void;
  isSearching?: boolean;
  hasError?: boolean;
  colCount?: number;
  view: TViewOperationalDashboard;
}

/**
 * Renders the table body for the operational dashboard
 * Handles empty state display when no records are present
 */
const TableBody = ({
  records,
  preferences,
  viewNotes,
  isSearching = false,
  hasError = false,
  colCount = 6,
  view,
}: IDashboardTableBodyProps) => {
  // Create a reference for the empty state row
  const emptyRowRef = React.useRef<HTMLTableRowElement>(null);

  // Optimize checking for empty records
  const hasRecords = records && records.length > 0;

  // Determine which empty state message type to show
  const getMessageType = () => {
    if (hasError) return 'error';
    if (isSearching) return 'search';
    return 'initial';
  };

  return (
    <Table.Body>
      {hasRecords ? (
        records.map((columnData) => (
          <TableRowItem
            key={ columnData.id }
            columnData={ columnData }
            preferences={ preferences }
            viewNotes={ viewNotes }
          />
        ))
      ) : (
        <EmptyState
          colspan={ colCount }
          messageType={ getMessageType() }
          rowRef={ emptyRowRef }
          view={ view }
        />
      )}
    </Table.Body>
  );
};

export default React.memo(TableBody);
