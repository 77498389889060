import React from 'react';

import NoDataIconSvg from '@assets/images/icons/no_data_icon.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const NoDataIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="No Data Icon"
      src={ NoDataIconSvg }
      width={ 70 }
      { ...props }
    />
  );
};

export default NoDataIcon;
