import React, { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { useGetBusinesses } from '@src/hooks/queries/businesses';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

import Filter from '@src/components/ui_v2/filter';

import BusinessMultiCustomReports from './custom_report';
import MultiAddItemField from '../select/multi_add_item_field';

interface IMultiReportsExportContentProps {
  multiReport: IMultiBusinessReportDetail | undefined
  query: UseQueryResult<IMultiBusinessReportDetail, Error>
}

const MultiReportsExportContent = ({
  multiReport,
  query,
}: IMultiReportsExportContentProps) => {
  const businessQuery = useGetBusinesses({
    includeEmployedBusiness: true,
    simple:                  true,
  });
  const businesses = useMemo(() => {
    const businessesPages = businessQuery.data?.collection || [];
    return businessesPages;
  }, [businessQuery.data?.collection]);

  const periodType = useMemo(() => {
    if (multiReport && multiReport.reports.length !== 0) {
      return multiReport.reports[0].periodType;
    }

    return undefined;
  }, [multiReport]);

  return (
    <div className="reports-export-content multi-reports-content">
      <div className="content-header">
        <div>
          <h3 className="title">Reports Details</h3>
          <p className="subtitle">
            Select business, reports, accounting period and specify whether to include drill down.
          </p>
        </div>
        <a className="pointer text-color" role="button" tabIndex={ -1 }>
          Autofill Saved Details
        </a>
      </div>
      <div className="content-select">
        <div className="select-field-item">
          <div className="select-label">
            <p className="select-label-txt">Select Business(es:)</p>
          </div>
          <MultiAddItemField businesses={ businesses } className="field" multiReport={ multiReport } />
        </div>
        <div className="select-field-item">
          <div className="select-label">
            <p className="select-label-txt">Select Accounting Period:</p>
          </div>
          <Filter.DateField className="field" name="reportDataExport" periodType={ periodType } />
        </div>
      </div>
      <div className="content-header">
        <div>
          <h3 className="title">Customize Reports</h3>
          <p className="subtitle">
            Select the report columns below to be included in the export:
          </p>
        </div>
      </div>
      <BusinessMultiCustomReports multiReport={ multiReport } query={ query } />
    </div>
  );
};

export default MultiReportsExportContent;
