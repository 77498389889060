import React from 'react';

import { useLocation } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { revenueServicePathDepositTrackingPath, revenueServicePathRevenueCapturePath } from '@src/routes';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import Label from '@src/components/ui_v2/labels/label';
import NewServiceMenu from '@src/components/ui_v2/service_sidebar';
import Tooltip from '@src/components/ui_v2/tooltip';
import { AllTransactionsIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ISubmenuRevenueSystemItemProps {
  revenueSystem: IRevenueSystem;
  revenueReportType?: IRevenueReportType;
  newNavigationRevenue?: boolean;
}

const SubmenuRevenueSystemItem = ({
  revenueSystem,
  revenueReportType,
  newNavigationRevenue,
}: ISubmenuRevenueSystemItemProps) => {
  const business = useBusinessContext();
  const location = useLocation();

  const integration = useGetIntegration(
    { integrationId: revenueSystem.integrationId, businessId: business.id },
    { enabled: !!revenueSystem.integrationId },
  );

  const reportTypeName = revenueReportType?.name;

  const reportType = reportTypeName ? (
    <Tooltip.Hover className={ styles['report-type-container'] } content={ reportTypeName }>
      <span className={ styles['revenue-system-report-type'] }>{reportTypeName}</span>
    </Tooltip.Hover>
  ) : null;

  const getSubTitle = () => {
    if (!integration?.data?.externalSystemConfig?.location) {
      return undefined;
    }

    return integration?.data?.externalSystemConfig?.location;
  };

  const subTitle = getSubTitle();

  const revenueCapturePath = revenueServicePathRevenueCapturePath(business.id, revenueSystem.id);
  const depositTrackingPath = revenueServicePathDepositTrackingPath(business.id, revenueSystem.id);

  let activeHref = revenueCapturePath;
  if (location.pathname.startsWith(depositTrackingPath)) {
    activeHref = depositTrackingPath;
  }

  return newNavigationRevenue ? (
    <NewServiceMenu.Item
      key={ revenueSystem.id }
      isHighlightRequired
      href={ activeHref }
      subText={ (
        <>
          {revenueSystem.isArchived && <Label variant="muted">Archived</Label>}
          {reportType}
        </>
      ) }
      subTitle={ subTitle }
      title={ revenueSystem.name }
    />
  ) : (
    <ServiceSidebar.SubMenu
      current={ revenueSystem.id }
      href={ revenueServicePathRevenueCapturePath(business.id, revenueSystem.id) }
      icon={ <AllTransactionsIcon variant="purple" /> }
      subTitle={ (
        <>
          {revenueSystem.isArchived && <Label variant="muted">Archived</Label>}
          {reportType}
        </>
      ) }
      title={ revenueSystem.name }
    />
  );
};

export default SubmenuRevenueSystemItem;
