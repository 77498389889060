import { useMemo } from 'react';

import { useGetFinancialInstitutionConnectionInfiniteScroll } from
  '@src/hooks/queries/financial_institution_connection';
import { TID } from '@src/types/common';

/**
 * Custom hook to get financial institution connections filtered by management group
 */
export const useGetFinancialInstitutionConnectionsByManagementGroup = (
  managementGroupId: TID,
) => {
  // Create a stable filter object with the management group ID
  // Use useMemo to ensure the filter object reference stays the same
  // as long as managementGroupId doesn't change
  const filter = useMemo(() => ({
    management_group_id: String(managementGroupId),
  }), [managementGroupId]);

  // Use the existing query hook with our stable filter object
  return useGetFinancialInstitutionConnectionInfiniteScroll({
    filter,
  });
};
