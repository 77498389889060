import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TWidgetViewCategory } from '@src/components/management_group_dashboard/types';

import styles from './styles.module.scss';

interface IFullViewHeaderItemProps {
  identifier: TWidgetViewCategory,
  title: string,
  prefixIcon?: React.ReactNode,
  isActivated?: boolean,
  onClick: (value: string) => void,
}

const FullViewTab = ({
  identifier,
  title,
  prefixIcon,
  isActivated,
  onClick,
}: IFullViewHeaderItemProps): JSX.Element => {
  const classes = classNames(
    styles['full-view-header-item'],
    { [styles.activated]: isActivated },
  );

  const handleClick = useCallback(() => {
    onClick(identifier);
  }, [identifier, onClick]);

  return (
    <div
      className={ classes }
      role="navigation"
      onClick={ handleClick }
    >
      { prefixIcon }
      { title }
    </div>
  );
};

export default FullViewTab;
