import React, { useMemo } from 'react';

import { UseQueryResult } from 'react-query';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IGetRevenueServiceDocumentsResponse } from '@src/requests/revenue_service_documents';
import { editRevenueReportTypePath } from '@src/routes';
import { IPaymentProcessor } from '@src/types/payment_processors';
import {
  IRevenueServiceDocument,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { ISorting } from '@src/types/sorting';

import {
  usePaymentProcessorsForRevenueReportType,
} from '@src/components/revenue_center/revenue_capture/hooks';
import {
  amountForPaymentProcessor,
  reconciledForPaymentProcessor,
  revenueReconciliationIdForPaymentProcessor,
  autoReconciledForPaymentProcessor,
} from '@src/components/revenue_center/utils';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { BotNotificationIcon, VerifiedManuallyIcon } from '@src/components/utils/icomoon';

import DepositTooltip from './deposit_tooltip';
import Filter from './filter';
import styles from './styles.module.scss';

const reconciliationStatusForPaymentProcessor = (
  revenue: IRevenueServiceDocument,
  paymentProcessor: IPaymentProcessor,
) => {
  if (!reconciledForPaymentProcessor(revenue, paymentProcessor.id)) {
    return null;
  }

  return autoReconciledForPaymentProcessor(revenue, paymentProcessor.id)
    ? <BotNotificationIcon className={ styles['align-reconcile-icon'] } />
    : <VerifiedManuallyIcon className={ styles['align-reconcile-icon'] } />;
};

const tooltipForPaymentProcessor = (
  revenue: IRevenueServiceDocument,
  paymentProcessor: IPaymentProcessor,
) => {
  const revenueReconciliationId = revenueReconciliationIdForPaymentProcessor(
    revenue,
    paymentProcessor.id,
  );

  if (!revenueReconciliationId) return null;

  return (
    <DepositTooltip
      paymentProcessor={ paymentProcessor }
      revenue={ revenue }
      revenueReconciliationId={ revenueReconciliationId }
    />
  );
};

interface IDepositTrackingRevenueTableProps {
  query: UseQueryResult<IGetRevenueServiceDocumentsResponse, Error>,
  records: IRevenueServiceDocument[],
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
  revenueSystem: IRevenueSystem,
}

const DepositTrackingRevenueTable = ({
  query,
  records,
  sorting,
  revenueSystem,
}: IDepositTrackingRevenueTableProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();
  const revenueReportType = useMemo(() => {
    return revenueService.revenueReportTypes.find((item) => {
      return item.id === revenueSystem.revenueReportTypeId;
    });
  }, [revenueService.revenueReportTypes, revenueSystem]);
  const paymentProcessors = usePaymentProcessorsForRevenueReportType(
    revenueReportType,
    revenueService,
  );

  return (
    <CollectionTable<
      IRevenueServiceDocument,
      TRevenueServiceDocumentsSortColumn
      >
      defaultColumnCount={ 6 }
      filter={ <Filter paymentProcessors={ paymentProcessors } /> }
      height="100%"
      idField="rowKey"
      noDataMessage={
        ((revenueReportType && paymentProcessors.length === 0)
        && (
          <div>
            <span>
              No Payment Processors are configured for the Revenue Mapping $
              {revenueReportType.name}
            </span>
            <br />
            <a href={ editRevenueReportTypePath(business.id, revenueReportType.id) }>
              Edit
            </a>
          </div>
        )) || 'No reports found'
      }
      query={ query }
      records={ ((paymentProcessors.length === 0) && []) || records }
      sorting={ sorting }
    >
      {
      [(
        <CollectionTable.DateColumn<
          IRevenueServiceDocument,
          TRevenueServiceDocumentsSortColumn
        >
          key="end_date"
          name="end_date"
          sortColumn="end_date"
          title="Date"
          value={ (revenue) => revenue.endDate }
        />
      )].concat(
        paymentProcessors.map((paymentProcessor) => (
          <CollectionTable.AmountColumn<
            IRevenueServiceDocument,
            TRevenueServiceDocumentsSortColumn
          >
            key={ paymentProcessor.id }
            icon={ (revenue) => reconciliationStatusForPaymentProcessor(revenue, paymentProcessor) }
            name={ paymentProcessor.name }
            sortColumn={ paymentProcessor.code }
            title={ paymentProcessor.name }
            tooltip={ (revenue) => tooltipForPaymentProcessor(revenue, paymentProcessor) }
            tooltipProps={ { keepShowOnHover: true } }
            value={ (revenue) => {
              return amountForPaymentProcessor(
                revenue,
                paymentProcessor.id,
              );
            } }
          />
        )),
      )
    }
    </CollectionTable>
  );
};

export default React.memo(DepositTrackingRevenueTable);
