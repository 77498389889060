import React, { useCallback, useEffect, useMemo } from 'react';

import { uniq, uniqBy } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { getSearchFilterAssignees, getSearchFilterBusinesses,
  ISearchTaskUsersParams } from '@src/requests/task_service_documents';
import { TFilterData } from '@src/types/filter';

import { VendorAvatar } from '@src/components/ui/avatars';
import Filter, { useFilterField } from '@src/components/ui_v2/filter';
import {
  TIconOption,
  TOption,
} from '@src/components/ui_v2/inputs';
import { SearchIcon } from '@src/components/utils/icomoon';

import FilterLabel from '../components/filter_label';
import { ASSIGNEE_USERS_PER_PAGE,
  filterStatusOptions, TASK_TABS } from '../components/helper/constants';
import MultiSelectField from '../components/multi_select_field';
import { ITaskIconOption, StatusKey, TTaskManagementTabs } from '../types';

import styles from './styles.module.scss';

interface ITaskFilterFormValues {
  inputValueUser: string;
  inputValueBusiness: string;
  statusValue: TOption;
  allUserOptions: ITaskIconOption[];
  allBusinessOptions: ITaskIconOption[];
  isLoading:boolean;
}
interface IAllTasksFilterProps {
  tab:TTaskManagementTabs;
  statuses:StatusKey[];
}
type TAllOptionsKey = 'allBusinessOptions' | 'allUserOptions'
interface IHandleSourceProps<T> {
  query: string;
  fetchData: (params: T) => Promise<{ collection: any[] }>;
  params: {
    page: number;
    perPage: number;
  };
  allOptionsKey: TAllOptionsKey;
  allOptions: ITaskIconOption[];
}

const AllTasksFilter = ({ tab, statuses }:IAllTasksFilterProps) => {
  const [userIds, updateUserIds] = useFilterField<TFilterData, { [key: number]: string | string[]}>(
    'selectedUserIds',
  );
  const [businessIds, updateBusinessIds] = useFilterField<TFilterData, { [key: number]: string }>(
    'selectedBusinessIds',
  );
  const [isAllBusinessSelected, setIsAllBusinessSelected] = useFilterField<TFilterData, string>(
    'allBusinessesSelected',
  );
  const [isAllUsersSelected, setIsAllUsersSelected] = useFilterField<TFilterData, string>(
    'allUsersSelected',
  );
  const [excludedBusinessIds, setExcludedBusinessIds] = useFilterField<TFilterData, string[]>(
    'excludedBusinessIds',
  );
  const [excludedUserIds, setExcludedUserIds] = useFilterField<TFilterData, string[]>(
    'excludedUserIds',
  );

  const [filterStatus, updateFilterStatus] = useFilterField<TFilterData, string[]>('statuses');
  const { control, setValue, watch } = useForm<ITaskFilterFormValues>({
    defaultValues: {
      inputValueUser:     '',
      inputValueBusiness: '',
      allUserOptions:     [],
      allBusinessOptions: [],
      isLoading:          false,
    },
  });
  useEffect(() => {
    if (!filterStatus) {
      updateFilterStatus(statuses);
    } else if (!Array.isArray(filterStatus) && typeof filterStatus === 'object') {
      const storedStatuses = Object.values(filterStatus);
      updateFilterStatus(storedStatuses as string[]);
    }
  }, [filterStatus, statuses, updateFilterStatus]);

  const allOptionsBusiness = watch('allBusinessOptions');
  const allOptionsUsers = watch('allUserOptions');

  const handleSource =
  useCallback(async ({ query, fetchData, params, allOptionsKey, allOptions }
    :IHandleSourceProps<ISearchTaskUsersParams>) => {
    setValue('isLoading', true);
    const filter = query ? { name: query } : {};
    try {
      const data = await fetchData({
        filter,
        page:    params.page,
        perPage: ASSIGNEE_USERS_PER_PAGE,
      });

      const hasMore = data.collection?.length > 0;
      const newOptions = data.collection
        .map((item) => ({
          key:    item?.businessId || item.userId,
          label:  item?.displayName ?? '',
          value:  String(item?.userId ?? item?.businessId),
          icon:   <VendorAvatar vendor={ { ...item, imageUrl: item?.icon } } />,
          helper: item?.title,
        }));
      const uniqueOptions = uniqBy([...allOptions, ...newOptions], 'value');
      setValue(allOptionsKey, uniqueOptions as ITaskIconOption[]);
      return {
        hasMore,
        options: params.page === 1 && !query
          ? [{ label: 'Select All', value: 'select-all' }, ...newOptions]
          : newOptions,
        additional: {
          page: params.page + 1,
        },
      };
    } catch {
      return {
        hasMore:    false,
        options:    [],
        additional: { page: params.page },
      };
    } finally {
      setValue('isLoading', false);
    }
  }, [setValue]);
  const handleSourceBusiness: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, _, { page }) => {
    return handleSource({
      query,
      fetchData:     getSearchFilterBusinesses,
      params:        { page, perPage: ASSIGNEE_USERS_PER_PAGE },
      allOptions:    allOptionsBusiness,
      allOptionsKey: 'allBusinessOptions',
    });
  }, [allOptionsBusiness, handleSource]);

  const handleSourceAssignees: LoadOptions<TIconOption, GroupBase<TIconOption>, any> =
  useCallback(async (query, _, { page }) => {
    return handleSource({
      query,
      fetchData:     getSearchFilterAssignees,
      params:        { page, perPage: ASSIGNEE_USERS_PER_PAGE },
      allOptions:    allOptionsUsers,
      allOptionsKey: 'allUserOptions',
    });
  }, [allOptionsUsers, handleSource]);

  const getSuffixLabel = ({ isAllSelected, selectedItems,
    singularLabel = '', pluralLabel = '' }:any) => {
    if (isAllSelected && JSON.parse(isAllSelected)) return 'All Selected';
    if (selectedItems?.length) {
      const count = Object.keys(selectedItems ?? {}).length;
      return count > 1 ? pluralLabel : singularLabel;
    }
    return '';
  };

  const onHandleRemoveBusinessFilter = useCallback(() => {
    updateBusinessIds([]);
    setValue('inputValueBusiness', '');
    setValue('allBusinessOptions', []);
    setExcludedBusinessIds([]);
    setIsAllBusinessSelected('false');
  }, [setExcludedBusinessIds, setIsAllBusinessSelected, setValue, updateBusinessIds]);

  const onHandleRemoveUserFilter = useCallback(() => {
    updateUserIds([]);
    setValue('inputValueUser', '');
    setValue('allUserOptions', []);
    setExcludedUserIds([]);
    setIsAllUsersSelected('false');
  }, [setExcludedUserIds, setIsAllUsersSelected, setValue, updateUserIds]);

  const businessIdsArray = useMemo(() => {
    return businessIds && Object.values(businessIds) as string[];
  }, [businessIds]);

  const userIdsArray = useMemo(() => {
    return userIds && Object.values(userIds) as string[];
  }, [userIds]);

  const excludedBusinessIdsArray = useMemo(() => {
    return excludedBusinessIds && Object.values(excludedBusinessIds) as string[];
  }, [excludedBusinessIds]);

  const excludedUserIdsArray = useMemo(() => {
    return excludedUserIds && Object.values(excludedUserIds) as string[];
  }, [excludedUserIds]);

  return (
    <Filter.TableContainer className={ styles['task-table-filter'] }>
      <div className={ styles['filters-container'] }>
        <Filter.TextField
          hideClear
          className={ styles['task-search-container'] }
          inputClassName={ styles['task-input-search'] }
          label="Task ID"
          name="taskId"
          placeholder="Task ID"
          prefixIcon={ <SearchIcon /> }
          showTooltip={ false }
          width="10%"
        />
        <Controller
          control={ control }
          name="inputValueBusiness"
          render={ ({ field }) => (
            <MultiSelectField
              hideIcon
              isMultiSelect
              allOptions={ watch('allBusinessOptions') }
              defaultSelectedOptionsIds={ businessIdsArray }
              excludedIds={ excludedBusinessIdsArray }
              getDropdownState={ (val) => {
                if (!val) {
                  setValue('inputValueBusiness', '');
                }
              } }
              getSelectedOptions={ (options) => {
                const ids = uniq(options.map((item) => String(item?.value)));
                updateBusinessIds(ids);
              } }
              handleSource={ handleSourceBusiness }
              inputValue={ field.value }
              isAllSelected={ isAllBusinessSelected ? JSON.parse(isAllBusinessSelected) : false }
              isLoading={ watch('isLoading') }
              noOptionMessage="No businesses found"
              setIsAllSelected={ (checked) => {
                setExcludedBusinessIds([]);
                setIsAllBusinessSelected(String(checked));
              } }
              toggleElement={ (
                <FilterLabel
                  isAllSelected={ isAllBusinessSelected ? JSON.parse(isAllBusinessSelected) : false }
                  label="All Businesses"
                  selectedItems={ businessIdsArray }
                  suffixLabel={ getSuffixLabel({
                    isAllSelected: isAllBusinessSelected,
                    selectedItems: businessIdsArray,
                    singularLabel: 'business',
                    pluralLabel:   'businesses',
                  }) }
                  onRemoveClick={ onHandleRemoveBusinessFilter }
                />
              ) }
              onInputChange={ (value, { action }) => {
                if (action === 'input-change') {
                  setValue('inputValueBusiness', value);
                }
              } }
              onSingleCheckClick={ (checked, option) => {
                const newExcludedBusinessIds = excludedBusinessIdsArray || [];
                if (!checked) {
                  setExcludedBusinessIds(newExcludedBusinessIds
                    ? [...newExcludedBusinessIds, option.value] : [option.value]);
                } else if (checked) {
                  const filteredData = newExcludedBusinessIds?.filter((item) => !(item === option.value));
                  setExcludedBusinessIds(filteredData);
                }
              } }
            />
          ) }
        />
        {tab !== TASK_TABS.OPEN && (
        <Controller
          control={ control }
          name="inputValueUser"
          render={ ({ field }) => (
            <MultiSelectField
              isMultiSelect
              allOptions={ watch('allUserOptions') }
              defaultSelectedOptionsIds={ userIdsArray }
              excludedIds={ excludedUserIdsArray }
              getDropdownState={ (val) => {
                if (!val) {
                  setValue('inputValueUser', '');
                }
              } }
              getSelectedOptions={ (options) => {
                const ids = uniq(options.map((item) => String(item?.value)));
                updateUserIds(ids);
              } }
              handleSource={ handleSourceAssignees }
              inputValue={ field.value }
              isAllSelected={ isAllUsersSelected ? JSON.parse(isAllUsersSelected) : false }
              isLoading={ watch('isLoading') }
              setIsAllSelected={ (checked) => {
                setExcludedUserIds([]);
                setIsAllUsersSelected(String(checked));
              } }
              toggleElement={ (
                <FilterLabel
                  isAllSelected={ isAllUsersSelected ? JSON.parse(isAllUsersSelected) : false }
                  label="All Assignees"
                  selectedItems={ userIdsArray }
                  suffixLabel={ getSuffixLabel({
                    isAllSelected: isAllUsersSelected,
                    selectedItems: userIdsArray,
                    singularLabel: 'assignee',
                    pluralLabel:   'assignees',
                  }) }
                  onRemoveClick={ onHandleRemoveUserFilter }
                />
                ) }
              onInputChange={ (value, { action }) => {
                if (action === 'input-change') {
                  setValue('inputValueUser', value);
                }
              } }
              onSingleCheckClick={ (checked, option) => {
                const newExcludedUserIds = excludedUserIdsArray || [];
                if (!checked) {
                  setExcludedUserIds(newExcludedUserIds ? [...newExcludedUserIds, option.value] : [option.value]);
                } else if (checked) {
                  const filteredData = newExcludedUserIds?.filter((item) => !(item === option.value));
                  setExcludedUserIds(filteredData);
                }
              } }
            />
          ) }
        />
        )}
        {tab === TASK_TABS.ASSIGNED && (
        <Filter.SelectField
          alwaysShowIndicator
          hideClear
          className={ styles['task-select-container'] }
          name="statuses"
          options={ filterStatusOptions }
          placeholder="All Statuses"
        />
        )}
      </div>
    </Filter.TableContainer>
  );
};
export default React.memo(AllTasksFilter);
