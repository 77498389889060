import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  activateRevenueSystemDepositTracking,
  createRevenueSystem,
  getRevenueSystems,
  getRevenueDeposits,
  IActivateRevenueSystemDepositTrackingParams,
  IActivateRevenueSystemDepositTrackingResponse,
  ICreateRevenueSystemParams,
  ICreateRevenueSystemResponse,
  IGetRevenueSystemsParams,
  IGetRevenueSystemsResponse,
  IGetRevenueDepositsParams,
  IGetRevenueDepositsResponse,
  ISetActiveRevenueSystemParams,
  setActiveRevenueSystem,
  IUpdateRevenueSystemParams,
  updateRevenueSystem,
} from '@src/requests/revenue_systems';

const useGetRevenueSystems = (params: IGetRevenueSystemsParams) => {
  return useQuery<IGetRevenueSystemsResponse, Error>(
    [QueryKey.revenueSystems, params],
    () => getRevenueSystems(params),
  );
};

const useCreateRevenueSystem = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateRevenueSystemResponse,
    Error,
    ICreateRevenueSystemParams
  >(
    createRevenueSystem,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
      },
    },
  );
};

const useUpdateRevenueSystem = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateRevenueSystemResponse,
    Error,
    IUpdateRevenueSystemParams
  >(
    updateRevenueSystem,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
      },
    },
  );
};

const useActivateRevenueSystemDepositTracking = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IActivateRevenueSystemDepositTrackingResponse,
    Error,
    IActivateRevenueSystemDepositTrackingParams
  >(
    activateRevenueSystemDepositTracking,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
      },
    },
  );
};

const useGetRevenueDeposits = (
  params: IGetRevenueDepositsParams,
) => {
  return useQuery<IGetRevenueDepositsResponse, Error>(
    [QueryKey.revenueSystem, params],
    () => getRevenueDeposits(params),
  );
};

const useSetActiveRevenueSystem = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ICreateRevenueSystemResponse,
    Error,
    ISetActiveRevenueSystemParams
  >(
    setActiveRevenueSystem,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(QueryKey.revenueServiceByBusinessId);
      },
    },
  );
};

export {
  useActivateRevenueSystemDepositTracking,
  useCreateRevenueSystem,
  useGetRevenueSystems,
  useGetRevenueDeposits,
  useSetActiveRevenueSystem,
  useUpdateRevenueSystem,
};
