import React from 'react';

import { IAccessibleManagementGroup } from '@src/types/management_groups';
import { IPortfolio } from '@src/types/portfolio';

import { AddCircleIcon } from '@src/components/utils/icomoon';

import { TUnifiedDropdownOption, TMenuItemType } from './types';

const managementGroupOptions:TUnifiedDropdownOption = {
  label:        'Management Groups',
  value:        'management-groups',
  displayLabel: 'Management Groups',
  type:         'navigate' as TMenuItemType,
  icon:         null,
};

const industryGroupOptions:TUnifiedDropdownOption = {
  label:        'Industry Groups',
  value:        'industry-groups',
  displayLabel: 'Industry Groups',
  type:         'navigate' as TMenuItemType,
  icon:         null,
};

export const prepareManagementGroupValue = (id: number) => `management-group-${id}`;
export const prepareManagementGroupBillPayValue = (id: number) => `management-group-bill-pay-${id}`;
export const prepareManagementGroupJournalEntriesValue = (id: number) => `management-group-journal-entries-${id}`;
export const prepareManagementGroupInterAccountTransfersValue = (id: number) => (
  `management-group-inter-account-transfers-${id}`
);
export const prepareManagementGroupFinancialConnectionValue = (id: number) => (
  `management-group-financial-connection-${id}`
);
export const prepareBusinessValue = (id: number) => `business-${id}`;
export const prepareIndustryGroupValue = (id: number) => `industry-group-${id}`;

// Portfolio helper functions
export const preparePortfolioDashboardValue = (id: number) => `portfolio-dashboard-${id}`;
export const preparePortfolioReportsValue = (id: number) => `portfolio-reports-${id}`;
export const preparePortfolioChartOfAccountsValue = (id: number) => `portfolio-chart-of-accounts-${id}`;

// Routes for navigating to portfolio pages
export const getPortfolioDashboardRoute = (id: number) => `/portfolio_dashboards/${id}`;
export const getPortfolioReportsRoute = (id: number) => `/portfolio_dashboards/${id}/multi_entity_reports`;
export const getPortfolioChartOfAccountsRoute = (id: number) => `/portfolio_dashboards/${id}/chart_of_accounts`;

const portfolioOptions = (
  managementGroups: IAccessibleManagementGroup[],
  industryGroups: IPortfolio[],
) => [
  { ...managementGroupOptions,
    subMenuItems: [
      {
        label:        'New Group',
        value:        'add-management-group',
        displayLabel: '',
        id:           undefined,
        type:         'action-add' as const,
        icon:         <AddCircleIcon fontSize={ 16 } />,
      },
      ...managementGroups.map((group) => ({
        label:        group.name,
        value:        prepareManagementGroupValue(group.id),
        id:           group.id,
        displayLabel: group.name,
        type:         'navigate' as const,
        icon:         null,
        subMenuItems: [
          {
            label:        'Financial Connection',
            value:        prepareManagementGroupFinancialConnectionValue(group.id),
            type:         'navigate' as const,
            id:           group.id,
            displayLabel: group.name,
            icon:         null,
          },
        ],
      })),
    ] },
  {
    ...industryGroupOptions,
    subMenuItems: [
      {
        id:           undefined,
        label:        'New Group',
        value:        'add-industry-group',
        displayLabel: '',
        type:         'action-add' as const,
        icon:         <AddCircleIcon fontSize={ 16 } />,
      },
      ...industryGroups.map((group) => ({
        label:        group.name,
        value:        prepareIndustryGroupValue(group.id),
        id:           group.id,
        displayLabel: group.name,
        type:         'navigate' as const,
        icon:         null,
        subMenuItems: [
          {
            label:        'Business KPI',
            value:        preparePortfolioDashboardValue(group.id),
            type:         'navigate' as TMenuItemType,
            id:           group.id,
            displayLabel: group.name,
            icon:         null,
          },
          {
            label:        'Reports',
            value:        preparePortfolioReportsValue(group.id),
            type:         'navigate' as TMenuItemType,
            id:           group.id,
            displayLabel: group.name,
            icon:         null,
          },
          {
            label:        'Chart of Accounts',
            value:        preparePortfolioChartOfAccountsValue(group.id),
            type:         'navigate' as TMenuItemType,
            id:           group.id,
            displayLabel: group.name,
            icon:         null,
          },
        ],
      })),
    ],
  },
];

export default portfolioOptions;
