import React, { useCallback } from 'react';

import { useAtom } from 'jotai';
import isEmpty from 'lodash/isEmpty';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TID } from '@src/types/common';
import { IReport, ITemplate, TReportDataPeriodType } from '@src/types/report_service/report';

import { Button } from '@src/components/ui/buttons';
import { SelectInput } from '@src/components/ui_v2/inputs';
import { ReactSelectDropdownIndicator } from '@src/components/ui_v2/inputs/react_select/helpers';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import { reportDataPeriodType, reportsAccountingMethod } from '../../atoms';
import Actions from '../actions/report_detail_header_actions';
import { isBasicReport } from '../hooks';

import styles from '@src/components/ui_v2/inputs/react_select/styles.module.scss';

import stylesReport from '../styles.module.scss';

interface IBusinessReportDetailHeaderProps {
  businessId: TID;
  report: IReport;
  template?: ITemplate;
}

const options = [
  {
    label: 'Daily',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY,
  },
  {
    label: 'Monthly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.MONTHLY,
  },
];

const allOptions = [
  {
    label: 'Daily',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY,
  },
  {
    label: 'Weekly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY,
  },
  {
    label: 'Monthly',
    value: window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.MONTHLY,
  },
];
const {
  CUSTOM_REPORT_PERIOD,
  ENABLE_WEEKLY_REPORT_FLAG,
  REPORT_ACCOUNTING_METHOD,
  REPORT_CATEGORY_NAMES,
} = window.Docyt.Common.Constants;

const BusinessReportDetailHeader = ({
  businessId,
  report,
  template,
}: IBusinessReportDetailHeaderProps): JSX.Element => {
  const [selectedPeriod, setPeriod] = useAtom(reportDataPeriodType);
  const [accountingMethod, setAccountingMethod] = useAtom(reportsAccountingMethod);
  const business = useBusinessContext();
  const isWeeklyReportEnabled = window.configData.launch_darkly_enabled_global_features.includes(
    ENABLE_WEEKLY_REPORT_FLAG,
  );
  const showPeriodTypeSelect = report.periodType === CUSTOM_REPORT_PERIOD.DAILY
   || (!isEmpty(report.periodTypes) && report.periodTypes.length > 1);
  const generateAvailableOptions = () => {
    if (!showPeriodTypeSelect) {
      return [];
    }
    let allowedOptions = allOptions;
    if (!isWeeklyReportEnabled) {
      allowedOptions = allOptions.filter((op) => op.value !== CUSTOM_REPORT_PERIOD.WEEKLY);
    }
    if (!isEmpty(report.periodTypes) && report.periodTypes.length > 1) {
      return allowedOptions.filter((op) => report.periodTypes.includes(op.value as TReportDataPeriodType));
    }
    return options;
  };
  const availableOptions = generateAvailableOptions();

  const handleChange = useCallback(
    (selectedOption) => {
      setPeriod(selectedOption.value);
    },
    [setPeriod],
  );

  const switchAccountingMethod = () => {
    if (accountingMethod === REPORT_ACCOUNTING_METHOD.CASH) {
      setAccountingMethod(REPORT_ACCOUNTING_METHOD.ACCRUAL);
    } else {
      setAccountingMethod(REPORT_ACCOUNTING_METHOD.CASH);
    }
  };
  let switchAccountingMethodButton = null;
  if (isBasicReport(report)) {
    switchAccountingMethodButton = (
      <Button bsStyle="primary" onClick={ switchAccountingMethod }>
        {accountingMethod === REPORT_ACCOUNTING_METHOD.CASH ? 'Switch to Accrual Basis' : 'Switch to Cash Basis'}
      </Button>
    );
  }

  let reportTitle = report.name;
  if (isBasicReport(report)) {
    reportTitle = `${report.name} - ${
      accountingMethod === REPORT_ACCOUNTING_METHOD.CASH ? 'Cash Basis' : 'Accrual Basis'
    }`;
  }
  return (
    <DetailsRegion.Header
      breadcrumbs={
        template && {
          title: REPORT_CATEGORY_NAMES[template?.category],
          href:  `/businesses/${business.id}/reports?category=${template?.category}`,
        }
      }
      className={ stylesReport['blue-color'] }
      divider="horizontal"
      title={ reportTitle }
    >
      {showPeriodTypeSelect && (
        <SelectInput
          hideClear
          components={ {
            DropdownIndicator: ReactSelectDropdownIndicator,
          } }
          options={ availableOptions }
          styles={ {
            menu: (provided) => ({
              ...provided,
              alignItems:    'flex-start',
              background:    styles['dropdown-bgcolor'],
              border:        styles['dropdown-border'],
              borderRadius:  styles['dropdown-border-radius'],
              boxShadow:     styles['dropdown-box-shadow'],
              cursor:        styles['select-menu-cursor'],
              display:       'flex',
              flexDirection: 'column',
              padding:       styles['select-menu-padding'],
              margin:        styles['select-menu-margin'],
              whiteSpace:    'nowrap',
              zIndex:        Number(styles['select-menu-z-index']),
              width:         130,
            }),
            valueContainer: (provided) => ({
              ...provided,
              width: 100,
            }),
          } }
          value={ availableOptions.find((option) => option.value === selectedPeriod) }
          onChange={ handleChange }
        />
      )}
      {switchAccountingMethodButton}
      <Actions businessId={ businessId } category={ template?.category } report={ report } />
    </DetailsRegion.Header>
  );
};

export default BusinessReportDetailHeader;
