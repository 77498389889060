import React from 'react';

import Root from '@src/components/root';

import TaskNotificationPage from './task_notification_page';

const TaskNotification = () => {
  return (
    <Root>
      <TaskNotificationPage />
    </Root>
  );
};

export default TaskNotification;
