import React from 'react';

import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { UserAvatar } from '@src/components/ui/avatars';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Table from '@src/components/ui_v2/table';

import {
  EditClientOrBusinessAction,
  RemoveMemberAction,
  ViewProfileAction,
} from './actions';

import styles from '../styles.module.scss';

interface ITableRowProps {
  isAccountant: boolean;
  member: IBusinessTeamMember;
}

const TableRow: React.FC<ITableRowProps> = ({
  isAccountant,
  member,
}) => {
  return (
    <Table.Row className={ styles['member-row'] }>
      <Table.Cell>
        <div className={ styles['member-info'] }>
          <div className={ styles['member-avatar'] }>
            <UserAvatar
              avatar={ { avatarUrl: member.avatar?.avatarUrl || undefined } }
              size={ 32 }
            />
          </div>
          <div className={ styles['member-name'] }>
            <div className={ styles['member-name'] }>
              {member.parsedFullname}
              <div className={ styles['member-role'] }>
                {member.userRole}
              </div>
            </div>
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className={ styles['member-phone'] }>
          {member.phone ?? 'NA'}
        </div>
      </Table.Cell>
      <Table.Cell>
        {member.clientCount}
        {member.clientCount === 1 ? ' Client' : ' Clients'}
      </Table.Cell>
      <Table.Cell>
        <div className={ styles['member-action'] }>
          <ActionsDropdown className={ styles['member-action-dropdown'] }>
            <ViewProfileAction member={ member } />
            <EditClientOrBusinessAction
              isAccountant={ isAccountant }
              member={ member }
            />
            <RemoveMemberAction
              member={ member }
            />
          </ActionsDropdown>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default TableRow;
