import React, { useCallback, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetRevenueReportTypeDetail } from '@src/hooks/queries/revenue_report_types';
import { useSetActiveRevenueSystem } from '@src/hooks/queries/revenue_systems';

import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import Alert from '@src/components/ui_v2/alerts/alert';
import { SwitchInput } from '@src/components/ui_v2/inputs';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import TableSection from '@src/components/ui_v2/table_section/table_section';
import { InfoIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import RevenueServiceTabs from '../common/revenue_service_tabs/revenue_service_tabs';
import { useRevenueSystemContext } from '../revenue_system_provider';
import { useRSDCollection } from './hooks';
import MissingRevenueMapping from './missing_revenue_mapping';
import RevenueCaptureHeaderTable from './revenue_capture_header_table';
import RevenueCaptureImportMenu from './revenue_capture_import_menu';
import RevenueCaptureIntegrationSubmenu from './revenue_capture_integration_submenu';
import Table from './table';

import styles from './styles.module.scss';

const RevenueCapture = (): JSX.Element => {
  const [showResetBanner, setShowResetBanner] = useState<boolean>(false);
  const revenueService = useRevenueServiceContext();
  const revenueSystem = useRevenueSystemContext();
  const [isRevenueSystemActive, setIsRevenueSystemActive] = useState(revenueSystem.isActive);
  const business = useBusinessContext();
  const setActiveRevenueSystem = useSetActiveRevenueSystem();
  const { mutate } = setActiveRevenueSystem;

  const onChangeActiveRevenueSystem = useCallback((checked: boolean) => {
    setIsRevenueSystemActive(checked);
    mutate({
      revenueServiceId: revenueService.id,
      revenueSystemId:  revenueSystem.id,
      isActive:         checked,
    });
  }, [mutate, revenueService.id, revenueSystem.id]);

  const handleOnReset = useCallback(() => {
    if (showResetBanner) return;

    setShowResetBanner(true);
    setTimeout(() => setShowResetBanner(false), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsRevenueSystemActive(revenueSystem.isActive);
  }, [revenueSystem]);

  useEffect(() => {
    window.Docyt.vent.on('revenue:reset:success', handleOnReset);
    setShowResetBanner(false);

    return () => {
      window.Docyt.vent.off('revenue:reset:success');
    };
  }, [handleOnReset, revenueSystem.id]);

  const collection = useRSDCollection({
    revenueService,
    revenueSystem,
  });

  const integration = useGetIntegration({
    integrationId: revenueSystem.integrationId,
    businessId:    business.id,
  });

  const revenueCaptureHeaderFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CAPTURE_NEW_HEADERS,
  );

  const hasRevenueReportCategory = (revenueReportData: any): boolean => {
    if (revenueReportData) {
      const { revenueReportType } = revenueReportData;
      return (
        revenueReportType.revenueCategories.length > 0
      || revenueReportType.taxCategories.length > 0
      || revenueReportType.otherLedgerCategories.length > 0
      || revenueReportType.paymentProcessors.length > 0
      || revenueReportType.expenseAdjustments.length > 0
      );
    }
    return false;
  };
  const {
    data: revenueReportData,
  } = useGetRevenueReportTypeDetail(revenueSystem.revenueReportTypeId);

  const revenueReportCategoryExists = hasRevenueReportCategory(revenueReportData);

  const showRevenueCapture = (
    !revenueSystem.integrationId && revenueSystem.revenueReportTypeId && revenueReportCategoryExists)
  || integration.data?.skipRevenueMapping === true
  || (
    integration.data?.externalSystemConfig.revenueReportTypeId
    && integration.data?.isEnabled === true && revenueReportCategoryExists);

  return (
    <>
      <Outlet />
      <PageContainer sidemenu={ <Sidemenu /> }>
        <DetailsRegion>
          <DetailsRegion.Header
            className={ styles['tab-container'] }
            disableTooltip={ revenueCaptureHeaderFeatureQuery.data }
            divider="horizontal"
            title={ !revenueCaptureHeaderFeatureQuery.data ? revenueSystem.name
              : (
                <RevenueCaptureHeaderTable
                  integrationId={ revenueSystem.integrationId }
                  revenueSystemName={ revenueSystem.name }
                />
              ) }
          >
            <RevenueServiceTabs
              activeTab="RevenueCapture"
              businessId={ business.id }
              revenueSystemId={ revenueSystem.id }
            />

            <RevenueCaptureIntegrationSubmenu
              revenueSystem={ revenueSystem }
            />

            <div className={ styles['ml-auto'] }>
              <SwitchInput
                checked={ isRevenueSystemActive }
                title={ isRevenueSystemActive ? 'Active' : 'Inactive' }
                onChange={ onChangeActiveRevenueSystem }
              />
            </div>
            <RevenueCaptureImportMenu
              businessId={ business.id }
              businessName={ `${business?.name}` }
              integrationId={ revenueSystem.integrationId }
              revenueServiceId={ revenueService.id }
              revenueSystemId={ revenueSystem.id }
              revenueSystemName={ `${revenueSystem.name}` }
              serviceEmail={ `${revenueService?.uploadEmail}` }
            />

          </DetailsRegion.Header>
          {
            showResetBanner && (
              <Alert
                icon={ <InfoIcon relative color="white" fontSize={ 18 } mr={ 8 } /> }
                mt={ 10 }
                variant="highlight"
              >
                Reset report in progress. This will take some time
              </Alert>
            )
          }
          { showRevenueCapture && (
            <Section.Provider section={ collection.section }>
              <TableSection>
                <Table
                  integrationId={ collection.integrationId }
                  queries={ collection.queries }
                  records={ collection.records }
                  sorting={ collection.sorting }
                />
              </TableSection>
            </Section.Provider>
          )}
          {!showRevenueCapture && (
          <MissingRevenueMapping
            integrationId={ revenueSystem.integrationId }
            isEnabled={ integration.data?.isEnabled }
            revenueReportTypeId={ revenueSystem.revenueReportTypeId }
            revenueService={ revenueService }
            revenueSystem={ revenueSystem }
          />
          )}
        </DetailsRegion>
      </PageContainer>
    </>
  );
};

export default React.memo(RevenueCapture);
