import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { MultipleFilesIcon, SingleFileIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IFileDraggingProps {
  draggedFilesCount: number;
}

const FileDragging = ({ draggedFilesCount }: IFileDraggingProps) => {
  return (
    <div>
      {draggedFilesCount === 1 ? (
        <SingleFileIcon fontSize={ 96 } />
      ) : (
        <MultipleFilesIcon fontSize={ 96 } />
      )}
      <div className={ styles['selected-file-preview-text-container'] }>
        <Button className={ styles['selected-file-preview-text'] } size="compact" variant="primary">
          {draggedFilesCount}
          {' '}
          selected
        </Button>
      </div>
    </div>
  );
};

export default React.memo(FileDragging);
