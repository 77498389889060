import React, { useMemo } from 'react';

import { useGetDashboards } from '@src/hooks/queries/dashboards/dashboards';
import { transactionsPath, documentRequestsPath, flaggedTransactionsPath, businessInboxPath, vaultTrashPath, accountsPath, CSVImportPath, bankingAccountsTrashPath, settingsIntegrationExternalSystemsPath, rcSettingsPath, businessSettingsPath, rcLedgerSyncingCentrePath, rcChartOfAccountsPath, businessSettingsStepPath, businessDashboardPath, flaggedInvoicesPath, flaggedReceiptsPath, clientBusinessSettingsStepPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { useCreateDashboardModal } from '@src/components/business_dashboards/create_dashboard/create_dashboard_modal';
import AddCircleIcon from '@src/components/utils/icomoon/add_circle';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const BusinessItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled,
}: IServiceMenuProps) => {
  const createDashboard = useCreateDashboardModal();

  const dashboardsQuery = useGetDashboards({ businessId });
  const dashboards = useMemo(() => {
    return dashboardsQuery.data?.collection || [];
  }, [dashboardsQuery.data?.collection]);

  const canVisibleInboxService = info.isBusinessAdmin || info.canAccessInboxService;
  const isClientView = window.Docyt.currentAdvisor.isBookgptAccountantUser();
  const defaultAccountingFirmId = window.Docyt.currentAdvisor.defaultAccountingFirmId();

  const generalSettingsItems: { step: number; title: string }[] = [
    { step: 1, title: 'Management Group' },
    { step: 2, title: 'Accounting Software' },
    { step: 3, title: 'Business Profile' },
    { step: 4, title: 'Business Employees' },
    { step: 5, title: 'Financial Accounts' },
    { step: 6, title: 'Payment Methods' },
    { step: 7, title: 'Billing Information' },
    { step: 8, title: 'Document Collection' },
    { step: 9, title: 'Notification Settings' },
  ];

  return (
    <>
      <ModuleHeaderMenu
        active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS }
        focusToMyClient={ focusToMyClient }
        isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
        title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS }
      >
        <li className={ styles['navigation-item-header-title'] }>
          <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
            {window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS.toUpperCase()}
          </span>
        </li>
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.DASHBOARD }
        >
          {dashboards.map((dashboard, index) => (
            <ServiceItem
              key={ dashboard.id }
              hasBorderBottom={ index === dashboards.length - 1 }
              serviceUrl={ businessDashboardPath(businessId, dashboard.id) }
              title={ dashboard.name }
            />
          ))}
          <ServiceItem
            serviceUrl=""
            title={ (
              <div className={ `${styles['icon-with-text']}` }>
                <span>
                  <AddCircleIcon fontSize={ 18 } />
                  <span className={ `${styles['sub-menu-text']}` }>Add New Dashboard</span>
                </span>
              </div>
            ) }
            onClickHandler={ createDashboard.open }
          />
        </ServiceSubMenu>
        {info?.canAccessTaskAdvisor && (
          <ServiceSubMenu
            hasBorderBottom
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.OPEN_ITEMS }
          >
            {info?.documentRequestServiceRole && (
              <ServiceItem
                serviceUrl={ documentRequestsPath(businessId) }
                title="Document Requests"
              />
            )}
            {(!!info?.isBusinessAdmin && info?.hasFlaggedTransactionsAdvisorRole) && (
              <ServiceItem
                serviceUrl={ flaggedTransactionsPath(businessId) }
                title="Flagged Transactions"
              />
            )}
            {info.rpServiceReady && (
              <ServiceItem
                serviceUrl={ flaggedReceiptsPath(businessId) }
                title="Flagged Receipts"
              />
            )}
            {info.apServiceReady && (
              <ServiceItem
                serviceUrl={ flaggedInvoicesPath(businessId) }
                title="Flagged Invoices"
              />
            )}
          </ServiceSubMenu>
        )}
        {canVisibleInboxService && (
          <ServiceSubMenu
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.VAULT }
          >
            <ServiceItem
              serviceUrl={ businessInboxPath(businessId) }
              title="Inbox"
            />
            <ServiceItem
              serviceUrl={ vaultTrashPath(businessId) }
              title="Trash"
            />
          </ServiceSubMenu>
        )}
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.FINANCIAL_ACCOUNTS }
        >
          {(info.isBankingServiceAdminUser) && (
            <ServiceItem
              serviceUrl={ accountsPath(businessId) }
              title="All Accounts"
            />
          )}
          <ServiceItem
            hasBorderBottom
            serviceUrl={ transactionsPath(businessId) }
            title="All Transactions"
          />
          {(info.isBankingServiceAdminUser) && (
            <>
              <ServiceItem
                serviceUrl={ CSVImportPath(businessId) }
                title="CSV Import"
              />
              <ServiceItem
                hasBorderBottom
                serviceUrl={ bankingAccountsTrashPath(businessId) }
                title="Trash"
              />
            </>
          )}
        </ServiceSubMenu>
        <ServiceItem
          serviceUrl={ settingsIntegrationExternalSystemsPath(businessId) }
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.DATA_INTEGRATIONS }
        />
        <ServiceSubMenu title={ window.Docyt.Common.Constants.SERVICE_NAMES.GENERAL_SETTINGS }>
          {generalSettingsItems.map((item) => (
            <ServiceItem
              key={ item.step }
              hasBorderBottom={ [2, 4, 8].includes(item.step) }
              serviceUrl={ isClientView
                ? clientBusinessSettingsStepPath(businessId, defaultAccountingFirmId, item.step)
                : businessSettingsStepPath(businessId, item.step) }
              title={ item.title }
            />
          ))}
        </ServiceSubMenu>
      </ModuleHeaderMenu>
      <createDashboard.Component
        { ...createDashboard.props }
        businessId={ businessId }
        standardCategoryId={ info?.standardCategoryId }
      />
    </>
  );
};

export default BusinessItem;
