import React, { useCallback, useEffect, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import CreateNewFinancialInstitutionModal from './connect_new_financial_institution_modal';
import UpdateFinancialInstitutionModal from './update_financial_institution_modal';

interface IConnectFinancialInstitutionProps extends IUseModalProps {
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  managementGroupId?: string | number; // Optional management group ID parameter
}

const ConnectFinancialInstitution = ({
  financialInstitutionConnections,
  managementGroupId,
  isOpen,
  onDone,
  onCancel,
}: IConnectFinancialInstitutionProps): JSX.Element => {
  const [isNewConnect, setIsNewConnect] = useState<boolean>(false);

  const handleDone = useCallback(() => {
    setIsNewConnect(false);
    onDone();
  }, [onDone]);

  const handleCancel = useCallback(() => {
    setIsNewConnect(false);
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setIsNewConnect(financialInstitutionConnections.length === 0);
  }, [financialInstitutionConnections]);

  return (
    <>
      <CreateNewFinancialInstitutionModal
        managementGroupId={ managementGroupId }
        show={ isOpen && isNewConnect }
        onCancel={ handleCancel }
        onDone={ handleDone }
      />
      <UpdateFinancialInstitutionModal
        financialInstitutionConnections={ financialInstitutionConnections }
        show={ isOpen && !isNewConnect }
        onCancel={ handleCancel }
        onConnectNewFinancialInstitution={ () => setIsNewConnect(true) }
        onDone={ handleDone }
      />
    </>
  );
};

const useConnectFinancialInstitutionModal = makeUseModal(ConnectFinancialInstitution);

export {
  IConnectFinancialInstitutionProps,
  ConnectFinancialInstitution,
  useConnectFinancialInstitutionModal as default,
};
