import React from 'react';

import Root from '@src/components/root';

import AskAIButton from './ask_ai_button';

const EntryPoint: React.FC<{ askDocytAIData: {id: number, businessId: number} }> = ({ askDocytAIData }) => {
  return (
    <Root>
      <AskAIButton askDocytAIData={ askDocytAIData } />
    </Root>
  );
};

export default EntryPoint;
