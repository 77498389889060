import React, { useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { useFlaggedTransactionPredictedFayeChannel } from '@src/hooks/faye/flagged_transaction_predicted';
import { useDebouncedCallback } from '@src/hooks/utils';
import { IFlaggedTransactionPredictedEvent } from '@src/types/docyt_events/flagged_transaction_predicted';

const AsyncAsk: React.FC<{ askDocytAIData: {id: number, businessId: number} }> = ({ askDocytAIData }) => {
  const { id, businessId } = askDocytAIData;
  const [result, setResult] = useState<IFlaggedTransactionPredictedEvent>({ txnID: id, businessId });
  const handleDataReceived = useDebouncedCallback(({ event }) => {
    if (event?.txnId === id && event?.businessId === businessId) {
      setResult(event);
    }
  }, [id, businessId], 300);
  const { error, tsd } = result;

  useFlaggedTransactionPredictedFayeChannel(id, businessId, handleDataReceived);

  useEffect(() => {
    if (tsd) {
      window.Docyt.vent.trigger('transaction:update', tsd);
    } else if (error) {
      toastr.error(error, 'Error');
    }
  }, [tsd, error]);

  return null;
};

export default AsyncAsk;
