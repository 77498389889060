import { object, string, number } from 'yup';

import { TID } from '@src/types/common';

import { availableStatuses } from './constants';

interface ITaskItemFormValues{
    title: string;
    businessId: TID;
    description: string;
    status?: string;
    userId?: TID;
    closeDate?:string;
    notes?:string;
  }

interface IBlockTaskStatusValuesProps{
    notes:string;
   }
interface IClosedTaskStatusValuesProps {
    closeDate:string;
   }
const blockTaskStatusValidation = object({
  notes: string().required('Notes are required.')
    .min(10, 'Notes must be at least 10 characters long.')
    .max(1000, 'Notes cannot exceed 1000 characters.'),
});
const closedTaskStatusValidation = object({
  closeDate: string().required('Close Date is required.'),
});

const createTaskValidation = object({
  businessId: number().required('Business is required.'),
  title:      string().required('Title is required.')
    .min(10, 'Title must be at least 10 characters long.')
    .max(255, 'Title must be less than 255 characters.'),
  description: string().required('Description is required.')
    .min(10, 'Description must be at least 10 characters long.')
    .max(1000, 'Description must be less than 1000 characters.'),
});
const updateTaskValidation = object({
  businessId: number().required('Business Id is required.'),
  notes:      string().optional(),
  closeDate:  string().optional(),
  userId:     number().when('status', {
    is:        availableStatuses.OPEN,
    then:      number().optional(),
    otherwise: number().required('User ID is required'),
  }),
  title: string().required('Title is required.')
    .min(10, 'Title must be at least 10 characters long.')
    .max(255, 'Title must be less than 255 characters.'),
  status:      string().required('Status is required.').max(100, 'Status must be less than 100 characters.'),
  description: string().required('Description is required.')
    .min(10, 'Description must be at least 10 characters long.')
    .max(1000, 'Description must be less than 1000 characters.'),
});

export { IBlockTaskStatusValuesProps, IClosedTaskStatusValuesProps, ITaskItemFormValues,
  updateTaskValidation, createTaskValidation, blockTaskStatusValidation, closedTaskStatusValidation };
