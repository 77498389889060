import React, { useMemo } from 'react';

import { Props, StylesConfig } from 'react-select';
import { withAsyncPaginate } from 'react-select-async-paginate';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import ReactSelectInput from '@src/components/ui_v2/inputs/react_select/react_select_input';
import { reactSelectStyles } from '@src/components/ui_v2/inputs/react_select/styles';
import { IInputCommonProps } from '@src/components/ui_v2/inputs/types';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

interface IReactSelectInputProps<TOption, TIsMulti extends boolean = false>
extends IInputCommonProps,
Props<TOption, TIsMulti> {
    alwaysShowIndicator?: boolean;
}

const TaskReactSelectInput = <TOption, TIsMulti extends boolean = false>({
  components: componentsProp = {},
  size,
  styles,
  value,
  ...props
}: IReactSelectInputProps<TOption, TIsMulti>) => {
  const [classes, selectProps] = uiStyleProps(undefined, props);
  const LoadingSpinner = useMemo(() => {
    return <SpinnerIcon spin />;
  }, []);

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      minWidth:  240,
      minHeight: 28,
      margin:    '8px 12px 12px',
      fontSize:  12,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow:            '0px 20px 21px 0px rgba(0, 0, 0, 0.21)',
      height:               210,
      paddingTop:           5,
      borderTopRightRadius: 0,
      borderTopLeftRadius:  0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: 204,
    }),
    option: (provided: any) => ({
      ...provided,
      fontSize:   12,
      display:    'flex',
      gap:        10,
      lineHeight: '16px',
      padding:    0,
      height:     '100%',
      width:      '100%',
    }),
  };

  return (
    <ReactSelectInput
      className={ classes }
      components={ {
        IndicatorSeparator: () => null,
        DropdownIndicator:  () => null,
        ...componentsProp,
      } }
      loadingMessage={ () => LoadingSpinner }
      styles={ {
        ...(reactSelectStyles({ size }) as StylesConfig<TOption, TIsMulti>),
        ...styles,
        ...selectStyles,
      } }
      value={ value }
      { ...selectProps }
    />
  );
};

const MemoizedIconSelectInput = React.memo(
  TaskReactSelectInput,
) as typeof TaskReactSelectInput;
const AsyncPaginateReactSelectInput = withAsyncPaginate(
  MemoizedIconSelectInput,
);

export {
  IReactSelectInputProps,
  AsyncPaginateReactSelectInput,
  TaskReactSelectInput as default,
};
