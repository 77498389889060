import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import ManagementGroupConnections from './management_group_connections';

interface IFinancialConnectionsProps {
  managementGroupId: TID;
}

const FinancialConnections = ({ managementGroupId }: IFinancialConnectionsProps): JSX.Element => {
  return (
    <Root>
      <ManagementGroupConnections
        managementGroupId={ managementGroupId }
      />
    </Root>
  );
};

export default FinancialConnections;
