import React, { FC, useMemo } from 'react';

import { flatten } from 'lodash';
import Drawer from 'react-modern-drawer';

import { useGetAuditTransactions } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { IAuditTransactionData } from '@src/types/operational_dashboard/audit_scan';

import Table from '@src/components/ui/table';
import HoverTooltip from '@src/components/ui_v2/tooltip/hover_tooltip';
import { BotNotificationIcon, NotAChargebackIcon } from '@src/components/utils/icomoon';
import OpenInNewIcon from '@src/components/utils/icomoon/open_in_new';

import styles from './style.module.scss';

interface IAnomaliesModalProps {
  isOpen: boolean;
  close: () => void;
  businessName?: string;
  lastAuditedOn?: string | number;
  businessId?: number
}

const AnomaliesModal: FC<IAnomaliesModalProps> = ({ isOpen, close, businessName, lastAuditedOn, businessId }) => {
  const query = useGetAuditTransactions({
    businessId,
    anomaly: true,
  });

  const auditTransactions = useMemo(() => {
    const auditTransactionsPages = query.data?.pages || [];
    return flatten(auditTransactionsPages.map((p) => p.collection));
  }, [query.data?.pages]);

  const totalCount = useMemo(() => {
    return query.data?.pages?.[0]?.meta?.totalCount || 0;
  }, [query.data?.pages]);

  const renderSuggestedChanges = (item: IAuditTransactionData) => {
    if (!item.mismatches) return '';
    const arr = item.mismatches.map((i) => {
      switch (i.field) {
        case 'accounting_class':
          return `Department: ${i.predictedValue}`;
        case 'chart_of_account':
          return `Chart of Account: ${i.predictedValue}`;
        case 'vendor':
          return `Vendor: ${i.predictedValue}`;
        case 'transaction_type':
          return `Accounting Type: ${i.predictedValue}`;
        default:
          return '';
      }
    });
    return arr.join(';');
  };

  return (
    <Drawer
      direction="bottom"
      open={ isOpen }
      size="100vh"
      onClose={ close }
    >
      <div className={ styles['anomalies-modal-container'] }>
        <div className={ styles['anomalies-modal-header'] }>
          <div className={ styles['anomalies-modal-header-col'] } />
          <div className={ styles['anomalies-modal-header-col'] }>
            <div className={ styles['anomalies-modal-header-title'] }>
              <span>{businessName}</span>
            </div>
          </div>
          <div className={ styles['anomalies-modal-header-col'] }>
            <div className={ styles['anomalies-modal-header-controls'] }>
              <NotAChargebackIcon pointer fontSize={ 20 } onClick={ close } />
            </div>
          </div>
        </div>

        <div className={ styles['anomalies-modal-body'] }>
          <div className={ styles['anomalies-modal-title'] }>
            <div>
              <BotNotificationIcon fontSize={ 24 } />
              General Ledger Audit Scan -
              {' '}
              { totalCount }
              {' '}
              { totalCount > 1 ? 'anomalies' : 'anomaly' }
              {' '}
              found
            </div>
            <div>
              Audit last run on
              {' '}
              { lastAuditedOn }
            </div>
          </div>

          <Table
            infiniteQuery={ query }
            wrapperClassName={ styles['anomalies-modal-table'] }
          >
            <Table.Head>
              <Table.Row>
                <Table.HCell widthPercent={ 3 }> S.No </Table.HCell>
                <Table.HCell widthPercent={ 10 }> Date </Table.HCell>
                <Table.HCell> Type </Table.HCell>
                <Table.HCell widthPercent={ 15 }> Memo/Description </Table.HCell>
                <Table.HCell widthPercent={ 10 }> Name </Table.HCell>
                <Table.HCell widthPercent={ 10 }> Department </Table.HCell>
                <Table.HCell widthPercent={ 15 }> Chart of Account </Table.HCell>
                <Table.HCell> Amount </Table.HCell>
                <Table.HCell widthPercent={ 15 }> Anomaly </Table.HCell>
                <Table.HCell widthPercent={ 15 }> Suggested Changes </Table.HCell>
                <Table.HCell widthPercent={ 3 }> </Table.HCell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
                auditTransactions.map((item, index) => (
                  <Table.Row key={ item.id }>
                    <Table.Cell widthPercent={ 3 }>{index + 1}</Table.Cell>
                    <Table.Cell widthPercent={ 10 }>{item.date}</Table.Cell>
                    <Table.Cell>{item.qboData?.txnType || item.transactionType}</Table.Cell>
                    <Table.Cell widthPercent={ 15 }>{item.description}</Table.Cell>
                    <Table.Cell widthPercent={ 10 }>{item.vendorName}</Table.Cell>
                    <Table.Cell widthPercent={ 10 }>{item.accountingClassName}</Table.Cell>
                    <Table.Cell widthPercent={ 15 }>{item.chartOfAccountName}</Table.Cell>
                    <Table.AmountCell amount={ item.amount } />
                    <Table.Cell widthPercent={ 15 }>{ item.mismatches?.map((i) => i.message)?.join(';') }</Table.Cell>
                    <Table.Cell widthPercent={ 15 }>{ renderSuggestedChanges(item) }</Table.Cell>
                    <Table.Cell widthPercent={ 3 }>
                      <HoverTooltip
                        keepShowOnHover
                        content="View Anomaly Details"
                        variant="dark"
                      >
                        <OpenInNewIcon pointer fontSize={ 16 } onClick={ () => item.link && window.open(item.link) } />
                      </HoverTooltip>
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Body>
          </Table>
        </div>
      </div>
    </Drawer>
  );
};

export default AnomaliesModal;
