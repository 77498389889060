import React from 'react';

import Table from '@src/components/ui_v2/table';
import ApiFailureIcon from '@src/components/utils/img_icons/api_failure_icon';
import NoDataIcon from '@src/components/utils/img_icons/no_data_icon';

import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

export type EmptyStateType = 'initial' | 'search' | 'error';

interface IEmptyStateProps {
  colspan: number;
  rowRef?: React.RefObject<HTMLTableRowElement>;
  messageType?: EmptyStateType;
  view: TViewOperationalDashboard;
}

/**
 * Shared empty state component used across table views
 * Shows appropriate messaging based on the contex
 * Customizes message for client vs business views
 */
const EmptyState: React.FC<IEmptyStateProps> = ({
  colspan,
  rowRef,
  messageType = 'initial',
  view,
}) => {
  // Determine if we're showing clients or businesses based on view
  const entityType = view === 'accountant' ? 'clients' : 'businesses';

  // Get message content based on messageType
  const getMessage = () => {
    // Determine the capitalized entity type now, outside case blocks
    const entityCapitalized = entityType.charAt(0).toUpperCase() + entityType.slice(1);

    switch (messageType) {
      case 'search':
        return {
          title:   'No data found that matches your search.',
          message: 'Try using a different keyword.',
          icon:    <NoDataIcon />,
        };
      case 'error':
        return {
          title:   'Couldn\'t fetch the data.',
          message: (
            <>
              This may be due to technical or network issue.
              <br />
              Try again later.
            </>
          ),
          icon: <ApiFailureIcon />,
        };
      case 'initial':
      default:
        return {
          title:   'No data available here',
          message: `${entityCapitalized} will appear once they are added or assigned.`,
          icon:    <NoDataIcon />,
        };
    }
  };

  const { title, message, icon } = getMessage();

  return (
    <Table.Row ref={ rowRef }>
      <Table.Cell colSpan={ colspan } style={ { padding: 0 } }>
        <div className={ styles['empty-state-container'] }>
          <div className={ styles['empty-state-icon-container'] }>
            {icon}
          </div>
          <h3 className={ styles['empty-state-title'] }>{ title }</h3>
          <p className={ styles['empty-state-message'] }>{ message }</p>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(EmptyState);
