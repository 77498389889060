import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetMultiEntityReportsServiceResponse,
  IGetStandardCategoriesResponse,
  getMultiEntityReportsService,
  getStandardCategories,
  IGetTemplatesResponse,
  getTemplates,
  getReportServicesByBusinessId,
  IGetReportServicesByBusinessIdResponse,
  ICreateMultiEntityBusinessReportParams,
  createMultiEntityBusinessReport,
  updateMultiEntityReport,
  refreshMultiEntityReport,
  deleteMultiEntityReport
} from '@src/requests/multi_entity_reports';
import { IMultiBusinessReportDetail } from '@src/requests/report_service/multi_business_report';

export const useGetMultiEntityReportsServiceQuery = () => {
  return useQuery<IGetMultiEntityReportsServiceResponse, Error>(
    [QueryKey.multiEntityReportsService], 
    () => getMultiEntityReportsService()
  );
};

export const useGetStandardCategoriesQuery = () => {
  return useQuery<IGetStandardCategoriesResponse[], Error>(
    [QueryKey.standardCategories],
    () => getStandardCategories()
  );
}

export const useGetTemplatesQuery = (params?: { 'standard_category_ids[]': number[] }) => {
  return useQuery<IGetTemplatesResponse[], Error>(
    [QueryKey.multiEntityTemplates],
    () => getTemplates(params),
    {
      enabled: !!params && !!params['standard_category_ids[]'] && params['standard_category_ids[]'].length !== 0
    }
  );
}

export const useGetReportServicesByBusinessId = (params: { 'business_ids[]': number[] }) => {
  return useQuery<IGetReportServicesByBusinessIdResponse[], Error>(
    [QueryKey.reportServicesByBusinessId],
    () => getReportServicesByBusinessId(params),
    {
      enabled: !!params['business_ids[]'] && !!params['business_ids[]'].length
    }
  );
}

export const useCreateMultiEntityBusinessReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IMultiBusinessReportDetail, Error, ICreateMultiEntityBusinessReportParams>(
    createMultiEntityBusinessReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.getMultiBusinessReports);
      },
    }
  );
}

export const useUpdateMultiEntityReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IMultiBusinessReportDetail, Error, IMultiBusinessReportDetail>(
    updateMultiEntityReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.getMultiBusinessReports);
      },
    }
  );
}

export const useRefreshMultiEntityReport = () => {
  const queryClient = useQueryClient();

  return useMutation<IMultiBusinessReportDetail, Error, string>(
    refreshMultiEntityReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.getMultiBusinessReports);
      },
    }
  );
}

export const useDeleteMultiEntityReport = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(
    deleteMultiEntityReport,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.getMultiBusinessReports);
      },
    }
  );
}

