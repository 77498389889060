import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getAllClients,
  getAllMemebers,
  getMemeberInfo,
  IGetAllClientsParams,
  IGetAllClientsResponse,
  IGetAllMembersParams,
  IGetAllMembersResponseInfite,
  IGetMemeberInfoParams,
  IManageClientAssignmentsParams,
  IManageClientAssignmentsResponse,
  manageClientAssignments,
  IUpdateBusinessRoleParams,
  IUpdateBusinessRoleResponse,
  removeMember,
  updateBusinessRole,
  IAssignClientParams,
  IRemoveClientParams,
  assignClient,
  removeClient,
} from '@src/requests/team_management/team_management';
import { IBusinessTeamMember, IMemberInfo } from '@src/types/team_management/team_management';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetAllMembers = createUseGetInfiniteCollection<
  IBusinessTeamMember,
  IGetAllMembersParams,
  IGetAllMembersResponseInfite
>({
  queryKey: QueryKey.myTeamMembers,
  request:  getAllMemebers,
});

const useDeleteMember = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (memberId: number) => removeMember({ id: memberId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.myTeamMembers);
      },
    },
  );
};

const useGetMemberInfo = (
  params: IGetMemeberInfoParams,
  options?: Omit<UseQueryOptions<IMemberInfo, Error, IMemberInfo, (string | number)[]>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery(
    [QueryKey.myTeamMemberInfo, params.id],
    () => getMemeberInfo({ id: params.id }),
    options,
  );
};

const useUpdateBusinessRole = () => {
  const queryClient = useQueryClient();

  return useMutation<IUpdateBusinessRoleResponse, Error & AxiosError, IUpdateBusinessRoleParams>(
    (params: IUpdateBusinessRoleParams) => updateBusinessRole(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.myTeamMembers);
      },
    },
  );
};

const useGetAllClients = (
  params: IGetAllClientsParams,
  options?: UseQueryOptions<IGetAllClientsResponse, Error>,
) => {
  return useQuery<IGetAllClientsResponse, Error>(
    [QueryKey.allClients, params],
    () => getAllClients(params),
    options,
  );
};

const useManageClientAssignments = () => {
  const queryClient = useQueryClient();

  return useMutation<IManageClientAssignmentsResponse, Error & AxiosError, IManageClientAssignmentsParams>(
    (params: IManageClientAssignmentsParams) => manageClientAssignments(params),
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(QueryKey.myTeamMembers);
        queryClient.invalidateQueries([QueryKey.allClients, params.userId]);
      },
    },
  );
};

const useAssignClient = () => {
  return useMutation<IManageClientAssignmentsResponse, Error & AxiosError, IAssignClientParams>(
    (params: IAssignClientParams) => assignClient(params),
  );
};

const useRemoveClient = () => {
  return useMutation<IManageClientAssignmentsResponse, Error & AxiosError, IRemoveClientParams>(
    (params: IRemoveClientParams) => removeClient(params),
  );
};

export {
  useGetAllMembers,
  useGetAllClients,
  useGetMemberInfo,
  useDeleteMember,
  useUpdateBusinessRole,
  useManageClientAssignments,
  useAssignClient,
  useRemoveClient,
};
