export const PaymentMethodLabel = {
  PAYMENT_DOCYT_ACH:           'Docyt ACH',
  PAYMENT_DOCYT_CHECK:         'Full Service Docyt Check',
  PAYMENT_BANK_CHECK:          'Manual Check (Mark as Paid)',
  PAYMENT_BANK_ACCOUNT:        'Bank Account (Mark as Paid)',
  PAYMENT_ADVANCED_SETTLEMENT: 'Advanced Settlement',
  PAYMENT_CREDIT_CARD:         'Credit Card (Mark as Paid)',
  PAYMENT_CASH:                'Cash (Mark as Paid)',
  PAYMENT_PAID_EXTERNALLY:     'Bank Account',
  PAYMENT_VENDOR_CREDIT:       'Vendor Credit',
  PAYMENT_SELF_PRINT_CHECK:    'Self print Docyt Check',
};
