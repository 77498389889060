import React from 'react';

import { TLoadingVariant } from '@src/components/ui_v2/table/types';

import { ICollectionTableProps } from './collection_table';
import EmptyView from './empty_view';
import LoadingRow from './loading_row';
import Row, { ICollectionTableRowProps } from './row';
import { TColumn, TModel } from './types';

interface TableContentProps<Model extends TModel, TSortColumn extends string = never> {
  isLoading: boolean;
  records: Model[];
  columns: TColumn<Model, TSortColumn>[];
  showSelect?: boolean;
  idField: keyof Model;
  isRowClickable?: ICollectionTableRowProps<Model, TSortColumn>['isRowClickable']
  isRowInactive?: ICollectionTableRowProps<Model, TSortColumn>['isRowInactive']
  isRowSelectable?: ICollectionTableRowProps<Model, TSortColumn>['isRowSelectable']
  isRowHoverable?: ICollectionTableRowProps<Model, TSortColumn>['isRowHoverable']
  stickySelectColumn?: boolean;
  onCheckboxClick?: ICollectionTableRowProps<Model, TSortColumn>['onCheckboxClick'],
  onRowClick?: ICollectionTableRowProps<Model, TSortColumn>['onClick'],
  emptyView?: React.ReactNode;
  isRowLoading?: ICollectionTableProps<Model, TSortColumn>['isRowLoading'],
}

const SKELETON_DEFAULT_COUNT = 20;

const TableContent = <Model extends TModel, TSortColumn extends string = never>({
  isLoading,
  columns,
  showSelect,
  records,
  idField = 'id',
  isRowClickable,
  isRowHoverable,
  isRowInactive,
  isRowSelectable,
  stickySelectColumn,
  onCheckboxClick,
  onRowClick,
  emptyView,
  isRowLoading,
}: TableContentProps<Model, TSortColumn>) => {
  const getRowLoadingVariant = React.useCallback((object: Model): TLoadingVariant => {
    const loadingState = isRowLoading?.(object);
    return loadingState === 'ai' ? loadingState : 'normal';
  }, [isRowLoading]);

  if (isLoading) {
    return (
      <>
        {
          Array.from({ length: SKELETON_DEFAULT_COUNT }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <LoadingRow key={ index } columns={ columns } showSelect={ showSelect } variant="normal" />
          ))
        }
      </>
    );
  }

  if (emptyView && records.length === 0) {
    return (
      <EmptyView columnsCount={ showSelect ? columns.length + 1 : columns.length }>
        { emptyView }
      </EmptyView>
    );
  }

  return (
    <>
      {records.map((object) => (
        isRowLoading && isRowLoading(object) ? (
          <LoadingRow
            key={ object[idField] }
            columns={ columns }
            showSelect={ showSelect }
            variant={ getRowLoadingVariant(object) }
          />
        ) : (
          <Row<Model, TSortColumn>
            key={ object[idField] }
            columns={ columns }
            idField={ idField }
            isRowClickable={ isRowClickable }
            isRowHoverable={ isRowHoverable }
            isRowInactive={ isRowInactive }
            isRowSelectable={ isRowSelectable }
            object={ object }
            showSelect={ showSelect }
            stickySelectColumn={ stickySelectColumn }
            onCheckboxClick={ onCheckboxClick }
            onClick={ onRowClick }
          />
        )
      ))}
    </>
  );
};

export default React.memo(TableContent) as typeof TableContent;
