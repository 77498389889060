import React from 'react';

import classNames from 'classnames';

import Modal from '@src/components/ui/modal';

import './my_team_modal_style.scss';

interface IProfileModalProps {
  isOpen: boolean;
  className?: string;
  children: React.ReactNode;
  modalTitle?: React.ReactNode;
  footerContent?: React.ReactNode;
}

const BaseModalMyTeam = ({
  isOpen,
  className,
  children,
  modalTitle,
  footerContent,
}: IProfileModalProps) => {
  return (
    <Modal
      className={ classNames('my-team-modal', className) }
      show={ isOpen }
      title={ modalTitle }
    >
      <Modal.Body>
        { children }
      </Modal.Body>
      { footerContent && (
        <Modal.Footer>
          { footerContent }
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default BaseModalMyTeam;
