/* eslint-disable eqeqeq */
import React, { useCallback, useState } from 'react';

import { IService, TServiceCode } from '@src/types/services';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import ServiceIcon from './service_icon';

const getServiceName = (serviceCode: TServiceCode) => {
  switch (serviceCode) {
    case window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNT_RECEIVABLES:
      return window.Docyt.Common.Constants.SERVICE_NAMES.ACCOUNT_RECEIVABLES;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VENDORS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.VENDORS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.TASKS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.TASKS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORTS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE_REPORTS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.CORPORATE_CARDS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.CORPORATE_CARDS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.BILL_PAY:
      return window.Docyt.Common.Constants.SERVICE_NAMES.BILL_PAY;
    case window.Docyt.Common.Constants.SERVICE_TYPES.ACCOUNTING:
      return window.Docyt.Common.Constants.SERVICE_NAMES.ACCOUNTING;
    case window.Docyt.Common.Constants.SERVICE_TYPES.FINANCIAL_ACCOUNTS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.FINANCIAL_ACCOUNTS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.OPEN_ITEMS:
      return window.Docyt.Common.Constants.SERVICE_NAMES.OPEN_ITEMS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.DASHBOARD:
      return window.Docyt.Common.Constants.SERVICE_NAMES.DASHBOARD;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VAULT:
      return window.Docyt.Common.Constants.SERVICE_NAMES.VAULT;
    case window.Docyt.Common.Constants.SERVICE_TYPES.AP_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.AP_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECEIPT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.RECEIPT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.FINANCIAL_REPORTS;
    case window.Docyt.Common.Constants.SERVICE_TYPES.BANKING_ACCOUNT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.BANKING_ACCOUNT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.REVENUE_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECONCILIATION_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.RECONCILIATION_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE_REPORT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.AR_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.AR_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VENDOR_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.VENDOR_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.OPERATIONAL_METRICS;
    default:
      return null;
  }
};

interface IServiceMenuProps {
  serviceCode: TServiceCode,
  service?: IService,
  hideEmailIcon?: boolean,
  featureQuery?: boolean,
}

const ServiceMenu = ({
  service,
  serviceCode,
  hideEmailIcon = false,
  featureQuery,
}: IServiceMenuProps) => {
  const noService =
    service
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.RECONCILIATION_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.BANKING_ACCOUNT_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.AR_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.VENDOR_SERVICE;

  const revenueService =
  service && serviceCode == window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnClick = useCallback(() => {
    setShowTooltip(true);
    setTimeout(() => {
      navigator.clipboard.writeText(`${service?.uploadEmail}`);
      setShowTooltip(false);
    }, 1500);
  }, [service?.uploadEmail]);

  const metricsService =
  service && serviceCode == window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE;
  return (
    <div className="service-header btn-group">
      <div className="pull-left">
        <ServiceIcon serviceCode={ serviceCode } />
      </div>
      <div className="business-service-dropdown-menu-wrapper">
        <span className="service-header-title pointer dropdown-toggle" data-toggle="dropdown">
          { getServiceName(serviceCode) }
          {' '}
        </span>
        {
          (noService && ((!revenueService || !featureQuery) && (!metricsService || !featureQuery)))
          && (
            <p className="service-email-panel in-blue-900 font-12">
              <a href={ `mailto:${service?.uploadEmail}` }>
                {!hideEmailIcon && (
                  <i className="icon icon-forwarding-email font-16 m-r-5" />
                )}
                {' '}
                { service?.uploadEmail }
              </a>
            </p>
          )
        }
        {(revenueService && featureQuery)
        && (
          <Tooltip.Hover isControl content="Email copied to clipboard" showTooltip={ showTooltip }>
            <p className="service-email-panel in-blue-900 font-12">
              <Button variant="link" onClick={ handleOnClick }>
                { service?.uploadEmail }
              </Button>
            </p>
          </Tooltip.Hover>
        )}
        {(metricsService && featureQuery)
        && (
          <Tooltip.Hover isControl content="Email copied to clipboard" showTooltip={ showTooltip }>
            <p className="service-email-panel in-blue-900 font-12">
              <Button variant="link" onClick={ handleOnClick }>
                { service?.uploadEmail }
              </Button>
            </p>
          </Tooltip.Hover>
        )}
      </div>
    </div>
  );
};

export default ServiceMenu;
