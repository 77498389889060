import React, { useCallback, useMemo, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { useGetManagementGroup, useDestroyManagementGroup } from '@src/hooks/queries/management_groups';
import { useLocalStorage } from '@src/hooks/storage';
import { IManagementGroup } from '@src/types/management_groups';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { useEditManagementGroupModal } from '@src/components/management_groups/modals/edit_management_group_modal';
import Text from '@src/components/ui/text';
import { TrashIcon } from '@src/components/utils/fa_icons';
import { EditIcon } from '@src/components/utils/icomoon';

import ServiceMenuItem from './module_header_item';
import ModuleHeaderMenu from './module_header_menu';

import styles from '../styles.module.scss';

// Define a union type for all possible page values
type PageType = 'financial_connections';

interface ModuleManagementGroupHeaderActionsProps {
  managementGroupId?: string | number;
}

const ModuleManagementGroupHeaderActions: React.FC<ModuleManagementGroupHeaderActionsProps> = ({
  managementGroupId,
}) => {
  // Safely parse the management group ID
  const mgId = managementGroupId ? Number(managementGroupId) : undefined;
  const storedManagementGroup = useLocalStorage('management_group_id', {});
  const [selectedManagementGroup, setSelectedManagementGroup] = useState<IManagementGroup>();

  // Get the management group data
  const managementGroupQuery = useGetManagementGroup(mgId);
  const { mutate: destroyManagementGroup } = useDestroyManagementGroup();

  // Setup the management group edit modal
  const editManagementGroupModal = useEditManagementGroupModal();

  // Get the management group data when it's available
  useEffect(() => {
    if (managementGroupQuery.data && mgId) {
      setSelectedManagementGroup(managementGroupQuery.data.managementGroup);
    }
  }, [managementGroupQuery.data, mgId]);

  const managementGroupDeleteConfirmMsg = window.Docyt.Common.Constants.Messages.REMOVE_MANAGEMENT_GROUP_CONFIRM_MSG;

  // Setup the delete handler
  const handleDeleteManagementGroup = useCallback(() => {
    if (!mgId) return;

    destroyManagementGroup({ id: mgId }, {
      onSuccess: () => {
        localStorage.removeItem('management_group_id');
        storedManagementGroup.setValue({});
        backboneNavigateToUrl('/');
        toastr.success('Management Group Deleted Successfully', 'Success!');
      },
      onError: (error: Error) => {
        toastr.error(error.message, 'Error!');
      },
    });
  }, [destroyManagementGroup, mgId, storedManagementGroup]);

  // Setup the delete confirmation modal
  const ConfirmDeleteManagementGroupModal = useConfirmDeleteModal({
    onDone: handleDeleteManagementGroup,
  });

  // Determine the current active page from URL
  const currentActivePage = useMemo<PageType>(() => {
    // Default to financial_connections
    return 'financial_connections';
  }, []);

  // Render icon helper function
  const renderIcon = (icon: string) => {
    return (
      <span className={ `m-l-18 ${styles['icon-wrapper']}` }>
        {icon === 'edit' ? <EditIcon /> : <TrashIcon />}
      </span>
    );
  };

  // Safety check - if no management group ID, render a simple message
  if (!mgId) {
    return (
      <div className={ styles['service-menu-page'] }>
        <Text>Management Group ID is missing</Text>
      </div>
    );
  }

  const MANAGEMENT_GROUP_PAGES = window.Docyt.Common.Constants.MANAGEMENT_GROUP_PAGES;
  return (
    <div className={ styles['service-menu-page'] }>
      <ModuleHeaderMenu
        active={ currentActivePage === MANAGEMENT_GROUP_PAGES.financial_connections }
        title={ window.Docyt.Common.Constants.MODULE_MANAGEMENT_GROUP_HEADER_ITEMS.FINANCIAL_CONNECTIONS }
        url={ `/management_groups/${mgId}/financial_connections` }
      />
      <ModuleHeaderMenu
        active={ false }
        title={ window.Docyt.Common.Constants.MODULE_MANAGEMENT_GROUP_HEADER_ITEMS.SETTINGS }
      >
        <ServiceMenuItem
          icon={ renderIcon('edit') }
          serviceUrl=""
          title="Edit"
          onClickHandler={ () => editManagementGroupModal.open() }
        />
        <ServiceMenuItem
          icon={ renderIcon('trash') }
          serviceUrl=""
          title="Delete"
          onClickHandler={ () => ConfirmDeleteManagementGroupModal.open() }
        />
        <ConfirmDeleteManagementGroupModal.Component
          { ...ConfirmDeleteManagementGroupModal.props }
          confirmStyle="primary"
          confirmTitle="Proceed"
          text={ (
            <Text as="span" dangerouslySetInnerHTML={ { __html: managementGroupDeleteConfirmMsg } } />
          ) }
          title="Delete Management Group"
        />
        <editManagementGroupModal.Component
          { ...editManagementGroupModal.props }
          managementGroup={ selectedManagementGroup }
        />
      </ModuleHeaderMenu>
    </div>
  );
};

export default React.memo(ModuleManagementGroupHeaderActions);
