import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  IUpsertIntegrationResponse,
} from '@src/requests/integrations/integrations';
import { revenueServicePathRevenueCapturePath } from '@src/routes';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import { useAddReportTypeModal } from '../../report_type/add_report_type/add_report_type_modal';

import styles from './styles.module.scss';

interface IpullIntegrationSuccessModalProps extends IUseModalProps {
  UpsertIntegrationResponse?: IUpsertIntegrationResponse | null
}

const PullIntegrationSuccessModal = ({
  isOpen,
  onDone,
  UpsertIntegrationResponse,
}: IpullIntegrationSuccessModalProps) => {
  const navigate = useNavigate();
  const business = useBusinessContext();
  const [addReport, setAddReport] = useState<boolean>(false);
  const service = useRevenueServiceContext();

  const addReportTypeModal = useAddReportTypeModal();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );
  const integration = useGetIntegration(
    { integrationId: UpsertIntegrationResponse?.id, businessId: business.id },
    { enabled: !!UpsertIntegrationResponse?.id },
  );

  const featureSkipRevenueReportTypeValidation = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.SKIP_REVENUE_REPORT_MAPPING,
  );

  const navigateToRevenueSystem = useCallback(() => {
    const integrationId = integration.data?.id;
    const selectedRevenueSystem =
            service.revenueSystems.find((o) => o.integrationId === Number(integrationId))
            || service.revenueSystems[0];
    const selectedId = selectedRevenueSystem?.id;
    navigate(
      revenueServicePathRevenueCapturePath(
        business.id,
        selectedId,
      ),
    );
  }, [business.id, integration.data?.id, navigate, service.revenueSystems]);

  const handleOnClick = useCallback(() => {
    setAddReport(!addReport);
    if (!integration?.data?.skipRevenueMapping) {
      addReportTypeModal.open();
    } else {
      navigateToRevenueSystem();
    }
    onDone();
  }, [
    addReport,
    addReportTypeModal,
    integration.data?.skipRevenueMapping,
    onDone,
    navigateToRevenueSystem,
  ]);

  const handleCancelClick = useCallback((event) => {
    event.preventDefault();
    navigateToRevenueSystem();
    onDone();
  }, [navigateToRevenueSystem, onDone]);

  return (
    <>
      {addReport && (
      <addReportTypeModal.Component
        UpsertIntegrationResponse={ UpsertIntegrationResponse }
        integration={ integration.data }
        isConnect="pull"
        { ...addReportTypeModal.props }
      />
      )}
      <Modal
        show={ isOpen && integration?.data !== undefined }
        title={ integration?.data?.skipRevenueMapping
          ? `${UpsertIntegrationResponse?.externalSystemName} System Connected!`
          : 'Revenue System Connected!' }
      >
        <Modal.Body>
          <div className={ styles['bold-text'] }>
            <strong>
              You have successfully connected to
              {' '}
              {UpsertIntegrationResponse?.externalSystemName}
              {' '}
              {integration?.data?.skipRevenueMapping ? 'system.' : 'revenue system.'}
              {' '}
            </strong>
          </div>
          {!integration?.data?.skipRevenueMapping && (
            <div className={ styles['bottom-text-pull-integration'] }>
              Next step is to map the report line items with the category, department and the journal entry.
              It will be saved as revenue report type after the mapping.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          {integration?.data?.skipRevenueMapping ? (
            <div className={ styles['center-align'] }>
              <Button
                type="submit"
                variant="primary"
                onClick={ handleOnClick }
              >
                Done
              </Button>
            </div>
          ) : (
            <>
              <Button
                variant="link"
                onClick={ handleCancelClick }
              >
                Do it Later
              </Button>
              {featureQuery.data && featureSkipRevenueReportTypeValidation.data && (
              <Button
                type="submit"
                variant="primary"
                onClick={ handleOnClick }
              >
                Map Now
              </Button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const usePullIntegrationSuccessModal = makeUseModal(PullIntegrationSuccessModal);

export {
  usePullIntegrationSuccessModal,
  PullIntegrationSuccessModal as default,
};
