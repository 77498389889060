import React from 'react';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface IHeaderProps {
  showAddMemberModal: () => void;
}

const Header: React.FC<IHeaderProps> = ({ showAddMemberModal }) => {
  return (
    <div className={ styles['page-main-header'] }>
      <div>
        <Text fontSize={ 20 } fontVariant="bold">My Team</Text>
      </div>
      <Button
        size="normal"
        variant="primary"
        onClick={ showAddMemberModal }
      >
        Add Member
      </Button>
    </div>
  );
};

export default Header;
