module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<ul class="nav nav-sidebar">\n  <li>\n    <a href="/settings" class="');
    
      __out.push(__sanitize(this.tab === 'personal' ? 'active' : ''));
    
      __out.push('">\n      <span class="icon-personal-profile service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n      <span class="nav-item-text">Personal Profile</span>\n    </a>\n  </li>\n  ');
    
      if (Docyt.currentAdvisor.isReceivableOnlyUser() || Docyt.currentAdvisor.isBookgptBusinessUser()) {
        __out.push('\n    <!-- My Businesses - Hide for BookGPT -->\n    <li>\n      <a href="/settings/businesses" class="');
        __out.push(__sanitize(this.tab === 'businesses' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-business-profiles service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n        <span class="nav-item-text">My Businesses</span>\n        <span class="nav-item-number font-12">');
        __out.push(__sanitize(this.businesses_count));
        __out.push('</span>\n      </a>\n    </li>\n    ');
        if (this.show_management_group) {
          __out.push('\n      <li data-global-feature-toggle="management-group-enabled">\n        <a href="/settings/management_groups" class="');
          __out.push(__sanitize(this.tab === 'management_groups' ? 'active' : ''));
          __out.push('">\n          <span class="icon icon-management-group service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n          <span class="nav-item-text">Management Group</span>\n          <span class="nav-item-number font-12">');
          __out.push(__sanitize(this.management_groups_count));
          __out.push('</span>\n        </a>\n      </li>\n    ');
        }
        __out.push('\n    <!-- Notifications - Hide for BookGPT -->\n    <li>\n      <a href="/settings/notifications" class="');
        __out.push(__sanitize(this.tab === 'notifications' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-notifications service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Notifications</span>\n      </a>\n    </li>\n    <!-- Security & Privacy - Always visible -->\n    <li>\n      <a href="/settings/security" class="');
        __out.push(__sanitize(this.tab === 'security' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-security-and-privacy service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Security &#38; Privacy</span>\n      </a>\n    </li>\n    <li>\n      <a href="/settings/integrations" class="');
        __out.push(__sanitize(this.tab === 'integrations' ? 'active' : ''));
        __out.push('">\n        <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n        <span class="nav-item-text">Integrations</span>\n      </a>\n    </li>\n  ');
      } else {
        __out.push('\n    ');
        if (Docyt.currentAdvisor.isPremiumFirmClosingSuite()) {
          __out.push('\n      ');
          if (this.show_management_group) {
            __out.push('\n        <li data-global-feature-toggle="management-group-enabled">\n          <a href="/settings/management_groups" class="');
            __out.push(__sanitize(this.tab === 'management_groups' ? 'active' : ''));
            __out.push('">\n            <span class="icon icon-management-group service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n            <span class="nav-item-text">Management Group</span>\n            <span class="nav-item-number font-12">');
            __out.push(__sanitize(this.management_groups_count));
            __out.push('</span>\n          </a>\n        </li>\n      ');
          }
          __out.push('\n    ');
        }
        __out.push('\n    <!-- My Firm - Visible only for BookGPT -->\n    <li>\n      <a href="/settings/firm" class="');
        __out.push(__sanitize(this.tab === 'firm' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-business-profiles service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n        <span class="nav-item-text">My Firm</span>\n      </a>\n    </li>\n    <!-- Billing - Visible only for BookGPT -->\n    <li>\n      <a href="/settings/billing" class="');
        __out.push(__sanitize(this.tab === 'billing' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-account service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Billing</span>\n      </a>\n    </li>\n    <!-- Security & Privacy - Always visible -->\n    <li>\n      <a href="/settings/security" class="');
        __out.push(__sanitize(this.tab === 'security' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-security-and-privacy service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">Security &#38; Privacy</span>\n      </a>\n    </li>\n    <!-- My Team - Always visible -->\n    <li>\n      <a href="/settings/team_management" class="');
        __out.push(__sanitize(this.tab === 'team_management' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-user-management service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">My Team</span>\n      </a>\n    </li>\n\n    <!-- My Clients - Always visible -->\n    <li>\n      <a href="/settings/client_management" class="');
        __out.push(__sanitize(this.tab === 'client_management' ? 'active' : ''));
        __out.push('">\n        <span class="icon icon-team-management service-icon-font"><span class="path1"></span><span class="path2"></span></span>\n        <span class="nav-item-text">My Clients</span>\n        <span class="nav-item-number clients-count font-12">');
        __out.push(__sanitize(this.clients_count));
        __out.push('</span>\n      </a>\n    </li>\n    <!-- Integrations - Always visible -->\n    ');
        if (Docyt.currentAdvisor.isPremiumFirmClosingSuite()) {
          __out.push('\n      <li>\n        <a href="/settings/integrations" class="');
          __out.push(__sanitize(this.tab === 'integrations' ? 'active' : ''));
          __out.push('">\n          <span class="icon-grid-list-blue in-blue-700 service-icon-font"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>\n          <span class="nav-item-text">Integrations</span>\n        </a>\n      </li>\n    ');
        }
        __out.push('\n  ');
      }
    
      __out.push('\n</ul>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}