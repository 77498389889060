import React, { memo } from 'react';

import ServiceMenu from '@src/components/common/service_menu';
import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

const SidebarMenuView = () => {
  return (
    <Root>
      <ServiceSidebar
        serviceMenu={ (
          <ServiceMenu
            serviceCode={ window.Docyt.Common.Constants.SERVICE_TYPES.TASKS }
          />
      ) }
      >
        <ServiceSidebar.Item
          href="/task_management/tasks/open"
          title="Open"
        />
        <ServiceSidebar.Item
          href="/task_management/tasks/assigned"
          title="Assigned"
        />
        <ServiceSidebar.Item
          href="/task_management/tasks/blocked"
          title="Blocked"
        />
        <ServiceSidebar.Item
          href="/task_management/tasks/closed"
          title="Closed"
        />
      </ServiceSidebar>
    </Root>
  );
};

export default memo(SidebarMenuView);
