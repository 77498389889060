import moment from 'moment';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TDate, TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

export const getInvoiceStatusLabel = (
  status: string,
): string | undefined => {
  switch (status) {
    case window.configData.ap_invoice_states.DOCUMENT_STATE_PAID:
      return 'Paid';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_CREDITED:
      return 'Credited';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_DO_NOT_PAY:
      return 'Customer - DO NOT PAY';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_PROCESSING:
      return 'Processing';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_PAYMENT:
      return 'Ready to Pay';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_CREDIT:
      return 'Ready for Credit';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_UNPROCESSABLE:
      return 'Unknown';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_VERIFYING:
      return 'In Verification';
    case window.configData.ap_invoice_states.DOCUMENT_STATE_APPROVAL:
      return 'Needs Approval';
    default:
      return status.split('_').map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
  }
};

const getApproverStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): string | undefined => {
  if ((userRole === 'APPROVER' || userRole === 'APPROVER_AND_PAYER')
    && ['ready_for_payment', 'ready_for_credit', 'approval'].includes(serviceDocument.state)) {
    let approvedByUser = false;
    let approvedDate = '';
    for (let i = 0; i < serviceDocument.serviceDocumentApprovers.length; i += 1) {
      if (serviceDocument.serviceDocumentApprovers[i].userId === currentUserId) {
        approvedDate = formatDate(serviceDocument.serviceDocumentApprovers[i].approvedAt, 'MMM DD, YYYY');
        approvedByUser = true;
      }
    }
    if (approvedByUser) {
      return `Approved: ${approvedDate}`;
    } if (['ready_for_payment', 'ready_for_credit'].includes(serviceDocument.state)) {
      return 'Approved';
    }
  }
  return undefined;
};

const getVerifierStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): string | undefined => {
  if (userRole === 'VERIFIER'
      && ['ready_for_payment', 'ready_for_credit', 'approval'].includes(serviceDocument.state)) {
    if (serviceDocument.serviceDocumentApprovers.length > 0
        && ['ready_for_payment', 'ready_for_credit'].includes(serviceDocument.state)) {
      return `Approved: ${formatDate(serviceDocument.serviceDocumentApprovers[0].approvedAt, 'MMM DD, YYYY')}`;
    }
    return `Verified: ${formatDate(serviceDocument.verifiedAt, 'MMM DD, YYYY')}`;
  }
  return undefined;
};

const getUploaderStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): string | undefined => {
  if (userRole === 'UPLOADER' && ['ready_for_payment',
    'ready_for_credit',
    'approval',
    'verifying'].includes(serviceDocument.state)) {
    return `Uploaded: ${formatDate(serviceDocument.createdAt, 'MMM DD, YYYY')}`;
  }
  return undefined;
};

const getPaidStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
): string | undefined => {
  if (serviceDocument.servicePaymentTransactionStatus === 'failed') {
    return 'Payment Error';
  }
  if (serviceDocument.servicePaymentTransactionStatus === 'processing') {
    if (serviceDocument.paymentMode === 'docyt_check') {
      return 'Generating Check';
    } if (serviceDocument.paymentTransactionError) {
      return 'Payment Error';
    }
    return 'In-progress';
  }
  return undefined;
};

const getRecurringBillStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
): string | undefined => {
  if (serviceDocument.scheduledBillState === 'scheduled') {
    return 'Recurring Payment';
  } if (serviceDocument.scheduledBillState === 'on_hold') {
    return 'Recurring Bill(On Hold)';
  } if (serviceDocument.scheduledBillState === 'cancelled') {
    return 'Recurring Bill(Cancelled)';
  }
  return undefined;
};

export const getInvoiceStatus = (
  serviceDocument: IAccountsPayableServiceDocument,
  currentUserId: TID,
  userRole: string | undefined,
): string | undefined => {
  if (serviceDocument.state === window.configData.ap_invoice_states.DOCUMENT_STATE_PROCESSING
    && ['pending', 'copying', 'uploading'].includes(serviceDocument.documentState)) {
    return 'Uploading';
  }

  let status = getUploaderStatus(serviceDocument, currentUserId, userRole);
  status ||= getVerifierStatus(serviceDocument, currentUserId, userRole);
  status ||= getApproverStatus(serviceDocument, currentUserId, userRole);

  if (['paid', 'credited'].includes(serviceDocument.state)) {
    return getPaidStatus(serviceDocument);
  } if (serviceDocument.state === 'credited') {
    return 'Credit Used';
  } if (serviceDocument.recurringBillId !== null) {
    status = getRecurringBillStatus(serviceDocument);
  } else if (serviceDocument.scheduledDetail !== null) {
    status = 'Scheduled to Pay';
  } else if (serviceDocument.isDuplicate) {
    return serviceDocument.duplicateStatus;
  }
  return status || getInvoiceStatusLabel(serviceDocument.state);
};

export const getInvoiceStatusClass = (
  document: IAccountsPayableServiceDocument,
  status: string | undefined,
) => {
  switch (status) {
    case document.duplicateStatus:
    case 'Payment Error':
      return 'error_state';
    case 'Generating Check':
      return 'generating_check';
    default:
      return document.state;
  }
};

export const getFormattedInvoiceDueDate = (
  date: TDate,
) => {
  if (!date) {
    return {
      dueDate:  null,
      dueDated: false,
    };
  }
  const dueDate = moment(date);
  if (dueDate.diff(moment()) >= 0) {
    return {
      dueDate:  dueDate.fromNow(),
      dueDated: false,
    };
  }
  return {
    dueDate:  dueDate.fromNow(true),
    dueDated: true,
  };
};

export const getFormattedDueDate = (
  date: TDate,
) => {
  if (!date) {
    return {
      dueIn:     null,
      isOverDue: false,
    };
  }

  const dueDate = moment(date);
  const today = moment();
  const diff = dueDate.diff(today, 'days');
  const absDays = Math.abs(diff);

  if (diff === 0) {
    return {
      dueIn:     'Today',
      isOverDue: true,
    };
  }

  let dueIn: string;

  if (absDays < 30) {
    dueIn = `${absDays}${diff < 0 ? 'd' : ' days'}`;
  } else if (absDays < 365) {
    const months = Math.round(absDays / 30);
    dueIn = `${months}${months === 1 ? ' month' : ' months'}`;
  } else {
    const years = Math.round(absDays / 365);
    dueIn = `${years}${years === 1 ? ' year' : ' years'}`;
  }

  return {
    dueIn,
    isOverDue: diff < 0,
  };
};

export const canMarkAsPaid = (
  document: IAccountsPayableServiceDocument,
) => {
  return [
    window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_PAYMENT,
    window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_CREDIT,
    window.configData.ap_invoice_states.DOCUMENT_STATE_APPROVAL,
  ].includes(document.state);
};

export const maxFileUploadCount = 25;

export const acceptedMimeTypes = {
  pdf:   ['application/pdf'],
  image: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  word:  ['application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  excel: ['application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  powerpoint: ['application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
};
