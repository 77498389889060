import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import toastr from '@lib/toastr';
import { useAssignTaskServiceDocument, useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ICreateTaskBody } from '@src/requests/task_service_documents';
import { ITaskServiceDocument } from '@src/types/task_service_documents';
import { getDateGap } from '@src/utils/date_helpers';

import Assignee from '@src/components/task_management/components/assignee';
import BlockModal from '@src/components/task_management/components/block_modal';
import CloseModal from '@src/components/task_management/components/close_modal';
import { availableStatuses } from '@src/components/task_management/components/helper/constants';
import TaskNotes from '@src/components/task_management/components/task_notes';
import TaskOpenSince from '@src/components/task_management/components/task_open_since';
import TaskStatusSelect from '@src/components/task_management/components/task_status_select';
import { ITaskIconOption, IUpdateStatusProps, StatusKey } from '@src/components/task_management/types';
import { TIconOption } from '@src/components/ui/form';
import Spinner from '@src/components/ui/spinner';
import { TOption } from '@src/components/ui_v2/inputs';
import Table from '@src/components/ui_v2/table';

import NoDataFound from '../../components/no_data_found';

import styles from '../styles.module.scss';

interface IDashboardTableBodyProps {
    records:ITaskServiceDocument[]
    onRowClick:(rowData:ITaskServiceDocument)=>void;
    isLoading:boolean;
}
const TableBody = ({ records, onRowClick, isLoading }:IDashboardTableBodyProps) => {
  const assignTask = useAssignTaskServiceDocument();
  const handleAssigneeChange = useCallback((
    columnData:ITaskServiceDocument,
    user:ITaskIconOption | TIconOption | null,
  ) => {
    if (user?.value) {
      assignTask.mutate(
        { formData: { userId: user?.value },
          taskId:   columnData.taskId },
        {
          onSuccess: () => {
            toastr.success(
              'Assign Task Successfully',
              'Assign Task',
            );
          },
          onError: ({ response }) => {
            toastr.error(
              response?.data?.error || 'Some error occurred',
              'Assign Task',
            );
          },
        },
      );
    }
  }, [assignTask]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState<TOption | null>(null);
  const [taskDocument, setTaskDocument] = useState<ITaskServiceDocument | null>(null);
  const updateStatus = useUpdateTaskServiceDocument();
  const handleCancel = useCallback(() => {
    setIsOpenModal(false);
    setTaskDocument(null);
    setStatusOption(null);
  }, [setIsOpenModal]);

  const handleUpdateStatus = useCallback((
    { formData, id }:IUpdateStatusProps,
  ) => {
    if (formData && (taskDocument?.taskId || id)) {
      updateStatus.mutate(
        { formData,
          taskId: id ?? taskDocument?.taskId as string },
        { onSuccess: () => {
          toastr.success(
            'Status Updated Successfully',
            'Update Task Status',
          );
          setTaskDocument(null);
          handleCancel();
        },
        onError: (error) => {
          toastr.error(
            error.message,
            'Error Update Status',
          );
        } },
      );
    }
  }, [handleCancel, taskDocument, updateStatus]);

  const handleStatusChange = useCallback(({ option, id, task }
    :{option:TOption, id:string, task?:ITaskServiceDocument;}) => {
    switch (option.value) {
      case availableStatuses.IN_PROGRESS: {
        if (task) setTaskDocument(task);
        setStatusOption(option);
        handleUpdateStatus({ formData: { status: option.value } as ICreateTaskBody, id });
        break;
      }
      case availableStatuses.BLOCKED: {
        setStatusOption(option);
        setIsOpenModal(true);
        if (task) setTaskDocument(task);
        break;
      }
      case availableStatuses.CLOSED: {
        setStatusOption(option);
        setIsOpenModal(true);
        if (task) setTaskDocument(task);
        break;
      }
      case availableStatuses.PENDING: {
        setStatusOption(option);
        if (task) setTaskDocument(task);
        handleUpdateStatus({ formData: { status: option.value } as ICreateTaskBody, id });
        break;
      }
      default: {
        break;
      }
    }
  }, [handleUpdateStatus]);
  const classes = classNames(styles['task-default-row'], styles['red-row']);

  if (records.length === 0 && !isLoading) {
    return (
      <NoDataFound noDataMessage="No Tasks Found" />
    );
  }

  return (
    <>
      <Table.Body>
        {!isLoading ? records?.map((columnData:ITaskServiceDocument) => (
          <Table.Row
            key={ columnData.taskId }
            className={ getDateGap({ date: columnData.createdAt }).isGreaterOrEqualTwoDays
              ? classes : styles['task-default-row'] }
          >
            <Table.Cell onClick={ () => onRowClick(columnData) }>
              <div className={ styles['row-text-values'] }>
                #
                {columnData?.taskId}
              </div>
            </Table.Cell>
            <Table.Cell onClick={ () => onRowClick(columnData) }>
              <div className={ styles['row-text-values'] }>
                {columnData?.title}
              </div>
            </Table.Cell>
            <Table.Cell onClick={ () => onRowClick(columnData) }>
              <div className={ styles['row-text-values'] }>
                {columnData?.businessName}
              </div>
            </Table.Cell>
            <Table.Cell>
              <Assignee
                hideSelectedOption
                businessId={ columnData.businessId }
                headerText="Assign To"
                isDisabled={ (!columnData?.canEdit || !columnData?.serviceProviderBusinessId) }
                selectedUser={ {
                  icon:   columnData.userIcon,
                  helper: columnData.userDesignation,
                  label:  columnData.userName,
                  value:  String(columnData.userId),
                } }
                serviceProviderBusinessId={ columnData.serviceProviderBusinessId }
                onChange={ (newValue) => {
                  if (newValue) handleAssigneeChange(columnData, newValue);
                } }
              />
            </Table.Cell>
            <Table.Cell onClick={ () => onRowClick(columnData) }>
              <div className={ styles['row-text-values'] }>
                <TaskOpenSince date={ columnData?.createdAt } />
              </div>
            </Table.Cell>
            <Table.Cell>
              <TaskStatusSelect
                disabled={ !columnData.canEdit }
                value={ columnData.status as StatusKey }
                onChange={ (option:TOption) => {
                  handleStatusChange({ option, id: columnData.taskId, task: columnData });
                } }
              />
            </Table.Cell>
            <Table.Cell>
              <TaskNotes
                disabled={ !columnData.canEdit }
                taskId={ columnData?.taskId }
                value={ columnData?.notes ?? '' }
              />
            </Table.Cell>
          </Table.Row>
        )) : <Spinner />}
      </Table.Body>
      <BlockModal
        businessName={ taskDocument?.businessName as string }
        open={ statusOption?.value === availableStatuses.BLOCKED && isOpenModal }
        taskDocument={ taskDocument }
        onCancel={ handleCancel }
      />
      <CloseModal
        businessName={ taskDocument?.businessName as string }
        minDate={ taskDocument?.createdAt }
        open={ statusOption?.value === availableStatuses.CLOSED && isOpenModal }
        taskDocument={ taskDocument }
        onCancel={ handleCancel }
      />
    </>
  );
};
export default TableBody;
