import React from 'react';

import {
  useLDBusinessFeatureQuery,
  useLDUserFeatureQuery,
} from '@src/hooks/queries/launch_darkly_features';
import {
  rcMonthEndDocmentsBankStatementsPath,
  rcMonthEndDocmentsOtherDocumentsPath,
  rcMonthEndReconciliationPath,
  rcTransactionsForReviewPath,
} from '@src/routes';
import { TID } from '@src/types/common';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import NewServiceSidebar from '@src/components/ui_v2/service_sidebar';
import {
  BankTransfersNavIcon,
  CategoryIcon,
  EquityLiabilityAssetsIcon,
  ExpensesNavIcon,
  LoanPaymentsIcon,
  MoneyExchangeIcon,
  PayrollLiabilityNavIcon,
  ReconciliationSummaryIcon,
  RevenueNavIcon,
  SettingsGreenIcon,
  AnalyticsIcon,
  UncategorizedIcon,
  UnreconciledIcon,
  AllTransactionsIcon,
  MonthEndDocumentsIcon,
  BankStatementsIcon,
  OtherDocumentsIcon,
  LedgerSyncingCentreIcon,
} from '@src/components/utils/icomoon';

import DocumentRequestsIcon from '../../utils/icomoon/document_requests';
import ReconciliationCenterGlobalNavigationSidebar from '../reconciliation_center_global_navigation_sidebar';

interface IReconciliationCenterSidebarProps {
  businessId: TID,
}

const Sidebar = ({ businessId }: IReconciliationCenterSidebarProps) => {
  const location = new URL(window.location.href);

  const { data: globalNavigationBusinessEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
  );

  const { data: globalNavigationUserEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.GLOBAL_NAVIGATION,
  );

  const isGlobalNavigationEnabled = !!((globalNavigationUserEnabled || globalNavigationBusinessEnabled));

  const isGlobalnavigationNewUi = isGlobalNavigationEnabled;

  const { data: monthEndCloseEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
  );

  let monthEndItem = null;
  if (monthEndCloseEnabled) {
    monthEndItem = isGlobalnavigationNewUi ? (
      <NewServiceSidebar.Item
        href={ rcMonthEndReconciliationPath(businessId) }
        title="Month-End Close"
      />
    ) : (
      <ServiceSidebar.Item
        href={ rcMonthEndReconciliationPath(businessId) }
        icon={ <AnalyticsIcon /> }
        title="Month-End Close"
      />
    );
  }

  const { data: transactionsWaitingForApprovalEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.TRANSACTIONS_WAITING_FOR_APPROVAL,
  );

  let transactionsWaitingForApprovalItem = null;
  let transactionsForReviewItem = null;
  if (transactionsWaitingForApprovalEnabled) {
    transactionsWaitingForApprovalItem = isGlobalnavigationNewUi ? (
      <NewServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/categorized_transactions/all` }
        title="Categorized Transactions"
      />
    ) : (
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/categorized_transactions/all` }
        icon={ <MoneyExchangeIcon /> }
        title="Categorized Transactions"
      />
    );
    transactionsForReviewItem = isGlobalnavigationNewUi ? (
      <NewServiceSidebar.Item
        href={ rcTransactionsForReviewPath(businessId) }
        title="Transactions for Review"
      />
    ) : (
      <ServiceSidebar.Item
        href={ rcTransactionsForReviewPath(businessId) }
        icon={ <AllTransactionsIcon /> }
        title="Transactions for Review"
      />
    );
  }

  if (isGlobalnavigationNewUi) {
    return (
      <ReconciliationCenterGlobalNavigationSidebar
        businessId={ businessId }
        monthEndItem={ monthEndItem }
        transactionsForReviewItem={ transactionsForReviewItem }
        transactionsWaitingForApprovalItem={ transactionsWaitingForApprovalItem }
      />
    );
  }

  return (
    <ServiceSidebar>
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center` }
        icon={ <ReconciliationSummaryIcon /> }
        title="Summary"
      />
      { monthEndItem }
      <ServiceSidebar.SubMenu
        expandedKey="doc_request_expanded"
        icon={ <MonthEndDocumentsIcon /> }
        launchDarklyFeature={ window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG }
        title="Month End Documents"
      >
        <ServiceSidebar.Item
          // eslint-disable-next-line max-len
          active={ window.location.pathname.includes(`/businesses/${businessId}/reconciliation_center/month_end_documents/bank_statements`) }
          href={ rcMonthEndDocmentsBankStatementsPath(businessId) }
          icon={ <BankStatementsIcon /> }
          launchDarklyFeature={ window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG }
          title="Bank Statements"
        />
        <ServiceSidebar.Item
          href={ rcMonthEndDocmentsOtherDocumentsPath(businessId) }
          icon={ <OtherDocumentsIcon /> }
          launchDarklyFeature={ window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG }
          title="Other Documents"
        />
      </ServiceSidebar.SubMenu>
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/journal_entries` }
        icon={ <DocumentRequestsIcon /> }
        title="Journal Entries"
      />
      <ServiceSidebar.Item
        active={ window.location.pathname === `/businesses/${businessId}/reconciliation_center/transactions` }
        href={ `/businesses/${businessId}/reconciliation_center/transactions` }
        icon={ <UncategorizedIcon /> }
        title="Uncategorized Transactions"
      />
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/documents` }
        icon={ <UnreconciledIcon /> }
        title="Uncleared Documents"
      />
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/all_transactions` }
        icon={ <AllTransactionsIcon /> }
        title="All Transactions"
      />
      { transactionsForReviewItem }
      { transactionsWaitingForApprovalItem }
      <ServiceSidebar.SubMenu
        expandedKey="reconciliation_expanded"
        icon={ <MoneyExchangeIcon /> }
        title="Transaction Types"
      >
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/expenses` }
          icon={ <ExpensesNavIcon /> }
          title="Expenses"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/revenue` }
          icon={ <RevenueNavIcon /> }
          title="Income"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/bank_transfers` }
          icon={ <BankTransfersNavIcon /> }
          title="Bank Transfers"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/payroll` }
          icon={ <PayrollLiabilityNavIcon /> }
          title="Payroll Charges"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/equity` }
          icon={ <EquityLiabilityAssetsIcon /> }
          title="Equity / Liabilities / Assets"
        />
        <ServiceSidebar.Item
          href={ `/businesses/${businessId}/reconciliation_center/loan_payments` }
          icon={ <LoanPaymentsIcon /> }
          title="Loan Payments"
        />
      </ServiceSidebar.SubMenu>
      <ServiceSidebar.Item
        // eslint-disable-next-line max-len
        active={ location.pathname.includes('reconciliation_center/accounts') || location.pathname.includes('reconciliation_center/categories') }
        href={ `/businesses/${businessId}/reconciliation_center/accounts?type=categories` }
        icon={ <CategoryIcon /> }
        title="Chart of Accounts"
      />
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre` }
        icon={ <LedgerSyncingCentreIcon /> }
        title="Ledger Syncing Center"
      />
      <ServiceSidebar.Item
        href={ `/businesses/${businessId}/reconciliation_center/settings` }
        icon={ <SettingsGreenIcon /> }
        title="Settings"
      />
    </ServiceSidebar>
  );
};

export default React.memo(Sidebar);
