import React from 'react';

import { useAtom, useAtomValue } from 'jotai';

import toastr from '@lib/toastr';
import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { monthsBetweenTwoDates } from '@src/utils/date_helpers';

import MultiColumnAddField from '@src/components/business_reports/common/multi_column_add_field';
import Filter from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import { reportDataPeriodType } from '../../atoms';
import { reportFilterConfiguration } from '../hooks';

import styles from '../styles.module.scss';

interface IReportOtherDetailTableFilterProps {
  configurations: IReportConfiguration[],
  report: IReport,
  isShownCustomize: boolean,
  comparerIds?: string[]
}

const ReportOtherDetailTableFilter = ({
  configurations,
  report,
  isShownCustomize,
  comparerIds,
}: IReportOtherDetailTableFilterProps) => {
  const [value, setValue] = useAtom(hideZeroRowsType);
  const reportFilters = reportFilterConfiguration(configurations);
  const periodType = useAtomValue(reportDataPeriodType);

  const handleValidateDateRange = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const diffInMonths = monthsBetweenTwoDates(startDate, endDate);
      if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY
        && diffInMonths >= 1) {
        toastr.error(
          'You can only choose up to 1 month for daily view',
          'Error',
        );
        return false;
      }
      if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY
        && diffInMonths > 3) {
        toastr.error(
          'You can only choose up to 3 months for weekly view',
          'Error',
        );
        return false;
      }
    }
    return true;
  };

  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div className={ styles['filter-area'] }>
        <Filter.TextNormalFiled label="Search by Name" placeholder="Search by Name" />
        <Filter.DateField
          hideAllTime
          hideClear
          className={ styles['filter-width'] }
          name="reportDataRange"
          periodType="monthly"
          validateDateRange={ handleValidateDateRange }
        />

        {isShownCustomize && (
          <MultiColumnAddField
            comparerIds={ comparerIds }
            kind="reportOtherDetail"
            placeholder="Customize"
            report={ report }
            reportFilters={ reportFilters }
          />
        )}

        <CheckboxInput checked={ value } title="Hide empty rows" onChange={ (checked: boolean) => setValue(checked) } />
      </div>
    </Filter.TableContainer>
  );
};

export default React.memo(ReportOtherDetailTableFilter);
