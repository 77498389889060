import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import Drawer from 'react-modern-drawer';

import toastr from '@lib/toastr';
import { useCreateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { TOption } from '@src/components/ui_v2/inputs';
import { PlusIcon, SpinnerIcon } from '@src/components/utils/fa_icons';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import { ITaskItemFormValues, createTaskValidation } from '../components/helper/validations';
import TaskBusinessSelect from '../components/task_business_select';

import styles from './styles.module.scss';

const CreateTaskDrawer = () => {
  const titleClasses = classNames(styles['value-field'], styles['title-textarea']);
  const descriptionClasses = classNames(styles['value-field'], styles['description-textarea']);
  const [selectedBusiness, setSelectedBusiness] = useState<TOption | null>(null);
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ITaskItemFormValues>({
    resolver: yupResolver(createTaskValidation),
  });
  const [show, setShow] = useState<boolean>(false);
  const createTask = useCreateTaskServiceDocument();
  const [loading, setLoading] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    reset({
      title:       '',
      description: '',
    });
    setSelectedBusiness(null);
    setShow(false);
    setLoading(false);
  }, [reset]);
  const handleSubmitHandler = handleSubmit(async (bodyData) => {
    setLoading(true);
    if (selectedBusiness?.value) {
      createTask.mutate(
        { ...bodyData, businessId: Number(selectedBusiness?.value) },
        {
          onSuccess: () => {
            toastr.success(
              'New Task Created Successfully',
              'Create Task',
            );
            handleClose();
          },
          onError: ({ response }) => {
            toastr.error(
              response?.data?.error || 'Some error occurred',
              'Create Task',
            );
            setLoading(false);
          },
        },
      );
    } else {
      toastr.error(
        'Please select business',
        'Error Create Task',
      );
    }
  });
  return (
    <>
      <Button
        className={ styles['add-task-button'] }
        prefixIcon={ <PlusIcon fontSize={ 14 } /> }
        variant="primary"
        onClick={ () => setShow(true) }
      >
        Add Task
      </Button>
      <Drawer
        lockBackgroundScroll
        direction="right"
        open={ show }
        size="50vh"
        onClose={ handleClose }
      >
        <div className={ styles.container }>
          <div className={ styles.header }>
            <div>Add New Task</div>
            <NotAChargebackIcon pointer fontSize={ 20 } onClick={ handleClose } />
          </div>
          <div className={ styles['form-container'] }>
            <Form onSubmit={ handleSubmitHandler }>
              <div className={ styles['form-content-select'] }>
                <p className={ styles['form-field'] }>Business : </p>
                <div>
                  <TaskBusinessSelect
                    selectedBusiness={ selectedBusiness }
                    setSelectedBusiness={ (e) => {
                      setSelectedBusiness(e);
                      setValue('businessId', Number(e?.value));
                    } }
                  />
                  {errors.businessId?.message && !selectedBusiness?.value ? (
                    <p className={ styles.errorMessage }>
                      <i className="fa fa-warning" />
                      {' '}
                      {errors.businessId?.message}
                    </p>
                  ) : null }
                </div>
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['form-field'] }>Title : </p>
                <Form.TextAreaField
                  hiddenLabel
                  className={ titleClasses }
                  error={ errors.title?.message }
                  label=""
                  { ...register('title') }
                />
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['form-field'] }>Description : </p>
                <Form.TextAreaField
                  hiddenLabel
                  className={ descriptionClasses }
                  error={ errors.description?.message }
                  label=""
                  { ...register('description') }
                />
              </div>
              <Button className={ styles['create-task-button'] } disabled={ loading } type="submit" variant="primary">
                {loading ? <div className={ styles['create-task-spinner'] }><SpinnerIcon spin /></div> : (
                  <span>
                    Create Task
                  </span>
                )}
              </Button>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default CreateTaskDrawer;
