import React from 'react';

import classNames from 'classnames';

import { TIconOption, TOption } from '@src/components/ui_v2/inputs';

import styles from './styles.module.scss';

const formatSelectOption = (
  { label }: TOption,
): JSX.Element => {
  return (
    <span className={ styles['select-option-label'] }>{ label }</span>
  );
};
interface IUserIconSelectOptionProps extends TIconOption{
  context :'menu' | 'value'
}
const UserIconSelectOption = (
  { icon, label, helper, subTitle, hideIcon = false, tooltip = false, context }: IUserIconSelectOptionProps,
) => {
  const classes = classNames(
    { [styles['icon-select-option-icon']]: !tooltip },
    { [styles['icon-select-option-tooltip-icon']]: tooltip },
    [styles['select-option-icon']],
  );

  return (
    <>
      { !hideIcon && <span className={ classes }>{ icon }</span> }
      { context === 'menu' && subTitle ? (
        <div className={ styles['icon-select-option'] }>
          <span className={ styles['icon-select-option-label'] }>{ label }</span>
          <span className={ styles['icon-select-option-subtitle'] }>{ subTitle }</span>
        </div>
      ) : (
        <span className={ styles['icon-select-option-label'] }>{ label }</span>
      ) }
      { context === 'menu' && helper && (
        <span className={ styles['icon-select-option-helper'] }>{ helper }</span>
      ) }
    </>
  );
};

export {
  formatSelectOption,
  UserIconSelectOption,
};
