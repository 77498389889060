import React from 'react';

import Root from '@src/components/root';

import ModuleManagementGroupHeaderActions from './module_management_group_header_actions';

interface IModuleManagementGroupHeaderPageProps {
  managementGroupId: number;
}

const ModuleManagementGroupHeaderPage = ({
  managementGroupId,
}: IModuleManagementGroupHeaderPageProps) => {
  return (
    <Root>
      <ModuleManagementGroupHeaderActions
        managementGroupId={ managementGroupId }
      />
    </Root>
  );
};

export default ModuleManagementGroupHeaderPage;
