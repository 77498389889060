import React from 'react';

import { TID } from '@src/types/common';

import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import Table from '@src/components/operational_dashboard/table/table';
import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { Filter } from './filter';
import { useNotesSideView } from '../components/sideview/notes_sideview';
import { availableViews } from '../helper';
import { TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

const OperationsDashboardTableList = () => {
  const currentAdvisor = window.Docyt.currentAdvisor;
  const view = currentAdvisor.isBookgptAccountantUser() ? availableViews.accountant as TViewOperationalDashboard
    : availableViews.businessOwnerView as TViewOperationalDashboard;
  const collection = useDashboardTableCollection({ view });
  const { control, watch } = collection.FilterForm;

  // Check if there's an active search term
  const searchBusinessByName = watch('search_business_by_name');
  const hasActiveSearch = !!searchBusinessByName?.trim();

  const {
    Component: NotesSideView,
    props: notesSideViewProps,
    openWithValue: openNotesSideView,
  } = useNotesSideView();

  const handleOpenNotesSideView = (businessId: TID) => {
    if (businessId) {
      openNotesSideView({
        businessId,
      });
    }
  };

  return (
    <Section.Provider section={ collection?.section }>
      <NotesSideView
        { ...notesSideViewProps }
      />
      <div className={ styles['operational-dashboard-table-section-container'] }>
        <Filter control={ control } />
        <TableSection className={ styles['operational-dashboard-table-section'] }>
          <Table
            hasActiveSearch={ hasActiveSearch }
            infiniteQuery={ collection.query }
            records={ collection.records }
            sorting={ collection.sorting }
            view={ view }
            viewNotes={ handleOpenNotesSideView }
          />
        </TableSection>
      </div>
    </Section.Provider>
  );
};

export default React.memo(OperationsDashboardTableList);
