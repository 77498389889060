import React from 'react';

import classNames from 'classnames';

import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IManagementGroup } from '@src/types/management_groups';

import Table from '@src/components/ui/table';

import FICBankAccountMappingInfo from './fic_bank_account_mapping_info';

import styles from '../styles.module.scss';

interface IfinancialInstitutionBankAccountCenterTableItemProps {
  financialInstitutionAccount: IFinancialInstitutionBankAccount,
  openFinancialBankAccountMapping: IFinancialInstitutionBankAccount | null,
  employeeCardFeatureFlag: boolean,
  managementGroup: IManagementGroup,
  refetchFinancialConnectionList: () => void,
  handleClickForSetupAccount: (financialBankAccount: IFinancialInstitutionBankAccount) => void,
  isUnifiedHeaderDropDownFeatureFlagEnabled: boolean | string | undefined,
}

const FinancialInstitutionBankAccountCenterTableItem = ({
  financialInstitutionAccount,
  employeeCardFeatureFlag,
  managementGroup,
  openFinancialBankAccountMapping,
  refetchFinancialConnectionList,
  handleClickForSetupAccount,
  isUnifiedHeaderDropDownFeatureFlagEnabled = false,
}: IfinancialInstitutionBankAccountCenterTableItemProps) => {
  const accountType = financialInstitutionAccount.accountType === 'credit' ? 'credit_card' : 'bank';

  const isSelected = openFinancialBankAccountMapping?.id === financialInstitutionAccount.id;

  return (
    <Table.Row className={ classNames(styles['financial-banking-accounts-item'], {
      [styles['selected-row']]: isSelected,
    }) }
    >
      <Table.Cell>
        { financialInstitutionAccount.name }
      </Table.Cell>
      <Table.Cell>
        { financialInstitutionAccount.last4 }
      </Table.Cell>
      {!isUnifiedHeaderDropDownFeatureFlagEnabled && (
        <Table.Cell />
      )}
      <FICBankAccountMappingInfo
        accountType={ accountType }
        employeeCardFeatureFlag={ employeeCardFeatureFlag }
        financialInstitutionBankAccount={ financialInstitutionAccount }
        handleSetupAccountClick={ () => handleClickForSetupAccount(financialInstitutionAccount) }
        managementGroup={ managementGroup }
        refetchFinancialConnectionList={ refetchFinancialConnectionList }
      />
      <Table.Cell />
      <Table.Cell />
    </Table.Row>
  );
};

export default React.memo(FinancialInstitutionBankAccountCenterTableItem);
