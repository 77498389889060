import React from 'react';

import { TaskNotificationType } from './types';

interface ITaskNotificationMessageProps {
  taskId: string;
  message: TaskNotificationType;
}

const notificationTemplates: Record<TaskNotificationType, (taskId: string) => JSX.Element> = {
  [TaskNotificationType.ASSIGNED]: (taskId) => (
    <div>
      Task
      {' '}
      <strong>{taskId}</strong>
      {' '}
      was assigned to you.
    </div>
  ),
  [TaskNotificationType.STATUS_CHANGED]: (taskId) => (
    <div>
      Status of Task
      {' '}
      <strong>{taskId}</strong>
      {' '}
      changed.
    </div>
  ),
  [TaskNotificationType.DETAILS_UPDATED]: (taskId) => (
    <div>
      Details of Task
      {' '}
      <strong>{taskId}</strong>
      {' '}
      were updated.
    </div>
  ),
  [TaskNotificationType.UNASSIGNED]: (taskId) => (
    <div>
      Task
      {' '}
      <strong>{taskId}</strong>
      {' '}
      was unassigned.
    </div>
  ),
};

const TaskNotificationMessage: React.FC<ITaskNotificationMessageProps> = ({ taskId, message }) => {
  return <span>{notificationTemplates[message] ? notificationTemplates[message](taskId) : <div>{message}</div>}</span>;
};

export default TaskNotificationMessage;
