import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { CaretIcon } from '@src/components/utils/fa_icons';

import { TUnifiedDropdownOption } from '../types';

import styles from '../styles.module.scss';

interface IMenuItemProps {
  item: TUnifiedDropdownOption,
  path?: string[],
  onSelect: (option: TUnifiedDropdownOption) => void,
  activePath: string[],
  setActivePath: (currentPath: string[]) => void,
}

const MenuItem = ({
  item,
  path = [],
  onSelect,
  activePath,
  setActivePath,
}: IMenuItemProps) => {
  const currentPath = useMemo(() => [...path, item.value], [path, item.value]);
  const isActive = useMemo(
    () => activePath.length >= currentPath.length
                   && activePath.slice(0, currentPath.length).every((v, i) => v === currentPath[i]),
    [activePath, currentPath],
  );

  const handleMouseEnter = useCallback(() => {
    if (item.subMenuItems && item.subMenuItems.length > 0) {
      setActivePath(currentPath);
    }
  }, [item.subMenuItems, currentPath, setActivePath]);

  const handleMouseLeave = useCallback(() => {
    if (item.subMenuItems && item.subMenuItems.length > 0) {
      setActivePath(['businesses']);
    }
  }, [setActivePath, item.subMenuItems]);

  const handleClick = useCallback(() => {
    if (!item.subMenuItems || item.subMenuItems.length === 0) {
      onSelect(item);
    }
  }, [item, onSelect]);

  return (
    <div
      key={ item.value }
      className={ classNames(
        styles['section-item'],
        {
          [styles.active]:           isActive,
          [styles['bottom-border']]: item.type === 'action-add',
        },
      ) }
      role="button"
      tabIndex={ 0 }
      onClick={ handleClick }
      onMouseEnter={ handleMouseEnter }
      onMouseLeave={ handleMouseLeave }
    >
      <div className={ classNames(
        styles['item-content'],
        { [styles['item-content-with-submenu']]: item.subMenuItems },
      ) }
      >
        {item.icon && <span className={ styles['item-icon'] }>{item.icon}</span>}
        <span className={ styles['item-label'] }>{item.label}</span>
        {item.subMenuItems && (
          <span className={ styles['arrow-icon'] }>
            <CaretIcon variant="right" />
          </span>
        )}
      </div>

      {/* Submenu that appears when active */}
      {item.subMenuItems && isActive && (
        <div className={ styles['submenu-container'] }>
          {item.subMenuItems.map((subItem) => (
            <MenuItem
              key={ subItem.value }
              activePath={ activePath }
              item={ subItem }
              path={ currentPath }
              setActivePath={ setActivePath }
              onSelect={ onSelect }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
