import React, { memo } from 'react';

import classNames from 'classnames';

import { getDateGap } from '@src/utils/date_helpers';

import styles from './styles.module.scss';

interface ITaskOpenSinceProps{
  date:string | number | Date;
  endDate?:string | number | Date;
  gapText?:string;
}
const TaskOpenSince = (({ date, endDate, gapText }:ITaskOpenSinceProps) => {
  const classes = classNames(styles['open-since-label-red'], styles['task-open-since']);
  return (
    <div className={ getDateGap({ date, endDate })?.isGreaterOrEqualTwoDays
      ? classes : styles['task-open-since'] }
    >
      {getDateGap({ date, endDate, gapText }).gap}
    </div>
  );
});
export default memo(TaskOpenSince);
