/* eslint-disable import/order */
import React, { useMemo } from 'react';

import toastr from '@lib/toastr';

import { useAtom, useAtomValue } from 'jotai';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';

import MultiColumnAddField from '@src/components/business_reports/common/multi_column_add_field';
import Filter from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import ReportRefreshStatus from './report_refresh_status';
import { reportDataPeriodType } from '../../atoms';
import { IReportDatasCollection, reportFilterConfiguration } from '../hooks';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetReportServiceAccess } from '@src/hooks/queries/report_service/custom_reports';
import { monthsBetweenTwoDates } from '@src/utils/date_helpers';

import styles from '../styles.module.scss';

const ReportDataTableFilter = ({
  configurations,
  report,
  collection,
  isShownCustomize,
  reportEstimateEnabled = false,
}: {
  configurations: IReportConfiguration[];
  report: IReport;
  collection: IReportDatasCollection;
  isShownCustomize: boolean;
  reportEstimateEnabled?: boolean;
}) => {
  const [value, setValue] = useAtom(hideZeroRowsType);
  const periodType = useAtomValue(reportDataPeriodType);
  const reportFilters = reportFilterConfiguration(configurations);
  const business = useBusinessContext();
  const reportServiceAccess = useGetReportServiceAccess(business.id);
  const subscriptionBasedAccess = useMemo(() => reportServiceAccess.data || {}, [reportServiceAccess]);
  const filtersAccess = subscriptionBasedAccess.filtersAccess;
  const subscriptionBasedReportFilter = reportFilters.filter((item) => filtersAccess?.includes(item.id));

  const handleValidateDateRange = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
      const diffInMonths = monthsBetweenTwoDates(startDate, endDate);
      if (periodType === 'daily' && diffInMonths >= 1) {
        toastr.error(
          'You can only choose up to 1 month for daily view',
          'Error',
        );
        return false;
      }
      if (periodType === 'weekly' && diffInMonths > 3) {
        toastr.error(
          'You can only choose up to 3 months for weekly view',
          'Error',
        );
        return false;
      }
    }
    return true;
  };

  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div className={ styles['filter-area'] }>
        <Filter.TextNormalFiled
          className={ styles['search-width'] }
          label="Search by Name"
          placeholder="Search by Name"
        />
        <Filter.DateField
          hideClear
          className={ styles['filter-width'] }
          minDate={ business.bookkeepingStartDate }
          name="reportDataRange"
          periodType={ periodType }
          validateDateRange={ handleValidateDateRange }
          weekStartDay={ periodType === 'weekly' ? 1 : 0 }
        />

        {isShownCustomize && (
          <MultiColumnAddField
            collection={ collection }
            kind="reportDetail"
            placeholder="Customize"
            report={ report }
            reportFilters={ subscriptionBasedReportFilter }
          />
        )}

        <CheckboxInput
          checked={ value }
          title="Hide empty rows"
          onChange={ (checked: boolean) => setValue(checked) }
        />
      </div>
      <ReportRefreshStatus
        collection={ collection }
        report={ report }
        reportEstimateEnabled={ reportEstimateEnabled }
      />
    </Filter.TableContainer>
  );
};

export default React.memo(ReportDataTableFilter);
