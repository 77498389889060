import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import ModuleHeaderPage from './module_header_page';

interface IHeaderPageProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  hasVaultServiceRole: boolean,
}

const HeaderPage = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  hasVaultServiceRole,
}: IHeaderPageProps) => {
  return (
    <Root>
      <ModuleHeaderPage
        businessId={ businessId }
        currentModulePath={ currentModulePath }
        focusToMyClient={ focusToMyClient }
        hasVaultServiceRole={ hasVaultServiceRole }
      />
    </Root>
  );
};

export default React.memo(HeaderPage);
