import React from 'react';

import classNames from 'classnames';

import { SmileIcon } from '@src/components/utils/icomoon';

import { ITaskIconOption } from '../types';

import styles from './styles.module.scss';

interface ITaskAssigneeLabelItemProps {
  hasBorder: boolean;
  toggleOpen: () => void;
  borderClassName: string;
  value: ITaskIconOption;
  disabled?: boolean;
}

const TaskAssigneeLabelItem = ({ hasBorder,
  toggleOpen, borderClassName, value, disabled = false }:ITaskAssigneeLabelItemProps) => {
  const classes = classNames({
    [styles['select-disabled']]: disabled,
    [styles['select-pointer']]:  !disabled,
  });

  const statusUrl = value?.icon?.props?.vendor?.imageUrl;

  return (
    <div className={ classes }>
      <div
        className={ `${hasBorder ? borderClassName : styles['profile-card']}` }
        role="contentinfo"
        onClick={ toggleOpen }
      >
        {statusUrl && statusUrl !== 'undefined'
          ? <img alt={ value?.label } className={ styles.avatar } src={ statusUrl } />
          : <SmileIcon className={ styles['default-user-icon'] } fontSize={ 28 } inColor="orange-500" />}
        <div className={ styles.info }>
          <div className={ styles.name }>{value?.label}</div>
          <div className={ styles.title }>{value?.helper}</div>
        </div>
      </div>
    </div>
  );
};

export default TaskAssigneeLabelItem;
