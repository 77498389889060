import React from 'react';

import classNames from 'classnames';

import Table from '@src/components/ui_v2/table';

import styles from './styles.module.scss';

interface INoDataFoundProps {
  noDataMessage:string;
  className?:string;
}

const NoDataFound = ({ noDataMessage, className }:INoDataFoundProps) => {
  const classes = classNames(styles['no-data-message'], className);
  return (
    <Table.Row>
      <Table.Cell className={ styles['no-data-container'] } colSpan={ 12 }>
        <div className={ classes }>
          {noDataMessage}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default NoDataFound;
