export const QueryKey = {
  appVersion: 'app_version',

  validateEmail: 'validate_email',

  defaultAccountingFirm: 'default_accounting_firm',

  accountingClasses:             'accounting_classes',
  accountingClassesWithMappings: 'accounting_classes_with_mappings',

  accountsPayableServices:          'accounts_payable_services',
  accountPayableServiceSummary:     'account_payable_service_summary',
  accountsPayableServiceDocument:   'accounts_payable_service_document',
  accountsPayableRelatedJournal:    'accounts_payable_related_journal',
  accountsPayableServiceDocuments:  'accounts_payable_service_documents',
  accountsPayableAchTransaction:    'accounts_payable_ach_transaction',
  accountsPayableAchTransactions:   'accounts_payable_ach_transactions',
  adjustmentEntries:                'adjustment_entries',
  adjustmentEntry:                  'adjustment_entry',
  receivableAccountPayableServices: 'receivable_accounts_payable_services',
  remainingApproverNames:           'remaining_approver_names',
  documentActivities:               'document_activities',

  downloadPrintableChecks: 'download_printable_checks',

  simpleChartOfAccounts:         'simple_chart_of_accounts',
  operationDashboardPreferences: 'operation_dashboard_preferences',
  operationDashboardTableData:   'operation_dashboard_table_data',
  auditScans:                    'audit_scans',
  auditScanNotes:                'audit_scan_notes',
  auditScanTransactions:         'audit_scan_transactions',

  document:                  'document',
  relatedDocuments:          'relatedDocuments',
  documentsByTransactionId:  'documentsByTransactionId',
  documentsByAchTransaction: 'documentsByAchTransaction',
  documentNotes:             'document_notes',
  documentMessages:          'document_messages',

  accountsReceivableServiceDocuments: 'accounts_receivable_service_documents',
  accountsReceivablePayments:         'accounts_receivable_payments',

  businessChartOfiAccounts: 'business_chart_of_accounts',

  chartOfAccount:       'chart_of_account',
  chartOfAccounts:      'chart_of_accounts',
  chartOfAccountsByIds: 'chart_of_accounts_by_ids',
  chartOfAccountById:   'chart_of_account_by_id',

  combinedServiceDocumentsQuery: 'combined_service_documents',

  dwollaCustomersByBusiness:    'dwolla_customers_by_business',
  dwollaCustomersByVendor:      'dwolla_customers_by_vendor',
  docytCheckEmailVerifications: 'docyt_check_email_verifications',

  integrationsRunDownloadUrls: 'integrations_run_download_urls',
  ldBusinessFeature:           'launch_darkly_business_feature',
  ldBusinessGroupFeature:      'launch_darkly_business_group_feature',
  ldUserFeature:               'launch_darkly_user_feature',

  integrationsRuns:                      'integrations_runs',
  integration:                           'integration',
  reconciliationCenterByBusinessId:      'reconciliation_center_by_business_id',
  reconciliationCenterExceptionMessages: 'reconciliation_center_exception_messages',

  reconciliationPaymentAccounts:   'reconciliation_payment_accounts',
  reconciliationPaymentProcessors: 'reconciliation_payment_processors',
  paymentAccountEmployeeCard:      'payment_account_employee_card',

  transactionServiceDocumentsQuery:            'transaction_service_documents',
  taskServiceDocuments:                        'task_service_documents',
  taskLogsDocuments:                           'task_logs_documents',
  taskServiceDetailsDocument:                  'task_service_details_document',
  subTransactionServiceDocumentsQuery:         'sub_transaction_service_documents',
  similarTransactionServiceDocumentsQuery:     'similar_transaction_service_documents',
  transfersQuery:                              'transfers',
  matchedDocumentsQuery:                       'matched_documents',
  transactionServiceDocumentsForDocumentQuery: 'transaction_service_documents_for_document',
  pastTransactionsQuery:                       'past_transactions',
  transactionMessages:                         'transaction_messages',
  bankStatement:                               'bank_statements_pending_review',
  bankStatementById:                           'bank_statement',
  bankStatementByDocytId:                      'bank_statement_by_docyt_id',
  nextBankStatement:                           'next_bank_statement',
  reviewedBankStatement:                       'reviewed_bank_statements',
  duplicatedBankStatement:                     'duplicated_bank_statement',

  balanceSheetStatement:                    'balance_sheet_statements',
  balanceSheetStatementByDocytId:           'balance_sheet_statement_by_docyt_id',
  balanceSheetGroupedAccounts:              'balance_sheet_grouped_accounts',
  bankAccountReconciliation:                'bank_account_reconciliation',
  calculateBankAccountReconciliation:       'calculate_bank_account_reconciliation',
  bankAccountReconciliationItemUpdate:      'bank_account_reconciliation_item_update',
  bankAccountReconciliationUnclearedDetail: 'bank_account_reconciliation_uncleared_detail',
  bankAccountReconciliationMatchedRules:    'bank_account_reconciliation_matched_rules',
  bankAccountReconciliationMessages:        'bank_account_reconciliation_messages',
  bankAccountReconciliationDocumentsQuery:  'bank_account_reconciliation_documents',
  bankAccountReconciliationPushedOutside:   'bank_account_reconciliation_pushed_outside',

  bankFeedTransactionServiceDocument: 'bank_feed_transaction_service_documents',
  bankFeedInformation:                'bank_feed_information',
  allSetBankStatement:                'all_set_bank_statement',

  report:                               'report',
  reports:                              'reports',
  reportsList:                          'reports-list-light',
  reportServiceAccess:                  'report-service-access',
  businessReports:                      'businessReports',
  reportService:                        'report_service',
  customReportService:                  'custom_report_service',
  reportAvailableBusiness:              'report_available_business',
  reportServiceQboLineItemDetails:      'report_service_qbo_line_item_details',
  getMultiBusinessReportDetail:         'multi_business_report_detail',
  getMultiBusinessReports:              'multi_business_reports',
  multiBusinessReportItemAccountValues: 'multi_business_report_item_account_values',
  reportServiceBudget:                  'report_service_budget',
  reportServiceBudgets:                 'report_service_budgets',
  reportServiceBudgetItems:             'report_service_budget_items',
  reportServiceBudgetFormulas:          'report_service_budget_formulas',
  reportTemplates:                      'report_templates',
  reportLastUpdatedDate:                'report_last_updated_date',
  reportDatas:                          'report_datas',
  reportItems:                          'report_items',
  reportIdentifierItem:                 'report_identifier_item',
  reportConfigurations:                 'report_configurations',
  portfolioReportConfigurations:        'portfolio_report_configurations',
  reportBudgetComparison:               'report_budget_comparison',
  reportBudgetComarerIds:               'report_budger_comparer_ids',
  reportIdentifierItemAccountValues:    'report_identifier_item_account_values',
  reportIdentifierItemValues:           'report_identifier_item_values',
  reportLaborDimensions:                'report_labor_dimensions',
  reportLaborAllDepartment:             'report_labor_all_department',
  reportRefreshLogs:                    'report_refresh_logs',
  reportUpdateEstimation:               'report_update_estimation',
  itemLineItemDetails:                  'item_line_item_details',

  transactionServiceDocumentForStatment: 'statement_transaction_service_documents',

  financial_institution_connection: 'financial_institution_connection',

  revenueReportTypes:         'revenue_report_types',
  revenueServiceByBusinessId: 'revenue_service_by_business_id',
  revenueServiceDocuments:    'revenue_service_documents',
  revenueServiceDocument:     'revenue_service_document',

  revenueSystem: 'revenue_system',

  noRevenueDates:    'no_revenue_dates',
  revenueSystems:    'revenue_systems',
  revenueReconciles: 'revenue_reconciles',

  business:         'business',
  businesses:       'businesses',
  linkedBusinesses: 'linked_businesses',

  onboardingStatusClientBusinesses: 'onboarding_status_client_businesses',

  dashboards:                'dashboards',
  dashboardTemplates:        'dashboard_templates',
  dashboardWidgets:          'dashboard_widgets',
  dashboardDataSets:         'dashboard_data_sets',
  dashboardUsers:            'dashboard_users',
  managementGroupDashboards: 'management_group_dashboards',
  managementGroupDashboard:  'management_group_dashboard',

  businessUsers:                'business_users',
  managementGroupUsers:         'management_group_users',
  allUsers:                     'management_group_and_business_users',
  docytAccountingBusinessUsers: 'docyt_accounting_business_users',
  currentAdvisor:               'current_advisor',

  portfolioUsers:            'portfolio_users',
  portfolioDashboard:        'portfolio_dashboard',
  portfolioDashboardWidgets: 'portfolio_dashboard_widgets',

  clientBusinesses: 'client_businesses',
  usersBusinesses:  'users_businesses',

  managementGroupById:                     'management_group',
  managementGroupsWithNotificationSetting: 'management_groups_with_notification_setting',
  notificationPreference:                  'notification_preference',
  financialInsights:                       'financial_insights',

  recentDataExports:  'recent_data_exports',
  globalSearchFilter: 'global_search_filter',
  globalSearch:       'global_search',

  businessVendors:       'business_vendors',
  businessGlobalVendors: 'business_global_vendors',

  selectOptions: 'select_options',

  billingBankAccounts: 'billing_bank_accounts',
  billingCreditCards:  'billing_credit_cards',

  ExpenseReportsServiceDocuments: 'expense_reports_service_documents',

  businessMetricValues:        'business_metric_values',
  businessMetricValuesByDate:  'business_metric_values_by_date',
  categorizedMetrics:          'categorized_metrics',
  reportExportConfig:          'report_export_config',
  portfolioReportExportConfig: 'portfolio_report_export_config',
  AiChatThreads:               'ai_chat_threads',
  AiChatThreadById:            'ai_chat_thread_by_id',
  AIChatThreadMessages:        'ai_chat_thread_messages',
  chat_central:                'chat_central',

  multiEntityReportsService:  'multi_entity_reports_service',
  standardCategories:         'standard_categories',
  multiEntityTemplates:       'multi_entity_templates',
  reportServicesByBusinessId: 'report_services_by_business_id',

  bankingAccountsServiceInfo: 'banking_accounts_service_info',

  AiAnalytics:   'ai_analytics',
  vendorService: 'vendor_service',
  duplicateVendors: 'duplicate_vendors',

  metricsReports:  'metrics_reports',
  sugestedAddress: 'sugested_address',
  vendorAddresses: 'vendor_addresses',
  portfolioList:   'portfolio_list',
  vendorW9Info:    'vendor_w9_info',
  vendorPayments:  'vendor_payments',

  systemCategoriesQuery: 'system_categories_query',
  receiptService:        'receiptService',

  bookGPT:                    'bookGPT',
  addBillingMethod:           'add_billing_method',
  billingPaymentMethodStatus: 'billing_payment_method_status',
  importClientsFromQbo:       'import_clients_from_qbo',

  myTeamMembers:    'my_team_members',
  myTeamMemberInfo: 'my_team_member_info',
  allClients:       'all_clients',
  managementGroups: 'management_groups',
};
