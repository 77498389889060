import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDeleteTaskServiceDocument } from '@src/hooks/queries/task_service_documents';

import Modal from '@src/components/ui/modal';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface ITaskDeleteModalProps{
  open:boolean,
  taskId:string,
  onDelete:()=>void
  onCancel:()=>void
}
const TaskDeleteModal = ({ open, taskId, onDelete, onCancel }:ITaskDeleteModalProps) => {
  const deleteTask = useDeleteTaskServiceDocument();
  const handleDeleteTask = useCallback(() => {
    deleteTask.mutate({ taskId }, {
      onSuccess: () => {
        toastr.success(
          'Task Deleted Successfully',
          'Delete Task',
        );
        onDelete();
      },
      onError: ({ response }) => {
        toastr.error(
          response?.data?.error || 'Some error occurred',
          'Delete Task',
        );
      },
    });
  }, [deleteTask, onDelete, taskId]);
  return (
    <Modal dialogClassName={ styles['task-delete-modal-container'] } show={ open } onCancel={ onCancel }>
      <div className={ styles['task-delete-modal'] }>
        <Text
          as="div"
          className={ styles['task-delete-heading'] }
          fontSize={ 20 }
          fontVariant="bold"
        >
          Delete Task #
          {taskId}
        </Text>
        <Modal.Body>
          <div className={ styles['task-delete-body'] }>
            <Text as="div">Are you sure you want to delete this task?</Text>
            <Text as="div">It can&apos;t be undone</Text>
          </div>
        </Modal.Body>
        <Modal.Footer className={ styles['task-delete-footer'] }>
          <Button variant="link" onClick={ onCancel }>Cancel</Button>
          <Button disabled={ deleteTask?.isLoading } variant="primary" onClick={ handleDeleteTask }>
            {deleteTask.isLoading ? (
              <>
                Deleting
                <SpinnerIcon spin fontSize={ 24 } />
              </>
            )
              : 'Delete'}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default TaskDeleteModal;
