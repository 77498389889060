import React, { useCallback } from 'react';

import { useMarkAsCleared } from '@src/hooks/queries/transaction_matches';
import { ICreateTransactionMatchData } from '@src/types/transaction_matches';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { PaidIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface IMarkAsClearedActionProps {
  matchData: ICreateTransactionMatchData;
}

const MarkAsClearedAction = ({
  matchData,
}: IMarkAsClearedActionProps) => {
  const markAsCleared = useMarkAsCleared();

  const { mutate } = markAsCleared;

  const handleConfirm = useCallback(() => {
    mutate({ data: matchData });
  }, [matchData, mutate]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  const handleClick = useCallback((e: React.MouseEvent) => {
    // Prevent event from bubbling up to parent row
    e.stopPropagation();
    confirmModal.open();
  }, [confirmModal]);

  return (
    <>
      <MutationStatus mutation={ markAsCleared } successMessage="The Document has marked as cleared successfully" />
      <confirmModal.Component
        text="Are you sure you want to mark this document as cleared from bank?"
        title="Mark as cleared from Bank"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <PaidIcon fontSize={ 20 } /> }
        title="Mark as cleared"
        onClick={ handleClick }
      />
    </>
  );
};

export default MarkAsClearedAction;
