import { taskNotificationsStatusPath } from '@src/routes';

import { StatusKey, TTaskManagementTabs } from '@src/components/task_management/types';

export const TASK_TABS: Record<TTaskManagementTabs, TTaskManagementTabs> = {
  OPEN:     'OPEN',
  CLOSED:   'CLOSED',
  ASSIGNED: 'ASSIGNED',
  BLOCKED:  'BLOCKED',
};
export const availableStatuses: Record<StatusKey, StatusKey> = {
  OPEN:        'OPEN',
  CLOSED:      'CLOSED',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING:     'PENDING',
  BLOCKED:     'BLOCKED',
};
export const logsDateFormat = 'ddd, MMM D YYYY h:mm a';
export const ASSIGNEE_USERS_PER_PAGE = 10;
export const maxDelayedDays = 2;
export const defaultStatusOptions = [
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Blocked', value: 'BLOCKED' },
  { label: 'Closed', value: 'CLOSED' },
];
export const allStatusOptions = [...defaultStatusOptions, { label: 'Open', value: 'OPEN' }];
export const statusLabels = {
  PENDING:     'Pending',
  IN_PROGRESS: 'In Progress',
  BLOCKED:     'Blocked',
  CLOSED:      'Closed',
  OPEN:        'Open',
};
export const getChangedFields = <T extends Record<string, any>>(
  bodyData: T,
  defaultValues: T,
): Partial<T> => {
  return Object.keys(bodyData).reduce((acc, key) => {
    const value = bodyData[key];
    if (value !== defaultValues[key] && value !== '' && value !== null) {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as Partial<T>);
};

export const filterStatusOptions = [
  { label: 'All Statuses', value: '' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
];
export const CLOSE_DATE_FORMAT = 'MM/DD/YYYY';
export const paginationDirections = {
  LEFT:  'left',
  RIGHT: 'right',
};
export const statusToUrlMap: Record<string, string> = {
  OPEN:        taskNotificationsStatusPath(TASK_TABS.OPEN.toLocaleLowerCase()),
  PENDING:     taskNotificationsStatusPath(TASK_TABS.ASSIGNED.toLocaleLowerCase()),
  IN_PROGRESS: taskNotificationsStatusPath(TASK_TABS.ASSIGNED.toLocaleLowerCase()),
  BLOCKED:     taskNotificationsStatusPath(TASK_TABS.BLOCKED.toLocaleLowerCase()),
  CLOSED:      taskNotificationsStatusPath(TASK_TABS.CLOSED.toLocaleLowerCase()),
};
