/* eslint-disable eqeqeq */
import React from 'react';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import {
  bankingAccountsPath,
  accountsPath,
  CSVImportPath,
  pendingReviewPath,
  reviewedPath,
  transactionsPath,
  bankingAccountsTrashPath,
  businessSettingsPath,
  rcMonthEndReconciliationPath,
  rcBankTransfersPath,
  rcChartOfAccountsPath,
  rcEquityPath,
  rcExpensesPath,
  rcLoanPaymentsPath,
  rcPayrollPath,
  rcRevenuePath,
  rcLedgerSyncingCentrePath,
  rcSettingsPath,
  rcUncategorizedTransactionsPath,
  rcUnclearedDocumentsPathPath,
  reconciliationCenterPath,
  rcAllTransactionsPath,
  rcMonthEndDocmentsOtherDocumentsPath,
  rcMonthEndDocmentsBankStatementsPath,
  rcJournalEntriesPath,
  rcCategorizedTransactionsPath,
  rcTransactionsForReviewPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

interface IServiceMenuProps {
  businessId: TID,
  info: IBusinessServicesInfo,
  activeItem?: string,
  bankStatementEnabled?: string | boolean,
  focusToMyClient: boolean
}

const BankingAndReconciliationItem = ({
  businessId,
  info,
  activeItem,
  bankStatementEnabled,
  focusToMyClient,
}: IServiceMenuProps) => {
  const { data: monthEndCloseEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
  );
  const { data: transactionsWaitingForApprovalEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.TRANSACTIONS_WAITING_FOR_APPROVAL,
  );

  const bankStatementsDocRequestEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG);

  const canVisibleBankingService = info.isBusinessAdmin || info.canAccessBankingService;
  const canVisibleRcService = info.isBusinessAdmin || info.canAccessReconciliationCenter;

  if (!canVisibleBankingService && !canVisibleRcService) return null;

  let transactionsWaitingForApprovalItem = null;
  let transactionsForReviewItem = null;
  if (transactionsWaitingForApprovalEnabled) {
    transactionsWaitingForApprovalItem = (
      <ServiceItem
        serviceUrl={
          info.reconciliationCenterReady
            ? rcCategorizedTransactionsPath(businessId)
            : businessSettingsPath(businessId)
        }
        title="Categorized Transactions"
      />
    );
    transactionsForReviewItem = (
      <ServiceItem
        serviceUrl={
          info.reconciliationCenterReady
            ? rcTransactionsForReviewPath(businessId)
            : businessSettingsPath(businessId)
        }
        title="Transactions for Review"
      />
    );
  }

  return (
    <ModuleHeaderMenu
      active={
        activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BANKING_AND_RECONCILIATION
      }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BANKING_AND_RECONCILIATION }
    >
      {
        canVisibleBankingService && (
          <ServiceSubMenu
            serviceUrl={ info.isBankingServiceAdminUser
              ? bankingAccountsPath(businessId)
              : transactionsPath(businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.BANKING_ACCOUNT_SERVICE }
          >
            {(info.isBankingServiceAdminUser) && (
            <>
              <ServiceItem
                serviceUrl={ bankingAccountsPath(businessId) }
                title="Summary"
              />
              <ServiceItem
                isHidden={ !bankStatementEnabled || bankStatementsDocRequestEnabled }
                serviceUrl={ pendingReviewPath(businessId) }
                title="Bank Statements"
              />
              <ServiceItem
                isSubMenu
                isHidden={ !bankStatementEnabled || bankStatementsDocRequestEnabled }
                serviceUrl={ pendingReviewPath(businessId) }
                title="Pending Review"
              />
              <ServiceItem
                isSubMenu
                isHidden={ !bankStatementEnabled || bankStatementsDocRequestEnabled }
                serviceUrl={ reviewedPath(businessId) }
                title="Reviewed"
              />
            </>
            )}
            <ServiceItem
              serviceUrl={ transactionsPath(businessId) }
              title="Transactions"
            />
            {(info.isBankingServiceAdminUser) && (
            <ServiceItem
              serviceUrl={ accountsPath(businessId) }
              title="Accounts"
            />
            )}
            {(info.isBankingServiceAdminUser) && (
            <ServiceItem
              serviceUrl={ CSVImportPath(businessId) }
              title="CSV Import"
            />
            )}
            {(info.isBankingServiceAdminUser) && (
              <ServiceItem
                serviceUrl={ bankingAccountsTrashPath(businessId) }
                title="Trash"
              />
            )}
          </ServiceSubMenu>
        )
      }
      {
        canVisibleRcService && (
          <ServiceSubMenu
            serviceUrl={
              info.reconciliationCenterReady
                ? reconciliationCenterPath(businessId)
                : businessSettingsPath(businessId)
            }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.RECONCILIATION_SERVICE }
          >
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? reconciliationCenterPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Summary"
            />

            { monthEndCloseEnabled
            && (
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcMonthEndReconciliationPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Month-End Close"
            />
            )}
            <ServiceItem
              launchDarklyFeature={
                window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG
              }
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcMonthEndDocmentsBankStatementsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Month End Documents"
            />
            <ServiceItem
              isSubMenu
              launchDarklyFeature={
                window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG
              }
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcMonthEndDocmentsBankStatementsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Bank Statements"
            />
            <ServiceItem
              isSubMenu
              launchDarklyFeature={
                window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG
              }
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcMonthEndDocmentsOtherDocumentsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Other Documents"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcJournalEntriesPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Journal Entries"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcUncategorizedTransactionsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Uncategorized Transactions"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcUnclearedDocumentsPathPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Uncleared Documents"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcAllTransactionsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="All Transactions"
            />
            { transactionsForReviewItem }
            { transactionsWaitingForApprovalItem }
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcExpensesPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Transaction Types"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcExpensesPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Expenses"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcRevenuePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Income"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcBankTransfersPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Bank Transfers"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcPayrollPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Payroll Charges"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcEquityPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Equity / Liabilities / Assets"
            />
            <ServiceItem
              isSubMenu
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcLoanPaymentsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Loan Payments"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcChartOfAccountsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Chart of Accounts"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcLedgerSyncingCentrePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Ledger Syncing Center"
            />
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcSettingsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Settings"
            />
          </ServiceSubMenu>
        )
      }
    </ModuleHeaderMenu>
  );
};

export default BankingAndReconciliationItem;
