import React, { memo } from 'react';

import Root from '@src/components/root';

import TaskDetails from './components/task_details';

import styles from './styles.module.scss';

interface ITaskManagementDetailsProps{
  taskId:string;
}

const TaskManagementDetailsView = ({ taskId }:ITaskManagementDetailsProps) => {
  return (
    <div className={ styles['task-details-main'] }>
      <Root>
        <TaskDetails taskId={ taskId } />
      </Root>
    </div>
  );
};
export default memo(TaskManagementDetailsView);
