import React from 'react';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { documentRequestsPath, flaggedTransactionsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ModuleHeaderMenu from '../module_header_menu';

interface IServiceMenuProps {
  businessId: TID,
  info?: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean,
}

const OpenItems = ({
  businessId,
  info,
  activeItem,
  focusToMyClient = false,
}: IServiceMenuProps) => {
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );

  if (!sunsetClientCentralEnabled && focusToMyClient) return null;

  let itemCount;
  if (sunsetClientCentralEnabled) {
    itemCount = 0;
    if (info?.documentRequestServiceRole) {
      itemCount += Number(info?.countDocumentRequests || 0);
    }
    if (info?.hasFlaggedTransactionsAdvisorRole) {
      itemCount += Number(info?.countOpenedItems || 0);
    }
    itemCount += Number(info?.countUnreadTransactionChatMessages || 0);
  } else {
    itemCount = info?.countOpenedItems;
  }

  return (
    <ModuleHeaderMenu
      needCount
      active={ activeItem === window.Docyt.Common.Constants.OPEN_ITEMS_PATH }
      itemNumber={ itemCount }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.OPEN_ITEMS }
      url={ sunsetClientCentralEnabled
        ? documentRequestsPath(businessId) : flaggedTransactionsPath(businessId) }
    />
  );
};

export default OpenItems;
