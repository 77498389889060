import React from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { TLoadingVariant } from '@src/components/ui_v2/table/types';

import Cell, { ITableCellProps } from '../cell';

import 'react-loading-skeleton/dist/skeleton.css';

import styles from './styles.module.scss';

interface IAvatarLoadingCellProps extends Omit<ITableCellProps, 'children'> {
  variant: TLoadingVariant,
}

const AvatarLoadingCell = ({
  variant,
  className,
  ...cellProps
}: IAvatarLoadingCellProps) => {
  const classes = classNames(styles['avatar-skeleton'], className);
  const loadingCellClass = classNames(styles.avatar, {
    [styles['ai-loading-cell']]: variant === 'ai',
  });

  const textClass = classNames(styles.text, {
    [styles['ai-loading-cell']]: variant === 'ai',
  });

  return (
    <Cell { ...cellProps } className={ classes }>
      <Skeleton
        circle
        className={ loadingCellClass }
      />
      <Skeleton className={ textClass } />
    </Cell>
  );
};

export default AvatarLoadingCell;
