import { Duration, differenceInDays, differenceInMonths, format } from 'date-fns';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfYear from 'date-fns/endOfYear';
import parse from 'date-fns/parse';
import startOfMonth from 'date-fns/startOfMonth';
import startOfQuarter from 'date-fns/startOfQuarter';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import sub from 'date-fns/sub';
import subYears from 'date-fns/subYears';
import moment from 'moment';

import { TDate, TDateTime, TYear, TMonth, TDateRange,
  DateGapResult, DateGapProps, TWeekStartsOn } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';

const MOMENT_DATE_FORMAT = 'MM/DD/YYYY';
const MOMENT_MONTH_FORMAT = 'MMM Y';
const MOMENT_FULL_MONTH_FORMAT = 'MMMM Y';
const MOMENT_FULL_MONTH_DAY_FORMAT = 'D MMMM Y';
const MOMENT_FULL_MONTH_YEAR_FORMAT = 'MMMM, Y';
const DATEPICKER_DATE_FORMAT = 'mm/dd/yyyy';
const DATEFNS_DATE_FORMAT = 'MM/dd/yyyy';
const DATEFNS_MONTH_FORMAT = 'MM/yyyy';
const API_DATE_FORMAT = 'YYYY-MM-DD';
const API_YEAR_FORMAT = 'YYYY';
const API_MONTH_FORMAT = 'YYYY-MM';
const DATEFNS_API_MONTH_FORMAT = 'yyyy-MM';
const YEAR_PICKER_DATE_FORMAT = 'yyyy';
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const MONTH_PICKER_DATE_FORMAT = 'MM yyyy';
const MOMENT_SIMPLE_YEAR_DATE_FORMAT = 'MM/DD/YY';
const MOMENT_FULL_MONTH_DAY_YEAR = 'MMM DD, YYYY';

const formatDate = (date?: TDate | Date | moment.Moment | null, formatMode: string = MOMENT_DATE_FORMAT): string => {
  if (!date) return '';

  return moment(date).format(formatMode);
};

function formatDateRangeInMonthYear(
  startDate: TDate | Date,
  endDate: TDate | Date,
): string {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    year:  '2-digit',
  };
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const end = typeof endDate === 'string' ? new Date(endDate) : endDate;
  const startMonthYear = new Intl.DateTimeFormat('en-US', options).format(
    start,
  );
  const endMonthYear = new Intl.DateTimeFormat('en-US', options).format(end);
  return `${startMonthYear}-${endMonthYear}`;
}

const formatDateRange = (dateRange?: TDateRange): string => {
  if (!dateRange) return '';
  if (!dateRange.startDate && !dateRange.endDate) return '';

  return `${formatDate(dateRange.startDate)} – ${formatDate(dateRange?.endDate)}`;
};

const formatMonth = (date: TDate | Date | moment.Moment | undefined | null): string => {
  if (!date) return '';

  return moment(date).format(MOMENT_MONTH_FORMAT);
};

const formatLastMonth = (date: TDate | Date): string => {
  if (!date) return '';

  const d = new Date(date).setMonth(new Date(date).getMonth() - 1);
  return moment(d).format(MOMENT_MONTH_FORMAT);
};

const formatFullMonth = (date: TDate | Date): string => {
  if (!date) return '';

  return moment(date).format(MOMENT_FULL_MONTH_FORMAT);
};

const formatFullMonthDay = (date: moment.MomentInput): string => {
  if (!date) return '';

  return moment(date).format(MOMENT_FULL_MONTH_DAY_FORMAT);
};

const formatFullMonthDayYear = (date: moment.MomentInput): string => {
  if (!date) return '';

  return moment(date).format(MOMENT_FULL_MONTH_DAY_YEAR);
};

const formatApiDate = (dateStr?: string | Date | moment.Moment | null): TDate => {
  if (!dateStr) return '';

  if (typeof dateStr === 'string') {
    return moment(dateStr, MOMENT_DATE_FORMAT).format(API_DATE_FORMAT);
  }

  return moment(dateStr).format(API_DATE_FORMAT);
};

const formatApiMonth = (dateStr?: string | Date | moment.Moment | null): TMonth => {
  if (!dateStr) return '';

  if (typeof dateStr === 'string') {
    return moment(dateStr, MOMENT_DATE_FORMAT).format(API_MONTH_FORMAT);
  }

  return moment(dateStr).format(API_MONTH_FORMAT);
};

const formatApiYear = (dateStr?: string | Date | moment.Moment): TDate => {
  if (!dateStr) return '';

  if (typeof dateStr === 'string') {
    return moment(dateStr, MOMENT_DATE_FORMAT).format(API_YEAR_FORMAT);
  }

  return moment(dateStr).format(API_YEAR_FORMAT);
};

const parseApiDate = (apiDate: TDate | undefined): Date | undefined => {
  if (!apiDate) return undefined;

  return moment(apiDate, API_DATE_FORMAT).toDate();
};

const parseApiMonth = (apiDate: TMonth | undefined): Date | undefined => {
  if (!apiDate) return undefined;

  return parse(apiDate, DATEFNS_API_MONTH_FORMAT, new Date());
};

const apiDateToYear = (apiDate?: TDate | undefined): TYear | undefined => {
  if (!apiDate) return undefined;

  return moment(apiDate, API_DATE_FORMAT).format(API_YEAR_FORMAT);
};

const apiDateToMonth = (apiDate?: TDate | undefined): TMonth | undefined => {
  if (!apiDate) return undefined;

  return moment(apiDate, API_DATE_FORMAT).format(API_MONTH_FORMAT);
};

const apiYearToDate = (apiYear: TYear | undefined): TDate | undefined => {
  if (!apiYear) return undefined;

  return moment(apiYear, API_YEAR_FORMAT).format(API_DATE_FORMAT);
};

const apiMonthToDate = (apiMonth: TMonth | undefined): TDate | undefined => {
  if (!apiMonth) return undefined;

  return moment(apiMonth, API_MONTH_FORMAT).format(API_DATE_FORMAT);
};

const formatDateAge = (date?: TDate | TDateTime): string => {
  if (!date) return '';

  if (moment().diff(moment(date), 'days') < 10) {
    return moment(date).fromNow();
  }

  return moment(date).format('D MMMM');
};

const todayApiDate = (): TDate => {
  return formatApiDate(new Date());
};

const subFromDate = (date: Date, duration: Duration): Date => {
  return sub(date, duration);
};

const startOfWeekApiDate = (date?: Date, weekStartsOn?: TWeekStartsOn): TDate => {
  let tempDate = date;
  if (!tempDate) tempDate = new Date();
  return formatApiDate(startOfWeek(tempDate, { weekStartsOn }));
};

const startOfMonthDate = (date: Date = new Date()): Date => {
  return startOfMonth(date);
};

const startOfMonthApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(startOfMonth(date));
};

const endOfMonthDate = (date: Date = new Date()): Date => {
  return endOfMonth(date);
};

const endOfMonthApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(endOfMonth(date));
};

const startOfQuarterDate = (date: Date = new Date()): Date => {
  return startOfQuarter(date);
};

const startOfQuarterApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(startOfQuarter(date));
};

const startOfYearDate = (date: Date = new Date()): Date => {
  return startOfYear(date);
};

const startOfYearApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(startOfYear(date));
};

const startOfLastYearApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(startOfYear(subYears(date, 1)));
};

const endOfWeekApiDate = (date?: Date, weekStartsOn?: TWeekStartsOn): TDate => {
  let tempDate = date;
  if (!tempDate) tempDate = new Date();
  return formatApiDate(endOfWeek(tempDate, { weekStartsOn }));
};

const endOfLastYearApiDate = (date: Date = new Date()): TDate => {
  return formatApiDate(endOfYear(subYears(date, 1)));
};

const getUTCTimezone = (date: Date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

const formatPeriod = (year: number) => `FY ${year} (Jan ${year} - Dec ${year})`;

const formatFullMonthYear = (year: number, month: number): TDate => {
  return moment([year, month]).format(MOMENT_FULL_MONTH_YEAR_FORMAT);
};

const formatFullMonthFullYear = (month: number, year: number): TDate => {
  return moment([year, month]).format(MOMENT_FULL_MONTH_FORMAT);
};

const invalidDateRange = (date?: TDateFilter) => {
  if (!date) return true;
  const startDay = parseApiDate(date.gte);
  const endDay = parseApiDate(date.lte);
  if (!startDay || !endDay) return true;
  if (endDay < startDay) return true;

  return false;
};

/**
 * Return array of date included in the provided period
 */
const apiDaysForPeriod = (startApiDate: TDate | undefined, endApiDate: TDate | undefined): TDate[] => {
  const startDay = parseApiDate(startApiDate);
  const endDay = parseApiDate(endApiDate);
  if (!startDay || !endDay) return [];

  const days: TDate[] = [];
  let currentDay: Date = startDay;
  while (currentDay <= endDay) {
    days.push(formatApiDate(currentDay));
    currentDay = addDays(currentDay, 1);
  }
  return days;
};

const apiDaysForMonth = (month: TMonth | undefined, opts: { untilToday: boolean }): TDate[] => {
  const monthDate = parseApiMonth(month) || new Date();

  const startDate = startOfMonthDate(monthDate);
  let endDate = endOfMonthDate(monthDate);
  if (opts.untilToday && (!endDate || endDate > new Date())) {
    endDate = new Date();
  }

  if (!startDate || !endDate) return [];

  const days: TDate[] = [];
  let currentDate: Date = startDate;
  while (currentDate <= endDate) {
    days.push(formatApiDate(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return days;
};

const daysBetweenTwoDates = (startDate: Date, endDate: Date): number => {
  return differenceInDays(endDate, startDate);
};

const monthsBetweenTwoDates = (startDate?: string, endDate?: string): number => {
  const start = parseApiDate(startDate);
  const end = parseApiDate(endDate);
  if (!start || !end) return 0;
  return differenceInMonths(end, start);
};

const formatNumber = (digit: number) => {
  return `0${digit}`.slice(-2);
};

const monthToApiStartDate = (startDate: TMonth) => {
  return formatApiDate(startOfMonthDate(parseApiMonth(startDate)));
};

const monthToApiEndDate = (endDate: TMonth) => {
  return formatApiDate(endOfMonthDate(parseApiMonth(endDate)));
};

const getDateGap = (
  { date,
    suffixText,
    maxDelayedDays,
    endDate, gapText }:DateGapProps,
): DateGapResult => {
  const inputDate = moment(typeof date === 'number' ? new Date(date) : date);
  if (!inputDate.isValid()) {
    return { gap: '', isGreaterOrEqualTwoDays: false };
  }
  const comparableDate = moment(typeof endDate === 'number' ? new Date(endDate) : endDate) || moment.now();
  const duration = moment.duration(moment(comparableDate).diff(inputDate));
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours() % 24);
  const minutes = Math.floor(duration.asMinutes() % 60);
  const isGreaterOrEqualTwoDays = days >= (maxDelayedDays ?? 2);
  let gap = '';
  if (days > 0) {
    gap = `${days} ${days === 1 ? 'day' : 'days'}`;
  } else if (hours > 0) {
    gap = `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  } else if (minutes > 0) {
    gap = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
  } else {
    gap = 'Just now';
  }
  if (gap !== 'Just now' && suffixText) {
    gap = `${gap} ${suffixText}`;
  }
  if (gapText && gap === 'Just now') {
    gap = gapText;
  }
  return { gap, isGreaterOrEqualTwoDays };
};
const months = Array.from({ length: 12 }, (_, i) => {
  const date = new Date(0, i); // Year 0, month `i`
  return format(date, 'MMMM'); // Full month name
});

const formatDuration = (seconds: number): string => {
  if (seconds < 60) {
    return `${Math.round(seconds)} seconds`;
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (remainingSeconds === 0) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  return `${minutes} minute${minutes !== 1 ? 's' : ''} ${remainingSeconds} seconds`;
};

const getMonthsInRange = (startDate: string, endDate: string): string[] => {
  const inRangeMonths = [];
  const current = new Date(startDate);
  const end = new Date(endDate);

  while (current <= end) {
    inRangeMonths.push(current.toLocaleString('default', { month: 'long' }));
    current.setMonth(current.getMonth() + 1);
  }

  return inRangeMonths;
};

const dateIsAfter = (date1: string, date2: string): boolean => {
  return moment(date1).isAfter(moment(date2));
};

export {
  MOMENT_MONTH_FORMAT,
  MOMENT_FULL_MONTH_FORMAT,
  DATEPICKER_DATE_FORMAT,
  DATEFNS_DATE_FORMAT,
  DATEFNS_MONTH_FORMAT,
  MOMENT_DATE_FORMAT,
  API_DATE_FORMAT,
  YEAR_PICKER_DATE_FORMAT,
  MONTHS,
  MONTH_PICKER_DATE_FORMAT,
  MOMENT_SIMPLE_YEAR_DATE_FORMAT,
  MOMENT_FULL_MONTH_DAY_YEAR,
  daysBetweenTwoDates,
  endOfLastYearApiDate,
  apiDateToYear,
  apiYearToDate,
  apiMonthToDate,
  apiDateToMonth,
  apiDaysForPeriod,
  apiDaysForMonth,
  formatApiDate,
  formatApiMonth,
  formatApiYear,
  formatDate,
  formatDateRange,
  formatDateRangeInMonthYear,
  formatDateAge,
  formatMonth,
  formatNumber,
  formatLastMonth,
  formatFullMonth,
  formatFullMonthDay,
  parseApiDate,
  parseApiMonth,
  startOfLastYearApiDate,
  startOfMonthApiDate,
  startOfMonthDate,
  startOfQuarterApiDate,
  startOfQuarterDate,
  startOfWeekApiDate,
  startOfYearApiDate,
  startOfYearDate,
  subFromDate,
  endOfMonthApiDate,
  endOfMonthDate,
  endOfWeekApiDate,
  todayApiDate,
  formatPeriod,
  getUTCTimezone,
  formatFullMonthYear,
  invalidDateRange,
  monthToApiStartDate,
  monthToApiEndDate,
  formatFullMonthDayYear,
  getDateGap,
  months,
  formatDuration,
  getMonthsInRange,
  formatFullMonthFullYear,
  monthsBetweenTwoDates,
  dateIsAfter,
};
