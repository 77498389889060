import React from 'react';

import classNames from 'classnames';

import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from '../navigation_styles.module.scss';

interface IServiceMenuProps {
  className?: any,
  title: string,
  children?: React.ReactNode,
  url?: string,
  active?: boolean,
  itemNumber?: number
  needCount?: boolean,
  showSelect?: boolean,
  focusToMyClient?: boolean | null,
  isGlobalNavigationEnabled?: string | boolean | undefined,
  activePlaceholder?: boolean,
  listClassName?: string,
}

const ModuleHeaderMenu = ({
  className,
  title,
  children,
  url,
  active = false,
  itemNumber,
  needCount = false,
  showSelect = true,
  isGlobalNavigationEnabled = false,
  activePlaceholder = false,
  listClassName = '',
}: IServiceMenuProps) => {
  const headerClass = classNames(
    className,
    'module-header-item-wrapper',
    {
      active,
      'show-selected-item': showSelect,
    },
  );

  const moduleHeaderClass = classNames(
    'module-header-menu',
    {
      active,
    },
  );

  if (url) {
    return (
      <div className="module-header btn-group">
        <div className={ headerClass }>
          <a className={ moduleHeaderClass } href={ url }>
            <span className="service-header-title">
              { title }
              { needCount && (<span className="module-item-number">{itemNumber}</span>) }
            </span>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={
      `module-header btn-group ${isGlobalNavigationEnabled
        ? `module-header-revamp ${styles['module-header-hover']}`
        : ''}`
    }
    >
      <div className={ headerClass }>
        <span
          className={ `service-header-title pointer ${isGlobalNavigationEnabled ? '' : 'dropdown-toggle'}` }
          { ...(isGlobalNavigationEnabled ? {} : { 'data-toggle': 'dropdown' }) }
        >
          <span className={ `title-ellipsis-text ${activePlaceholder ? 'placeholder-item' : ''}` }>{ title }</span>
          {' '}
          <CaretIcon ml={ 10 } variant="down" />
        </span>
        <ul
          aria-labelledby="business-inbox-dropdown-btn"
          className={
            `dropdown-menu width-200px ${isGlobalNavigationEnabled
              ? styles['dropdown-menu']
              : ''} ${listClassName}`
          }
        >
          { children }
        </ul>
      </div>
    </div>
  );
};

export default ModuleHeaderMenu;
