import React, { useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAllMembersResponseInfite } from '@src/requests/team_management/team_management';
import { IBusinessTeamMember } from '@src/types/team_management/team_management';

import { ProgressActivityLoader } from '@src/components/ui_v2/progress_activity_loader';
import Table from '@src/components/ui_v2/table';

import TableHead from './table_head';
import TableRow from './table_row';

import styles from '../styles.module.scss';

interface ITableListProps {
  hasNoMessages: boolean;
  query: UseInfiniteQueryResult<IGetAllMembersResponseInfite, Error>;
  membersList: IBusinessTeamMember[];
}

const TableList: React.FC<ITableListProps> = ({ hasNoMessages, query, membersList }) => {
  const isAccountant = useMemo(() => {
    return window.Docyt.currentAdvisor.isBookgptAccountantUser();
  }, []);

  return (
    <Table
      className={ styles['team-management-table'] }
      query={ query }
    >
      <TableHead />
      <Table.Body>
        { hasNoMessages ? (
          <Table.Row>
            <Table.Cell colSpan={ 4 }>
              <div className={ styles['no-team-members-cell'] }>
                No team members found
              </div>
            </Table.Cell>
          </Table.Row>
        ) : (
          membersList.map((member) => (
            <TableRow
              key={ member.id }
              isAccountant={ isAccountant }
              member={ member }
            />
          ))
        )}
        {query.isLoading ? (
          <Table.Row>
            <Table.Cell colSpan={ 4 }>
              <div className={ styles['loading-cell'] }>
                <ProgressActivityLoader size="xlarge" />
              </div>
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>
  );
};

export default TableList;
