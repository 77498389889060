import React from 'react';

import ApiFailureSvg from '@assets/images/icons/api_failure_lm.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const ApiFailureIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="API Failure Icon"
      src={ ApiFailureSvg }
      width={ 340 }
      { ...props }
    />
  );
};

export default ApiFailureIcon;
