import React, { useState, useCallback } from 'react';

import classNames from 'classnames';

import { TFileStatus } from '@src/types/accounts_payable/accounts_payable_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import ProgressActivity from '@src/components/ui_v2/progress_activity_loader/progress_activity_loader';
import Tooltip from '@src/components/ui_v2/tooltip';
import { ErrorReportIcon, NewFileIcon, CheckGreenIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IFileStatusInfo {
  name: string;
  status: TFileStatus;
  errorMessage: string;
}

interface InvoiceUploadStatusPopoverProps {
  files: IFileStatusInfo[];
  onStop: () => void;
  isExpanded: boolean;
}

const truncateText = (text: string) => {
  if (!text) return '';
  return text.length > 25 ? `${text.substring(0, 25)}...` : text;
};

const FileItem: React.FC<{ file: IFileStatusInfo }> = ({ file }) => {
  const getIconForStatus = useCallback((status: TFileStatus, errorMessage?: string) => {
    switch (status) {
      case 'success':
        return <CheckGreenIcon fontSize={ 14 } />;
      case 'error':
        return (
          <Tooltip.Hover content={ errorMessage || 'Upload failed' }>
            <ErrorReportIcon fontSize={ 14 } />
          </Tooltip.Hover>
        );
      default:
        return <ProgressActivity />;
    }
  }, []);

  return (
    <li className={ `${styles['file-item']} ${file.status}` }>
      <div className={ styles['file-icon-container'] }>
        <span className={ styles['file-icon'] }>
          <NewFileIcon fontSize={ 14 } />
        </span>
        <span className={ styles['file-name'] }>{truncateText(file.name)}</span>
      </div>
      <span className={ styles['status-icon'] }>
        {getIconForStatus(file.status, file.errorMessage)}
      </span>
    </li>
  );
};

const InvoiceUploadStatusPopover: React.FC<InvoiceUploadStatusPopoverProps> = ({
  files,
  onStop,
  isExpanded,
}) => {
  const [isListVisible, setIsListVisible] = useState<boolean>(isExpanded);

  const toggleListVisibility = useCallback(() => {
    setIsListVisible((prev) => !prev);
  }, []);

  const completedFilesCount = files.filter(
    (file) => file.status === 'success' || file.status === 'error',
  ).length;

  const calculateUploadProgress = useCallback(() => {
    if (!files || files.length === 0) return 0;
    return (completedFilesCount / files.length) * 100;
  }, [files, completedFilesCount]);

  const fileStatusText = files.length > 1
    ? `${completedFilesCount} of ${files.length} items`
    : `${files.length} item`;

  const classes = classNames(
    styles['file-list'],
    styles['file-list-scroll'],
  );

  return (
    <div className={ styles['uploading-status-popover'] }>
      <div className={ styles['upload-header'] }>
        <span className={ styles['upload-item-text'] }>
          Uploading
          {' '}
          {fileStatusText}
        </span>
        {files.length > 1 && (
          <Button
            variant="link"
            onClick={ toggleListVisibility }
          >
            {isListVisible ? 'Show Less' : 'Show More'}
          </Button>
        )}
        <Button
          size="compact"
          variant="destructive"
          onClick={ onStop }
        >
          Stop
        </Button>
      </div>
      {files.length === 1 ? (
        <FileItem file={ files[0] } />
      ) : (
        isListVisible && (
          <ul className={ classes }>
            {files.map((file) => (
              <FileItem key={ file.name } file={ file } />
            ))}
          </ul>
        )
      )}
      <div className={ styles['progress-bar-container'] }>
        <div
          className={ styles['progress-bar-container-inner'] }
          style={ { width: `${calculateUploadProgress()}%` } }
        />
      </div>
    </div>
  );
};

export default InvoiceUploadStatusPopover;
