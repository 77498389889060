import { IBusinessOperationalDashboardData } from '@src/types/businesses';
import { TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import {
  IBusinessOwnerConfigFormType,
  isDisabledForFirmClosingSuite,
} from '@src/components/operational_dashboard/helper';
import { IDashboardData, TDashboardSortColumn,
  TViewOperationalDashboard } from '@src/components/operational_dashboard/types';

import { apiGet, apiPatch } from '../helpers';

interface IGetDashboardTableServiceResponse {
  meta: {
    totalCount: number;
  };
  collection: IDashboardData[];
}

interface IGetDashboardTableServiceParams {
  pageSize?: number;
  page?: number;
  orderColumn?: TDashboardSortColumn,
  orderDirection?: TOrderDirection
  view?: TViewOperationalDashboard
  searchBusinessByName?: string;
}

interface IResponseData {
  businesses:IBusinessOperationalDashboardData[];
  totalCount:number;
}

interface IUpdateOperationDashboardPreferencesBody {
  preference: IBusinessOwnerConfigFormType;
}

const getDashboardTablePreferences =
(data?: IGetDashboardTableServiceParams): Promise<IBusinessOwnerConfigFormType> => {
  // this Line of code will be replaced by the BE team once they add authentication logic in the api
  const url = '/business_dashboards/api/v1/preferences';
  return apiGet(url, data).then((res) => {
    const cdata = <IBusinessOwnerConfigFormType>camelizeKeys(res);
    // TODO: Uncomment this once the BE ready for the COA sync error issue
    // if (cdata) return cdata; return cdata;
    // TODO: remove this once the BE ready for the COA sync error issue
    // then remove below code completley and relay on backend response
    const defaultPreferences:IBusinessOwnerConfigFormType = {
      ...cdata,
      issues: {
        ...cdata.issues,
        // Overriding below keys from the api response to handle on frontend for the FirmClosingSuite Intent
        chartOfAccountSyncErrors: (!isDisabledForFirmClosingSuite() === true)
          ? cdata.issues?.chartOfAccountSyncErrors
          : false,
      },
      // Overriding below keys from the api response to handle on frontend for the FirmClosingSuite Intent
      businessChat: {
        ...cdata.businessChat,
        unreadBusinessChatsCounts: (!isDisabledForFirmClosingSuite() === true)
          ? cdata.businessChat?.unreadBusinessChatsCounts
          : false,
      },
      // Overriding below keys from the api response to handle on frontend for the FirmClosingSuite Intent
      collaboration: {
        ...cdata.collaboration,
        transactionMessages: {
          ...cdata.collaboration?.transactionMessages,
          unreadTransactionMessages: (!isDisabledForFirmClosingSuite() === true)
            ? cdata.collaboration?.transactionMessages?.unreadTransactionMessages
            : false,
        },
      },
    };
    return defaultPreferences;
  });
};

const updateOperationDashboardPreferences = (
  formData: IUpdateOperationDashboardPreferencesBody,
): Promise<IBusinessOwnerConfigFormType> => {
  // url path of code will be replaced by the BE team once they add authentication logic in the api
  return apiPatch(
    '/business_dashboards/api/v1/preferences',
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as IBusinessOwnerConfigFormType;
  });
};

const resetDashboardPreferencesToDefault = (
  formData: IUpdateOperationDashboardPreferencesBody,
): Promise<IBusinessOwnerConfigFormType> => {
  // url path of code will be replaced by the BE team once they add authentication logic in the api
  return apiPatch(
    '/business_dashboards/api/v1/reset-preferences-user',
    underscoreKeys(formData),
  ).then((data) => {
    return camelizeKeys(data) as IBusinessOwnerConfigFormType;
  });
};

const getDashboardTableData =
(params: IGetDashboardTableServiceParams): Promise<IGetDashboardTableServiceResponse> => {
  return apiGet('/business_dashboards/api/v1/data', params).then((data) => {
    const cdata = <IResponseData>camelizeKeys(data);
    return {
      collection: cdata.businesses,
      meta:       {
        totalCount: cdata.totalCount,
      },
    };
  });
};

export {
  IGetDashboardTableServiceResponse,
  IGetDashboardTableServiceParams,
  getDashboardTablePreferences,
  getDashboardTableData,
  updateOperationDashboardPreferences,
  resetDashboardPreferencesToDefault,
  IUpdateOperationDashboardPreferencesBody,
};
