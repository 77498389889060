import React, { useCallback } from 'react';

import { ITaskServiceDocument, TTaskDetailsPaginationDirection } from '@src/types/task_service_documents';

import { paginationDirections } from '@src/components/task_management/components/helper/constants';
import CopyClipboard from '@src/components/ui/details_page_title/copy_clipboard';
import { Button } from '@src/components/ui_v2/buttons';
import AngleIcon from '@src/components/utils/angle_icon';
import { RemoveIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface ITaskDetailsHeaderProps {
  taskId:string;
  onClick:(direction:TTaskDetailsPaginationDirection)=>void;
  data?:ITaskServiceDocument
  copyUrl?:string
  onGoBack?:()=>void
}
const TaskDetailsHeader = ({ taskId, onClick, data, copyUrl, onGoBack }:ITaskDetailsHeaderProps) => {
  const handleOnClick = useCallback((direction:TTaskDetailsPaginationDirection) => {
    onClick(direction);
  }, [onClick]);

  return (
    <div className={ styles['header-container'] }>
      <div className={ styles['inner-container'] }>
        <div className={ styles['task-id-container'] }>
          <Button
            disabled={ !data?.prevId }
            variant="link"
            onClick={ () => handleOnClick(paginationDirections.LEFT as TTaskDetailsPaginationDirection) }
          >
            <AngleIcon
              fontSize={ 38 }
              inColor="grey-800"
              variant="left"
            />
          </Button>
          <div className={ styles['header-task-title'] }>
            #
            {taskId}
            <CopyClipboard copyUrl={ copyUrl ?? '' } />
          </div>
          <div>
            <Button
              disabled={ !data?.nextId }
              variant="link"
              onClick={ () => handleOnClick(paginationDirections.RIGHT as TTaskDetailsPaginationDirection) }
            >
              <AngleIcon
                fontSize={ 38 }
                inColor="grey-800"
                variant="right"
              />
            </Button>
          </div>
        </div>
      </div>
      <div className={ styles['back-icon-container'] }>
        <Button variant="link" onClick={ onGoBack }>
          <RemoveIcon fontSize={ 20 } inColor="grey-800" />
        </Button>
      </div>

    </div>
  );
};

export default TaskDetailsHeader;
