module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="m-l-auto m-r-auto dashboard-header-view border-bottom-gray p-t-20 p-b-10 text-center">\n  <div class="pull-right m-t-20 display-inline-flex">\n    ');
    
      if (this.clients_mode) {
        __out.push('\n      <div class=\'m-t-7 search-input-wrapper\'>\n        <i class=\'icon icon-search\'></i>\n        <input class=\'form-control text-right business-client-search-input\' placeholder=\'\' type=\'text\'>\n      </div>\n      <div class="horizontal-divider"></div>\n      <div class="">\n        <button class="btn btn-plus font-23 add-business-client-btn">\n          <i class="icon icon-plus" />\n        </button>\n      </div>\n    ');
      } else if ((this.active_tab === "ACCOUNTING" || this.active_tab === "FINANCE") && this.isAccountantUser && !this.isBusinessUser && !this.isDocytSupport) {
        __out.push('\n      <div class="m-r-10 display-flex align-items-center" >\n        ');
        if (this.isAdminInAnyBusiness) {
          __out.push('\n          <button class="btn btn-plus font-23 add-business-btn">\n            <i class="icon icon-plus" />\n          </button>\n        ');
        }
        __out.push('\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n  <div class="text-center inline-flex-wrapper dashboard-menu-list">\n    ');
    
      if (!this.clients_mode) {
        __out.push('\n      <a class="font-12 m-r-15 pointer dashboard-menu-item relative ');
        __out.push(__sanitize(this.active_tab === 'TASKS' ? 'active' : ''));
        __out.push('" href="/dashboard?tab=TASKS">\n        <div class="middle-avatar-wrapper">\n          <img src="');
        __out.push(__sanitize(configData.dashboard_images.TASKS));
        __out.push('">\n        </div>\n        <span class="m-t-0 m-b-0 text-center">Tasks</span>\n      </a>\n      <div class="separator"></div>\n      ');
        if (this.user_status.mode !== 'ready' || !configData.individual_user_onboarding_statuses.includes(this.user_status.user_type)) {
          __out.push('\n      <a class="font-12 m-l-15 m-r-15 pointer dashboard-menu-item relative ');
          __out.push(__sanitize(this.active_tab === 'ACCOUNTING' ? 'active' : ''));
          __out.push('" href="/dashboard?tab=ACCOUNTING">\n        <div class="middle-avatar-wrapper">\n          <img src="');
          __out.push(__sanitize(configData.dashboard_images.ACCOUNTING));
          __out.push('">\n        </div>\n        <span class="m-t-0 m-b-0 text-center">Accounting</span>\n      </a>\n      ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n  </div>\n  ');
    
      if (this.isMultiBusinessDashboardEnabled) {
        __out.push('  \n    <div class="m-r-10 switch-dashboard-view-btn-wrapper" >\n      <button class="btn btn-blue switch-dashboard-view-btn">\n        <i class="icon icon-switch-view"></i>\n        Try the New View\n      </button>\n    </div>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}