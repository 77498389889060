import omit from 'lodash/omit';

import { TDate, TID, TOrderDirection } from '@src/types/common';
import {
  ISplitTransaction,
} from '@src/types/split_transactions';
import {
  ITransactionServiceDocument,
  IResolveDuplicatedTransactionData,
  TTransactionServiceDocumentsSortColumn,
  ITransactionServiceDocumentFilterQuery,
  IUpdateTransactionServiceDocumentData,
  ICreateTransactionServiceDocumentData,
  TTransfersSortColumn,
} from '@src/types/transaction_service_documents';
import { camelizeKeys, false2Undefined, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface IGetTransactionServiceDocumentsParams {
  businessId: TID,
  filter?: ITransactionServiceDocumentFilterQuery,
  excluded?: boolean | 'true' | 'false',
  exclude?: TID,
  withoutScope?: boolean,
  withDuplicateOriginTransaction?: boolean,
  page?: number,
  perPage?: number,
  orderColumn?: TTransactionServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
  state?: string,
  includeUnverified?: boolean,
  isTrash?: boolean,
  matchedType?: string,
  reconciliationScope?: boolean,
  queryType?: string,
  transactionType?: string[],
  withMatches?: boolean,
  withoutMatches?: boolean,
}

interface IGetTransfersParams {
  businessId: TID,
  filter?: ITransactionServiceDocumentFilterQuery,
  page?: number,
  orderColumn?: TTransfersSortColumn,
  orderDirection?: TOrderDirection,
}

interface IGetTransactionServiceDocumentsResponse {
  meta: {
    totalCount: number,
    totalAmount?: number,
    duplicatedCount: number,
  },
  collection: ITransactionServiceDocument[],
}

const getTransactionServiceDocuments = ({
  includeUnverified,
  withMatches,
  ...params
}: IGetTransactionServiceDocumentsParams): Promise<IGetTransactionServiceDocumentsResponse> => {
  let url = '/api/v1/transaction_service_documents';
  if (withMatches) {
    url += '/with_matches';
  }

  return apiGet(
    url,
    {
      ...underscoreKeys(false2Undefined(
        omit(params, ['includeUnverified', 'withMatches']),
        ['withoutScope', 'isTrash', 'reconciliationScope', 'withoutMatches'],
      )),
      includeUnverified: includeUnverified ? 'true' : undefined,
    },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.transactionServiceDocuments,
    } as IGetTransactionServiceDocumentsResponse;
  });
};

const getTransfers =
  (params: IGetTransfersParams): Promise<IGetTransactionServiceDocumentsResponse> => {
    const url = '/api/v1/transaction_service_documents/with_matches';

    return apiGet(
      url,
      { ...underscoreKeys(params) },
    ).then((data) => {
      const cdata = <any>camelizeKeys(data);
      return {
        meta:       cdata.meta,
        collection: cdata.transactionServiceDocuments,
      } as IGetTransactionServiceDocumentsResponse;
    });
  };

interface IDeleteServiceTransactionDocumentParams {
  id: TID,
}

interface IMoveToTrashTransactionServiceDocumentParams {
  value: 'true' | 'false',
  document_ids: [TID],
}

interface IRestoreFromTrashTransactionServiceDocumentParams {
  document_ids: [TID],
}

interface ISplitTransactionsParams {
  transactionId: TID,
  splittedTransactions: ISplitTransaction[],
}

interface IUndoSplitTransactionsParams {
  transactionId: TID,
}

interface IGetRelatedTransactionParams {
  transactionId: TID,
}

interface IGetResultantTransactionParams {
  transactionId: TID,
}

interface IAddTransactionServiceDocumentParams {
  businessId: TID,
  reconciliationPaymentAccountId?: TID,
  paymentAccountId?: TID,
  transactionServiceDocument: ICreateTransactionServiceDocumentData,
}

interface IResolveDuplicateParams {
  resolveDuplication: IResolveDuplicatedTransactionData,
}

const deleteServiceTransactionDocument = (
  params: IDeleteServiceTransactionDocumentParams,
): Promise<void> => {
  return apiDelete(
    `api/v1/transaction_service_documents/${params.id}`,
  );
};

const moveToTrashTransactionServiceDocument = (
  params: IMoveToTrashTransactionServiceDocumentParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/documents/move_to_trash',
    underscoreKeys(params),
  );
};

const restoreFromTrashTransactionServiceDocument = (
  params: IRestoreFromTrashTransactionServiceDocumentParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/documents/restore_from_trash',
    underscoreKeys(params),
  );
};

const postSplitTransactions = (
  params: ISplitTransactionsParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/transaction_service_documents/${params.transactionId}/split_transactions`,
    underscoreKeys(params),
  );
};

const postUndoSplitTransactions = (
  params: IUndoSplitTransactionsParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/transaction_service_documents/${params.transactionId}/undo_split_transactions`,
    underscoreKeys(params),
  );
};

const getRelatedTransactions = (id: TID): Promise<ITransactionServiceDocument[]> => {
  return apiGet(
    `/api/v1/transaction_service_documents/${id}/related_transactions`,
  ).then(
    (data) => camelizeKeys(data
      .related_transaction_service_documents) as ITransactionServiceDocument[],
  );
};

const getResultantTransaction = (id: TID): Promise<ITransactionServiceDocument[]> => {
  return apiGet(
    `/api/v1/transaction_service_documents/${id}/resultant_transaction`,
  ).then((data) => camelizeKeys(data.resultant_bank_transaction) as ITransactionServiceDocument[]);
};

const addTransactionServiceDocument = (
  params: IAddTransactionServiceDocumentParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/transaction_service_documents',
    underscoreKeys(params),
  );
};

const resolveDuplicateTransactionServiceDocument = (
  params: IResolveDuplicateParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/transaction_service_documents/${params.resolveDuplication.id}/resolve_duplication`,
    underscoreKeys(params),
  );
};

interface IVerifyTransactionServiceDocumentParams {
  transactionId: TID,
  data: IUpdateTransactionServiceDocumentData,
}

interface IVerifyTransactionServiceDocumentResponse {
  transactionServiceDocument: ITransactionServiceDocument,
}

const verifyTransactionServiceDocument = ({
  transactionId,
  ...params
}: IVerifyTransactionServiceDocumentParams): Promise<IVerifyTransactionServiceDocumentResponse> => {
  return apiPut(
    `/api/v1/transaction_service_documents/${transactionId}/verify`,
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as IVerifyTransactionServiceDocumentResponse;
  });
};

interface ISetBankStatementParams {
  id: TID,
  bankStatementId: TID,
  selected: boolean,
}

interface ISetTransactionServiceDocumentBankStatementResponse {
  bankFeedTransactionServiceDocument: ITransactionServiceDocument,
}

const setTransactionServiceDocumentBankStatement = (
  params: ISetBankStatementParams,
): Promise<ISetTransactionServiceDocumentBankStatementResponse> => {
  return apiPut(
    '/api/v1/transaction_service_documents/set_bank_statement',
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as ISetTransactionServiceDocumentBankStatementResponse;
  });
};

interface IGetTransactionServiceDocumentsForBankStatementParams {
  reconciliationPaymentAccountId: TID,
  startingDate?: TDate,
  closingDate?: TDate,
}

interface IGetTransactionServiceDocumentsForBankStatementResponse {
  transactionServiceDocuments: ITransactionServiceDocument[],
}

const getTransactionServiceDocumentsForBankStatement = (
  params: IGetTransactionServiceDocumentsForBankStatementParams,
): Promise<IGetTransactionServiceDocumentsForBankStatementResponse> => {
  return apiGet('/api/v1/transaction_service_documents/for_bank_statement', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetTransactionServiceDocumentsForBankStatementResponse);
};

interface IUpdateTsdFlaggedStateResponse {
  transactionServiceDocument: ITransactionServiceDocument,
}

const updateTsdFlaggedState = (
  tsd: ITransactionServiceDocument,
): Promise<IUpdateTsdFlaggedStateResponse> => {
  return apiPut(
    `/api/v1/transaction_service_documents/${tsd.id}/update_flagged_state`,
    underscoreKeys(tsd),
  ).then((data) => {
    return camelizeKeys(data) as IUpdateTsdFlaggedStateResponse;
  });
};

interface IUpdateTransactionServiceDocumentParams {
  id: TID,
  excluded?: boolean,
}

interface IUpdateTransactionServiceDocumentResponse {
  transactionServiceDocument: ITransactionServiceDocument,
}

const updateTransactionServiceDocument = (
  params: IUpdateTransactionServiceDocumentParams,
): Promise<IUpdateTransactionServiceDocumentResponse> => {
  return apiPut(
    `/api/v1/transaction_service_documents/${params.id}`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IUpdateTransactionServiceDocumentResponse;
  });
};

interface IUpdateTransactionServiceDocumentDataParams {
  id: TID,
  data: IUpdateTransactionServiceDocumentData,
}

interface IUpdateTransactionServiceDocumentDataResponse {
  transactionServiceDocument: ITransactionServiceDocument,
}

const updateTransactionServiceDocumentData = (
  params: IUpdateTransactionServiceDocumentDataParams,
): Promise<IUpdateTransactionServiceDocumentDataResponse> => {
  return apiPut(
    `/api/v1/transaction_service_documents/${params.id}`,
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as IUpdateTransactionServiceDocumentDataResponse;
  });
};

interface IUpdateTransactionServiceDocumentDoNotLearnParams {
  id: TID,
  doNotLearn?: boolean,
  noPushQbo?: boolean,
  vendor_id?: string,
  user_vendor_id?: string,
  noDocumentRequired?: boolean,
}

const updateTransactionServiceDocumentDoNotLearn = (
  params: IUpdateTransactionServiceDocumentDoNotLearnParams,
): Promise<IUpdateTransactionServiceDocumentResponse> => {
  return apiPut(
    `/api/v1/transaction_service_documents/${params.id}`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IUpdateTransactionServiceDocumentResponse;
  });
};

interface IGetSimilarTransactionServiceDocumentsParams {
  businessId: TID,
  similarIds: TID[],
  state?: string,
  noAutoVerify?: boolean
}

interface IGetSimilarTransactionServiceDocumentsResponse {
  transactionServiceDocuments: ITransactionServiceDocument[],
}

const getSimilarTransactionServiceDocuments = (
  params: IGetSimilarTransactionServiceDocumentsParams,
): Promise<IGetTransactionServiceDocumentsForBankStatementResponse> => {
  return apiGet('/api/v1/transaction_service_documents/similar', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IGetSimilarTransactionServiceDocumentsResponse);
};

interface IRemoveNoAutoVerifyRulesParams {
  businessId: TID,
  similarIds: TID[],
}

interface IRemoveNoAutoVerifyResponse {
  message: string
}

// eslint-disable-next-line max-len
const removeNoAutoVerifyRules = (params: IRemoveNoAutoVerifyRulesParams): Promise<IRemoveNoAutoVerifyResponse> => {
  const url = '/api/v1/transaction_service_documents/remove_no_auto_verify_rules';

  return apiPost(url, underscoreKeys(params));
};

interface IGroupSimilarTsdsParams {
  ids: TID[];
}

interface IGroupSimilarTsdsResponse {
  message: string;
}

const groupSimilarTsds = (
  params: IGroupSimilarTsdsParams,
): Promise<IGroupSimilarTsdsResponse> => {
  return apiPost(
    '/api/v1/transaction_service_documents/group_similar',
    { ids: params.ids, id: params.ids[0] },
  );
};

interface IAskDocytAIParams {
  businessId: number,
  id: number
}

interface IAskDocytAIResponse {
  transactionServiceDocument: ITransactionServiceDocument,
  asyncPredictionInitiated: boolean
}

const askDocytAI = (
  params: IAskDocytAIParams,
): Promise<IAskDocytAIResponse> => {
  const url = `/api/v1/transaction_service_documents/${params.id}/ask_docyt_ai`;
  return apiPost(url, underscoreKeys(params)).then((data) => {
    return camelizeKeys(data) as IAskDocytAIResponse;
  });
};

interface IGetTransactionServiceDocumentResponse {
  transactionServiceDocument: ITransactionServiceDocument,
}

const getTransactionServiceDocument = (
  id: TID,
): Promise<IGetTransactionServiceDocumentResponse> => {
  return apiGet(
    `/api/v1/transaction_service_documents/${id}`,
  ).then((data) => {
    return camelizeKeys(data) as IUpdateTransactionServiceDocumentDataResponse;
  });
};

export {
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse,
  IDeleteServiceTransactionDocumentParams,
  IMoveToTrashTransactionServiceDocumentParams,
  IRestoreFromTrashTransactionServiceDocumentParams,
  ISplitTransactionsParams,
  IUndoSplitTransactionsParams,
  IGetRelatedTransactionParams,
  IGetResultantTransactionParams,
  IAddTransactionServiceDocumentParams,
  IResolveDuplicateParams,
  IVerifyTransactionServiceDocumentParams,
  IVerifyTransactionServiceDocumentResponse,
  ISetBankStatementParams,
  ISetTransactionServiceDocumentBankStatementResponse,
  IGetTransactionServiceDocumentsForBankStatementParams,
  IGetTransactionServiceDocumentsForBankStatementResponse,
  IUpdateTransactionServiceDocumentDataParams,
  IUpdateTransactionServiceDocumentDataResponse,
  getTransactionServiceDocuments,
  deleteServiceTransactionDocument,
  moveToTrashTransactionServiceDocument,
  restoreFromTrashTransactionServiceDocument,
  postSplitTransactions,
  postUndoSplitTransactions,
  getRelatedTransactions,
  getResultantTransaction,
  addTransactionServiceDocument,
  resolveDuplicateTransactionServiceDocument,
  verifyTransactionServiceDocument,
  setTransactionServiceDocumentBankStatement,
  getTransactionServiceDocumentsForBankStatement,
  IGetTransfersParams,
  getTransfers,
  updateTsdFlaggedState,
  IUpdateTsdFlaggedStateResponse,
  updateTransactionServiceDocument,
  IUpdateTransactionServiceDocumentParams,
  IUpdateTransactionServiceDocumentResponse,
  updateTransactionServiceDocumentData,
  IGetSimilarTransactionServiceDocumentsParams,
  IGetSimilarTransactionServiceDocumentsResponse,
  getSimilarTransactionServiceDocuments,
  IRemoveNoAutoVerifyRulesParams,
  IRemoveNoAutoVerifyResponse,
  removeNoAutoVerifyRules,
  IUpdateTransactionServiceDocumentDoNotLearnParams,
  updateTransactionServiceDocumentDoNotLearn,
  IGroupSimilarTsdsParams,
  IGroupSimilarTsdsResponse,
  groupSimilarTsds,
  IAskDocytAIParams,
  IAskDocytAIResponse,
  askDocytAI,
  getTransactionServiceDocument,
  IGetTransactionServiceDocumentResponse,
};
