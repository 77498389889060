import React, { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ICreateTaskBody } from '@src/requests/task_service_documents';
import { TSection } from '@src/types/common';
import { ITaskServiceDocument } from '@src/types/task_service_documents';

import { UserAvatar } from '@src/components/ui/avatars';
import TextAreaField from '@src/components/ui/form/fields/text_area_field';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { TOption } from '@src/components/ui_v2/inputs';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import Section from '@src/components/utils_v2/section';

import AssigneeField from './assignee';
import BlockModal from './block_modal';
import CloseModal from './close_modal';
import { allStatusOptions, availableStatuses,
  defaultStatusOptions, getChangedFields } from './helper/constants';
import { ITaskItemFormValues, updateTaskValidation } from './helper/validations';
import TaskBusinessSelect from './task_business_select';
import TaskDeleteModal from './task_delete_modal';
import TaskStatusSelect from './task_status_select';
import { ITaskIconOption, StatusKey } from '../types';

import styles from '../styles.module.scss';

interface DetailsSectionProps {
  taskServiceDocument: ITaskServiceDocument;
  onGoBack:()=>void;
}

const DetailsSection = ({ taskServiceDocument, onGoBack }:DetailsSectionProps) => {
  const [selectedBusiness, setSelectedBusiness] = useState<TOption | null>(null);
  const [selectedUser, setSelectedUser] = useState<ITaskIconOption | null | undefined>(null);
  const [selectedStatus, setSelectedStatus] = useState<TOption | null | undefined>(null);
  const [open, setOpen] = useState<boolean>(false);
  const updateTask = useUpdateTaskServiceDocument();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState<TOption | null>(null);
  const [taskDocument, setTaskDocument] = useState<ITaskServiceDocument | null>(null);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm<ITaskItemFormValues>({
    resolver:         yupResolver(updateTaskValidation),
    reValidateMode:   'onChange',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (taskServiceDocument) {
      setValue('userId', taskServiceDocument.userId);
      setValue('businessId', taskServiceDocument.businessId);
      setValue('title', taskServiceDocument.title);
      setValue('status', taskServiceDocument.status);
      setValue('description', taskServiceDocument.description);
    }
  }, [setValue, taskServiceDocument]);

  const handleCloseDelete = useCallback(() => {
    onGoBack();
    handleClose();
  }, [handleClose, onGoBack]);

  const submitHandler = handleSubmit((bodyData) => {
    const prevValues = {
      userId:      taskServiceDocument.userId,
      businessId:  Number(taskServiceDocument.businessId),
      title:       taskServiceDocument.title,
      status:      taskServiceDocument.status,
      description: taskServiceDocument.description,
    };
    const changedFields = getChangedFields(bodyData, prevValues);

    if (Object.keys(changedFields).length) {
      updateTask.mutate(
        {
          formData: changedFields as ICreateTaskBody,
          taskId:   taskServiceDocument.taskId,
        },
        {
          onSuccess: () => {
            toastr.success(
              'Task Updated Successfully',
              'Update Task',
            );
            handleClose();
            reset({
              notes:     '',
              closeDate: '',
            });
          },
          onError: ({ response }) => {
            toastr.error(
              response?.data?.error || 'Some error occurred',
              'Update Task',
            );
          },
        },
      );
    }
  });

  const handleCancel = useCallback(() => {
    setIsOpenModal(false);
    setTaskDocument(null);
    setStatusOption(null);
  }, [setIsOpenModal]);

  const handleSaveStatus = useCallback(({ formData }:{formData:ICreateTaskBody}) => {
    setValue('status', formData.status);
    if (formData.notes) {
      setValue('notes', formData.notes);
    } else if (formData.closeDate) {
      setValue('closeDate', formData.closeDate);
    }
    handleCancel();
    setSelectedStatus(statusOption);
  }, [handleCancel, setValue, statusOption]);

  const handleStatusChange = useCallback(({ option, task }
    :{option:TOption, task?:ITaskServiceDocument;}) => {
    switch (option.value) {
      case availableStatuses.BLOCKED: {
        setStatusOption(option);
        if (task?.status === availableStatuses.BLOCKED) {
          setValue('status', availableStatuses.BLOCKED);
          setSelectedStatus(option);
        } else {
          setIsOpenModal(true);
        }
        if (task) setTaskDocument(task);
        break;
      }
      case availableStatuses.CLOSED: {
        setStatusOption(option);
        if (task?.status === availableStatuses.CLOSED) {
          setValue('status', availableStatuses.CLOSED);
          setSelectedStatus(option);
        } else {
          setIsOpenModal(true);
        }
        if (task) setTaskDocument(task);
        break;
      }
      case availableStatuses.PENDING: {
        setStatusOption(option);
        setSelectedStatus(option);
        setValue('status', option.value);
        if (task) setTaskDocument(task);
        break;
      }
      case availableStatuses.IN_PROGRESS: {
        setStatusOption(option);
        setSelectedStatus(option);
        setValue('status', option.value);
        if (task) setTaskDocument(task);
        break;
      }
      default: {
        break;
      }
    }
  }, [setValue]);
  return (
    <div className={ styles['left-section'] }>
      <p className={ styles['logs-header'] }>Details</p>
      <div className={ styles['divider-bottom'] } />
      <Form
        onSubmit={ submitHandler }
      >
        <div className={ styles['form-row'] }>
          <label htmlFor="business">Business:</label>
          <div className={ styles['task-detail-business'] }>
            <TaskBusinessSelect
              disabled={ !taskServiceDocument?.canEdit }
              selectedBusiness={ selectedBusiness?.value ? selectedBusiness as TOption
                : { label: taskServiceDocument.businessName, value: String(taskServiceDocument?.businessId) } }
              setSelectedBusiness={ (e) => {
                setSelectedBusiness(e);
                setValue('businessId', Number(e?.value));
              } }
            />
          </div>
          {errors.businessId?.message
           && <span className={ styles['error-message'] }>{errors.businessId?.message}</span>}
        </div>

        <div className={ styles['form-row'] }>
          <label htmlFor="status">Status:</label>
          <div className={ styles['task-details-status'] }>
            <TaskStatusSelect
              hasBackgroundColor
              className={ styles['task-status-select-detail-container'] }
              disabled={ taskServiceDocument.status === availableStatuses.OPEN || !taskServiceDocument.canEdit }
              menuClassName={ styles['task-status-select-menu-details'] }
              statusOptions={
                taskServiceDocument.status === availableStatuses.OPEN
                  ? allStatusOptions : defaultStatusOptions
              }
              value={ selectedStatus?.value as StatusKey
                || taskServiceDocument?.status as StatusKey }
              onChange={ (e) => {
                handleStatusChange({ option: e, task: taskServiceDocument });
              } }
            />
          </div>
        </div>

        <Section.Provider section={ { section: 'assignee' } as TSection }>
          <div className={ styles['form-row'] }>
            <label htmlFor="assignee">Assignee:</label>
            <div className={ styles['task-details-assignee'] }>
              <AssigneeField
                hasBorder
                hideSelectedOption
                businessId={ taskServiceDocument.businessId }
                className={ styles['task-assignee-inner'] }
                isDisabled={ !taskServiceDocument?.canEdit
                  || !taskServiceDocument?.serviceProviderBusinessId
                  || taskServiceDocument.status === availableStatuses.CLOSED }
                selectedUser={ {
                  icon: selectedUser ? selectedUser?.icon?.props?.vendor?.imageUrl
                    : taskServiceDocument.userIcon,
                  label:  selectedUser ? selectedUser?.label : taskServiceDocument?.userName,
                  helper: selectedUser ? selectedUser?.helper : taskServiceDocument.userDesignation,
                  value:  String(selectedUser ? selectedUser?.value : taskServiceDocument.userId),
                } }
                serviceProviderBusinessId={ taskServiceDocument?.serviceProviderBusinessId }
                toggleElement={
                taskServiceDocument.status === availableStatuses.OPEN && !selectedUser?.value
                  ? (
                    <UserAvatar
                      className={ classNames({
                        [styles['select-disabled']]:
                        !taskServiceDocument?.canEdit
                        || !taskServiceDocument?.serviceProviderBusinessId
                        || taskServiceDocument.status === availableStatuses.CLOSED,
                      }) }
                      inColor="grey-800"
                      size={ 32 }
                    />
                  )
                  : null
              }
                onChange={ (option) => {
                  setSelectedUser(option);
                  setValue('userId', Number(option?.value));
                } }
              />
            </div>
          </div>
        </Section.Provider>

        <div className={ styles['task-feild-title'] }>
          <label htmlFor="title">Title:</label>
          <Form.TextField
            hiddenLabel
            hideClear
            defaultValue={ taskServiceDocument?.title }
            disabled={ !taskServiceDocument.canEdit }
            error={ errors.title?.message }
            label=""
            { ...register('title') }
          />
        </div>

        <div className={ styles['task-feild-description'] }>
          <label htmlFor="description">Description:</label>
          <TextAreaField
            className={ styles['task-feild-description-textarea'] }
            defaultValue={ taskServiceDocument?.description }
            disabled={ !taskServiceDocument.canEdit }
            error={ errors.description?.message }
            label=""
            { ...register('description') }
          />
        </div>
        {taskServiceDocument.canEdit && (
        <div className={ styles['btn-bottom-container'] }>
          <Button
            className={ styles['task-delete-btn'] }
            disabled={ updateTask.isLoading || !taskServiceDocument.canEdit }
            type="button"
            variant="link"
            onClick={ () => setOpen(true) }
          >
            Delete
          </Button>
          <Button
            className={ styles['task-update-btn'] }
            disabled={ updateTask.isLoading || !taskServiceDocument.canEdit }
            type="submit"
            variant="primary"
          >
            {updateTask.isLoading ? (
              <>
                Updating
                {' '}
                <SpinnerIcon spin fontSize={ 10 } />
              </>
            )
              : 'Update'}
          </Button>
        </div>
        )}
      </Form>
      <TaskDeleteModal
        open={ open }
        taskId={ taskServiceDocument?.taskId }
        onCancel={ handleClose }
        onDelete={ handleCloseDelete }
      />
      <BlockModal
        businessName={ taskDocument?.businessName as string }
        open={ statusOption?.value === availableStatuses.BLOCKED && isOpenModal }
        shouldUpdate={ false }
        taskDocument={ taskServiceDocument }
        onCancel={ handleCancel }
        onSubmit={ (bodyData) => {
          handleSaveStatus({ formData: { ...bodyData, status: availableStatuses.BLOCKED } as ICreateTaskBody });
        } }
      />
      <CloseModal
        businessName={ taskDocument?.businessName as string }
        minDate={ taskDocument?.createdAt }
        open={ statusOption?.value === availableStatuses.CLOSED && isOpenModal }
        shouldUpdate={ false }
        taskDocument={ taskServiceDocument }
        onCancel={ handleCancel }
        onSubmit={ (bodyData) => {
          handleSaveStatus({ formData: { ...bodyData, status: availableStatuses.CLOSED } as ICreateTaskBody });
        } }
      />
    </div>
  );
};

export default DetailsSection;
