/* eslint-disable max-len */
import React, { useMemo, useCallback } from 'react';

import flatten from 'lodash/flatten';

import { TID } from '@src/types/common';

import useConnectFinancialInstitutionModal from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/connect_financial_institution/modal';
import BlankList from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/list_item/blank_list';
import Table from '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/list_item/financial_institution_connection_table';
import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { PlusIcon } from '@src/components/utils/icomoon';

import { useGetFinancialInstitutionConnectionsByManagementGroup } from './hooks';

import styles from
  '@src/components/financial_institution_connection_center/financial_institution_connection_center_details/styles.module.scss';

interface IManagementGroupConnectionsProps {
  managementGroupId: TID;
}

const ManagementGroupConnections: React.FC<IManagementGroupConnectionsProps> = ({
  managementGroupId,
}) => {
  // Use our specialized hook to get connections filtered by management group
  const query = useGetFinancialInstitutionConnectionsByManagementGroup(managementGroupId);

  // Flatten the paginated results into a single array of connections
  const financialInstitutionConnectionsPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const financialInstitutionConnections = useMemo(() => {
    return flatten(financialInstitutionConnectionsPages.map((item) => {
      return item.collection;
    }));
  }, [financialInstitutionConnectionsPages]);

  // Get filtered count for empty state handling
  const totalCount = query.data?.pages[0]?.meta.totalCount || 0;

  // Reuse the same modal for connecting new financial institutions
  const connectFinancialInstitutionModal = useConnectFinancialInstitutionModal();

  // Function to refresh the connections list - use useCallback to create a stable function reference
  const refetchFinancialConnectionList = useCallback(() => {
    query.refetch();
  }, [query]);

  return (
    <div>
      <SideView.Provider>
        <connectFinancialInstitutionModal.Component
          { ...connectFinancialInstitutionModal.props }
          financialInstitutionConnections={ financialInstitutionConnections }
          managementGroupId={ managementGroupId }
        />
        {query.isFetching && <Spinner />}
        {
          totalCount === 0 && !query.isLoading
            ? (
              <BlankList
                onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
              />
            ) : (
              <div className={ styles['main-details'] }>
                <DetailsPageTitle
                  title="Financial Connections"
                >
                  <Button
                    bsColor="blue"
                    onClick={ connectFinancialInstitutionModal.open }
                  >
                    <PlusIcon mr={ 10 } />
                    Connect
                  </Button>
                </DetailsPageTitle>
                <div className="banking-accounts-table-wrapper">
                  <Table
                    financialInstitutionConnections={ financialInstitutionConnections }
                    query={ query }
                    refetchFinancialConnectionList={ refetchFinancialConnectionList }
                  />
                </div>
              </div>
            )
        }
        <SideView.Render />
      </SideView.Provider>
    </div>
  );
};

export default ManagementGroupConnections;
