import React from 'react';

import { TDuplicateVendors } from '@src/types/vendor';

import Table from '@src/components/ui_v2/table';

import styles from './styles.module.scss';

interface IDuplicateVendorsTableProps {
  duplicateVendors: TDuplicateVendors;
}

const DuplicateVendorsTable = ({ duplicateVendors }: IDuplicateVendorsTableProps) => {
  const duplicateVendorsCount = Object.keys(duplicateVendors).length;

  return (
    <Table className={ styles['duplicate-vendors-table'] }>
      <Table.Head>
        <Table.Row>
          <Table.HCell className={ styles['table-number-h-cell'] } width="5%">S.No</Table.HCell>
          <Table.HCell>Duplicates</Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        { duplicateVendorsCount === 0 ? (
          <Table.Row>
            <Table.Cell colSpan={ 2 } textAlign="center">No duplicate vendors found</Table.Cell>
          </Table.Row>
        ) : (
          Object.entries(duplicateVendors).map(([mergableId, vendorNames], index) => (
            <Table.Row key={ mergableId }>
              <Table.Cell textAlign="center">{ index + 1 }</Table.Cell>
              <Table.Cell>{ vendorNames.join(', ') }</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default React.memo(DuplicateVendorsTable);
