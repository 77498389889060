/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ITask,
} from '@src/types/task_notification';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetTaskNotificationParams {
  page?: number,
  ps?: number,
}

interface IGetTaskNotificationCountResponse {
  meta: {
    totalCount: number,
  },
  collection: ITask[],
}

interface IGetTaskNotificationResponse {
  meta: {
    totalCount: number,
  },
  collection: ITask[],
}

const getTaskNotificationData = async (
  params: IGetTaskNotificationParams,
): Promise<IGetTaskNotificationResponse> => {
  try {
    const data = await apiGet('/task/api/v1/notification', underscoreKeys(params));
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.totalElements,
      },
      collection: cdata.response as ITask[],
    };
  } catch (error) {
    return {
      meta: {
        totalCount: 0,
      },
      collection: [] as ITask[],
    };
  }
};

const getTaskNotificationCount = (
  params: IGetTaskNotificationParams,
): Promise<IGetTaskNotificationCountResponse> => {
  return apiGet(
    '/task/api/v1/task/all',
    underscoreKeys(params),
  ).then((data) => {
    const countData = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: countData.totalElements,
      },
      collection: countData.response as ITask[],
    };
  });
};

export {
  IGetTaskNotificationParams,
  IGetTaskNotificationResponse,
  getTaskNotificationData,
  IGetTaskNotificationCountResponse,
  getTaskNotificationCount,
};
