import React from 'react';

import { ICombinedServiceDocument } from '@src/types/combined_service_documents';
import { ICreateTransactionMatchData } from '@src/types/transaction_matches';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { FileTextIcon } from '@src/components/utils/fa_icons';
import { ChatIcon } from '@src/components/utils/icomoon';

import MarkAsClearedAction from '../mark_as_cleared_action';

type TMatchDocumentsCombinedListActionsProps = {
  document: ICombinedServiceDocument,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disabledRow?: boolean
  reconciliationCenterId: number
}

const MatchDocumentsCombinedListActions = ({
  document,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disabledRow,
  reconciliationCenterId,
}: TMatchDocumentsCombinedListActionsProps) => {
  let documentMatchType: 'ServiceDocument' | 'ReceiptServiceDocument' | 'TransactionServiceDocument' | undefined;
  if (document.documentType === 'Invoice') {
    documentMatchType = 'ServiceDocument';
  } else if (document.documentType === 'Receipt') {
    documentMatchType = 'ReceiptServiceDocument';
  } else {
    documentMatchType = undefined;
  }

  const docMatchData: ICreateTransactionMatchData = {
    reconciliationCenterId,
    qboPreference:    'documents',
    transactionMatch: {
      transactionMatchItems: [
        {
          id:   document.serviceDocumentId,
          type: documentMatchType,
        },
      ],
    },
  };
  return (
    <>
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}/chat` }
        icon={ <ChatIcon fontSize={ 20 } variant="round" /> }
        title="Send a Message"
      />
      <ActionsDropdown.LinkAction
        href={ `/document/${document.documentId}` }
        icon={ <FileTextIcon fontSize={ 20 } variant="o" /> }
        title="View Detail"
      />
      <MarkAsClearedAction matchData={ docMatchData } />
    </>
  );
};

export default MatchDocumentsCombinedListActions;
