import React from 'react';

import { IDocumentModel } from '@src/types/document_model';

import styles from '../document_list.module.scss';

interface RequestDetailsSectionProps {
  model: IDocumentModel;
}

const RequestDetailsSection: React.FC<RequestDetailsSectionProps> = ({ model }) => {
  return (
    <div className={ styles.section }>
      <div className={ styles['section-content'] }>
        <div className={ styles['request-details'] }>
          <div className={ styles['detail-row'] }>
            <div className={ styles['detail-label'] }>Request Name</div>
            <div className={ styles['detail-value'] }>{model.get('name')}</div>
          </div>
          <div className={ styles['detail-row'] }>
            <div className={ styles['detail-label'] }>Description</div>
            <div className={ styles['detail-value'] }>{model.get('description')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDetailsSection;
