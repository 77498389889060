import React from 'react';

import Table from '@src/components/ui_v2/table';

const TableHead: React.FC = () => {
  return (
    <Table.Head>
      <Table.Row>
        <Table.HCell widthPercent={ 40 }>Member</Table.HCell>
        <Table.HCell widthPercent={ 20 }>Contact Number</Table.HCell>
        <Table.HCell widthPercent={ 20 }>Clients</Table.HCell>
        <Table.HCell />
      </Table.Row>
    </Table.Head>
  );
};

export default TableHead;
