import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { clientCenteralPath, documentRequestsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { CreateDocumentRequestAction } from '@src/components/client_central';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { useItemsSelector } from '@src/components/utils/items_selector';

import AddDocument from './list_actions/add_document_action';
import Delete from './list_actions/delete_action';
import Download from './list_actions/download_action';
import Forward from './list_actions/forward_action';
import MarkAsRead from './list_actions/mark_as_read_action';

const BusinessInboxListHeader = (): JSX.Element => {
  const selector = useItemsSelector<TID>();
  const business = useBusinessContext();
  const { data: sunsetClientCentralEnabled } = useLDUserFeatureQuery(
    window.Docyt.currentAdvisor.id,
    window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
  );
  const { data: info } = useGetBusinessServicesInfo(business.id);

  const handleDocumentRequestCreated = useCallback(() => {
    backboneNavigateToUrl(
      sunsetClientCentralEnabled
        ? documentRequestsPath(business.id)
        : clientCenteralPath(business.id),
    );
  }, [business.id, sunsetClientCentralEnabled]);

  return (
    <DetailsPageTitle
      title="Inbox"
    >
      { info?.accountingFirmBusinessId && (
        <CreateDocumentRequestAction
          businessId={ business.id }
          onCreateConfirmed={ handleDocumentRequestCreated }
        />
      )}
      <Download selectedIds={ selector.selected } />
      <MarkAsRead documentIds={ selector.selected } />
      <Forward selectedIds={ selector.selected } onDone={ selector.reset } />
      <Delete documentIds={ selector.selected } onDone={ selector.reset } />
      <AddDocument />
    </DetailsPageTitle>
  );
};

export default React.memo(BusinessInboxListHeader);
