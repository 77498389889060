import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Sidebar from './reconciliation_center_sidebar/sidebar';

interface IReconciliationCenterSidebarProps {
  businessId: TID,
}

const ReconciliationCenterSidebar = ({
  businessId,
}: IReconciliationCenterSidebarProps): JSX.Element => {
  return (
    <Root>
      <Sidebar businessId={ businessId } />
    </Root>
  );
};

export default ReconciliationCenterSidebar;
