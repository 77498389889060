import React from 'react';

import { makeUseModal } from '@src/hooks/modal';

import BaseModalMyTeam from '@src/components/settings/my_team/components/modal/base_modal_my_team';
import ImgIcon from '@src/components/utils/img_icons/img_icon';

import styles from './styles.module.scss';

interface IAnimatedLoaderModalProps {
  isOpen: boolean;
  currentCount?: number;
  totalCount?: number;
  showProgress?: boolean;
}

const FOLDER_ANIMATION_IMAGE = 'https://docyt-assets.s3.us-east-1.amazonaws.com/all/assets/foldar_animation.gif';

const AnimatedLoaderModal = ({
  isOpen,
  currentCount = 0,
  totalCount = 0,
  showProgress = false,
}: IAnimatedLoaderModalProps) => {
  // Calculate progress percentage
  const progressPercentage = totalCount > 0 ? (currentCount / totalCount) * 100 : 0;

  return (
    <BaseModalMyTeam
      className={ styles['animated-loader-modal'] }
      isOpen={ isOpen }
    >
      <div className={ styles['processing-container'] }>
        <div className={ styles['processing-icon'] }>
          <div className={ styles['folder-animation'] }>
            <div className={ styles['folder-icon'] }>
              <ImgIcon
                alt="Folder Icon"
                height={ 150 }
                loading="lazy"
                src={ FOLDER_ANIMATION_IMAGE }
                width={ 150 }
              />
            </div>
          </div>
        </div>
        <div className={ styles['processing-text'] }>
          Setting up access for selected clients... Almost done!
        </div>

        {showProgress && totalCount > 0 && (
          <div className={ styles['progress-container'] }>
            <div className={ styles['progress-count'] }>
              <b>
                {currentCount}
                /
                {totalCount}
              </b>
              {' '}
              Clients
            </div>
            <div className={ styles['progress-bar-wrapper'] }>
              <div className={ styles['progress-bar'] }>
                <div
                  className={ styles['progress-bar-fill'] }
                  style={ { width: `${progressPercentage}%` } }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </BaseModalMyTeam>
  );
};

const useAnimatedLoaderModal = makeUseModal(AnimatedLoaderModal);

export {
  useAnimatedLoaderModal,
  AnimatedLoaderModal as default,
};
