/* eslint-disable eqeqeq, import/order */
import React, { useCallback, useMemo } from 'react';

import { useGetReportServiceAccess } from '@src/hooks/queries/report_service/custom_reports';
import { useGetMetricsTotalCount } from '@src/hooks/queries/metrics';
import {
  budgetsPath,
  departmentReportsPath,
  expenseReportsPath,
  forReviewMetricsPath,
  laborMetricsPath,
  laborReportsPath,
  managementReportsPath,
  metricsCSVImportPath,
  metricsPath,
  revenueReportsPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import { useSubscriptionModal } from '@src/components/common/subscription_modal/subscription_modal';
import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';
import { reportServiceUrl } from '../utils';
import RegularReportServiceItems from './regular_report_service_item';
interface IServiceMenuProps {
  businessId: TID,
  info: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean
}

const BusinessReportItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient
}: IServiceMenuProps) => {
  const reportServiceAccess = useGetReportServiceAccess(businessId);
  const subscriptionBasedAccess = useMemo(() => reportServiceAccess.data || {}, [reportServiceAccess]);
  const isBudgetEnabled = subscriptionBasedAccess.reportAccess?.includes('budget');
  const subscriptionModal = useSubscriptionModal();

  const { data: metricsTotalCount } = useGetMetricsTotalCount({
    businessId,
    standardIndustryId: info.standardCategoryId || -1,
    hasMetricsServiceRole: info.hasMetricsServiceRole
  });

  const canVisibleReportService = info.isBusinessAdmin || info.canAccessReportService;
  const canVisibleMetricsService = info.isBusinessAdmin || info.hasMetricsServiceRole;

  const handleClick = useCallback(() => {
    if (isBudgetEnabled) {
      Backbone.history.navigate(budgetsPath(businessId), { trigger: true });
    }
    else {
      subscriptionModal.open();
    }
  }, [businessId, isBudgetEnabled, subscriptionModal]);

  if (!canVisibleReportService && !canVisibleMetricsService) return null;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BUSINESS_REPORTS }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BUSINESS_REPORTS }
    >
      {
        canVisibleReportService && (
          <>
            <subscriptionModal.Component { ...subscriptionModal.props } businessId={ businessId } />
            <ServiceSubMenu
              serviceUrl={ reportServiceUrl(info, businessId) }
              title={ window.Docyt.Common.Constants.SERVICE_NAMES.FINANCIAL_REPORTS }
            >
              <RegularReportServiceItems
                businessId={ businessId }
                businessInfo={ info }
              />
              <ServiceItem
                isHidden={
                  info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.UPS_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.QSR_ID
                }
                serviceUrl={ revenueReportsPath(businessId) }
                title="Revenue"
              />
              <ServiceItem
                serviceUrl={ expenseReportsPath(businessId) }
                title="Expense"
              />
              <ServiceItem
                isHidden={
                  info.standardCategoryId != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                }
                serviceUrl={ laborReportsPath(businessId) }
                title="Labor"
              />
              <ServiceItem
                isHidden={
                  info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.UPS_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.QSR_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.OTHER_ID
                }
                serviceUrl={ managementReportsPath(businessId) }
                title="Management Reports"
              />
              <ServiceItem
                launchDarklyFeature="budgets-enabled"
                serviceUrl={ budgetsPath(businessId) }
                title="Budget and Forecast"
                onClickHandler={ handleClick }
              />
              <ServiceItem
                isHidden={
                  info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
                  && info.standardCategoryId
                  != window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.SAAS_ID
                }
                serviceUrl={ departmentReportsPath(businessId) }
                title="Departmental Reports"
              />
            </ServiceSubMenu>
          </>
        )
      }
      {
        canVisibleMetricsService && (
          <ServiceSubMenu
            isHidden={ metricsTotalCount?.totalCount == 0 }
            launchDarklyFeature="metrics-service"
            serviceUrl={ metricsPath(businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.OPERATIONAL_METRICS }
          >
            <ServiceItem
              serviceUrl={ metricsPath(businessId) }
              title="Industry Metrics"
            />
            <ServiceItem
              isHidden
              serviceUrl={ laborMetricsPath(businessId) }
              title="Metrics"
            />
            <ServiceItem
              isHidden
              serviceUrl={ metricsCSVImportPath(businessId) }
              title="CSV Import"
            />
            <ServiceItem
              serviceUrl={ forReviewMetricsPath(businessId) }
              title="For Review"
            />
          </ServiceSubMenu>
        )
      }
    </ModuleHeaderMenu>
  );
};

export default BusinessReportItem;
