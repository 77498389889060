import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useUpdateTaskServiceDocument } from '@src/hooks/queries/task_service_documents';
import { ITaskServiceDocument } from '@src/types/task_service_documents';

import { availableStatuses } from '@src/components/task_management/components/helper/constants';
import { blockTaskStatusValidation, IBlockTaskStatusValuesProps }
  from '@src/components/task_management/components/helper/validations';
import Modal from '@src/components/ui/modal';
import ModalBody from '@src/components/ui/modal/body';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import { IUpdateStatusProps } from '../types';

import styles from './styles.module.scss';

interface IBlockModalProps {
    open:boolean;
    onCancel:()=>void;
    onSubmit?:(bodyData:IBlockTaskStatusValuesProps)=>void;
    businessName:string;
    shouldUpdate?:boolean;
    taskDocument?:ITaskServiceDocument | null;
}

const BlockModal = ({ open, onCancel, businessName, onSubmit,
  taskDocument, shouldUpdate = true }:IBlockModalProps) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<IBlockTaskStatusValuesProps>({
    defaultValues: { notes: '' },
    resolver:      yupResolver(blockTaskStatusValidation),
  });
  const updateStatus = useUpdateTaskServiceDocument();
  const handleCancel = useCallback(() => {
    setValue('notes', '');
    clearErrors('notes');
    onCancel();
  }, [clearErrors, onCancel, setValue]);

  const handleUpdateStatus = useCallback((
    { formData, id }:IUpdateStatusProps,
  ) => {
    if (formData && (taskDocument?.taskId || id)) {
      updateStatus.mutate(
        { formData: { ...formData, status: availableStatuses.BLOCKED },
          taskId:   id ?? taskDocument?.taskId as string },
        { onSuccess: () => {
          toastr.success(
            'Status Updated Successfully',
            'Update Task Status',
          );
          handleCancel();
        },
        onError: (error) => {
          toastr.error(
            error.message,
            'Error Update Status',
          );
        } },
      );
    }
  }, [handleCancel, taskDocument?.taskId, updateStatus]);

  const submitHandler = handleSubmit((bodyData) => {
    if (bodyData.notes) {
      if (shouldUpdate && taskDocument) {
        handleUpdateStatus({ formData: bodyData, id: taskDocument.taskId });
      } else if (onSubmit) {
        onSubmit(bodyData);
        setValue('notes', '');
        clearErrors('notes');
      }
    }
  });

  return (
    <Modal
      show={ open }
      title="Block Task"
      onCancel={ handleCancel }
    >
      <Form onSubmit={ submitHandler }>
        <ModalBody>
          <div className={ styles['status-modal-body'] }>
            <div className={ styles['task-info'] }>
              <p>
                Task ID :&nbsp;
                #
                {taskDocument?.taskId}
              </p>
              <p>
                Business :&nbsp;

                {businessName}
              </p>
            </div>
            <span className={ styles['notes-input-title'] }>Please add a note to mention possible reason</span>
            <Form.TextAreaField
              hiddenLabel
              className={ styles['status-notes-input'] }
              error={ errors.notes?.message }
              label=""
              placeholder="Add note"
              { ...register('notes') }
            />
          </div>
        </ModalBody>
        <Modal.Footer className={ styles['status-modal-footer'] }>
          <Button variant="link" onClick={ handleCancel }>Cancel</Button>
          <Button disabled={ updateStatus?.isLoading } type="submit" variant="primary">
            {updateStatus?.isLoading ? (
              <>
                Updating
                <SpinnerIcon spin />
              </>
            ) : 'Update'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BlockModal;
