import {
  IGetTaskNotificationCountResponse,
  IGetTaskNotificationParams,
  IGetTaskNotificationResponse,
  getTaskNotificationCount,
  getTaskNotificationData,
} from '@src/requests/task_notification';
import { ITask } from '@src/types/task_notification';

import {
  createUseGetInfiniteCollection,
} from './infinite_collection_queries';

const TASK_NOTIFICATION_QUERY = 'notification_response';
const TASK_NOTIFICATION_COUNT_QUERY = 'response';

const useGetTaskNotification = createUseGetInfiniteCollection<
  ITask,
  IGetTaskNotificationParams,
  IGetTaskNotificationResponse
>({
  queryKey: TASK_NOTIFICATION_QUERY,
  request:  getTaskNotificationData,
});

const useGetTaskNotificationCount = createUseGetInfiniteCollection<
  ITask,
  IGetTaskNotificationParams,
  IGetTaskNotificationCountResponse
>({
  queryKey: TASK_NOTIFICATION_COUNT_QUERY,
  request:  getTaskNotificationCount,
});

export {
  TASK_NOTIFICATION_QUERY,
  TASK_NOTIFICATION_COUNT_QUERY,
  useGetTaskNotification,
  useGetTaskNotificationCount,
};
