import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { uniqBy } from 'lodash';
import { GroupBase } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { VendorAvatar } from '@src/components/ui/avatars';
import { IFilterFieldUIProps } from '@src/components/ui/filter/utils';
import {
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';

import TaskAssigneeSelect from './task_assignee_select';
import { ITaskIconOption, ITaskUserOptionItem } from '../types';

interface IMultiSelectFilterFieldProps extends
  Omit<IIconSelectInputProps, 'name' | 'value' | 'onChange'>, IFilterFieldUIProps
{
  toggleElement?: string | React.ReactNode;
  className?: string;
  hasBorder?: boolean;
  selectedUser?: ITaskUserOptionItem;
  isDisabled?: boolean;
  onChange?:(newValue: ITaskIconOption | TIconOption | null | undefined)=>void;
  isMultiSelect?: boolean;
  hideIcon?: boolean;
  isLoading?: boolean;
  headerText?: string;
  getSelectedOptions?:(options : ITaskIconOption[])=>void;
  onInputChange?:(val: string, { action }:{action:string})=>void;
  inputValue?:string;
  defaultSelectedOptionsIds?:string[];
  excludedIds?:string[];
  allOptions?:ITaskIconOption[];
  noOptionMessage?:string;
  handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, { page: number }>;
  getDropdownState?:(val:boolean)=>void;
  setIsAllSelected?:(checked:boolean)=>void;
  isAllSelected?:boolean;
  onSingleCheckClick?:(checked:boolean, option:TIconOption)=>void;
}

const MultiSelectField = ({
  isLoading = false,
  toggleElement,
  hasBorder,
  className = '',
  selectedUser,
  onChange,
  isMultiSelect = false,
  getSelectedOptions,
  defaultSelectedOptionsIds,
  headerText,
  isDisabled,
  handleSource,
  hideIcon,
  allOptions,
  noOptionMessage,
  inputValue,
  onInputChange,
  getDropdownState,
  setIsAllSelected,
  onSingleCheckClick,
  isAllSelected = false,
  excludedIds,
}: IMultiSelectFilterFieldProps) => {
  const [selectedOptions, setSelectedOptions] = useState<ITaskIconOption[]>([]);
  const onSingleChecked = useCallback((checked:boolean, option:ITaskIconOption) => {
    if (isAllSelected && onSingleCheckClick) {
      return onSingleCheckClick(checked, option);
    }
    const isExist = selectedOptions.find((item) => item.value === option.value);
    if (checked && option && !isExist) {
      setSelectedOptions([...selectedOptions, option]);
      if (getSelectedOptions) {
        getSelectedOptions([...selectedOptions, option]);
      }
    } else {
      const filteredList = selectedOptions.filter((item) => item.value !== option.value);
      setSelectedOptions(filteredList);
      if (getSelectedOptions) {
        getSelectedOptions(filteredList);
      }
    }
    return null;
  }, [getSelectedOptions, isAllSelected, onSingleCheckClick, selectedOptions]);

  const onSelectAllChecked = useCallback((checked:boolean) => {
    if (setIsAllSelected) setIsAllSelected(checked);
    setSelectedOptions([]);
    if (getSelectedOptions) {
      getSelectedOptions([]);
    }
  }, [setIsAllSelected, getSelectedOptions]);

  useEffect(() => {
    if (defaultSelectedOptionsIds && allOptions) {
      const defaultSelectedOptions = uniqBy(
        allOptions?.filter((item) => defaultSelectedOptionsIds?.includes(item.value)),
        'value',
      );
      setSelectedOptions(defaultSelectedOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [allOptions, defaultSelectedOptionsIds]);
  const selectedItem = useMemo(() => {
    if (!selectedUser?.value) return undefined;

    return {
      icon: <VendorAvatar
        size="100%"
        vendor={ { ...selectedUser,
          imageUrl: String(selectedUser.icon) } }
      />,
      label:  selectedUser?.label,
      helper: selectedUser?.helper,
      value:  selectedUser?.value,
    };
  }, [selectedUser]);
  return (
    <TaskAssigneeSelect
      className={ className }
      disabled={ isDisabled }
      excludedIds={ excludedIds }
      getDropdownState={ getDropdownState }
      handleSource={ handleSource }
      hasBorder={ hasBorder }
      headerText={ headerText }
      hideIcon={ hideIcon }
      inputValue={ inputValue }
      isAllSelected={ isAllSelected }
      isLoading={ isLoading }
      isMulti={ isMultiSelect }
      noOptionMessage={ noOptionMessage }
      selectedItem={ selectedItem }
      selectedOptions={ selectedOptions }
      toggleElement={ toggleElement }
      onChange={ onChange }
      onInputChange={ onInputChange }
      onSelectAllChecked={ onSelectAllChecked }
      onSingleChecked={ onSingleChecked }
    />
  );
};

export default React.memo(MultiSelectField);
