import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { BusinessChartOfAccounts } from '@src/requests/system_categories';
// eslint-disable-next-line max-len
import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import { getTransactionType } from '@src/components/banking_accounts/transactions/utils';
import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import BulkActions from '@src/components/reconciliation_center/all_transactions/bulk_actions/bulk_actions';
import { ITSDCollection } from '@src/components/reconciliation_center/all_transactions/hooks';
import CollectionTable from '@src/components/ui_v2/collection_table';

import AllTransactionsListCategorizeActions from './categorize';
import CategorizedByIcon from '../../action_icons/categorized_by_icon';
import DocumentIcon from '../../action_icons/document_icon';
import FlaggedStateIcon from '../../action_icons/flagged_state_icon';
import AllTransactionsFilter from '../filter';
import Actions from './actions';

import styles from '../styles.module.scss';

const accountName = (document: ITransactionServiceDocument) => {
  if (document.employeeCardName && document.employeeCardName !== document.paymentAccountName) {
    return [document.paymentAccountName, document.employeeCardName].join(' / ');
  }

  return document.paymentAccountName;
};

const categoryValue = (document: ITransactionServiceDocument, category: string | null) => {
  if (document.category === '' && document.transactionType === 'payroll') {
    return category;
  }

  if (document.category.indexOf('Class') !== -1 && document.transactionType === 'payroll') {
    return `${document.category} | ${category}`;
  }

  return document.category;
};

const amount = (document: ITransactionServiceDocument) => {
  const isCreditCard = document.reconciliationPaymentAccount.accountType
    === window.Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE;
  return isCreditCard ? -Number(document.amount) : (document.amount);
};

const documentIcon = (document: ITransactionServiceDocument) => {
  return <DocumentIcon document={ document } />;
};

const flaggedStateIcon = (document: ITransactionServiceDocument) => {
  return <FlaggedStateIcon document={ document } />;
};

const categorizedIcon = (document: ITransactionServiceDocument) => {
  return <CategorizedByIcon document={ document } />;
};

const transactionQBOStatusIcon = (document: ITransactionServiceDocument) => {
  return <QBOStatusIcon showIndividualSyncStatus fontSize={ 18 } model={ document } />;
};

const transactionType = (document: ITransactionServiceDocument) => {
  return (
    <div className={ styles['transaction-type-container'] }>
      <CategorizedByIcon document={ document } showTooltip={ false } />
      { getTransactionType(document, true) }
    </div>
  );
};

const actions = (document: ITransactionServiceDocument) => {
  return <Actions document={ document } />;
};

const categorizeActions = (
  document: ITransactionServiceDocument,
  payrollBCOAId: number | null,
  collection: ITSDCollection,
) => {
  return (
    <AllTransactionsListCategorizeActions
      collection={ collection }
      document={ document }
      payrollBCOAId={ payrollBCOAId }
    />
  );
};

// @typescript-eslint/no-unused-vars
const AllTransactionsTable = ({
  collection,
  onRowClick,
  type,
  payrollBCOAId,
  systemCategories,
}: {
  collection: ITSDCollection;
  onRowClick: (rowData: any) => void;
  type?: string;
  payrollBCOAId: number | null;
  systemCategories: BusinessChartOfAccounts | null;
}) => {
  const {
    query: infiniteQuery,
    records,
    sorting,
    selectedRecords,
    markAll,
    getSelectionState,
  } = collection;
  const business = useBusinessContext();
  const category = systemCategories?.payrollBusinessChartOfAccount?.qboName || null;

  const handleIsRowSelectable = useCallback((document: ITransactionServiceDocument) => {
    // eslint-disable-next-line max-len
    return business.reconciliationStartDate == null || document.transactionDate >= business.reconciliationStartDate;
  }, [business.reconciliationStartDate]);

  const bulkActions = (
    <BulkActions
      getSelectionState={ getSelectionState }
      markAll={ markAll }
      // eslint-disable-next-line max-len
      selectedRecords={ selectedRecords.filter((record) => record.transactionDate >= business.reconciliationStartDate) }
    />
  );

  const handleRowClick = useCallback((rowData) => {
    onRowClick(rowData);
  }, [onRowClick]);

  return (
    <div style={ { display: 'flex', flexDirection: 'row', height: '100%' } }>
      <CollectionTable<ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn>
        isRegionScroll
        showSelect
        filter={ (
          <AllTransactionsFilter bulkActions={ bulkActions } type={ type } />
      ) }
        height="750px"
        isRowSelectable={ handleIsRowSelectable }
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
        onRowClick={ handleRowClick }
      >
        <CollectionTable.DateColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="transaction_date"
          sortColumn="transaction_date"
          title="Date"
          value={ (document) => document.transactionDate }
        />
        <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="description"
          sortColumn="description"
          title="Description"
          value={ (document) => document.description }
          width="30%"
        />
        <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="payment_account"
          sortColumn="payment_account"
          title="Banking Account"
          value={ accountName }
          width="20%"
        />
        <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="transaction_type"
          sortColumn="transaction_type"
          title="Type"
          value={ transactionType }
          width="130px"
        />
        <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="category"
          sortColumn="category"
          title="Category"
          value={ (document) => categoryValue(document, category) }
          width="20%"
        />
        <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="vendor_id"
          sortColumn="vendor_id"
          title="Vendor"
          value={ (document) => document?.vendor?.name }
          width="110px"
        />
        <CollectionTable.ButtonColumn<ITransactionServiceDocument>
          hidden={ type === window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_CATEGORIZED }
          name="action"
          title="Actions"
          value={ (document) => categorizeActions(document, payrollBCOAId, collection) }
          width="120px"
        />
        <CollectionTable.AmountColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
          name="amount"
          sortColumn="amount"
          title="Amount"
          value={ amount }
        />
        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="flagged_state"
          title="Flagged State"
          value={ flaggedStateIcon }
        />
        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="categorized_by"
          title="Categorized By"
          value={ categorizedIcon }
        />
        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="qbo_status"
          title="QBO Status"
          value={ transactionQBOStatusIcon }
        />
        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="document_type"
          title="Document Type"
          value={ documentIcon }
        />
        <CollectionTable.ActionsColumn<ITransactionServiceDocument>
          name="actions"
          title="Actions"
          value={ actions }
        />
      </CollectionTable>
    </div>
  );
};

export default React.memo(AllTransactionsTable);
