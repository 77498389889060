import React from 'react';

import Table from '@src/components/ui_v2/table';
import { TLoadingVariant } from '@src/components/ui_v2/table/types';

import { TColumn, TModel } from './types';

interface ICollectionTableLoadingCellProps<Model extends TModel, TSortColumn extends string = never> {
  column: TColumn<Model, TSortColumn>,
  variant: TLoadingVariant
}

const CollectionTableLoadingCell = <Model extends TModel, TSortColumn extends string = never>({
  column,
  variant,
}: ICollectionTableLoadingCellProps<Model, TSortColumn>) => {
  if (column.hidden) return null;

  if (column.icon) {
    return <Table.AvatarLoadingCell variant={ variant } />;
  }

  if (column.type === 'icon') {
    return <Table.IconLoadingCell variant={ variant } />;
  }

  return <Table.NormalLoadingCell variant={ variant } />;
};

export default React.memo(CollectionTableLoadingCell) as typeof CollectionTableLoadingCell;
