import React from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

import BankingAndReconciliation from './items/banking_and_reconciliation_item';
import BusinessReport from './items/business_reports_item';
import Dashboard from './items/dashboard_item';
import Documents from './items/documents_item';
import ExpenseManagement from './items/expense_management_item';
import OpenItems from './items/open_items';
import RevenueReconciliation from './items/revenue_reconciliation_item';
import Settings from './items/settings_item';
import { activatedModuleHeader } from './utils';

import styles from '../styles.module.scss';

interface IModuleHeaderActionsProps {
  businessId: TID,
  currentModulePath?: string,
  focusToMyClient: boolean,
  hasVaultServiceRole: boolean,
}

const ModuleHeaderActions = ({
  businessId,
  currentModulePath,
  focusToMyClient,
  hasVaultServiceRole,
}: IModuleHeaderActionsProps) => {
  const { data: bankStatementEnabled } = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.BANK_STATEMENTS_FLAG,
  );

  const { data: info } = useGetBusinessServicesInfo(businessId);

  const sunsetVaultFeatureFlagEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG);

  const activeItem = activatedModuleHeader(currentModulePath, sunsetVaultFeatureFlagEnabled);

  return (
    <div className={ styles['service-menu-page'] }>
      <Dashboard
        activeItem={ activeItem }
        businessId={ businessId }
        focusToMyClient={ focusToMyClient }
        info={ info }
      />
      { info && (
        <>
          <ExpenseManagement
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
          <RevenueReconciliation
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
          <BankingAndReconciliation
            activeItem={ activeItem }
            bankStatementEnabled={ bankStatementEnabled }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
          <BusinessReport
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
          <Documents
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            hasVaultServiceRole={ hasVaultServiceRole }
            info={ info }
            sunsetVaultFeatureFlagEnabled={ sunsetVaultFeatureFlagEnabled }
          />
        </>
      )}
      {
        (info?.isBusinessAdmin || info?.activatedEmployeeManagement) && (
          <Settings
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
        )
      }
      {
        (info?.isAccountingFirmUser
          || info?.hasFlaggedTransactionsAdvisorRole
          || info?.documentRequestServiceRole) && (
          <OpenItems
            activeItem={ activeItem }
            businessId={ businessId }
            focusToMyClient={ focusToMyClient }
            info={ info }
          />
        )
      }
    </div>
  );
};

export default ModuleHeaderActions;
